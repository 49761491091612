/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PageResultProductPricePlanDC } from '../models/page-result-product-price-plan-dc';
import { HardwarePhonesDetailsDC } from '../models/hardware-phones-details-dc';
import { ProfileAddonFeatureDC } from '../models/profile-addon-feature-dc';
import { AddonFeatureDC } from '../models/addon-feature-dc';
import { ProfileHardwareProductDC } from '../models/profile-hardware-product-dc';
import { ProfileHardwareByCategoryDC } from '../models/profile-hardware-by-category-dc';
import { DependentProductDC } from '../models/dependent-product-dc';
import { ProfileProductDC } from '../models/profile-product-dc';
import { BillingImpactDC } from '../models/billing-impact-dc';
import { QuotePriceRequestDC } from '../models/quote-price-request-dc';
@Injectable({
  providedIn: 'root',
})
class ProductsService extends __BaseService {
  static readonly ProductsGetProductPricePlansByAccountPagedPath = '/api/Account/{accountUuid}/ProductPricePlans';
  static readonly ProductsGetHardwarePhonesDetailsPath = '/api/Account/HardwarePhones/Details';
  static readonly ProductsGetProfileAddonsPath = '/api/Account/Locations/{locationUuid}/Products/{productId}/Addons';
  static readonly ProductsGetAddonsPath = '/api/Account/Locations/{locationUuid}/Products/Addons';
  static readonly ProductsGetProfileHardwarePath = '/api/Account/Locations/{locationUuid}/Products/Hardware';
  static readonly ProductsGetHardwareByCategoryPath = '/api/Account/Locations/{locationUuid}/Products/HardwareByCategory';
  static readonly ProductsGetDependentProductsPath = '/api/Account/Locations/{locationUuid}/Products/Options';
  static readonly ProductsGetProfileProductsPath = '/api/Account/Locations/{locationUuid}/Products/Profiles';
  static readonly ProductsQuotePurchasePricePath = '/api/Account/Locations/{locationUuid}/QuotePurchasePrice';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: SystemSyncCPQ (10341)
   * @param params The `ProductsService.ProductsGetProductPricePlansByAccountPagedParams` containing the following parameters:
   *
   * - `accountUuid`: account to query price plans for
   *
   * - `Authorization`: access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  ProductsGetProductPricePlansByAccountPagedResponse(params: ProductsService.ProductsGetProductPricePlansByAccountPagedParams): __Observable<__StrictHttpResponse<PageResultProductPricePlanDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Select != null) __params = __params.set('$select', params.Select.toString());
    if (params.OrderBy != null) __params = __params.set('$orderBy', params.OrderBy.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    if (params.Count != null) __params = __params.set('$count', params.Count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/${params.accountUuid}/ProductPricePlans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultProductPricePlanDC>;
      })
    );
  }
  /**
   * Required Permission: SystemSyncCPQ (10341)
   * @param params The `ProductsService.ProductsGetProductPricePlansByAccountPagedParams` containing the following parameters:
   *
   * - `accountUuid`: account to query price plans for
   *
   * - `Authorization`: access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  ProductsGetProductPricePlansByAccountPaged(params: ProductsService.ProductsGetProductPricePlansByAccountPagedParams): __Observable<PageResultProductPricePlanDC> {
    return this.ProductsGetProductPricePlansByAccountPagedResponse(params).pipe(
      __map(_r => _r.body as PageResultProductPricePlanDC)
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param Authorization access token
   * @return OK
   */
  ProductsGetHardwarePhonesDetailsResponse(Authorization: string): __Observable<__StrictHttpResponse<HardwarePhonesDetailsDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/HardwarePhones/Details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HardwarePhonesDetailsDC>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param Authorization access token
   * @return OK
   */
  ProductsGetHardwarePhonesDetails(Authorization: string): __Observable<HardwarePhonesDetailsDC> {
    return this.ProductsGetHardwarePhonesDetailsResponse(Authorization).pipe(
      __map(_r => _r.body as HardwarePhonesDetailsDC)
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetProfileAddonsParams` containing the following parameters:
   *
   * - `productId`: Product ID
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetProfileAddonsResponse(params: ProductsService.ProductsGetProfileAddonsParams): __Observable<__StrictHttpResponse<Array<ProfileAddonFeatureDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Products/${params.productId}/Addons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProfileAddonFeatureDC>>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetProfileAddonsParams` containing the following parameters:
   *
   * - `productId`: Product ID
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetProfileAddons(params: ProductsService.ProductsGetProfileAddonsParams): __Observable<Array<ProfileAddonFeatureDC>> {
    return this.ProductsGetProfileAddonsResponse(params).pipe(
      __map(_r => _r.body as Array<ProfileAddonFeatureDC>)
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetAddonsParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetAddonsResponse(params: ProductsService.ProductsGetAddonsParams): __Observable<__StrictHttpResponse<Array<AddonFeatureDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Products/Addons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AddonFeatureDC>>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetAddonsParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetAddons(params: ProductsService.ProductsGetAddonsParams): __Observable<Array<AddonFeatureDC>> {
    return this.ProductsGetAddonsResponse(params).pipe(
      __map(_r => _r.body as Array<AddonFeatureDC>)
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetProfileHardwareParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetProfileHardwareResponse(params: ProductsService.ProductsGetProfileHardwareParams): __Observable<__StrictHttpResponse<Array<ProfileHardwareProductDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Products/Hardware`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProfileHardwareProductDC>>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetProfileHardwareParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetProfileHardware(params: ProductsService.ProductsGetProfileHardwareParams): __Observable<Array<ProfileHardwareProductDC>> {
    return this.ProductsGetProfileHardwareResponse(params).pipe(
      __map(_r => _r.body as Array<ProfileHardwareProductDC>)
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetHardwareByCategoryParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetHardwareByCategoryResponse(params: ProductsService.ProductsGetHardwareByCategoryParams): __Observable<__StrictHttpResponse<Array<ProfileHardwareByCategoryDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Products/HardwareByCategory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProfileHardwareByCategoryDC>>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetHardwareByCategoryParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetHardwareByCategory(params: ProductsService.ProductsGetHardwareByCategoryParams): __Observable<Array<ProfileHardwareByCategoryDC>> {
    return this.ProductsGetHardwareByCategoryResponse(params).pipe(
      __map(_r => _r.body as Array<ProfileHardwareByCategoryDC>)
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetDependentProductsParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetDependentProductsResponse(params: ProductsService.ProductsGetDependentProductsParams): __Observable<__StrictHttpResponse<Array<DependentProductDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Products/Options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DependentProductDC>>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetDependentProductsParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetDependentProducts(params: ProductsService.ProductsGetDependentProductsParams): __Observable<Array<DependentProductDC>> {
    return this.ProductsGetDependentProductsResponse(params).pipe(
      __map(_r => _r.body as Array<DependentProductDC>)
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetProfileProductsParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetProfileProductsResponse(params: ProductsService.ProductsGetProfileProductsParams): __Observable<__StrictHttpResponse<Array<ProfileProductDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Products/Profiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProfileProductDC>>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsGetProfileProductsParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsGetProfileProducts(params: ProductsService.ProductsGetProfileProductsParams): __Observable<Array<ProfileProductDC>> {
    return this.ProductsGetProfileProductsResponse(params).pipe(
      __map(_r => _r.body as Array<ProfileProductDC>)
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsQuotePurchasePriceParams` containing the following parameters:
   *
   * - `request`: Request parameters
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsQuotePurchasePriceResponse(params: ProductsService.ProductsQuotePurchasePriceParams): __Observable<__StrictHttpResponse<BillingImpactDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/QuotePurchasePrice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingImpactDC>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `ProductsService.ProductsQuotePurchasePriceParams` containing the following parameters:
   *
   * - `request`: Request parameters
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ProductsQuotePurchasePrice(params: ProductsService.ProductsQuotePurchasePriceParams): __Observable<BillingImpactDC> {
    return this.ProductsQuotePurchasePriceResponse(params).pipe(
      __map(_r => _r.body as BillingImpactDC)
    );
  }
}

module ProductsService {

  /**
   * Parameters for ProductsGetProductPricePlansByAccountPaged
   */
  export interface ProductsGetProductPricePlansByAccountPagedParams {

    /**
     * account to query price plans for
     */
    accountUuid: string;

    /**
     * access token
     */
    Authorization: string;

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Select this field
     */
    Select?: string;

    /**
     * Order by this field
     */
    OrderBy?: string;

    /**
     * Odata filter param
     */
    Filter?: string;

    /**
     * Include the total item count
     */
    Count?: boolean;
  }

  /**
   * Parameters for ProductsGetProfileAddons
   */
  export interface ProductsGetProfileAddonsParams {

    /**
     * Product ID
     */
    productId: number;

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for ProductsGetAddons
   */
  export interface ProductsGetAddonsParams {

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for ProductsGetProfileHardware
   */
  export interface ProductsGetProfileHardwareParams {

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for ProductsGetHardwareByCategory
   */
  export interface ProductsGetHardwareByCategoryParams {

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for ProductsGetDependentProducts
   */
  export interface ProductsGetDependentProductsParams {

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for ProductsGetProfileProducts
   */
  export interface ProductsGetProfileProductsParams {

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for ProductsQuotePurchasePrice
   */
  export interface ProductsQuotePurchasePriceParams {

    /**
     * Request parameters
     */
    request: QuotePriceRequestDC;

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { ProductsService }
