/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OrderDC } from '../models/order-dc';
import { AddOrderDC } from '../models/add-order-dc';
import { CloseOrderDC } from '../models/close-order-dc';
import { BillingImpactDC } from '../models/billing-impact-dc';
import { OrderProcessingStatusDC } from '../models/order-processing-status-dc';
import { PageResultOrderDC } from '../models/page-result-order-dc';
import { OrderProcessingResultDC } from '../models/order-processing-result-dc';
import { OrderShippingSpeedsDaysDC } from '../models/order-shipping-speeds-days-dc';
@Injectable({
  providedIn: 'root',
})
class OrdersService extends __BaseService {
  static readonly OrdersGetOrdersByAccountPath = '/api/Account/Orders';
  static readonly OrdersCreateAddOrderPath = '/api/Account/Orders/Add';
  static readonly OrdersCreateCloseOrderPath = '/api/Account/Orders/Close';
  static readonly OrdersPreviewCloseOrderPath = '/api/Account/Orders/Close/Preview';
  static readonly OrdersGetOrderProcessingStatusPath = '/api/Account/Orders/ProcessingStatus';
  static readonly OrdersGetOrdersByAccountPagedPath = '/api/Account/OrdersPaged';
  static readonly OrdersGetOrderCloseReasonsPath = '/api/OrderCloseReasons';
  static readonly OrdersGetOrderProcessingStatusesPath = '/api/OrderProcessingStatuses';
  static readonly OrdersGetOrderRequestSourcesPath = '/api/OrderRequestSources';
  static readonly OrdersProcessAsyncPath = '/api/Orders/{orderId}/Process';
  static readonly OrdersGetOrderShippingSpeedsPath = '/api/OrderShippingSpeeds';
  static readonly OrdersGetOrderShippingSpeedsDaysPath = '/api/OrderShippingSpeedsDays';
  static readonly OrdersGetReportableOrderStatusesPath = '/api/OrderStatuses';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: AddUsers (221), ViewOrders (140), ViewBilling (158)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrdersByAccountResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<OrderDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrderDC>>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221), ViewOrders (140), ViewBilling (158)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrdersByAccount(Authorization: string): __Observable<Array<OrderDC>> {
    return this.OrdersGetOrdersByAccountResponse(Authorization).pipe(
      __map(_r => _r.body as Array<OrderDC>)
    );
  }

  /**
   * Required Permission: AddUsers (221), EditOrders (141)
   * @param params The `OrdersService.OrdersCreateAddOrderParams` containing the following parameters:
   *
   * - `createOrderDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  OrdersCreateAddOrderResponse(params: OrdersService.OrdersCreateAddOrderParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.createOrderDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Orders/Add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221), EditOrders (141)
   * @param params The `OrdersService.OrdersCreateAddOrderParams` containing the following parameters:
   *
   * - `createOrderDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  OrdersCreateAddOrder(params: OrdersService.OrdersCreateAddOrderParams): __Observable<{}> {
    return this.OrdersCreateAddOrderResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: AddUsers (221), EditOrders (141)
   * @param params The `OrdersService.OrdersCreateCloseOrderParams` containing the following parameters:
   *
   * - `createOrderDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  OrdersCreateCloseOrderResponse(params: OrdersService.OrdersCreateCloseOrderParams): __Observable<__StrictHttpResponse<OrderDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.createOrderDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Orders/Close`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderDC>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221), EditOrders (141)
   * @param params The `OrdersService.OrdersCreateCloseOrderParams` containing the following parameters:
   *
   * - `createOrderDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  OrdersCreateCloseOrder(params: OrdersService.OrdersCreateCloseOrderParams): __Observable<OrderDC> {
    return this.OrdersCreateCloseOrderResponse(params).pipe(
      __map(_r => _r.body as OrderDC)
    );
  }

  /**
   * Required Permission: AddUsers (221), EditOrders (141)
   * @param params The `OrdersService.OrdersPreviewCloseOrderParams` containing the following parameters:
   *
   * - `createOrderDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  OrdersPreviewCloseOrderResponse(params: OrdersService.OrdersPreviewCloseOrderParams): __Observable<__StrictHttpResponse<BillingImpactDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.createOrderDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Orders/Close/Preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingImpactDC>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221), EditOrders (141)
   * @param params The `OrdersService.OrdersPreviewCloseOrderParams` containing the following parameters:
   *
   * - `createOrderDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  OrdersPreviewCloseOrder(params: OrdersService.OrdersPreviewCloseOrderParams): __Observable<BillingImpactDC> {
    return this.OrdersPreviewCloseOrderResponse(params).pipe(
      __map(_r => _r.body as BillingImpactDC)
    );
  }

  /**
   * Required Permission: AddUsers (221), EditOrders (141)
   * @param params The `OrdersService.OrdersGetOrderProcessingStatusParams` containing the following parameters:
   *
   * - `orderIds`: Array of Order IDs
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  OrdersGetOrderProcessingStatusResponse(params: OrdersService.OrdersGetOrderProcessingStatusParams): __Observable<__StrictHttpResponse<Array<OrderProcessingStatusDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.orderIds || []).forEach(val => {if (val != null) __params = __params.append('orderIds', val.toString())});
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Orders/ProcessingStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrderProcessingStatusDC>>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221), EditOrders (141)
   * @param params The `OrdersService.OrdersGetOrderProcessingStatusParams` containing the following parameters:
   *
   * - `orderIds`: Array of Order IDs
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  OrdersGetOrderProcessingStatus(params: OrdersService.OrdersGetOrderProcessingStatusParams): __Observable<Array<OrderProcessingStatusDC>> {
    return this.OrdersGetOrderProcessingStatusResponse(params).pipe(
      __map(_r => _r.body as Array<OrderProcessingStatusDC>)
    );
  }

  /**
   * Required Permission: AddUsers (221), ViewOrders (140), ViewBilling (158)
   * @param params The `OrdersService.OrdersGetOrdersByAccountPagedParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  OrdersGetOrdersByAccountPagedResponse(params: OrdersService.OrdersGetOrdersByAccountPagedParams): __Observable<__StrictHttpResponse<PageResultOrderDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Select != null) __params = __params.set('$select', params.Select.toString());
    if (params.OrderBy != null) __params = __params.set('$orderBy', params.OrderBy.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    if (params.Count != null) __params = __params.set('$count', params.Count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/OrdersPaged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultOrderDC>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221), ViewOrders (140), ViewBilling (158)
   * @param params The `OrdersService.OrdersGetOrdersByAccountPagedParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  OrdersGetOrdersByAccountPaged(params: OrdersService.OrdersGetOrdersByAccountPagedParams): __Observable<PageResultOrderDC> {
    return this.OrdersGetOrdersByAccountPagedResponse(params).pipe(
      __map(_r => _r.body as PageResultOrderDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrderCloseReasonsResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OrderCloseReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrderCloseReasons(Authorization: string): __Observable<{[key: string]: string}> {
    return this.OrdersGetOrderCloseReasonsResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrderProcessingStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OrderProcessingStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrderProcessingStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.OrdersGetOrderProcessingStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrderRequestSourcesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OrderRequestSources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrderRequestSources(Authorization: string): __Observable<{[key: string]: string}> {
    return this.OrdersGetOrderRequestSourcesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: AddUsers (221), EditOrders (141)
   * @param params The `OrdersService.OrdersProcessAsyncParams` containing the following parameters:
   *
   * - `orderId`: Order ID.
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  OrdersProcessAsyncResponse(params: OrdersService.OrdersProcessAsyncParams): __Observable<__StrictHttpResponse<OrderProcessingResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Orders/${params.orderId}/Process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderProcessingResultDC>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221), EditOrders (141)
   * @param params The `OrdersService.OrdersProcessAsyncParams` containing the following parameters:
   *
   * - `orderId`: Order ID.
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  OrdersProcessAsync(params: OrdersService.OrdersProcessAsyncParams): __Observable<OrderProcessingResultDC> {
    return this.OrdersProcessAsyncResponse(params).pipe(
      __map(_r => _r.body as OrderProcessingResultDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrderShippingSpeedsResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OrderShippingSpeeds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrderShippingSpeeds(Authorization: string): __Observable<{[key: string]: string}> {
    return this.OrdersGetOrderShippingSpeedsResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrderShippingSpeedsDaysResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<OrderShippingSpeedsDaysDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OrderShippingSpeedsDays`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrderShippingSpeedsDaysDC>>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetOrderShippingSpeedsDays(Authorization: string): __Observable<Array<OrderShippingSpeedsDaysDC>> {
    return this.OrdersGetOrderShippingSpeedsDaysResponse(Authorization).pipe(
      __map(_r => _r.body as Array<OrderShippingSpeedsDaysDC>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetReportableOrderStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/OrderStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  OrdersGetReportableOrderStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.OrdersGetReportableOrderStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }
}

module OrdersService {

  /**
   * Parameters for OrdersCreateAddOrder
   */
  export interface OrdersCreateAddOrderParams {
    createOrderDC: AddOrderDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for OrdersCreateCloseOrder
   */
  export interface OrdersCreateCloseOrderParams {
    createOrderDC: CloseOrderDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for OrdersPreviewCloseOrder
   */
  export interface OrdersPreviewCloseOrderParams {
    createOrderDC: CloseOrderDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for OrdersGetOrderProcessingStatus
   */
  export interface OrdersGetOrderProcessingStatusParams {

    /**
     * Array of Order IDs
     */
    orderIds: Array<number>;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for OrdersGetOrdersByAccountPaged
   */
  export interface OrdersGetOrdersByAccountPagedParams {

    /**
     * access token
     */
    Authorization: string;

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Select this field
     */
    Select?: string;

    /**
     * Order by this field
     */
    OrderBy?: string;

    /**
     * Odata filter param
     */
    Filter?: string;

    /**
     * Include the total item count
     */
    Count?: boolean;
  }

  /**
   * Parameters for OrdersProcessAsync
   */
  export interface OrdersProcessAsyncParams {

    /**
     * Order ID.
     */
    orderId: number;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { OrdersService }
