/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CustomerInvoicesResponseDC } from '../models/customer-invoices-response-dc';
import { InvoiceGroupGridDC } from '../models/invoice-group-grid-dc';
import { EditInvoiceGroupDC } from '../models/edit-invoice-group-dc';
@Injectable({
  providedIn: 'root',
})
class BillingService extends __BaseService {
  static readonly BillingGetCustomerInvoicesPath = '/api/Account/Billing/CustomerInvoices';
  static readonly BillingGetCustomerInvoicesFilePath = '/api/Account/Billing/CustomerInvoices/year/{year}/month/{month}';
  static readonly BillingGetInvoiceGroupPath = '/api/Account/Billing/InvoiceGroup';
  static readonly BillingEditInvoiceGroupPath = '/api/Account/Billing/InvoiceGroup/{uuid}';
  static readonly BillingGetInvoiceGroupListPath = '/api/Account/Billing/ListInvoiceGroups';
  static readonly BillingGetAccountBillingSummaryPath = '/api/Account/Billing/Summary';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: SDApiReadCustomerInvoices (356)
   * @param Authorization Access token
   * @return OK
   */
  BillingGetCustomerInvoicesResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CustomerInvoicesResponseDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Billing/CustomerInvoices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CustomerInvoicesResponseDC>>;
      })
    );
  }
  /**
   * Required Permission: SDApiReadCustomerInvoices (356)
   * @param Authorization Access token
   * @return OK
   */
  BillingGetCustomerInvoices(Authorization: string): __Observable<Array<CustomerInvoicesResponseDC>> {
    return this.BillingGetCustomerInvoicesResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CustomerInvoicesResponseDC>)
    );
  }

  /**
   * Required Permission: SDApiReadCustomerInvoices (356)
   * @param params The `BillingService.BillingGetCustomerInvoicesFileParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `month`:
   *
   * - `Authorization`: Access token
   *
   * @return File response
   */
  BillingGetCustomerInvoicesFileResponse(params: BillingService.BillingGetCustomerInvoicesFileParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Billing/CustomerInvoices/year/${params.year}/month/${params.month}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Required Permission: SDApiReadCustomerInvoices (356)
   * @param params The `BillingService.BillingGetCustomerInvoicesFileParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `month`:
   *
   * - `Authorization`: Access token
   *
   * @return File response
   */
  BillingGetCustomerInvoicesFile(params: BillingService.BillingGetCustomerInvoicesFileParams): __Observable<Blob> {
    return this.BillingGetCustomerInvoicesFileResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Required Permission: ViewInvoiceGroups (10302)
   * @param params The `BillingService.BillingGetInvoiceGroupParams` containing the following parameters:
   *
   * - `invoiceGroupId`: Invoice Group ID
   *
   * - `Authorization`: Access token
   */
  BillingGetInvoiceGroupResponse(params: BillingService.BillingGetInvoiceGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.invoiceGroupId != null) __params = __params.set('invoiceGroupId', params.invoiceGroupId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Billing/InvoiceGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Required Permission: ViewInvoiceGroups (10302)
   * @param params The `BillingService.BillingGetInvoiceGroupParams` containing the following parameters:
   *
   * - `invoiceGroupId`: Invoice Group ID
   *
   * - `Authorization`: Access token
   */
  BillingGetInvoiceGroup(params: BillingService.BillingGetInvoiceGroupParams): __Observable<null> {
    return this.BillingGetInvoiceGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Required Permission: EditInvoiceGroups (185)
   * @param params The `BillingService.BillingEditInvoiceGroupParams` containing the following parameters:
   *
   * - `uuid`:
   *
   * - `invoiceGroupId`: Invoice Group ID
   *
   * - `editInvoiceGroupDC`: Invoice Group data
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  BillingEditInvoiceGroupResponse(params: BillingService.BillingEditInvoiceGroupParams): __Observable<__StrictHttpResponse<Array<InvoiceGroupGridDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.invoiceGroupId != null) __params = __params.set('invoiceGroupId', params.invoiceGroupId.toString());
    __body = params.editInvoiceGroupDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/Billing/InvoiceGroup/${params.uuid}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<InvoiceGroupGridDC>>;
      })
    );
  }
  /**
   * Required Permission: EditInvoiceGroups (185)
   * @param params The `BillingService.BillingEditInvoiceGroupParams` containing the following parameters:
   *
   * - `uuid`:
   *
   * - `invoiceGroupId`: Invoice Group ID
   *
   * - `editInvoiceGroupDC`: Invoice Group data
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  BillingEditInvoiceGroup(params: BillingService.BillingEditInvoiceGroupParams): __Observable<Array<InvoiceGroupGridDC>> {
    return this.BillingEditInvoiceGroupResponse(params).pipe(
      __map(_r => _r.body as Array<InvoiceGroupGridDC>)
    );
  }

  /**
   * Required Permission: ViewInvoiceGroups (10302)
   * @param Authorization Access token
   */
  BillingGetInvoiceGroupListResponse(Authorization: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Billing/ListInvoiceGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Required Permission: ViewInvoiceGroups (10302)
   * @param Authorization Access token
   */
  BillingGetInvoiceGroupList(Authorization: string): __Observable<null> {
    return this.BillingGetInvoiceGroupListResponse(Authorization).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Required Permission: ViewBilling (158)
   * @param Authorization Access token
   */
  BillingGetAccountBillingSummaryResponse(Authorization: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Billing/Summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Required Permission: ViewBilling (158)
   * @param Authorization Access token
   */
  BillingGetAccountBillingSummary(Authorization: string): __Observable<null> {
    return this.BillingGetAccountBillingSummaryResponse(Authorization).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BillingService {

  /**
   * Parameters for BillingGetCustomerInvoicesFile
   */
  export interface BillingGetCustomerInvoicesFileParams {
    year: number;
    month: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for BillingGetInvoiceGroup
   */
  export interface BillingGetInvoiceGroupParams {

    /**
     * Invoice Group ID
     */
    invoiceGroupId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for BillingEditInvoiceGroup
   */
  export interface BillingEditInvoiceGroupParams {
    uuid: string;

    /**
     * Invoice Group ID
     */
    invoiceGroupId: number;

    /**
     * Invoice Group data
     */
    editInvoiceGroupDC: EditInvoiceGroupDC;

    /**
     * Access token
     */
    Authorization: string;
  }
}

export { BillingService }
