import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {CallflowAdaptorService} from '../../services/callflow-adaptor.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-prompt-uploader',
  templateUrl: './prompt-uploader.component.html',
  styleUrls: ['./prompt-uploader.component.scss']
})
export class PromptUploaderComponent implements OnInit {
  @Input() title = 'prompt_model.title';
  @Input() cancelText = 'prompt_model.cancel';
  @Input() retryText = 'prompt_model.retry';
  @Input() enableContent1 = false;
  @Input() enableContent2 = false;
  @Input() mainBlock = true;
  @Input() autoAttentantId: '';
  @Input() timeType: '';
  @Output() promptData: EventEmitter<any> = new EventEmitter();
  fileName = '';
  fileSelect = true;
  error = '';
  showConverter = false;
  showUploader = false;
  enableCancel = true;
  noFileChosen = true;
  @ViewChild('myFile') fileElement: ElementRef;
  private uploadSubscriptions: Subscription = new Subscription();
  private conversionSubscriptions: Subscription = new Subscription();
  static validateAudioFormat(file) {
    // Validate Audio File Type (allows only mp3 or wav)
    const audioFormat = file.name.split('.').pop();
    console.log(`Audio Format is ${audioFormat}`);
    return audioFormat === 'mp3' || audioFormat === 'wav' || audioFormat === 'wave';
  }

  static validateAudioSize(file) {
    // Validate Audio File size (allows file size less than or equal to 3 MB)
    console.log('File Type', file.type);
    const size = Math.round(file.size / 1024);
    console.log(`Audio Size is ${size}`);
    return size <= 3072 ;
  }

  constructor(public translateSvc: TranslateService,
              private activeModal: NgbActiveModal,
              private aaSvc: CallflowAdaptorService) { }

  ngOnInit() {
  }

  cancel() {
    this.uploadSubscriptions.unsubscribe();
    this.conversionSubscriptions.unsubscribe();
    this.activeModal.close(false);
    this.showUploader = false;
    this.showConverter = false;
  }

  retry() {
    this.fileElement.nativeElement.value = '';
    this.enableContent1 = false;
    this.enableContent2 = false;
    this.enableCancel = true;
    this.title = 'prompt_model.title';
    this.fileSelect = true;
    this.noFileChosen = true;
  }

  fetchFile(files) {
    this.error = '';
    const _this = this;
    const f = files[0]; // FileList object
    this.fileSelect = true;
    if (files.length > 0) {
      this.noFileChosen = false;
      const validFormat = PromptUploaderComponent.validateAudioFormat(f); // Validate Audio File Type
      const validSize = PromptUploaderComponent.validateAudioSize(f); // Validate Audio File Size
      if (validFormat && validSize) {
        this.fileName = f.name + '...';
        const reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function(_) {
          return function(e) {
            const binaryData = e.target.result;
            // Converting Binary Data to base 64
            const base64String = window.btoa(binaryData);
            // showing file converted to base64
            console.log('File converted to base64 successfuly');
            console.log('Audio Undergone for Conversion');
            _this.showConverter = true;
            _this.sentToConversion(base64String);
          };
        })(f);
        reader.readAsBinaryString(f);
      } else {
        this.enableContent1 = true;
        this.enableCancel = false;
        this.fileSelect = false;
      }
    }
  }

  sendToBackend(content) {
    this.showUploader = true;
    this.uploadSubscriptions = this.aaSvc.createRecordings(this.autoAttentantId, this.timeType, content)
        .subscribe(
            result => {
              this.promptData.emit(JSON.parse(result));
              this.cancel();
            },
            error => {
              console.error(error);
              // this.error = JSON.parse(error.error)['message'];
              this.fileSelect = false;
              this.showUploader = false;
              this.title = 'prompt_model.error_title';
              this.enableContent2 = true;
              this.enableCancel = false;
            },
        );
  }

  sentToConversion(content) {
    // this.error = 'Under Implementation For mp3 conversion';
    this.conversionSubscriptions =  this.aaSvc.transcode(content, 'mulaw')
        .subscribe(
            result => {
              this.showConverter = false;
              this.sendToBackend(result['content']);
            },
            error => {
              console.error(error);
              // this.error = JSON.parse(error.error)['message'];
              this.fileSelect = false;
              this.showConverter = false;
              this.title = 'prompt_model.error_title';
              if (error.status === 500) {
                this.enableContent2 = true;
              } else {
                this.enableContent1 = true;
              }
              this.enableCancel = false;
            },
        );
  }

  triggerOnLoad() {
    this.fileElement.nativeElement.click();
  }
}
