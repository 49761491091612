import { PhoneNumberFormatService } from './phone-number-format.service';
import { Injectable } from '@angular/core';
import { Location, LocationAdaptorService } from './location-adaptor.service';
import {
  AddressService,
  EmergencyService,
  PhoneSettingsService,
  UsersService,
  UsersService as BossApiUsersService
} from '../modules/boss-api/generated/services';
import { OrdersService as BossApiOrdersService } from '../modules/boss-api/generated/services';
import {
  AddressService as AddressServicePrevious,
  EmergencyService as EmergencyServicePrevious,
  PhoneSettingsService as PhoneSettingsServicePrevious,
  UsersService as BossApiUsersServicePrevious
} from '../modules/boss-api-previous/generated/services';
import { OrdersService as BossApiOrdersServicePrevious } from '../modules/boss-api-previous/generated/services';
import { SDApiVersionControlService } from 'src/app/services/sdapi-version-control';
import {
  PersonDC,
  CreatePersonDC,
  EditPersonDC,
  CreateProfileDC,
  UserDC,
  ProfileDC,
  OrderDC,
  SwapUserProductDC,
  ResetPinDC,
  ProfileAddressDC,
  EmergencyRegistrationDC,
  UpdateProfileRegistrationDC,
  AddressDC,
  CreateUserResultDC,
  ValidateAddressResultDC, AddressSchemaDC, E911ConsentDC
} from '../modules/boss-api/generated/models';

import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import UsersGetUsersByAccountParams = UsersService.UsersGetUsersByAccountParams;
import PhoneSettingsResetPinParams = PhoneSettingsService.PhoneSettingsResetPinParams;
import EmergencyGetProfileAddressesParams = EmergencyService.EmergencyGetProfileAddressesParams;
import EmergencyUpdateProfileEmergencyRegistrationParams = EmergencyService.EmergencyUpdateProfileEmergencyRegistrationParams;
import { HttpErrorResponse } from '@angular/common/http';
import { Error } from 'tslint/lib/error';
import { TranslateService } from '@ngx-translate/core';
import UsersAssignNewPersonParams = UsersService.UsersAssignNewPersonParams;

/**
 * Below is an email outlying the Boss side role mapping enum.
 *
 Andrew Shurak
 Mar 1, 2019, 9:53 AM
 Hi Don, below is a list of the roles in our db, access_Role table. I believe that I previously sent Roy the list
 in more of a JSON format (on a call right now, trying to multi-task so sending what I have right at hand).

 Only Decision Maker and Billing (ids 1 and 3) will be used for Console right now.

 1              Decision Maker
 2              Phone Manager
 3              Billing
 4              Emergency
 5              Root
 6              M5 Staff
 7              Partner
 8              Technical
 10           M5 Staff Read-only
 15           DataExport
 16           Phone User
 17           Team Lead
 18           IT Consultant
 20           PortalUser

 Note, in email update on Mar 28,2019, Andrew added
 "The list I sent you was from the m5db..access_role table, which also matches the Role.Values.cs file in Portal."
 *
 */
export enum UserRole {
  // left side is app's front-end model's key
  // right side is back-end Boss role id
  ADMIN = 1,      //  1              Decision Maker
  BILLING = 3,     //  3              Billing
  STAFF = 6       // 6                MS Staff
}

// Indicates if a user is selected, and if so, whether the user's profile has any orders in an pending or errored state.
export enum UserState {
  NONE = 0,
  NORMAL = 1,
  PENDING = 2,
  ERROR = 3
}

export enum OrderStatuses {
  SUBMITTED = 'Submitted',
  COMPLETED = 'Completed',
  CANCELED = 'Canceled',
  IN_PROGRESS = 'In Progress'
}

export class User {
  bossId: number;
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  bossLocationId: number;
  locationId: string;
  locationName: string;
  phoneDisplay: string;
  email: string;
  loginId: string;            // aka username
  roles: UserRole[];
  accountId: string;
  accountUuid: string;
  plan: string;
  tokenValue?: string;    // for that ADMIN badge on users-dashboard list
  phoneNumber?: string;
  countryCode?: number;
  extension?: string;
  partitionId?: number;
  planDisplay?: string;
  mobileNumber?: string;
  profile?: ProfileDC = null;
  profileState?: number;
}


export interface CreateUser {
  locationId?: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password?: string;
  mobilePhone: string;
  id?: string;
  roles?: UserRole[];
}

export interface CreateUserProfile {
  productId: number;
  tn?: string;
  extension: string;
  requestedByPersonUuid?: string;
  requestSourceId?: 0;
  caseNumber?: 0;
  addonProducts: number[];
}

export interface CreatePersonWithProfile {
  person: CreatePersonDC;
  profile: CreateUserProfile;
}


export interface emergencyAddress {
  type: string,
  address: {
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    address5: string,
    address6: string,
    address7: string,
    address8: string,
    subPremises: string,
    dependentLocality: string,
    city: string,
    stateCodeAlpha: string,
    zipCode: string,
    countryId: number,
    countryName: string
  }
}


/**
 * Manages  Front End User objects by mapping to server-side REST API calls.
 */
@Injectable({
  providedIn: 'root'
})

export class UserAdaptorService {

  private readonly authToken = null;  // empty so that our  auth interceptor will handle this. Else should be 'Bearer <token>'
  addressService: AddressService | AddressServicePrevious;
  emergencyService: EmergencyService | EmergencyServicePrevious;
  bossOrdersService: BossApiOrdersService | BossApiOrdersServicePrevious;
  bossUsersService: BossApiUsersService | BossApiUsersServicePrevious;
  phoneSettingsSvc: PhoneSettingsService | PhoneSettingsServicePrevious;

  constructor(private locSvc: LocationAdaptorService,
    private pnService: PhoneNumberFormatService,
    private translationService: TranslateService,
    private sdapiVersion: SDApiVersionControlService) {
    this.addressService = this.sdapiVersion.addressService;
    this.emergencyService = this.sdapiVersion.emergencyService;
    this.bossOrdersService = this.sdapiVersion.ordersService;
    this.bossUsersService = this.sdapiVersion.usersService;
    this.phoneSettingsSvc = this.sdapiVersion.phoneSettingsService;
  }

  getUsers(accountId: string, includeOrders: boolean): Observable<User[]> {
    const params: UsersGetUsersByAccountParams = {
      Authorization: this.authToken,
      includeOrders: includeOrders
    };

    return this.bossUsersService.UsersGetUsersByAccount(params)
      .pipe(
        // mergeMap
        // wait for promise to resolve, then return resolved result. In this case, users. See  https://stackoverflow.com/a/53650135
        mergeMap(async users => {
          return await this.makeFullUsers(accountId, users); // returns a Promise

        })
      );
  }

  public createUserForAccount(accountId: string, userData: CreateUser): Observable<User> {

    const createPersonDC: CreatePersonDC = {
      locationUuid: userData.locationId,
      username: userData.username,
      businessEmail: userData.email,
      password: userData.password,
      firstName: userData.firstName,
      lastName: userData.lastName,
      mobilePhone: userData.mobilePhone || '',
      roles: userData.roles || []
    };

    return this.bossUsersService.UsersCreatePerson({ createPersonDC: createPersonDC, Authorization: this.authToken })
      .pipe(
        // convert to User object
        mergeMap(createPersonResponse => {

          return this.makeUsers(accountId, [createPersonResponse])
            .then(users => {
              return Promise.resolve(users[0]);
            });
        })
      );
  }


  public updateUser(accountId: string, userId: string, userData: CreateUser): Observable<User> {

    const editPersonDC: EditPersonDC = {
      username: userData.username,
      businessEmail: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      mobilePhone: userData.mobilePhone,
      locationUuid: userData.locationId,
      roles: userData.roles
    };

    // 1st, edit
    // 2nd, reformat output to User object

    return this.bossUsersService.UsersEditPerson({ uuid: userId, editPersonDC: editPersonDC, Authorization: this.authToken })
      .pipe(
        // edit
        mergeMap(apiResponsePersonDC => {
          return this.makeUsers(accountId, [apiResponsePersonDC])
            .then(users => {
              return Promise.resolve(users[0]);
            });
        })
      );
  }


  public deleteUser(userId: string): Observable<void> {

    return this.bossUsersService.UsersDeletePerson({ uuid: userId, Authorization: this.authToken })
      .pipe(
        // tap(apiResponse => {
        //    if ( ! apiResponse.isSuccess) {
        //      throw new Error(this.parseApiError(apiResponse));
        //    }
        //  }),

        map(apiResponse => {
          return; // void
        })
      );
  }

  /**
   *
   * @param person Build a User object from various inputs.
   * @param location
   */
  private makeUser(person: PersonDC, location: Location): User {
    if (!person.roles) { person.roles = []; }
    const user: User = {
      firstName: person.firstName || 'noFirst',
      lastName: person.lastName || 'noLast',
      displayName: this.processUserDisplayName(person.firstName, person.lastName),
      email: person.businessEmail || '',
      loginId: person.username,
      accountId: person.accountId ? person.accountId.toString() : '0',
      id: person.uuid ? person.uuid : person.id ? person.id.toString() : '',   // or id  ???
      bossId: person.id || 0,
      locationId: location ? location.value : '',
      bossLocationId: person.locationId || 0,
      locationName: location ? location.displayName : '',
      phoneDisplay: '',
      roles: person.roles ? person.roles : [],
      plan: '',
      mobileNumber: person.mobilePhone,
      accountUuid: person.accountUuid,
      // tslint:disable-next-line
      tokenValue: (person.roles && person.roles.includes(UserRole.BILLING)) ? 'users_list.billing_admin_token' : '' // for that BILLING badge on user list
    };

    if (user.roles.includes(UserRole.ADMIN)) {
      user.tokenValue = 'users_list.account_admin_token'; // for that ADMIN badge on user list
    }
    return user;
  }

  makeUsers(accountId: string, persons: PersonDC[]): Promise<User[]> {

    // get locations
    // then update location property of each user
    return this.locSvc.getLocations(accountId)
      .toPromise().then(locations => {

        const users: User[] = [];
        persons.forEach(person => {
          users.push(this.makeUser(person, locations.find(element => element.value === person.locationUuid)));
        });
        this.processPhoneNumber(users);
        return users;
      },
        error => {
          console.error(error);
          throw new Error('Failure fetching user location: ' + error.message);
        });
  }

  private makeFullUser(userDC: UserDC, location: Location): User {
    // console.log("User plan", userDC.profile ? userDC.profile.id : 'none');
    let unAssigned = '';
    this.translationService.get('users_list').subscribe(value => unAssigned = value.Unassigned).unsubscribe();
    const user: User = {
      firstName: userDC.person.firstName || 'noFirst',
      lastName: userDC.person.lastName || 'noLast',
      displayName: this.processUserDisplayName(userDC.person.firstName, userDC.person.lastName),
      email: userDC.person.businessEmail || '',
      loginId: (userDC.person.username !== null && userDC.person.uuid !== null) ? userDC.person.username : unAssigned,
      accountId: userDC.person.accountId ? userDC.person.accountId.toString() : '0',
      accountUuid: userDC.person.accountUuid ? userDC.person.accountUuid : '',
      id: userDC.person.uuid ? userDC.person.uuid : userDC.person.id ? userDC.person.id.toString() : '',   // or id  ???
      bossId: userDC.person.id || 0,
      locationId: location ? location.value : '',
      bossLocationId: userDC.person.locationId || 0,
      locationName: location ? location.displayName : '',
      phoneDisplay: '',
      roles: (userDC.person && userDC.person.roles) ? userDC.person.roles : [],
      phoneNumber: userDC.profile ? userDC.profile.tnId || '' : '',
      countryCode: userDC.profile ? userDC.profile.tnCountryId || 0 : 0,
      extension: userDC.profile ? userDC.profile.extension || '' : '',
      partitionId: userDC.profile ? userDC.profile.partitionId || 0 : 0,
      plan: userDC.profile ? userDC.profile.productShortName || '' : '',
      mobileNumber: userDC.person.mobilePhone,
      tokenValue: (userDC.person.roles && userDC.person.roles.includes(UserRole.BILLING)) ? 'users_list.billing_admin_token' : '', // for that BILLING badge on user list
      profileState: UserState.NORMAL
    };
    if (userDC.profile) {
      user.profile = userDC.profile;
    }

    if (user.roles.includes(UserRole.ADMIN)) {
      user.tokenValue = 'users_list.account_admin_token'; // for that ADMIN badge on user list
    }

    return user;
  }

  private async makeFullUsers(accountId: string, usersDC: UserDC[]): Promise<User[]> {

    // get locations
    // then update location property of each user
    return this.locSvc.getLocations(accountId)
      .toPromise().then(async locations => {

        const users: User[] = [];
        usersDC.forEach(user => {
          const newUser = this.makeFullUser(user, locations.find(element => element.value === user.person.locationUuid));
          users.push(newUser);
        });
        this.processPhoneNumber(users);
        await this.processOrders(users);
        return users;
      },
        error => {
          console.error(error);
          throw new Error('Failure fetching user location: ' + error.message);
        });
  }


  processUserDisplayName(firstName: string, lastName: string) {
    return (lastName ? lastName.toUpperCase() + ', ' : '') + (firstName ? firstName.toUpperCase() : '');  // LAST, FIRST
  }

  async processPhoneNumber(users: User[]) {
    for (const user of users) {
      if (user.phoneNumber && (user.phoneNumber !== '')) {
        const formattedpn = await this.pnService.formatPhoneNumberUsingIso3166NumericCountryCode(user.phoneNumber, user.countryCode);
        user.phoneDisplay = formattedpn + ' x' + user.extension;
      } else if (user.extension && (user.extension !== '')) {
        user.phoneDisplay = 'x' + user.extension;
      } else {
        user.phoneDisplay = '';
      }
    }
  }


  /**
   * Get all roles this person has directly (no roles by group member are checked for)
   * @param userUuid
   */
  public getUserRole(userUuid: string, accountId?: string): Observable<UserRole[]> {
    return this.bossUsersService.UsersGetPersonRoles({
      userUuid: userUuid,
      Authorization: this.authToken,
      XMitelSDAPITargetAccount: accountId
    })
      .pipe(
        map(data => data as Array<UserRole>)
      );

  }

  //original for reference
  // getUsersHighestRole(userId: string): Promise<UserRole> {
  //   return this.getUserRole(userId)
  //     .toPromise().then(roles => {
  //         if (roles.includes(UserRole.STAFF)) {
  //           return UserRole.STAFF;
  //         } else if (roles.includes(UserRole.ADMIN)) {
  //           return UserRole.ADMIN;
  //         } else if (roles.includes(UserRole.BILLING)) {
  //           return UserRole.BILLING;
  //         } else {
  //           console.log('there is no valid role');
  //           return null;
  //         }
  //       },
  //       error => {
  //         console.log('the user is not found on the boss system, so no role');
  //         return null;
  //       });
  // }

  //above is the original correct way to approach this. But the BOSS API can not return the role for a user once its role
  // has been assumed, so below is a work around. The app will use the cloudlink claims and convert it, and if it is USER,
  // then it will ask BOSS for the role.

  getUsersHighestRole(userId: string, role: string): Promise<UserRole> {
    if (role == 'PARTNER_ADMIN')
      return Promise.resolve(UserRole.ADMIN);

    if (role == 'ACCOUNT_ADMIN')
      return Promise.resolve(UserRole.ADMIN);

    return this.getUserRole(userId)
      .toPromise().then(roles => {
        if (roles.includes(UserRole.STAFF)) {
          if (!roles.includes(UserRole.ADMIN)) {
            roles.push(UserRole.ADMIN);
          }
          return UserRole.ADMIN;
        } else if (roles.includes(UserRole.ADMIN)) {
          return UserRole.ADMIN;
        } else if (roles.includes(UserRole.BILLING)) {
          return UserRole.BILLING;
        } else {
          console.log('there is no valid role');
          return null;
        }
      },
        error => {
          console.log('the user is not found on the boss system, so no role');
          return null;
        });
  }


  convertBossRoleToCloudLinkRole(role: UserRole) {
    if (role === UserRole.STAFF) {
      return 'PARTNER_ADMIN';
    } else if (role === UserRole.ADMIN) {
      return 'ACCOUNT_ADMIN';
    } else if (role === UserRole.BILLING) {
      return 'BILLING_ADMIN';
    } else {
      return 'USER';
    } // users are not defined. All other boss roles are users
  }

  /*
  // This was the work around for creating a user
  */
  // public createUserWithProfile(accountId: string, userData: CreateUser, profileData: CreateUserProfile): Observable<User> {
  //
  //   const createUserDC: CreateProfileDC = {
  //     productId: profileData.productId,
  //     tn: profileData.tn,
  //     extension: profileData.extension,
  //   };
  //
  //   return this.createUserForAccount(accountId, userData).pipe(mergeMap(createPersonResponse => {
  //     const personUuid = createPersonResponse.id;
  //     return this.bossUsersService.UsersCreateProfile({
  //       personUuid: personUuid,
  //       createProfileDC: createUserDC, Authorization: null
  //     }).toPromise().then(() => {
  //       return Promise.resolve(createPersonResponse);
  //     });
  //   }));
  // }

  public createPersonWithProfile(accountId: string, userData: CreatePersonWithProfile): Observable<CreateUserResultDC> {
    return this.bossUsersService.UsersCreateUser({ createUserDC: userData, Authorization: null });
  }


  public addProfileToAUserOnEdit(personUuid: string, profileData: CreateUserProfile): Observable<{}> {
    const createUserDC: CreateProfileDC = {
      productId: profileData.productId,
      tn: profileData.tn,
      extension: profileData.extension,
      addonProducts: profileData.addonProducts
    };
    return this.bossUsersService.UsersCreateProfile({
      personUuid: personUuid,
      createProfileDC: createUserDC, Authorization: null
    });
  }

  public swapProductOrderOnEdit(userUuid: string, swapProduct: SwapUserProductDC, profileId: number): Observable<OrderDC> {
    const swapUserProductDC: SwapUserProductDC = {
      productId: swapProduct.productId,
      addonProducts: []
    };
    return this.bossUsersService.UsersSwapProduct({
      userUuid: userUuid,
      swapUserProductDC: swapUserProductDC, profileId: profileId, Authorization: null
    });
  }

  public async getUser(userUuid: string, accountId?: string): Promise<User> {
    const userDC = await this.bossUsersService.UsersGetUser({
      uuid: userUuid,
      Authorization: null,
      XMitelSDAPITargetAccount: accountId
    }).toPromise();
    const locations = await this.locSvc.getLocations(userDC.person.accountUuid).toPromise();
    const user = this.makeFullUser(userDC, locations.find(element => element.value === userDC.person.locationUuid));
    await this.processPhoneNumber([user]);
    await this.processOrders([user]);
    return user;
  }

  public getPersonalEmergencyAddresses(profileId: number): Observable<ProfileAddressDC[]> {
    const emergencyAddressParams: EmergencyGetProfileAddressesParams = {
      profileId: profileId,
      Authorization: null
    };
    return this.emergencyService.EmergencyGetProfileAddresses(emergencyAddressParams);
  }

  public updateUserEmergencyAddress(profileId: number, locationUuid: string): Observable<EmergencyRegistrationDC> {
    const update: UpdateProfileRegistrationDC = {
      profileId: profileId,
      locationUuid: locationUuid,
    };
    const emergencyAddressParams: EmergencyUpdateProfileEmergencyRegistrationParams = {
      profileRegistration: update,
      Authorization: null
    };
    return this.emergencyService.EmergencyUpdateProfileEmergencyRegistration(emergencyAddressParams);
  }

  public updateUserPersonalEmergencyAddress(profileId: number, locationUuid: string, selectedAddress: AddressDC): Observable<EmergencyRegistrationDC> {
    const update: UpdateProfileRegistrationDC = {
      profileId: profileId,
      locationUuid: locationUuid,
      address: selectedAddress
    };
    const emergencyAddressParams: EmergencyUpdateProfileEmergencyRegistrationParams = {
      profileRegistration: update,
      Authorization: null
    };
    return this.emergencyService.EmergencyUpdateProfileEmergencyRegistration(emergencyAddressParams);
  }

  public resetPin(resetPinParams: PhoneSettingsResetPinParams): Observable<ResetPinDC> {
    const params: PhoneSettingsResetPinParams = {
      profileId: resetPinParams.profileId,
      reset: resetPinParams.reset,
      useDefaultPin: resetPinParams.useDefaultPin,
      notifyUser: resetPinParams.notifyUser,
      emailList: resetPinParams.emailList,
      newPin: resetPinParams.newPin,
      Authorization: null
    };
    return this.phoneSettingsSvc.PhoneSettingsResetPin(params);
  }

  addPersonalAddressToProfile(address: AddressDC, profileId: number): Observable<EmergencyRegistrationDC> {

    const params: EmergencyUpdateProfileEmergencyRegistrationParams = {
      profileRegistration: {
        profileId: profileId,
        address: address,
        locationUuid: ''
      },
      Authorization: null
    };

    return this.emergencyService.EmergencyUpdateProfileEmergencyRegistration(params);
  }

  validateAddress(address: AddressDC): Observable<ValidateAddressResultDC> {
    const params = {
      address: address,
      Authorization: null
    };

    return this.addressService.AddressValidateAddress(params);
  }

  getAddressSchema(countryId: number): Observable<AddressSchemaDC> {
    const params = {
      countryId: countryId,
      Authorization: null
    };

    return this.addressService.AddressGetAddressSchema(params);
  }


  async processOrders(users: User[]) {
    const submittedOrders: {
      user: User,
      id: number
    }[] = [];
    for (const user of users) {
      if (user.profile && user.profile.orders) {
        for (const order of user.profile.orders) {
          if ((order.orderType === 'Add' || order.orderType === 'Change') && order.orderStatus === OrderStatuses.SUBMITTED) {
            user.profileState = UserState.PENDING;
            if (!submittedOrders.find((el) => {
              return el.id === order.id;
            })) {
              submittedOrders.push({ user: user, id: order.id });
            }
          }
        }
      }
    }
    await this.checkForOrderErrors(submittedOrders);
  }

  async checkForOrderErrors(orders: { user: User, id: number }[]): Promise<void> {
    const ids = orders.map(obj => obj.id);
    if (ids.length > 0) {
      const orderStatuses = await this.bossOrdersService.OrdersGetOrderProcessingStatus({ orderIds: ids, Authorization: null })
        .toPromise();
      for (const status of orderStatuses) {
        if (status.processingStatus === 'Failed') {
          const failedUser = orders.find((obj) => {
            return obj.id === status.orderId;
          }).user;
          failedUser.profileState = UserState.ERROR;
        } else if (status.processingStatus === 'Succeeded') {
          const succeededUser = orders.find((obj) => {
            return obj.id === status.orderId;
          }).user;
          // Only update to succeeded if there hasn't already been a failed order found
          if (succeededUser.profileState !== UserState.ERROR) {
            succeededUser.profileState = UserState.NORMAL;
          }
        }
      }
    }
  }

  public assignNewPersonToExistingProfile(profileId: number, personDC: CreateUser): Observable<UserDC> {
    const params: UsersAssignNewPersonParams = {
      profileId: profileId,
      createPersonDC: {
        locationUuid: personDC.locationId,
        username: personDC.username,
        businessEmail: personDC.email,
        password: personDC.password,
        mobilePhone: personDC.mobilePhone,
        firstName: personDC.firstName,
        lastName: personDC.lastName,
        roles: personDC.roles
      },
      Authorization: null
    };
    return this.bossUsersService.UsersAssignNewPerson(params);
  }

  get911EmergencyLink(): Observable<E911ConsentDC[]> {
    return this.emergencyService.EmergencyGetAccountE911Consents(null);
  }

  /**
  * Get all permissions for this person
  */
  public getUserPermissions(): Observable<number[]> {
    return this.bossUsersService.UsersGetMyPermissions(null);
  }
}
