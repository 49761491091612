import { Injectable } from '@angular/core';
import { PartnersService as BossApiPartnersService } from 'src/app/modules/boss-api/generated/services';
import { PartnersService as BossApiPartnersServicePrevious } from 'src/app/modules/boss-api-previous/generated/services';
import { SDApiVersionControlService } from 'src/app/services/sdapi-version-control';
import { AccountPartnerDC } from 'src/app/modules/boss-api/generated/models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  private readonly authToken = null;
  public bossPartnersService: BossApiPartnersService | BossApiPartnersServicePrevious;
  constructor(private sdapiVersion: SDApiVersionControlService) {
    this.bossPartnersService = this.sdapiVersion.partnersService;
  }

  getPartners(): Observable<AccountPartnerDC[]> {
    return this.bossPartnersService.PartnersGetAccountPartners(this.authToken);
  }

  getPartnerByCode(partnerCode): Observable<AccountPartnerDC> {
    const params = {
      partnerCode: partnerCode,
      Authorization: this.authToken
    };
    return this.bossPartnersService.PartnersGetSupportPartnerByCode(params);
  }

  getVarPartners() {
    return this.bossPartnersService.PartnersGetAccountVarPartners(null);
  }

  getPartnerLogo(partnerCode): Observable<Blob> {
    const params = {
      partnerCode: partnerCode,
      Authorization: this.authToken
    };
    return this.bossPartnersService.PartnersLogo(params);
  }

}
