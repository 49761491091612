import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActiveFeatureFlags } from './launchdarkly-info';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapperService } from 'src/app/services/bootstrapper.service';
import { LDUserRole } from 'src/app/shared/constants';
import { isEqual as _isEqual } from 'lodash';

type FlagValue = any;
interface FlagSet {
  [key: string]: FlagValue;
}

@Injectable(
  { providedIn: 'root' }
)

export class LaunchDarklyService {

  allFeatureFlags: FlagSet = {
    flags_by_environment: {},
    flags_by_country: {}
  };
  countryCode = '';
  loggedInUserRoles = [];

  constructor(
    private ngbModal: NgbModal,
    private bootstrapperService: BootstrapperService) { 
      this.setFeatureFlags({...ActiveFeatureFlags});
    }
  
  initialize() {
    this.setFeatureFlags({...ActiveFeatureFlags});
  }

  private setFeatureFlags(allFlags: any) {
    this.allFeatureFlags = allFlags;
    this.allFeatureFlags.flags_by_environment = this._computeFlagsForEnvioremnt(this.allFeatureFlags.flags_by_environment);
    this.allFeatureFlags.default = false;
  }

  private _computeFlagsForEnvioremnt(flags_by_environment) {
    const partnerId = this.bootstrapperService.getPartnerId();
    return flags_by_environment[partnerId];
  }

  setCountryCode(countryCode) {
    this.countryCode = countryCode;
  }

  setRoles(roles: any[]) {
    this.loggedInUserRoles = roles;
  }

  // check if the provided version is smaller or equal to the console-version in LD
  isFeatureEnabledByVersion(version: string) {
    return parseFloat(this.allFeatureFlags.flags_by_environment['console-version']) >= parseFloat(version);
  }

  // check if the provided flag_name is exist for the current country in LD
  isFeatureEnabledByCountry(flag_name: string) {
    if (this.allFeatureFlags
    &&  this.allFeatureFlags.flags_by_country
    &&  this.allFeatureFlags.flags_by_country[this.countryCode]
    &&  this.allFeatureFlags.flags_by_country[this.countryCode].hasOwnProperty(flag_name)) {
      return this.allFeatureFlags.flags_by_country[this.countryCode][flag_name];
    } else {
      return (this.allFeatureFlags
          &&  this.allFeatureFlags.flags_by_country  
          &&  this.allFeatureFlags.flags_by_country['all']  
          &&  this.allFeatureFlags.flags_by_country['all'][flag_name]) ? true : false; 
    }
  }

  // check current logged in user role with LD flag_name roles
  // for this to check flag_name value in LD need to be an array of roles
  isFeatureEnabledByRolesForFlag(flag_name: string): boolean {
    if (this.loggedInUserRoles && 
        this.loggedInUserRoles.length && 
        this.allFeatureFlags &&
        this.allFeatureFlags.flags_by_country &&
        this.allFeatureFlags.flags_by_country[this.countryCode] &&
        this.allFeatureFlags.flags_by_country[this.countryCode][flag_name] &&
        this.allFeatureFlags.flags_by_country[this.countryCode][flag_name].length) {

      const result = this.allFeatureFlags.flags_by_country[this.countryCode][flag_name].some(lDRole => {
        return this.loggedInUserRoles.includes(LDUserRole[lDRole])
      });
      return result;
    }
    return true;
  }

  // used for checking the flag based on the provided method and their values
  isEnabled(data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (!this[key](data[key])) {
          return false;
        }
      }
    }
    return true;
  }

}
