import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, Renderer2, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cux-datepicker',
  templateUrl: './cux-datepicker.component.html',
  styleUrls: ['./cux-datepicker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CuxDatepickerComponent),
    multi: true
  }]
})
export class CuxDatepickerComponent implements OnInit, OnChanges {

  @ViewChild('cuxDatePickerWrapper', {}) cuxDatePickerWrapper: ElementRef;
  @Input() disabledDates: Array<Date>;
  @Input() disabledDays: Array<number>;
  @Input() value: Date =  null;
  @Input() minDate: Date;
  @Input() maxDate: Date = null;
  @Input() disabled: boolean = null;
  @Input() isTimeOnly = false;
  @Input() appendTo = '';
  @Input() containsError = false;

  @Output() onSelect = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onShow = new EventEmitter();
  @Output() onClickOutside = new EventEmitter();
  @Output() onInput = new EventEmitter();

  viewReady: boolean = false;
  locale: any;
  placeholder: string;
  hourFormat: string;
  showIcon: boolean = true;

  public onTouched: any = Function.prototype; // Trascent the onTouch event

  constructor(
    private renderer: Renderer2,
    private translateSvc: TranslateService
  ) { }

  ngOnInit() {
    this.translateSvc
    .get([
      'calendar.day_names',
      'calendar.day_short_names',
      'calendar.day_names_min',
      'calendar.month_names',
      'calendar.month_short_names',
      'date_picker.date_placeholder',
      'date_picker.date_format',
      'date_picker.time_placeholder',
      'date_picker.hour_format',
      'date_picker.first_day_of_week',
      'date_picker.today',
      'date_picker.clear',
      'date_picker.weekHeader'
    ]).subscribe(translated => {
      this.locale = {
        firstDayOfWeek: translated['date_picker.first_day_of_week'],
        dayNames: translated['calendar.day_names'],
        dayNamesShort: translated['calendar.day_short_names'],
        dayNamesMin: translated['calendar.day_names_min'],
        monthNames: translated['calendar.month_names'],
        monthNamesShort: translated['calendar.month_short_names'],
        today: translated['date_picker.today'],
        clear: translated['date_picker.clear'],
        dateFormat: translated['date_picker.date_format'],
        weekHeader: translated['date_picker.weekHeader']
      };
      if (this.isTimeOnly) {
        this.placeholder = translated['date_picker.time_placeholder'];
        this.hourFormat = translated['date_picker.hour_format'];
        this.showIcon = false;
      } else {
        this.placeholder = translated['date_picker.date_placeholder'];
      }
      this.viewReady = true;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    /* istanbul ignore else */
    if (changes.containsError && !changes.containsError.firstChange) {
      if (changes.containsError.currentValue) {
        this.renderer.addClass(this.cuxDatePickerWrapper.nativeElement.getElementsByClassName('ui-inputtext')[0], 'error-border');
      } else {
        this.renderer.removeClass(this.cuxDatePickerWrapper.nativeElement.getElementsByClassName('ui-inputtext')[0], 'error-border');
      }
    }
  }

  writeValue(val: Date) {
    this.value = val;
  }

  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }


  // not used, used for touch input
  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  // the method set in registerOnChange to emit changes back to the form
  /* istanbul ignore next */
  public propagateChange = (_: any) => { };

  select(event) {
    this.onSelect.emit(event);
  }

  blur(event) {
    this.onBlur.emit(this.value);
  }

  focus(event) {
    this.onFocus.emit(event);
  }

  close(event) {
    this.onClose.emit(event);
  }

  show(event) {
    this.onShow.emit(event);
  }

  clickOutSide(event) {
    this.renderer.removeClass(this.cuxDatePickerWrapper.nativeElement.getElementsByClassName('ui-calendar')[0], 'add-border-background');
    this.onClickOutside.emit(event);
  }

  input(event) {
    this.onInput.emit(event);
  }
}
