<div class="container">
  <div class="row header-block">
    <div class="col-md-7 title">
      {{ 'change_number_modal.title' | translate }}
    </div>
    <div class="col-md-5" *ngIf="isCartOpen">
      <app-shopping-cart
              class="pull-right new-number-shopping-cart" >
      </app-shopping-cart>
    </div>
  </div>
  <div class="row error-block">
    <div *ngIf="showFormError" class="col-sm-12">
        <cl-error-alert [errorMessage]="errMsg" ></cl-error-alert>
    </div>
  </div>

  <!-- Emergency message not required for callflow
  <div class="info-section">
    <div class="fa fa-info-circle float-left info-icon-padding"></div>
    <div>{{'change_number_modal.caution1' | translate }}</div>
  </div>
-->
  <form [formGroup]="changeNumberForm">
    <div class="radio-spacing content-block">

      <div class="radio">
        <input type="radio" id="actionChoice1" name="changeNumberType" formControlName="changeNumberType"
          value="replace_with_existing" (change)="onOptionChange('replace_with_existing')">
        <label for="actionChoice1">{{'change_number_modal.replace_with_existing' | translate}}</label>
        <span class="radiomark"></span>
        <br>
        <cl-dropdown-with-headings [disabledropdown]="disable_existingPhoneNumbers" [items]="availableExistingPhoneNumbers"
          formControlName="existingPhoneNumbers" id="existingPhoneNumbers" class="phone-number-dropdown"
          [tabbable]="tabbable" ngDefaultControl (change)="onChangedExistingPhoneNumberDigits($event)">
        </cl-dropdown-with-headings>
        <div class="text-danger" *ngIf=" changeNumberForm.hasError('digits')">
          {{ 'users_right_panel.phoneNumber_invalid' | translate }}
        </div>
        <div id="existing-number-temp-tn" class="temp-num-display" *ngIf='showTemporaryNumber && !disable_existingPhoneNumbers'>
          <app-temp-number [existingTempTn]='temporaryTn' [tempTnRequired]='tnPortedOrTransferred'
                           [countryId]='defaultUserCountryCode' (newTempTn)="onTemporaryNumberUpdate($event)"></app-temp-number>
        </div>
      </div>

      <br>
      <span *ngIf="this.featureService.isEnabled(FeatureType.GEOGRAPHIC, GeographicFeatures.FEATURE.CALL_FLOW_ADD_NEW_PHONE_NO)">
        <div class="radio" [ngbTooltip]="userPendingClose ? ('users_right_panel.service_pending_cancellation' | translate) : ''">
          <input type="radio" id="actionChoice2" name="changeNumberType" formControlName="changeNumberType"
            value="replace_with_new" (change)="onOptionChange('replace_with_new')"
            [attr.disabled]="userPendingClose ? true : null">
          <label [ngClass]="userPendingClose ? 'disabled-class': ''" for="actionChoice2" >{{'change_number_modal.replace_with_new' | translate}}</label>
          <span class="radiomark"></span>
          <br>
          <div class="search-box-group" (click)="!disable_newPhoneNumbers && onNewPhoneInputClicked($event)">
            <input #newPhoneNumberDigits type="text" formControlName="newPhoneNumberDigits" [attr.tabindex]="tabbable ? 0 : -1" [maxlength]="10"
              class="form-control" [attr.disabled]="loadingNewNumber ? '' : null" (keydown)="onNewPhoneInputClicked($event)">
            <span class="search-icon"></span>
          </div>
          <img [src]="imgname" alt="loading" class="input-spinner" *ngIf="loadingNewNumber">
          <div id="new-number-temp-tn" class="temp-num-display" *ngIf='showTemporaryNumber && !disable_newPhoneNumbers'>
            <app-temp-number [existingTempTn]='temporaryTn' [tempTnRequired]='tnPortedOrTransferred'
                             [countryId]='defaultUserCountryCode' (newTempTn)="onTemporaryNumberUpdate($event)"></app-temp-number>
          </div>
        </div>
  
        <br>
      </span>
      <div class="radio" *ngIf="hasTelephoneNumber" [ngbTooltip]="mobilityAddOns ? (tooltipString | translate) : ''">
        <input type="radio" class="unassignRadio" id="actionChoice3" name="changeNumberType" formControlName="changeNumberType"
          value="unassign" (change)="onOptionChange('unassign')" [attr.disabled]="disableUnassign">
        <label for="actionChoice3">{{'change_number_modal.unassign_without_replacing' | translate}}</label>
        <span class="radiomark"></span>
        <div id="unassign-number-temp-tn" class="temp-num-display" *ngIf='showTemporaryNumber && disable_existingPhoneNumbers && disable_newPhoneNumbers'>
          <app-temp-number [existingTempTn]='temporaryTn' [tempTnRequired]='tnPortedOrTransferred'
                           [countryId]='defaultUserCountryCode' (newTempTn)="onTemporaryNumberUpdate($event)"></app-temp-number>
        </div>
      </div>


      <br>
      <div class="checkbox">
        <input type="checkbox" formControlName="updateExtension" id="alsoUpdateExtension"
          (change)="onOptionChange('update_extension')">
        <span class="checkmark"></span>
      </div>
      <label for="alsoUpdateExtension">{{'change_number_modal.also_update_extension' | translate}}</label>

      <input type="text" formControlName="extension" minlength="4" maxlength="8" size="10"
      [ngClass]="{'error-input': changeNumberForm.get('extension').touched && changeNumberForm.get('extension').hasError('pattern') }">
      <div class="text-danger" id="errorExtenson"
            *ngIf="changeNumberForm.get('extension').touched && changeNumberForm.get('extension').hasError('pattern')">
          {{ 'change_number_modal.error_extension' | translate }}
      </div>
    </div>
  </form>
  <div class="row footer-block">
      <div class="col-7 content-text-block">
        <p class="content-text-style pull-right">
          {{ 'change_number_modal.terms_text' | translate }}
          <a [href]=termsAndServiceUrl style="color: #0073D0;" target="_blank">{{ 'change_number_modal.terms_link' | translate }}</a>
        </p>
      </div>
      <div class="col-5 footer-btn-block">
        <button id="change-phone-number-button-cancel" type="button" class="btn btn-default"
                (click)="onCancel($event) " cuxTitleTooltip cuxEllipsify>{{ 'change_number_modal.cancel' | translate }}</button>
        <button id="change-phone-number-button-change" [disabled]="!enableSubmit" type="button" class="btn btn-primary"
                (click)="onChange()" cuxTitleTooltip cuxEllipsify>{{ 'change_number_modal.change' | translate }}</button>
      </div>
  </div>
  <app-spinner delay="500" [isPopUp]="true" [showSpinner]="displayLoadingSpinner" message="{{ 'app.loading' | translate }}"></app-spinner>
</div>

