
import { ItemsWithHeading } from '../shared/common.interface';
import {
  assignedPhoneNumbers,
  availableExtensions, InvoiceInfo,
  PHONE_NUMBER_ASSIGNMENTS,
  portedPhoneNumbers,
  emergencyNumbers,
  maxPhoneNumberLength, ProfileStatus
} from './phone-service';
import { Injectable } from '@angular/core';
import { mockPhoneServices, PhoneService, UserPhoneService, diallingPlanExtensionLength } from './phone-service';
import * as _ from 'lodash';
import { TnAdapterService, RateCenter } from './tn-adapter.service';

import { calcBindingFlags } from '@angular/core/src/view/util';


// How to Mock APIs? Ans: https://atom-morgan.github.io/how-to-mock-an-api-in-angular/
import { Observable, of, Subject, from, zip, forkJoin, BehaviorSubject } from 'rxjs';
import { filter, first, flatMap, map, switchMap } from 'rxjs/operators';
import {
  OrderDC,
  SwapUserProductDC,
  AddonFeatureProductDC
} from '../modules/boss-api/generated/models';

import { LocationDC, TnDC, ValidateExtensionDC, EditProfileDC, ProfileDC } from '../modules/boss-api/generated/models';
import { LocationsService } from '../modules/boss-api/generated/services/locations.service';

import { LocationsService as BossApiLocationsService } from '../modules/boss-api/generated/services/locations.service';
import { TnsService } from '../modules/boss-api/generated/services/tns.service';
import TnsGetTnsParams = TnsService.TnsGetTnsParams;
import LocationsGetLocationParams = LocationsService.LocationsGetLocationParams;
import { ProductsService as BossApiProductsService } from '../modules/boss-api/generated/services/products.service';
import { UsersService as BossUsersService } from '../modules/boss-api/generated/services';

import { LocationsService as BossApiLocationsServicePrevious } from '../modules/boss-api-previous/generated/services/locations.service';
import { TnsService as TnsServicePrevious } from '../modules/boss-api-previous/generated/services/tns.service';
import { ProductsService as BossApiProductsServicePrevious } from '../modules/boss-api-previous/generated/services/products.service';
import { UsersService as BossUsersServicePrevious } from '../modules/boss-api-previous/generated/services';

import { SDApiVersionControlService } from 'src/app/services/sdapi-version-control';
import { User } from './user-adaptor.service';
import { ProfileProductDC } from '../modules/boss-api/generated/models';

import { PhoneNumberFormatService } from './phone-number-format.service';
import { PhoneNumber } from './tn-adaptor';

@Injectable({
  providedIn: 'root'
})

export class PhoneServicesService {

  public currentSubscription: PhoneService;
  public changePhoneNumberType: string;
  public currentSelectedExtension: string;
  public accountId: string;

  public tnIntegration: {
    filterByCity: boolean,
    saveSearchCriteria: boolean,
    currentSelectedPhoneNumber?: any,
    currentUserLocation?: LocationDC,
    tabChanged?
  } = {
      filterByCity: true,
      saveSearchCriteria: true,
      tabChanged: new Subject()
    };

  private mockUserPhoneServiceDB: Array<UserPhoneService> = new Array<UserPhoneService>();
  phoneServices: PhoneService[] = [];
  private changeLocation = new Subject<string>();   // private, use the getter
  changeInModalInRightPanel = new Subject<boolean>();
  onChangePhoneServiceBundleSubject = new Subject<boolean>();
  private aggregatedTns: string[] = [];
  phoneService = new Subject<PhoneService>();
  selectedPhoneServiceChanged = new Subject<any>();
  public currentReleaseNumberFlag = false;
  boolPhoneCard = new Subject<any>();
  newSelectedProduct: PhoneService = null;
  newSelectedAddOns: Array<AddonFeatureProductDC> = null;

  public editPhoneServiceData: any = null; // TODO  make this into a data structure
  public editPhoneServiceModalData: any = null; // TODO refactor as it is UI data.
  public userUpdated = false;

  editPhoneServiceModalIsOpened = false;

  phoneServcie = new Subject<PhoneService>();

  currentSelectedLocationId: string = undefined;

  // For communication between the Edit AddOns button from the Right Panel and Edit Product for the Devices and Services.
  // Both popup the same modal dialog for change products and addons.
  editAddOns = new Subject<any>();
  // code to set the extension length
  clusterBasedExtensionLength = new BehaviorSubject(4);
  private tnsService: TnsService | TnsServicePrevious;
  private bossProductsService: BossApiProductsService | BossApiProductsServicePrevious;
  private bossLocSvc: BossApiLocationsService | BossApiLocationsServicePrevious;
  private bossUsersService: BossUsersService | BossUsersServicePrevious;

  constructor(
    private formatService: PhoneNumberFormatService,
    private tnAdaptersvc: TnAdapterService,
    private sdapiVersion: SDApiVersionControlService) {
    this.tnsService = this.sdapiVersion.tnsService;
    this.bossProductsService = this.sdapiVersion.productsService;
    this.bossLocSvc = this.sdapiVersion.locationSevice;
    this.bossUsersService = this.sdapiVersion.usersService;
  }

  public getSubscriptions(locationId: string): Observable<PhoneService[]> {
    console.log('PhoneServicesService getSubscriptions for location ', locationId);
    return this.bossProductsService.ProductsGetProfileProducts({ locationUuid: locationId, Authorization: null })
      .pipe(
        map(profileProducts => {
          this.phoneServices = this.convertToPhoneServices(profileProducts);
          return this.phoneServices;
        })
      );
  }

  // General cleanup
  public resetServiceAfterOnSubmitClose() {
    this.currentReleaseNumberFlag = false;
    this.userUpdated = false;
  }

  private convertToPhoneServices(profileProducts: ProfileProductDC[]): PhoneService[] {
    const phoneServices: PhoneService[] = [];
    // Add 'None' Product
    // phoneServices.push({
    //   uuid: '0',
    //   name: 'None',
    //   description: 'No phone service',
    //   mrc: 0.00,
    //   nrc: 0.00,
    //   currency: "USD",
    //   products: [],
    // });

    profileProducts.forEach(product => {
      const phoneService = {
        uuid: String(product.id),
        name: product.shortName,
        description: product.description,
        mrc: product.mrc,
        nrc: product.nrc,
        currency: product.currency,
        products: [],
      };
      phoneServices.push(phoneService);
    });
    return phoneServices;
  }

  // Retrieve a user's phone service profile including product, phone number and extension
  public getUserPhoneService(user: User): Observable<UserPhoneService> {
    return this.formatPhoneNumber(user).pipe(map(phoneNumber => {
      return new UserPhoneService(this.getProductById(user.profile.productId),
        user.id,
        user.extension,
        phoneNumber);
    }));
  }

  // Update a user's phone service profile
  public updateUserPhoneService(userId: string, phoneBundle: PhoneService, extension: string, phoneNumber?: PhoneNumber): Observable<any> {
    // Delete and save
    this.deleteUserPhoneService(userId);
    this.mockUserPhoneServiceDB.push(new UserPhoneService(phoneBundle, userId, extension, phoneNumber));
    return of('result'); // send back the api result for error checking - TODO
  }

  // Delete a user's phone service profile
  public deleteUserPhoneService(userId: string): Observable<any> {
    // Remove all records whose userId is given
    _.pullAllWith(this.mockUserPhoneServiceDB, [userId], (v, o) => v.userId === o);
    return of('result'); // send back the api result for error checking - TODO
  }

  public countPhoneServicesAssigned() {
    return this.mockUserPhoneServiceDB.length; // not returning an observable here TODO?
  }

  getAvailPhoneNumbers(flow: any, locationId: string): Observable<ItemsWithHeading[]> {

    // TODO Are these definitely the only statuses we need to get
    const current$ = this.formatPhoneNumberForFlows(flow);
    const assigned$ = this.getTnsByStatus(+locationId, [ProfileStatus.available, ProfileStatus.pendingTurnup]);
    const ported$ = this.getTnsByStatus(+locationId, [ProfileStatus.ported, ProfileStatus.transferRequested]);

    return zip(current$, assigned$, ported$)
      .pipe(
        map(([current, assigned, ported]) => {
          console.log('current number #: ', current);
          // console.log('available #: ', assigned);
          // console.log('ported #: ', ported);
          this.aggregatePhoneNumbers(assigned, ported);
          const result: Array<ItemsWithHeading> = [];

          if (current && current.displayName.length > 0) {
            result.push({
              heading: 'cl_dropdown_headers.current',
              items: [current]
            });
          }
          if (assigned.length > 0) {
            result.push({
              heading: 'cl_dropdown_headers.available',
              items: assigned,
            });
          }
          if (ported.length > 0) {
            result.push({
              heading: 'cl_dropdown_headers.ported',
              items: ported,
            });
          }
          return result;
        })
      );
  }

  private aggregatePhoneNumbers(assigned: PhoneNumber[], ported: PhoneNumber[]): Array<string> {
    const assignedNums = assigned.map(pn => pn.value);
    const portedNums = ported.map(pn => pn.value);
    this.aggregatedTns = assignedNums.concat(portedNums);
    return this.aggregatedTns;
  }

  getAggregatedPhoneNumbers(): string[] {
    return this.aggregatedTns;
  }

  // Get the dialling plan/extension length
  getDiallingPlanExtensionLength(): number {
    // return diallingPlanExtensionLength;
    return this.clusterBasedExtensionLength.value;
  }

  // Get max phone number length
  getMaxPhoneNumberLength(): number {
    return maxPhoneNumberLength;
  }

  convertPhoneNumberToExtension(phoneNumber: string): string {
    if (!phoneNumber || phoneNumber.length < this.getDiallingPlanExtensionLength()) {
      return phoneNumber;
    }
    return phoneNumber.substr(phoneNumber.length - this.getDiallingPlanExtensionLength(), this.getDiallingPlanExtensionLength());
  }

  // Get a list of available extensions
  aggregateExtensions() {
    const nums: Array<string> = this.getAggregatedPhoneNumbers();
    return availableExtensions.concat(_.map(nums, this.convertPhoneNumberToExtension.bind(this)));
  }

  getNextExtension() {
    return _.head(this.aggregateExtensions());
  }

  // Get ported phone numbers
  getPortedPhoneNumbers(): Observable<string[]> {
    return of(portedPhoneNumbers);
  }

  // Get assigned phone numbers
  getAssignedPhoneNumbers(): Observable<string[]> {
    return of(assignedPhoneNumbers);
  }

  // Get the list of emergency phone numbers
  getEmergencyPhoneNumbers(): Observable<string[]> {
    return of(emergencyNumbers);
  }

  isEmergencyNumber(number: string): boolean {
    return (emergencyNumbers.indexOf(number) !== -1);
  }

  // Get the prorate information for the billing impact
  getProrateInfo() {
    return undefined;
  }

  isExtensionValid(extension: string): Promise<ValidateExtensionDC> {

    const authToken = null;  // empty so that our  auth interceptor will handle this.  Else should be 'Bearer <token>'
    const params: BossUsersService.UsersExtensionValidationParams = {
      Authorization: authToken,
      extension: extension
    };
    return this.bossUsersService.UsersExtensionValidation(params).toPromise();
  }

  public getLocationDC(locationUuid: string): Observable<LocationDC> {
    const authToken = null;  // empty so that our  auth interceptor will handle this.  Else should be 'Bearer <token>'

    let locationParams = {
      locationUuid: locationUuid,
      Authorization: authToken
    };
    return this.bossLocSvc.LocationsGetLocation(locationParams).pipe(
      switchMap((location) => {
        this.tnIntegration.currentUserLocation = location;
        return of(location);
      }));
  }

  async updatePhoneService(user, phoneNumber, releaseNumberFlag, extension): Promise<ProfileDC> {

    const authToken = null;

    // *** TODO ***
    // Ensure the phoneNumber is in E164 format.
    // Currently this is hardcode to +1 (which will only work for north america) in this method.
    // When there is a fix, make the needed changes here.

    // Thought optional parameters, we need to send all parameters?
    // Use case: when Extension was changed, we needed to send down the phone number as well,
    // we cannot omit the 'tn' field in the DC structure.
    if (phoneNumber !== null && phoneNumber.length > 0) {
      await this.formatService.formatE164PhoneNumberUsingIso3166NumericCountryCode(phoneNumber, 840)
        .then(value => phoneNumber = value);
    }
    const editProfile: EditProfileDC = {
      tn: phoneNumber,
      releaseTn: releaseNumberFlag,
      extension: extension
    };

    // {
    //   "tn":{"description":"New profile TN (E164 format). Blank to unassign","type":"string"},
    //   "releaseTn":{"description":"Indicates whether to release current profile TN into system inventory (true),
    //   or keep on account (false)","type":"boolean"},
    //   "extension":{"description":"New profile extension (optional)","type":"string"}
    // }

    const params: BossUsersService.UsersEditProfileParams = {
      userUuid: user.id,
      profileId: user.profile.id,
      profileData: editProfile,
      Authorization: authToken
    };

    return this.bossUsersService.UsersEditProfile(params).toPromise();
  }

  getTnsByStatus(location: number, statuses: number[]): Observable<PhoneNumber[]> {

    const authToken = null;

    // Note about includeAssigned:
    // The affect of this parameter is that when true, it will look at open orders to determine
    // the isAssigned property of the returned phone numbers. Using false will not return assigned
    // TNs for numbers that are pendingPortIn (status = 2), but will for available. Therefore it seems
    // to be better at this point to using includeAssigned = true and filter out those marked as assigned.
    const params: TnsGetTnsParams = {
      typeIds: [1],
      statusIds: statuses,
      includeAssigned: true,
      includeCarrierInfo: true,
      Authorization: authToken
    };

    return this.tnsService.TnsGetTns(params)
      .pipe(flatMap(tns => {
        const promises: Promise<PhoneNumber>[] = tns.filter((tn) => {
          return tn.isAssigned === false;
        }).map(tn => {
          return this.formatService.formatPhoneNumberBasedOnCountry(tn.id).then(pn => {
            const tnNumber: PhoneNumber = {
              value: tn.id,
              displayName: pn,
              locationUuid: tn.locationUuid || '0',
              countryId: tn.countryId || 0
            };
            return tnNumber;
          });
        });

        return from(Promise.all(promises));
      }));
  }

  formatPhoneNumber(user: User): Observable<PhoneNumber> {
    if (user && user.phoneNumber) {
      return from(this.formatService.formatPhoneNumberBasedOnCountry(user.phoneNumber).then(pn => {
        const tnNumber: PhoneNumber = {
          value: user.profile.tnId,
          displayName: pn,
          locationUuid: user.locationId || '0',
          countryId: user.countryCode || 0
        };
        return tnNumber;
      }));
    } else {
      return of(null);
    }
  }

  formatPhoneNumberForFlows(flow: any): Observable<PhoneNumber> {
    if (flow && flow.tnId) {
      return from(this.formatService.formatPhoneNumberBasedOnCountry(flow.tnId).then(pn => {
        const tnNumber: PhoneNumber = {
          value: flow.tnId,
          displayName: pn,
          locationUuid: flow.locationUuid || '0',
          countryId: flow.tnCountryId || 0
        };
        return tnNumber;
      }));
    } else {
      return of(null);
    }
  }

  getProductById(id: number): PhoneService {
    return _.find(this.phoneServices, (service) => {
      return service.uuid === id.toString();
    });
  }

  /**
   * Set the location of currently selected user and notify any subject listeners.
   * @param locationId
   */
  setSelectedLocation(locationId: string) {
    this.currentSelectedLocationId = locationId;
    this.changeLocation.next(locationId);
  }

  getSelectedLocation() {
    return this.currentSelectedLocationId;
  }

  /**
   * Caller can listen on this subject for location changes
   */
  getChangeLocationSubject() {
    return this.changeLocation;
  }

  setSelectedProduct(selectedProduct: PhoneService) {
    this.newSelectedProduct = selectedProduct;
  }

  getSelectedProduct(): PhoneService {
    return this.newSelectedProduct;
  }

  resetSelectedProduct() {
    this.newSelectedProduct = null;
  }

  setSelectedAddOns(selectedAddOns: Array<AddonFeatureProductDC>) {
    this.newSelectedAddOns = selectedAddOns;
  }

  getSelectedAddOns(): Array<AddonFeatureProductDC> {
    return this.newSelectedAddOns;
  }

  resetSelectedAddOns() {
    this.newSelectedAddOns = null;
  }

  setEditPhoneServiceData(editPhoneServiceData: any) {
    this.editPhoneServiceData = editPhoneServiceData;
  }

  getEditPhoneServiceData(): any {
    return this.editPhoneServiceData;
  }

  resetEditPhoneServiceData(): void {
    this.editPhoneServiceData = null;
  }

  setEditPhoneServiceModalData(editPhoneServiceModalData: any) {
    this.editPhoneServiceModalData = editPhoneServiceModalData;
  }

  getEditPhoneServiceModalData(): any {
    return this.editPhoneServiceModalData;
  }

  resetEditPhoneServiceModalData(): void {
    this.editPhoneServiceModalData = null;
  }

  setEditPhoneServiceModalIsOpened(editPhoneServiceModalIsOpened) {
    this.editPhoneServiceModalIsOpened = editPhoneServiceModalIsOpened;
  }

  getEditPhoneServiceModalIsOpened(): boolean {
    return this.editPhoneServiceModalIsOpened;
  }

  public swapProductOrderOnEdit(user: User, swapProduct: PhoneService, addOns: Array<number>): Observable<OrderDC> {

    let addOnsList: number[] = [];
    if (addOns !== null && addOns.length !== 0) {
      addOnsList = addOns;
    }

    const swapUserProductDC: SwapUserProductDC = {
      productId: Number(swapProduct.uuid),
      addonProducts: addOnsList // Needed to add as of May 9th after server update.
    };
    return this.bossUsersService.UsersSwapProduct(
      {
        userUuid: user.id,
        swapUserProductDC: swapUserProductDC,
        profileId: user.profile.id,
        Authorization: null
      });
  }

  setUserUpdated(userUpdated: boolean) {
    this.userUpdated = userUpdated;
  }

  isUserUpdated(): boolean {
    return this.userUpdated;
  }
}
