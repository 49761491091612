import {Directive, ElementRef, Renderer2, Input} from '@angular/core';


interface ElementStyles {
  'text-overflow'?: string,
  'overflow'?: string,
  'white-space'?: string,
  'display'?: string,
  'justify-content'?: string,
  'width'?: string
}
@Directive({
  selector: '[cuxEllipsify]'
})
export class CuxEllipsifyDirective {
  @Input() cuxEllipsify: boolean = false;

  domElement: any;
  baseEllipseStyles: ElementStyles = {
    'text-overflow': 'ellipsis',
    'overflow': 'hidden',
    'white-space': 'nowrap'
  };
  pButtonWrapperStyles: ElementStyles = {
    'display': 'flex',
    'justify-content': 'stretch',
    'width': '100%'
  };

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {
    this.domElement = this.elementRef.nativeElement;
  }

  ngOnInit() {
       this.addStylesToElement(this.domElement, this.baseEllipseStyles);
  }

  addStylesToElement(el: HTMLElement, ellipseStyles:ElementStyles ) {
    Object.keys(ellipseStyles).forEach(element => {
      this.renderer.setStyle(
        el, `${element}`, ellipseStyles[element]
      );
    });
 } 

}
