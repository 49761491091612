/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CallFlowDC } from '../models/call-flow-dc';
import { AutoAttendantDC } from '../models/auto-attendant-dc';
import { CallForwardCandidatesDC } from '../models/call-forward-candidates-dc';
import { ExtensionListDC } from '../models/extension-list-dc';
import { PageResultCallFlowDC } from '../models/page-result-call-flow-dc';
import { HuntGroupDC } from '../models/hunt-group-dc';
import { HuntGroupMemberCandidatesDC } from '../models/hunt-group-member-candidates-dc';
import { ScheduleDC } from '../models/schedule-dc';
import { CallFlowVisualizationDC } from '../models/call-flow-visualization-dc';
import { IsHuntgroupMemberResultDC } from '../models/is-huntgroup-member-result-dc';
@Injectable({
  providedIn: 'root',
})
class CallFlowsService extends __BaseService {
  static readonly CallFlowsGetCallFlowsByAccountPath = '/api/Account/CallFlows';
  static readonly CallFlowsGetDefaultAutoAttendantSettingsPath = '/api/Account/CallFlows/AutoAttendant';
  static readonly CallFlowsCreateAutoAttendantPath = '/api/Account/CallFlows/AutoAttendants';
  static readonly CallFlowsGetAutoAttendantByIdPath = '/api/Account/CallFlows/AutoAttendants/{AutoAttendantId}';
  static readonly CallFlowsUpdateAutoAttendantPath = '/api/Account/CallFlows/AutoAttendants/{AutoAttendantId}';
  static readonly CallFlowsDeleteAutoAttendantPath = '/api/Account/CallFlows/AutoAttendants/{AutoAttendantId}';
  static readonly CallFlowsGetAutoAttendantPromptPath = '/api/Account/CallFlows/AutoAttendants/{AutoAttendantId}/Prompt/TimeType/{TimeTypeId}';
  static readonly CallFlowsPostAutoAttendantPromptPath = '/api/Account/CallFlows/AutoAttendants/{AutoAttendantId}/Prompt/TimeType/{TimeTypeId}';
  static readonly CallFlowsGetCallForwardDestinationsPath = '/api/Account/CallFlows/CallForwardDestinations';
  static readonly CallFlowsGetCFTypesPath = '/api/Account/CallFlows/CFTypes';
  static readonly CallFlowsGetDefaultExtensionListSettingsPath = '/api/Account/CallFlows/ExtensionList';
  static readonly CallFlowsGetExtensionListsByAccountPath = '/api/Account/CallFlows/ExtensionLists';
  static readonly CallFlowsCreateExtensionListPath = '/api/Account/CallFlows/ExtensionLists';
  static readonly CallFlowsGetExtensionListByIdPath = '/api/Account/CallFlows/ExtensionLists/{ExtensionListId}';
  static readonly CallFlowsUpdateExtensionListPath = '/api/Account/CallFlows/ExtensionLists/{ExtensionListId}';
  static readonly CallFlowsDeleteExtensionListPath = '/api/Account/CallFlows/ExtensionLists/{ExtensionListId}';
  static readonly CallFlowsGetExtensionListsByAccountPagedPath = '/api/Account/CallFlows/ExtensionListsPaged';
  static readonly CallFlowsGetDefaultHuntGroupSettingsPath = '/api/Account/CallFlows/HuntGroup';
  static readonly CallFlowsGetHuntGroupByIdPath = '/api/Account/CallFlows/HuntGroup/{HuntGroupId}';
  static readonly CallFlowsGetHuntGroupMemberCandidatesPath = '/api/Account/CallFlows/HuntGroupMemberCandidates';
  static readonly CallFlowsCreateHuntGroupPath = '/api/Account/CallFlows/HuntGroups';
  static readonly CallFlowsUpdateHuntGroupPath = '/api/Account/CallFlows/HuntGroups/{HuntGroupId}';
  static readonly CallFlowsDeleteHuntGroupPath = '/api/Account/CallFlows/HuntGroups/{HuntGroupId}';
  static readonly CallFlowsGetDefaultScheduleSettingsPath = '/api/Account/CallFlows/Schedule';
  static readonly CallFlowsGetSchedulesByAccountPath = '/api/Account/CallFlows/Schedules';
  static readonly CallFlowsCreateSchedulePath = '/api/Account/CallFlows/Schedules';
  static readonly CallFlowsGetScheduleByIdPath = '/api/Account/CallFlows/Schedules/{ScheduleId}';
  static readonly CallFlowsUpdateSchedulePath = '/api/Account/CallFlows/Schedules/{ScheduleId}';
  static readonly CallFlowsDeleteSchedulePath = '/api/Account/CallFlows/Schedules/{ScheduleId}';
  static readonly CallFlowsGetSchedulesByAccountPagedPath = '/api/Account/CallFlows/SchedulesPaged';
  static readonly CallFlowsGetTimeTypesPath = '/api/Account/CallFlows/TimeTypes';
  static readonly CallFlowsGetCallFlowVisualizationPath = '/api/Account/CallFlows/Visualization';
  static readonly CallFlowsGetCallFlowsByAccountPagedPath = '/api/Account/CallFlowsPaged';
  static readonly CallFlowsUserIsHuntGroupMemberPath = '/api/Account/Persons/{userUuid}/IsHuntGroupMember';
  static readonly CallFlowsProfileIsHuntGroupMemberPath = '/api/Profiles/{profileId}/IsHuntGroupMember';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetCallFlowsByAccountResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CallFlowDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CallFlowDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetCallFlowsByAccount(Authorization: string): __Observable<Array<CallFlowDC>> {
    return this.CallFlowsGetCallFlowsByAccountResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CallFlowDC>)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetDefaultAutoAttendantSettingsResponse(Authorization: string): __Observable<__StrictHttpResponse<AutoAttendantDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/AutoAttendant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AutoAttendantDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetDefaultAutoAttendantSettings(Authorization: string): __Observable<AutoAttendantDC> {
    return this.CallFlowsGetDefaultAutoAttendantSettingsResponse(Authorization).pipe(
      __map(_r => _r.body as AutoAttendantDC)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsCreateAutoAttendantParams` containing the following parameters:
   *
   * - `autoattendantDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsCreateAutoAttendantResponse(params: CallFlowsService.CallFlowsCreateAutoAttendantParams): __Observable<__StrictHttpResponse<AutoAttendantDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.autoattendantDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/CallFlows/AutoAttendants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AutoAttendantDC>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsCreateAutoAttendantParams` containing the following parameters:
   *
   * - `autoattendantDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsCreateAutoAttendant(params: CallFlowsService.CallFlowsCreateAutoAttendantParams): __Observable<AutoAttendantDC> {
    return this.CallFlowsCreateAutoAttendantResponse(params).pipe(
      __map(_r => _r.body as AutoAttendantDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetAutoAttendantByIdParams` containing the following parameters:
   *
   * - `AutoAttendantId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetAutoAttendantByIdResponse(params: CallFlowsService.CallFlowsGetAutoAttendantByIdParams): __Observable<__StrictHttpResponse<AutoAttendantDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/AutoAttendants/${params.AutoAttendantId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AutoAttendantDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetAutoAttendantByIdParams` containing the following parameters:
   *
   * - `AutoAttendantId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetAutoAttendantById(params: CallFlowsService.CallFlowsGetAutoAttendantByIdParams): __Observable<AutoAttendantDC> {
    return this.CallFlowsGetAutoAttendantByIdResponse(params).pipe(
      __map(_r => _r.body as AutoAttendantDC)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsUpdateAutoAttendantParams` containing the following parameters:
   *
   * - `autoAttendantDC`:
   *
   * - `AutoAttendantId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsUpdateAutoAttendantResponse(params: CallFlowsService.CallFlowsUpdateAutoAttendantParams): __Observable<__StrictHttpResponse<AutoAttendantDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.autoAttendantDC;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/CallFlows/AutoAttendants/${params.AutoAttendantId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AutoAttendantDC>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsUpdateAutoAttendantParams` containing the following parameters:
   *
   * - `autoAttendantDC`:
   *
   * - `AutoAttendantId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsUpdateAutoAttendant(params: CallFlowsService.CallFlowsUpdateAutoAttendantParams): __Observable<AutoAttendantDC> {
    return this.CallFlowsUpdateAutoAttendantResponse(params).pipe(
      __map(_r => _r.body as AutoAttendantDC)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsDeleteAutoAttendantParams` containing the following parameters:
   *
   * - `AutoAttendantId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsDeleteAutoAttendantResponse(params: CallFlowsService.CallFlowsDeleteAutoAttendantParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Account/CallFlows/AutoAttendants/${params.AutoAttendantId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsDeleteAutoAttendantParams` containing the following parameters:
   *
   * - `AutoAttendantId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsDeleteAutoAttendant(params: CallFlowsService.CallFlowsDeleteAutoAttendantParams): __Observable<Array<string>> {
    return this.CallFlowsDeleteAutoAttendantResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetAutoAttendantPromptParams` containing the following parameters:
   *
   * - `TimeTypeId`:
   *
   * - `FileName`:
   *
   * - `AutoAttendantId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetAutoAttendantPromptResponse(params: CallFlowsService.CallFlowsGetAutoAttendantPromptParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.FileName != null) __params = __params.set('FileName', params.FileName.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/AutoAttendants/${params.AutoAttendantId}/Prompt/TimeType/${params.TimeTypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetAutoAttendantPromptParams` containing the following parameters:
   *
   * - `TimeTypeId`:
   *
   * - `FileName`:
   *
   * - `AutoAttendantId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetAutoAttendantPrompt(params: CallFlowsService.CallFlowsGetAutoAttendantPromptParams): __Observable<string> {
    return this.CallFlowsGetAutoAttendantPromptResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsPostAutoAttendantPromptParams` containing the following parameters:
   *
   * - `TimeTypeId`:
   *
   * - `Content`:
   *
   * - `AutoAttendantId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsPostAutoAttendantPromptResponse(params: CallFlowsService.CallFlowsPostAutoAttendantPromptParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.Content;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/CallFlows/AutoAttendants/${params.AutoAttendantId}/Prompt/TimeType/${params.TimeTypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsPostAutoAttendantPromptParams` containing the following parameters:
   *
   * - `TimeTypeId`:
   *
   * - `Content`:
   *
   * - `AutoAttendantId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsPostAutoAttendantPrompt(params: CallFlowsService.CallFlowsPostAutoAttendantPromptParams): __Observable<string> {
    return this.CallFlowsPostAutoAttendantPromptResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetCallForwardDestinationsParams` containing the following parameters:
   *
   * - `SortOrder`: Define sort order to use return results by Extension(Parameter value DN/Extension) or Description(Parameter value Description/Name")
   *
   * - `SearchCriteria`: Input of alphabet/digits(s),(* for all) to filter in Description/Extension to match
   *
   * - `Rows`: Number of Matching Rows to return
   *
   * - `CFType`: 1 for Hunt Group Call Forward, 2 for Auto Attendant Take a Message, 3 for Auto Attendant Call Forward, 4 for User Call Forward, 5 for User Ring Down
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetCallForwardDestinationsResponse(params: CallFlowsService.CallFlowsGetCallForwardDestinationsParams): __Observable<__StrictHttpResponse<CallForwardCandidatesDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SortOrder != null) __params = __params.set('SortOrder', params.SortOrder.toString());
    if (params.SearchCriteria != null) __params = __params.set('SearchCriteria', params.SearchCriteria.toString());
    if (params.Rows != null) __params = __params.set('Rows', params.Rows.toString());
    if (params.CFType != null) __params = __params.set('CFType', params.CFType.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/CallForwardDestinations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CallForwardCandidatesDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetCallForwardDestinationsParams` containing the following parameters:
   *
   * - `SortOrder`: Define sort order to use return results by Extension(Parameter value DN/Extension) or Description(Parameter value Description/Name")
   *
   * - `SearchCriteria`: Input of alphabet/digits(s),(* for all) to filter in Description/Extension to match
   *
   * - `Rows`: Number of Matching Rows to return
   *
   * - `CFType`: 1 for Hunt Group Call Forward, 2 for Auto Attendant Take a Message, 3 for Auto Attendant Call Forward, 4 for User Call Forward, 5 for User Ring Down
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetCallForwardDestinations(params: CallFlowsService.CallFlowsGetCallForwardDestinationsParams): __Observable<CallForwardCandidatesDC> {
    return this.CallFlowsGetCallForwardDestinationsResponse(params).pipe(
      __map(_r => _r.body as CallForwardCandidatesDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetCFTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/CFTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetCFTypes(Authorization: string): __Observable<{[key: string]: string}> {
    return this.CallFlowsGetCFTypesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetDefaultExtensionListSettingsResponse(Authorization: string): __Observable<__StrictHttpResponse<ExtensionListDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/ExtensionList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExtensionListDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetDefaultExtensionListSettings(Authorization: string): __Observable<ExtensionListDC> {
    return this.CallFlowsGetDefaultExtensionListSettingsResponse(Authorization).pipe(
      __map(_r => _r.body as ExtensionListDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetExtensionListsByAccountResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CallFlowDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/ExtensionLists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CallFlowDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetExtensionListsByAccount(Authorization: string): __Observable<Array<CallFlowDC>> {
    return this.CallFlowsGetExtensionListsByAccountResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CallFlowDC>)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsCreateExtensionListParams` containing the following parameters:
   *
   * - `extensionListDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsCreateExtensionListResponse(params: CallFlowsService.CallFlowsCreateExtensionListParams): __Observable<__StrictHttpResponse<ExtensionListDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.extensionListDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/CallFlows/ExtensionLists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExtensionListDC>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsCreateExtensionListParams` containing the following parameters:
   *
   * - `extensionListDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsCreateExtensionList(params: CallFlowsService.CallFlowsCreateExtensionListParams): __Observable<ExtensionListDC> {
    return this.CallFlowsCreateExtensionListResponse(params).pipe(
      __map(_r => _r.body as ExtensionListDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetExtensionListByIdParams` containing the following parameters:
   *
   * - `ExtensionListId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetExtensionListByIdResponse(params: CallFlowsService.CallFlowsGetExtensionListByIdParams): __Observable<__StrictHttpResponse<ExtensionListDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/ExtensionLists/${params.ExtensionListId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExtensionListDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetExtensionListByIdParams` containing the following parameters:
   *
   * - `ExtensionListId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetExtensionListById(params: CallFlowsService.CallFlowsGetExtensionListByIdParams): __Observable<ExtensionListDC> {
    return this.CallFlowsGetExtensionListByIdResponse(params).pipe(
      __map(_r => _r.body as ExtensionListDC)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsUpdateExtensionListParams` containing the following parameters:
   *
   * - `extensionListDC`:
   *
   * - `ExtensionListId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsUpdateExtensionListResponse(params: CallFlowsService.CallFlowsUpdateExtensionListParams): __Observable<__StrictHttpResponse<ExtensionListDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.extensionListDC;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/CallFlows/ExtensionLists/${params.ExtensionListId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExtensionListDC>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsUpdateExtensionListParams` containing the following parameters:
   *
   * - `extensionListDC`:
   *
   * - `ExtensionListId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsUpdateExtensionList(params: CallFlowsService.CallFlowsUpdateExtensionListParams): __Observable<ExtensionListDC> {
    return this.CallFlowsUpdateExtensionListResponse(params).pipe(
      __map(_r => _r.body as ExtensionListDC)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsDeleteExtensionListParams` containing the following parameters:
   *
   * - `ExtensionListId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsDeleteExtensionListResponse(params: CallFlowsService.CallFlowsDeleteExtensionListParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Account/CallFlows/ExtensionLists/${params.ExtensionListId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsDeleteExtensionListParams` containing the following parameters:
   *
   * - `ExtensionListId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsDeleteExtensionList(params: CallFlowsService.CallFlowsDeleteExtensionListParams): __Observable<string> {
    return this.CallFlowsDeleteExtensionListResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetExtensionListsByAccountPagedParams` containing the following parameters:
   *
   * - `Authorization`: Access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  CallFlowsGetExtensionListsByAccountPagedResponse(params: CallFlowsService.CallFlowsGetExtensionListsByAccountPagedParams): __Observable<__StrictHttpResponse<PageResultCallFlowDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Select != null) __params = __params.set('$select', params.Select.toString());
    if (params.OrderBy != null) __params = __params.set('$orderBy', params.OrderBy.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    if (params.Count != null) __params = __params.set('$count', params.Count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/ExtensionListsPaged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultCallFlowDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetExtensionListsByAccountPagedParams` containing the following parameters:
   *
   * - `Authorization`: Access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  CallFlowsGetExtensionListsByAccountPaged(params: CallFlowsService.CallFlowsGetExtensionListsByAccountPagedParams): __Observable<PageResultCallFlowDC> {
    return this.CallFlowsGetExtensionListsByAccountPagedResponse(params).pipe(
      __map(_r => _r.body as PageResultCallFlowDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetDefaultHuntGroupSettingsResponse(Authorization: string): __Observable<__StrictHttpResponse<HuntGroupDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/HuntGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HuntGroupDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetDefaultHuntGroupSettings(Authorization: string): __Observable<HuntGroupDC> {
    return this.CallFlowsGetDefaultHuntGroupSettingsResponse(Authorization).pipe(
      __map(_r => _r.body as HuntGroupDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetHuntGroupByIdParams` containing the following parameters:
   *
   * - `HuntGroupId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetHuntGroupByIdResponse(params: CallFlowsService.CallFlowsGetHuntGroupByIdParams): __Observable<__StrictHttpResponse<HuntGroupDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/HuntGroup/${params.HuntGroupId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HuntGroupDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetHuntGroupByIdParams` containing the following parameters:
   *
   * - `HuntGroupId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetHuntGroupById(params: CallFlowsService.CallFlowsGetHuntGroupByIdParams): __Observable<HuntGroupDC> {
    return this.CallFlowsGetHuntGroupByIdResponse(params).pipe(
      __map(_r => _r.body as HuntGroupDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetHuntGroupMemberCandidatesParams` containing the following parameters:
   *
   * - `searchCriteria`:
   *
   * - `rows`:
   *
   * - `orderBy`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetHuntGroupMemberCandidatesResponse(params: CallFlowsService.CallFlowsGetHuntGroupMemberCandidatesParams): __Observable<__StrictHttpResponse<HuntGroupMemberCandidatesDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchCriteria != null) __params = __params.set('searchCriteria', params.searchCriteria.toString());
    if (params.rows != null) __params = __params.set('rows', params.rows.toString());
    if (params.orderBy != null) __params = __params.set('orderBy', params.orderBy.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/HuntGroupMemberCandidates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HuntGroupMemberCandidatesDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetHuntGroupMemberCandidatesParams` containing the following parameters:
   *
   * - `searchCriteria`:
   *
   * - `rows`:
   *
   * - `orderBy`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetHuntGroupMemberCandidates(params: CallFlowsService.CallFlowsGetHuntGroupMemberCandidatesParams): __Observable<HuntGroupMemberCandidatesDC> {
    return this.CallFlowsGetHuntGroupMemberCandidatesResponse(params).pipe(
      __map(_r => _r.body as HuntGroupMemberCandidatesDC)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsCreateHuntGroupParams` containing the following parameters:
   *
   * - `huntgroupDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsCreateHuntGroupResponse(params: CallFlowsService.CallFlowsCreateHuntGroupParams): __Observable<__StrictHttpResponse<HuntGroupDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.huntgroupDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/CallFlows/HuntGroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HuntGroupDC>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsCreateHuntGroupParams` containing the following parameters:
   *
   * - `huntgroupDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsCreateHuntGroup(params: CallFlowsService.CallFlowsCreateHuntGroupParams): __Observable<HuntGroupDC> {
    return this.CallFlowsCreateHuntGroupResponse(params).pipe(
      __map(_r => _r.body as HuntGroupDC)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsUpdateHuntGroupParams` containing the following parameters:
   *
   * - `huntgroupDC`:
   *
   * - `HuntGroupId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsUpdateHuntGroupResponse(params: CallFlowsService.CallFlowsUpdateHuntGroupParams): __Observable<__StrictHttpResponse<HuntGroupDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.huntgroupDC;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/CallFlows/HuntGroups/${params.HuntGroupId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HuntGroupDC>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsUpdateHuntGroupParams` containing the following parameters:
   *
   * - `huntgroupDC`:
   *
   * - `HuntGroupId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsUpdateHuntGroup(params: CallFlowsService.CallFlowsUpdateHuntGroupParams): __Observable<HuntGroupDC> {
    return this.CallFlowsUpdateHuntGroupResponse(params).pipe(
      __map(_r => _r.body as HuntGroupDC)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsDeleteHuntGroupParams` containing the following parameters:
   *
   * - `HuntGroupId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsDeleteHuntGroupResponse(params: CallFlowsService.CallFlowsDeleteHuntGroupParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Account/CallFlows/HuntGroups/${params.HuntGroupId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsDeleteHuntGroupParams` containing the following parameters:
   *
   * - `HuntGroupId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsDeleteHuntGroup(params: CallFlowsService.CallFlowsDeleteHuntGroupParams): __Observable<Array<string>> {
    return this.CallFlowsDeleteHuntGroupResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetDefaultScheduleSettingsParams` containing the following parameters:
   *
   * - `TimeTypeId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetDefaultScheduleSettingsResponse(params: CallFlowsService.CallFlowsGetDefaultScheduleSettingsParams): __Observable<__StrictHttpResponse<ScheduleDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.TimeTypeId != null) __params = __params.set('TimeTypeId', params.TimeTypeId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/Schedule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScheduleDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetDefaultScheduleSettingsParams` containing the following parameters:
   *
   * - `TimeTypeId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetDefaultScheduleSettings(params: CallFlowsService.CallFlowsGetDefaultScheduleSettingsParams): __Observable<ScheduleDC> {
    return this.CallFlowsGetDefaultScheduleSettingsResponse(params).pipe(
      __map(_r => _r.body as ScheduleDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetSchedulesByAccountResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CallFlowDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/Schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CallFlowDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetSchedulesByAccount(Authorization: string): __Observable<Array<CallFlowDC>> {
    return this.CallFlowsGetSchedulesByAccountResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CallFlowDC>)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsCreateScheduleParams` containing the following parameters:
   *
   * - `scheduleDC`:
   *
   * - `TimeTypeId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsCreateScheduleResponse(params: CallFlowsService.CallFlowsCreateScheduleParams): __Observable<__StrictHttpResponse<ScheduleDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.scheduleDC;
    if (params.TimeTypeId != null) __params = __params.set('TimeTypeId', params.TimeTypeId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/CallFlows/Schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScheduleDC>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsCreateScheduleParams` containing the following parameters:
   *
   * - `scheduleDC`:
   *
   * - `TimeTypeId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsCreateSchedule(params: CallFlowsService.CallFlowsCreateScheduleParams): __Observable<ScheduleDC> {
    return this.CallFlowsCreateScheduleResponse(params).pipe(
      __map(_r => _r.body as ScheduleDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetScheduleByIdParams` containing the following parameters:
   *
   * - `TimeTypeId`:
   *
   * - `ScheduleId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetScheduleByIdResponse(params: CallFlowsService.CallFlowsGetScheduleByIdParams): __Observable<__StrictHttpResponse<ScheduleDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.TimeTypeId != null) __params = __params.set('TimeTypeId', params.TimeTypeId.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/Schedules/${params.ScheduleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScheduleDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetScheduleByIdParams` containing the following parameters:
   *
   * - `TimeTypeId`:
   *
   * - `ScheduleId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsGetScheduleById(params: CallFlowsService.CallFlowsGetScheduleByIdParams): __Observable<ScheduleDC> {
    return this.CallFlowsGetScheduleByIdResponse(params).pipe(
      __map(_r => _r.body as ScheduleDC)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsUpdateScheduleParams` containing the following parameters:
   *
   * - `scheduleDC`:
   *
   * - `TimeTypeId`:
   *
   * - `ScheduleId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsUpdateScheduleResponse(params: CallFlowsService.CallFlowsUpdateScheduleParams): __Observable<__StrictHttpResponse<ScheduleDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.scheduleDC;
    if (params.TimeTypeId != null) __params = __params.set('TimeTypeId', params.TimeTypeId.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/CallFlows/Schedules/${params.ScheduleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScheduleDC>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsUpdateScheduleParams` containing the following parameters:
   *
   * - `scheduleDC`:
   *
   * - `TimeTypeId`:
   *
   * - `ScheduleId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsUpdateSchedule(params: CallFlowsService.CallFlowsUpdateScheduleParams): __Observable<ScheduleDC> {
    return this.CallFlowsUpdateScheduleResponse(params).pipe(
      __map(_r => _r.body as ScheduleDC)
    );
  }

  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsDeleteScheduleParams` containing the following parameters:
   *
   * - `TimeTypeId`:
   *
   * - `ScheduleId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsDeleteScheduleResponse(params: CallFlowsService.CallFlowsDeleteScheduleParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.TimeTypeId != null) __params = __params.set('TimeTypeId', params.TimeTypeId.toString());

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Account/CallFlows/Schedules/${params.ScheduleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Required Permission: EditCallFlow (135)
   * @param params The `CallFlowsService.CallFlowsDeleteScheduleParams` containing the following parameters:
   *
   * - `TimeTypeId`:
   *
   * - `ScheduleId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsDeleteSchedule(params: CallFlowsService.CallFlowsDeleteScheduleParams): __Observable<string> {
    return this.CallFlowsDeleteScheduleResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetSchedulesByAccountPagedParams` containing the following parameters:
   *
   * - `Authorization`: Access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  CallFlowsGetSchedulesByAccountPagedResponse(params: CallFlowsService.CallFlowsGetSchedulesByAccountPagedParams): __Observable<__StrictHttpResponse<PageResultCallFlowDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Select != null) __params = __params.set('$select', params.Select.toString());
    if (params.OrderBy != null) __params = __params.set('$orderBy', params.OrderBy.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    if (params.Count != null) __params = __params.set('$count', params.Count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/SchedulesPaged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultCallFlowDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetSchedulesByAccountPagedParams` containing the following parameters:
   *
   * - `Authorization`: Access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  CallFlowsGetSchedulesByAccountPaged(params: CallFlowsService.CallFlowsGetSchedulesByAccountPagedParams): __Observable<PageResultCallFlowDC> {
    return this.CallFlowsGetSchedulesByAccountPagedResponse(params).pipe(
      __map(_r => _r.body as PageResultCallFlowDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetTimeTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/TimeTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param Authorization Access token
   * @return OK
   */
  CallFlowsGetTimeTypes(Authorization: string): __Observable<{[key: string]: string}> {
    return this.CallFlowsGetTimeTypesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetCallFlowVisualizationParams` containing the following parameters:
   *
   * - `DnId`:
   *
   * - `Authorization`: Access token
   *
   * - `Level`:
   *
   * @return OK
   */
  CallFlowsGetCallFlowVisualizationResponse(params: CallFlowsService.CallFlowsGetCallFlowVisualizationParams): __Observable<__StrictHttpResponse<CallFlowVisualizationDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.DnId != null) __params = __params.set('DnId', params.DnId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Level != null) __params = __params.set('Level', params.Level.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlows/Visualization`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CallFlowVisualizationDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetCallFlowVisualizationParams` containing the following parameters:
   *
   * - `DnId`:
   *
   * - `Authorization`: Access token
   *
   * - `Level`:
   *
   * @return OK
   */
  CallFlowsGetCallFlowVisualization(params: CallFlowsService.CallFlowsGetCallFlowVisualizationParams): __Observable<CallFlowVisualizationDC> {
    return this.CallFlowsGetCallFlowVisualizationResponse(params).pipe(
      __map(_r => _r.body as CallFlowVisualizationDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetCallFlowsByAccountPagedParams` containing the following parameters:
   *
   * - `Authorization`: Access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  CallFlowsGetCallFlowsByAccountPagedResponse(params: CallFlowsService.CallFlowsGetCallFlowsByAccountPagedParams): __Observable<__StrictHttpResponse<PageResultCallFlowDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Select != null) __params = __params.set('$select', params.Select.toString());
    if (params.OrderBy != null) __params = __params.set('$orderBy', params.OrderBy.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    if (params.Count != null) __params = __params.set('$count', params.Count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CallFlowsPaged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultCallFlowDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsGetCallFlowsByAccountPagedParams` containing the following parameters:
   *
   * - `Authorization`: Access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  CallFlowsGetCallFlowsByAccountPaged(params: CallFlowsService.CallFlowsGetCallFlowsByAccountPagedParams): __Observable<PageResultCallFlowDC> {
    return this.CallFlowsGetCallFlowsByAccountPagedResponse(params).pipe(
      __map(_r => _r.body as PageResultCallFlowDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsUserIsHuntGroupMemberParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsUserIsHuntGroupMemberResponse(params: CallFlowsService.CallFlowsUserIsHuntGroupMemberParams): __Observable<__StrictHttpResponse<IsHuntgroupMemberResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Persons/${params.userUuid}/IsHuntGroupMember`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IsHuntgroupMemberResultDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsUserIsHuntGroupMemberParams` containing the following parameters:
   *
   * - `userUuid`: User CL UUID
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsUserIsHuntGroupMember(params: CallFlowsService.CallFlowsUserIsHuntGroupMemberParams): __Observable<IsHuntgroupMemberResultDC> {
    return this.CallFlowsUserIsHuntGroupMemberResponse(params).pipe(
      __map(_r => _r.body as IsHuntgroupMemberResultDC)
    );
  }

  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsProfileIsHuntGroupMemberParams` containing the following parameters:
   *
   * - `profileId`: Profile ID
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsProfileIsHuntGroupMemberResponse(params: CallFlowsService.CallFlowsProfileIsHuntGroupMemberParams): __Observable<__StrictHttpResponse<IsHuntgroupMemberResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Profiles/${params.profileId}/IsHuntGroupMember`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IsHuntgroupMemberResultDC>;
      })
    );
  }
  /**
   * Required Permission: ViewCallFlow (134)
   * @param params The `CallFlowsService.CallFlowsProfileIsHuntGroupMemberParams` containing the following parameters:
   *
   * - `profileId`: Profile ID
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  CallFlowsProfileIsHuntGroupMember(params: CallFlowsService.CallFlowsProfileIsHuntGroupMemberParams): __Observable<IsHuntgroupMemberResultDC> {
    return this.CallFlowsProfileIsHuntGroupMemberResponse(params).pipe(
      __map(_r => _r.body as IsHuntgroupMemberResultDC)
    );
  }
}

module CallFlowsService {

  /**
   * Parameters for CallFlowsCreateAutoAttendant
   */
  export interface CallFlowsCreateAutoAttendantParams {
    autoattendantDC: AutoAttendantDC;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsGetAutoAttendantById
   */
  export interface CallFlowsGetAutoAttendantByIdParams {
    AutoAttendantId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsUpdateAutoAttendant
   */
  export interface CallFlowsUpdateAutoAttendantParams {
    autoAttendantDC: AutoAttendantDC;
    AutoAttendantId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsDeleteAutoAttendant
   */
  export interface CallFlowsDeleteAutoAttendantParams {
    AutoAttendantId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsGetAutoAttendantPrompt
   */
  export interface CallFlowsGetAutoAttendantPromptParams {
    TimeTypeId: number;
    FileName: string;
    AutoAttendantId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsPostAutoAttendantPrompt
   */
  export interface CallFlowsPostAutoAttendantPromptParams {
    TimeTypeId: number;
    Content: string;
    AutoAttendantId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsGetCallForwardDestinations
   */
  export interface CallFlowsGetCallForwardDestinationsParams {

    /**
     * Define sort order to use return results by Extension(Parameter value DN/Extension) or Description(Parameter value Description/Name")
     */
    SortOrder: string;

    /**
     * Input of alphabet/digits(s),(* for all) to filter in Description/Extension to match
     */
    SearchCriteria: string;

    /**
     * Number of Matching Rows to return
     */
    Rows: number;

    /**
     * 1 for Hunt Group Call Forward, 2 for Auto Attendant Take a Message, 3 for Auto Attendant Call Forward, 4 for User Call Forward, 5 for User Ring Down
     */
    CFType: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsCreateExtensionList
   */
  export interface CallFlowsCreateExtensionListParams {
    extensionListDC: ExtensionListDC;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsGetExtensionListById
   */
  export interface CallFlowsGetExtensionListByIdParams {
    ExtensionListId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsUpdateExtensionList
   */
  export interface CallFlowsUpdateExtensionListParams {
    extensionListDC: ExtensionListDC;
    ExtensionListId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsDeleteExtensionList
   */
  export interface CallFlowsDeleteExtensionListParams {
    ExtensionListId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsGetExtensionListsByAccountPaged
   */
  export interface CallFlowsGetExtensionListsByAccountPagedParams {

    /**
     * Access token
     */
    Authorization: string;

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Select this field
     */
    Select?: string;

    /**
     * Order by this field
     */
    OrderBy?: string;

    /**
     * Odata filter param
     */
    Filter?: string;

    /**
     * Include the total item count
     */
    Count?: boolean;
  }

  /**
   * Parameters for CallFlowsGetHuntGroupById
   */
  export interface CallFlowsGetHuntGroupByIdParams {
    HuntGroupId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsGetHuntGroupMemberCandidates
   */
  export interface CallFlowsGetHuntGroupMemberCandidatesParams {
    searchCriteria: string;
    rows: number;
    orderBy: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsCreateHuntGroup
   */
  export interface CallFlowsCreateHuntGroupParams {
    huntgroupDC: HuntGroupDC;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsUpdateHuntGroup
   */
  export interface CallFlowsUpdateHuntGroupParams {
    huntgroupDC: HuntGroupDC;
    HuntGroupId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsDeleteHuntGroup
   */
  export interface CallFlowsDeleteHuntGroupParams {
    HuntGroupId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsGetDefaultScheduleSettings
   */
  export interface CallFlowsGetDefaultScheduleSettingsParams {
    TimeTypeId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsCreateSchedule
   */
  export interface CallFlowsCreateScheduleParams {
    scheduleDC: ScheduleDC;
    TimeTypeId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsGetScheduleById
   */
  export interface CallFlowsGetScheduleByIdParams {
    TimeTypeId: number;
    ScheduleId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsUpdateSchedule
   */
  export interface CallFlowsUpdateScheduleParams {
    scheduleDC: ScheduleDC;
    TimeTypeId: number;
    ScheduleId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsDeleteSchedule
   */
  export interface CallFlowsDeleteScheduleParams {
    TimeTypeId: number;
    ScheduleId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsGetSchedulesByAccountPaged
   */
  export interface CallFlowsGetSchedulesByAccountPagedParams {

    /**
     * Access token
     */
    Authorization: string;

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Select this field
     */
    Select?: string;

    /**
     * Order by this field
     */
    OrderBy?: string;

    /**
     * Odata filter param
     */
    Filter?: string;

    /**
     * Include the total item count
     */
    Count?: boolean;
  }

  /**
   * Parameters for CallFlowsGetCallFlowVisualization
   */
  export interface CallFlowsGetCallFlowVisualizationParams {
    DnId: number;

    /**
     * Access token
     */
    Authorization: string;
    Level?: number;
  }

  /**
   * Parameters for CallFlowsGetCallFlowsByAccountPaged
   */
  export interface CallFlowsGetCallFlowsByAccountPagedParams {

    /**
     * Access token
     */
    Authorization: string;

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Select this field
     */
    Select?: string;

    /**
     * Order by this field
     */
    OrderBy?: string;

    /**
     * Odata filter param
     */
    Filter?: string;

    /**
     * Include the total item count
     */
    Count?: boolean;
  }

  /**
   * Parameters for CallFlowsUserIsHuntGroupMember
   */
  export interface CallFlowsUserIsHuntGroupMemberParams {

    /**
     * User CL UUID
     */
    userUuid: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for CallFlowsProfileIsHuntGroupMember
   */
  export interface CallFlowsProfileIsHuntGroupMemberParams {

    /**
     * Profile ID
     */
    profileId: number;

    /**
     * Access token
     */
    Authorization: string;
  }
}

export { CallFlowsService }
