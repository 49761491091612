/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FlatSapUpdateDC } from '../models/flat-sap-update-dc';
import { SapUpdateDC } from '../models/sap-update-dc';
import { FlatEntitlementToSapDC } from '../models/flat-entitlement-to-sap-dc';
import { EntitlementToSapDC } from '../models/entitlement-to-sap-dc';
@Injectable({
  providedIn: 'root',
})
class ShippingService extends __BaseService {
  static readonly ShippingPushFlatSapUpdatePath = '/api/Entitlements/PushSapUpdate';
  static readonly ShippingPushSapUpdatePath = '/api/Entitlements/PushSapUpdate/ByShipment';
  static readonly ShippingGetSapSyncStatesPath = '/api/Entitlements/SapSyncStates';
  static readonly ShippingGetFlatEntitlementsForSapSyncPath = '/api/Entitlements/SyncToSap';
  static readonly ShippingGetEntitlementsForSapSyncPath = '/api/Entitlements/SyncToSap/ByOrder';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: SyncSapServices (10342)
   * @param params The `ShippingService.ShippingPushFlatSapUpdateParams` containing the following parameters:
   *
   * - `flatSapUpdate`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ShippingPushFlatSapUpdateResponse(params: ShippingService.ShippingPushFlatSapUpdateParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.flatSapUpdate;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entitlements/PushSapUpdate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: SyncSapServices (10342)
   * @param params The `ShippingService.ShippingPushFlatSapUpdateParams` containing the following parameters:
   *
   * - `flatSapUpdate`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ShippingPushFlatSapUpdate(params: ShippingService.ShippingPushFlatSapUpdateParams): __Observable<{}> {
    return this.ShippingPushFlatSapUpdateResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: SyncSapServices (10342)
   * @param params The `ShippingService.ShippingPushSapUpdateParams` containing the following parameters:
   *
   * - `sapUpdate`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ShippingPushSapUpdateResponse(params: ShippingService.ShippingPushSapUpdateParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.sapUpdate;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Entitlements/PushSapUpdate/ByShipment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: SyncSapServices (10342)
   * @param params The `ShippingService.ShippingPushSapUpdateParams` containing the following parameters:
   *
   * - `sapUpdate`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ShippingPushSapUpdate(params: ShippingService.ShippingPushSapUpdateParams): __Observable<{}> {
    return this.ShippingPushSapUpdateResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: SyncSapServices (10342)
   * @param Authorization access token
   * @return OK
   */
  ShippingGetSapSyncStatesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entitlements/SapSyncStates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: SyncSapServices (10342)
   * @param Authorization access token
   * @return OK
   */
  ShippingGetSapSyncStates(Authorization: string): __Observable<{[key: string]: string}> {
    return this.ShippingGetSapSyncStatesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: SyncSapServices (10342)
   * @param Authorization access token
   * @return OK
   */
  ShippingGetFlatEntitlementsForSapSyncResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<FlatEntitlementToSapDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entitlements/SyncToSap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FlatEntitlementToSapDC>>;
      })
    );
  }
  /**
   * Required Permission: SyncSapServices (10342)
   * @param Authorization access token
   * @return OK
   */
  ShippingGetFlatEntitlementsForSapSync(Authorization: string): __Observable<Array<FlatEntitlementToSapDC>> {
    return this.ShippingGetFlatEntitlementsForSapSyncResponse(Authorization).pipe(
      __map(_r => _r.body as Array<FlatEntitlementToSapDC>)
    );
  }

  /**
   * Required Permission: SyncSapServices (10342)
   * @param Authorization access token
   * @return OK
   */
  ShippingGetEntitlementsForSapSyncResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<EntitlementToSapDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Entitlements/SyncToSap/ByOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EntitlementToSapDC>>;
      })
    );
  }
  /**
   * Required Permission: SyncSapServices (10342)
   * @param Authorization access token
   * @return OK
   */
  ShippingGetEntitlementsForSapSync(Authorization: string): __Observable<Array<EntitlementToSapDC>> {
    return this.ShippingGetEntitlementsForSapSyncResponse(Authorization).pipe(
      __map(_r => _r.body as Array<EntitlementToSapDC>)
    );
  }
}

module ShippingService {

  /**
   * Parameters for ShippingPushFlatSapUpdate
   */
  export interface ShippingPushFlatSapUpdateParams {
    flatSapUpdate: FlatSapUpdateDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for ShippingPushSapUpdate
   */
  export interface ShippingPushSapUpdateParams {
    sapUpdate: SapUpdateDC;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { ShippingService }
