import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';
import { rightPanelCloseReason } from '../../shared/constants';
import { NavigationConfirmationComponent } from '../../navigation-confirmation/navigation-confirmation.component';
import { SchedulesService } from '../../services/schedules.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { ScheduleAdaptorService } from '../../services/schedule-adaptor.service';
import { DeleteConfirmationComponent } from '../../delete-confirmation/delete-confirmation.component';
import { BossApiUtils } from '../../shared/BossApiUtils';
import { OnHoursScheduleLabels, TimeZone } from 'src/app/models/schedules-model';
@Component({
    selector: 'app-on-hours-schedule',
    templateUrl: './on-hours-schedule.component.html',
    styleUrls: ['./on-hours-schedule.component.css']
})
export class OnHoursScheduleComponent implements OnInit, OnDestroy {
    @Input() popUp = false;
    @Input() dialogHeader = 'on_hours.new_title';
    @Input() tabbable = true;
    @Input() toOpen: boolean;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() resetToOpen = new EventEmitter();
    modalRef: NgbModalRef;
    closeReason: string;
    onHoursForm: FormGroup;
    mondaySchedule: FormArray;
    tuesdaySchedule: FormArray;
    wednesdaySchedule: FormArray;
    thursdaySchedule: FormArray;
    fridaySchedule: FormArray;
    saturdaySchedule: FormArray;
    sundaySchedule: FormArray;
    timeZones: any[] = [];
    submitting = false;
    showFormError = false;
    errMsg = '';
    errMsgStyle: any = { 'max-width': '650px' };
    displayLoadingSpinner = false;
    dayScheduleNames = [
        ['mondaySchedule', 'monday', 'mondayScheduleInitial', 1, 'on_hours.monday'],
        ['tuesdaySchedule', 'tuesday', 'tuesdayScheduleInitial', 2, 'on_hours.tuesday'],
        ['wednesdaySchedule', 'wednesday', 'wednesdayScheduleInitial', 3, 'on_hours.wednesday'],
        ['thursdaySchedule', 'thursday', 'thursdayScheduleInitial', 4, 'on_hours.thursday'],
        ['fridaySchedule', 'friday', 'fridayScheduleInitial', 5, 'on_hours.friday'],
        ['saturdaySchedule', 'saturday', 'saturdayScheduleInitial', 6, 'on_hours.saturday'],
        ['sundaySchedule', 'sunday', 'sundayScheduleInitial', 7, 'on_hours.sunday']];
    scheduleMappings = {
        '1': 'mondaySchedule',
        '2': 'tuesdaySchedule', '3': 'wednesdaySchedule', '4': 'thursdaySchedule',
        '5': 'fridaySchedule', '6': 'saturdaySchedule', '7': 'sundaySchedule'
    };
    schedule: any;
    clickedScheduleSubscription: Subscription;
    updateSchedule = false;
    deleteConfirmDisplayFlag = false;
    scheduleType = 1;
    otherErr: string[] = [];
    deletedSchedule = [];
    errorMsg: string[];
    scheduleNameError = '';
    stopTimeValidationMessage = 'on_hours.stop_time_validation';
    rxjsSubscriptions: Subscription[] = []
    newOHSchedule: boolean = true;
    translationsKeys: string[] = ['on_hours.new_title', 'time_zones'];
    onScheduleLabels: OnHoursScheduleLabels = {
        titleLabel: ''
    }

    constructor(public translateSvc: TranslateService,
                private fb: FormBuilder,
                private activeModal: NgbActiveModal,
                private modalSvc: NgbModal,
                public schedulesSvc: SchedulesService,
                private notificationService: NotificationService,
                private scheduleSvc: ScheduleAdaptorService) {
    }

    ngOnInit() {
        this.clickedScheduleSubscription = this.schedulesSvc.clickedScheduleChanged.subscribe(async schedule => {
            this.schedule = schedule;
            if (schedule !== null) {
                await this.setFormWithSchedule();
            }
        });
        this.schedule = this.schedulesSvc.getClickedSchedule();
        this.updateSchedule = !_.isEmpty(this.schedule);
        this.initForm();
        this.setFormWithSchedule();
        this.rxjsSubscriptions.push(this.translateSvc.stream(this.translationsKeys).subscribe(translations => {
            this.onScheduleLabels.titleLabel = translations['on_hours.new_title'];
        }));
    }

    async initForm() {
        this.onHoursForm = this.fb.group({
            onHoursSection: this.fb.group({
                name: ['New On-Hours Schedule', Validators.required],
                timeZone: [null, Validators.required],
                mondaySchedule: this.fb.array([this.createDayScheduleArray(1)]),
                tuesdaySchedule: this.fb.array([this.createDayScheduleArray(2)]),
                wednesdaySchedule: this.fb.array([this.createDayScheduleArray(3)]),
                thursdaySchedule: this.fb.array([this.createDayScheduleArray(4)]),
                fridaySchedule: this.fb.array([this.createDayScheduleArray(5)]),
                saturdaySchedule: this.fb.array([]),
                sundaySchedule: this.fb.array([]),
                mondayScheduleInitial: this.getInitialScheduleValue('monday', 1),
                tuesdayScheduleInitial: this.getInitialScheduleValue('tuesday', 2),
                wednesdayScheduleInitial: this.getInitialScheduleValue('wednesday', 3),
                thursdayScheduleInitial: this.getInitialScheduleValue('thursday', 4),
                fridayScheduleInitial: this.getInitialScheduleValue('friday', 5),
                saturdayScheduleInitial: this.getInitialScheduleValue('saturday', 6),   
                sundayScheduleInitial: this.getInitialScheduleValue('sunday', 0)
            })
        });
    }

    async reInitForm() {
        this.onHoursForm = this.fb.group({
            onHoursSection: this.fb.group({
                name: ['', Validators.required],
                timeZone: [null, Validators.required],
                mondaySchedule: this.fb.array([]),
                tuesdaySchedule: this.fb.array([]),
                wednesdaySchedule: this.fb.array([]),
                thursdaySchedule: this.fb.array([]),
                fridaySchedule: this.fb.array([]),
                saturdaySchedule: this.fb.array([]),
                sundaySchedule: this.fb.array([]),
                mondayScheduleInitial: this.getInitialScheduleValue('monday', 1),
                tuesdayScheduleInitial: this.getInitialScheduleValue('tuesday', 2),
                wednesdayScheduleInitial: this.getInitialScheduleValue('wednesday', 3),
                thursdayScheduleInitial: this.getInitialScheduleValue('thursday', 4),
                fridayScheduleInitial: this.getInitialScheduleValue('friday', 5),
                saturdayScheduleInitial: this.getInitialScheduleValue('saturday', 6),
                sundayScheduleInitial: this.getInitialScheduleValue('sunday', 7)
            })
        });
    }

    getInitialScheduleValue(name, day): FormGroup {
        return this.fb.group({
            enable: false,
            name: name,
            DayOfWeek: day
        });
    }

    createDayScheduleArray(day): FormGroup {
        return this.fb.group({
            id: null,
            dayOfWeek: day,
            startTime: [new Date(2019, 10, 16, 8, 0, 30, 0), Validators.required],
            stopTime: [new Date(2019, 10, 16, 17, 0, 30, 0), Validators.required],
            enable: true,
            uniqueId: null,
            startTimeReq: false,
            startTimeInvalid: false,
            stopTimeReq: false,
            stopTimeInvalid: false,
            stopTimeValidationErrorMessage: '',
            stopTimeValidation: false,
            stopTimeValidationDisabled: ['true', Validators.required],
            _destroy: 0
        });
    }

    async setFormWithSchedule() {
        this.displayLoadingSpinner = true;
        this.onHoursForm.reset();
        if (!this.schedule) {
            this.onHoursForm.get('onHoursSection').patchValue({ name: '' });
            await this.scheduleSvc.getNewSchedule(this.scheduleType).toPromise().then(
                hours => {
                    this.newOHSchedule = true;
                    this.schedule = hours;
                    // this.schedule.name = this.schedule.name === null ? 'New On-Hours Schedule' : this.schedule.name;
                    this.schedule.name = 'New On-Hours Schedule';
                    this.displayLoadingSpinner = false;
                },
                error => {
                    this.displayLoadingSpinner = false;
                    console.error('failed to getNewSchedule', error);
                    this.showServerError(
                        this.translateSvc.instant('error_messages.on_hours_schedule.failed_to_get_new') + BossApiUtils.extractErrorMessage(error));
                }
            );
        } else {
            await this.scheduleSvc.getSchedule(this.scheduleType, this.schedule.id).toPromise().then(
                hours => {
                    this.newOHSchedule = false;
                    this.schedule = hours;
                },
                error => {
                    console.error('failed to fetch hours schedule with id', this.schedule.id, error);
                    this.showServerError(this.translateSvc.instant('error_messages.on_hours_schedule.failed_to_fetch') + BossApiUtils.extractErrorMessage(error));
                }
            );
        }
        if (this.schedule != null) {
            this.timeZones = this.schedule.timeZones;
            const schedule = this.loadAvailableSchedules(this.schedule);
            this.onHoursForm.get('onHoursSection').patchValue(
                schedule);
            if (this.schedule.timeZone) {
                this.onHoursForm.get('onHoursSection').patchValue({ timeZone: { value: this.schedule.timeZone } });
            }
            this.displayLoadingSpinner = false;
        }
    }

    loadAvailableSchedules(schedule) {
        this.reInitForm();
        const _this = this;
        const scheduleMappings = this.scheduleMappings;
        let schedules = _.cloneDeep(schedule.scheduleItems);
        schedule.name = this.newOHSchedule ? this.onScheduleLabels.titleLabel : schedule.name;
        if (_.isEmpty(schedule.id)) {
            schedules = _.flatten(_.map(schedules, returnFilteredSchedules));
        }
        function returnFilteredSchedules(value) {
            if (_.includes([6, 7], value['dayOfWeek'])) {
                value = [];
                return value;
            }
            return value;
        }
        if (schedules.length > 0) {
            _.forEach(_this.dayScheduleNames, function (dayValue) {
                const dayWeek = dayValue[3];
                let daySchedules = _.map(schedules, returnDaySchedules);

                function returnDaySchedules(value) {
                    if (value['dayOfWeek'] === dayWeek) {
                        const splitStartTime = _.split(value.startTime, ':');
                        const splitStopTime = _.split(value.stopTime, ':');
                        value.startTime = new Date(2019, 10, 16, splitStartTime[0], splitStartTime[1], 30, 0);
                        value.stopTime = new Date(2019, 10, 16, splitStopTime[0], splitStopTime[1], 30, 0);
                        value._destroy = 0;
                        return value;
                    }
                    return [];
                }

                daySchedules = _.flatten(_.isEmpty(daySchedules) ? [] : daySchedules);
                if (!_.isEmpty(daySchedules)) {
                    _.forEach(daySchedules, function (value) {
                        if (_.isEmpty(schedule[scheduleMappings[dayWeek]])) {
                            schedule[scheduleMappings[dayWeek]] = [];
                        }
                        value['enable'] = true;
                        schedule[scheduleMappings[value.dayOfWeek]].push(value);
                        _this.reInitialAddMoreSchedule(dayValue[0], dayWeek);
                    });
                }
            });
        }
        return schedule;
    }

    reInitialAddMoreSchedule(name, day) {
        const scheduleObject = this.getScheduleArrayVal(name);
        scheduleObject.insert(scheduleObject.controls.length, this.createDayScheduleArray(day));
    }

    addMoreSchedule(name, day) {
        const scheduleObject = this.getScheduleArrayVal(name);
        scheduleObject.insert(scheduleObject.controls.length, this.createDayScheduleArray(day));
        this.onHoursForm.markAsDirty();
    }

    deleteCurrentSchedule(name, index) {
        const scheduleObj = this.getScheduleArrayVal(name);
        const selectedObject = scheduleObj.controls[index].value;
        if (selectedObject.id !== null) {
            selectedObject._destroy = 1;
            this.deletedSchedule.push(selectedObject);
        }
        scheduleObj.removeAt(index);
        this.onHoursForm.markAsDirty();
    }

    verifySchedulesCount(name) {
        const scheduleObject = this.getScheduleArrayVal(name);
        return scheduleObject.length <= 0;
    }

    getScheduleArrayVal(name) {
        return this.onHoursForm.get('onHoursSection').get(name) as FormArray;
    }

    enableSchedule(name, initialDayName) {
        const _this = this;
        /* istanbul ignore else*/
        if (!this.onHoursForm.get('onHoursSection').get(name)['controls'][0].value.enable) {
            const scheduleItems = this.onHoursForm.controls['onHoursSection']['controls'][name].value;
            _.forEach(scheduleItems, function (scheduleItem) {
                /* istanbul ignore else*/
                if (scheduleItem.id !== null) {
                    scheduleItem._destroy = 1;
                    _this.deletedSchedule.push(scheduleItem);
                }
            });
            this.onHoursForm.controls['onHoursSection']['controls'][name] = this.fb.array([]);
            this.onHoursForm.controls['onHoursSection']['controls'][initialDayName].patchValue({ enable: false });
        }
    }

    enableScheduleByInitialCheckevent(name, initialDayName, day) {
        /* istanbul ignore else*/
        if (this.onHoursForm.get('onHoursSection').get(initialDayName).value.enable) {
            this.addMoreSchedule(name, day);
        }
    }

    cancel() {
        if (this.popUp) {
            this.activeModal.close(false);
        } else {
            this.rightPanelcancel();
        }
    }

    async onSubmit() {
        this.submitting = true;
        this.clearServerErrorOnForm();
        if (this.onHoursForm.valid) {
            this.displayLoadingSpinner = true;
            if (this.schedule && this.schedule.id != null) {
                console.log('Update Hours schedule');
                const params = this.getOnHoursParams(false);
                await this.scheduleSvc.updateOnHoursSchedule(this.schedule.id, this.scheduleType, params)
                    .toPromise().then(
                        response => {
                            this.schedule = response;
                            if (this.schedule.errors != null && this.schedule.errors !== '') {
                                console.error(this.schedule.errors);
                                this.displayLoadingSpinner = false;
                                this.showServerError(this.translateSvc.instant('error_messages.on_hours_schedule.failed_to_update'));
                                this.parseServerError(this.schedule.errors);
                            } else {
                                this.callOnClose(true);
                                this.displayLoadingSpinner = false;
                                this.insertMessage('on_hours.updated_on_hours');
                            }
                        },
                        error => {
                            // if not caught here, will get an 'core.js:15714
                            // ERROR Error: Uncaught (in promise)' error without a useful stack trace
                            console.error('failed to update hours schedule', error);
                            this.displayLoadingSpinner = false;
                            this.translateSvc.get('error_messages').subscribe(err => {
                                this.showServerError(this.translateSvc.instant('error_messages.on_hours_schedule.unable_to_update') + BossApiUtils.extractErrorMessage(error));
                            });
                        }
                    );
                this.submitting = false;
            } else {
                console.log('Create Hours schedule');
                const params = this.getOnHoursParams(true);

                await this.scheduleSvc.createOnHourschedule(this.scheduleType, params)
                    .toPromise().then(
                        response => {
                            this.schedule = response;
                            if (this.schedule.errors != null && this.schedule.errors !== '') {
                                console.error(this.schedule.errors);
                                this.displayLoadingSpinner = false;
                                this.showServerError(this.translateSvc.instant('error_messages.on_hours_schedule.failed_to_create'));
                                this.parseServerError(this.schedule.errors);
                            } else {
                                this.displayLoadingSpinner = false;
                                if (this.popUp) {
                                    this.activeModal.close({ id: this.schedule.id, name: this.schedule.name });
                                    // this.insertMessage('on_hours.created_on_hours');
                                } else {
                                    this.callOnClose(true);
                                    this.insertMessage('on_hours.created_on_hours');
                                }
                            }
                        },
                        error => {
                            // if not caught here, will get an 'core.js:15714
                            // ERROR Error: Uncaught (in promise)' error without a useful stack trace
                            console.error('failed to create hours schedule', error);
                            this.displayLoadingSpinner = false;
                            this.translateSvc.get('error_messages').subscribe(err => {
                                this.showServerError(this.translateSvc.instant('error_messages.on_hours_schedule.unable_to_create') + BossApiUtils.extractErrorMessage(error));
                            });
                        }
                    );
                this.submitting = false;
            }
        } else {
            this.validateAllFormFields(this.onHoursForm.get('onHoursSection'));
        }
    }

    insertMessage(msg) {
        this.notificationService.updateMessage(msg);
    }

    getOnHoursParams(isCreate) {
        const _this = this;
        const input = this.onHoursForm.get('onHoursSection').value;
        this.schedule.name = input.name;
        this.schedule.timeZone = input.timeZone.value;
        let totalScheduleValues = [];
        _.forEach(this.dayScheduleNames, function (value) {
            const scheduleObjects = _.forEach(input[value[0]], function (value1) {
                if (isCreate) {
                    value1._create = true;
                }
                value1.startTime = _this.scheduleSvc.validateDateString(value1.startTime, 'HH:mm');
                value1.stopTime = _this.scheduleSvc.validateDateString(value1.stopTime, 'HH:mm');
            });
            totalScheduleValues.push(scheduleObjects);
        });
        totalScheduleValues = _.union(_.flattenDeep(totalScheduleValues), this.deletedSchedule);
        this.schedule.onHoursCreateItems = totalScheduleValues;
        this.schedule.errors = null;
        console.log(this.schedule);
        return this.schedule;
    }

    validateAllFormFields(formGroup: any) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }

    async onDelete() {
        console.log('Delete Schedule');
        if (!this.modalRef) {

            // makes it a modal window that cannot be dismissed by clicking outside.
            const options: NgbModalOptions = {
                backdrop: 'static',
                keyboard: false
            };

            this.deleteConfirmDisplayFlag = true;
            this.modalRef = this.modalSvc.open(DeleteConfirmationComponent, options);
            this.modalRef.componentInstance.title = 'schedule_delete_confirmation.title';
            this.modalRef.componentInstance.content = 'schedule_delete_confirmation.content';
            this.modalRef.result.then(async (result) => {
                this.modalRef = null;
                console.log(result);
                if (result === true) {
                    await this.handleDelete();
                } else {
                    this.toOpen = true; // keep the Edit side window open
                }
                this.deleteConfirmDisplayFlag = false;
            }, (reason) => {
                // this.closeReason = this.getDismissReason(reason);
                this.modalRef = null;
                this.deleteConfirmDisplayFlag = false;
                console.log(reason);
            });
        } else {
            console.log('modalRef not se to null.');
            this.modalRef = null;
        }
    }

    async handleDelete() {
        this.clearServerErrorOnForm();
        this.displayLoadingSpinner = true;
        await this.scheduleSvc.deleteSchedule(this.scheduleType, this.schedule.id)
            .toPromise().then(
                response => {
                    response = JSON.parse(response);
                    console.log('response from delete', response);
                    if (!_.isEmpty(response)) {
                        this.displayLoadingSpinner = false;
                        console.error(response);
                        this.showServerError(this.translateSvc.instant('error_messages.on_hours_schedule.failed_to_delete'));
                        this.parseDeleteError(response);
                    } else {
                        this.displayLoadingSpinner = false;
                        this.callOnClose(true);
                        this.insertMessage('on_hours.deleted_on_hours');
                    }
                    this.deleteConfirmDisplayFlag = false;
                },
                error => {
                    // if not caught here, will get an 'core.js:15714
                    // ERROR Error: Uncaught (in promise)' error without a useful stack trace
                    console.error('failed to delete onHours schedule ', error);
                    this.displayLoadingSpinner = false;
                    this.showServerError(this.translateSvc.instant('error_messages.on_hours_schedule.unable_to_delete') + error.message ? error.message : JSON.stringify(error));
                }
            );
    }

    parseServerError(message: string) {
        const splitMsg = message.split('Error:');

        this.errorMsg = splitMsg[1] ? splitMsg[1].split('\r\n') : [];
        if (this.errorMsg.length > 0) {
            this.errorMsg.forEach(err => {
                this.showErrorMessage(err);
            });
        }
    }

    parseDeleteError(message) {
        const msg = message.split('Error: delete - ');
        this.otherErr.push(msg[1]);
    }

    showErrorMessage(message) {
        const msg = message.split('-');
        if (msg[0].trim() === 'ScheduleName') {
            this.scheduleNameError = msg[1].trim();
        }
        console.log(msg);
    }

    showServerError(message) {
        this.errMsg = message;
        this.showFormError = true;
    }


    clearServerErrorOnForm() {
        this.errMsg = '';
        this.showFormError = false;
        this.scheduleNameError = '';
        this.otherErr = [];
    }

    openConfirmDialog() {
        if (!this.modalRef) {
            // makes it a modal window that cannot be dismissed by clicking outside.
            const options: NgbModalOptions = {
                backdrop: 'static',
                windowClass: 'confimation-box'
            };
            this.modalRef = this.modalSvc.open(NavigationConfirmationComponent, options);
            this.modalRef.result.then((result) => {
                this.modalRef = null;
                if (result === rightPanelCloseReason.LEAVE) {
                    this.callOnClose();
                }
            }, (reason) => {
                this.closeReason = this.getDismissReason(reason);
                this.modalRef = null;
            });
        } else {
            console.log('modalRef not set to null.');
            this.modalRef = null;
        }
    }

    getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return rightPanelCloseReason.ESC;
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return rightPanelCloseReason.BACKDROP;
        } else {
            return reason;
        }
    }

    onClose() {
        // TODO reset
        if (this.onHoursForm.dirty) {
            this.openConfirmDialog();
        } else {
            this.callOnClose();
        }
    }

    callOnClose(flowsUpdated = false) {
        this.activeModal.close(false);
        this.schedulesSvc.setClickedSchedule(null);
        this.close.emit(flowsUpdated);
    }

    rightPanelcancel() {
        this.onClose();
    }

    updateStartTime(event, index, mainKey) {
        this.onHoursForm.get('onHoursSection').get(mainKey)['controls'][index].controls['startTime'].setValue(event);
        this.onHoursForm.markAsDirty();
        this.validateStartStopTime(index, mainKey);
    }
    updateStopTime(event, index, mainKey) {
        this.onHoursForm.get('onHoursSection').get(mainKey)['controls'][index].controls['stopTime'].setValue(event);
        this.onHoursForm.markAsDirty();
        this.validateStartStopTime(index, mainKey);
    }

    validateStartStopTime(index, name) {
    const startTime = this.onHoursForm.get('onHoursSection')
        .get(name)['controls'][index].controls['startTime'].value;
    const stopTime = this.onHoursForm.get('onHoursSection')
        .get(name)['controls'][index].controls['stopTime'].value;
    if (!_.isNull(startTime) && typeof (startTime) === 'object' && !_.isNull(stopTime) && typeof (stopTime) === 'object') {
        const formattedStartTime = new Date(2019, 10, 16, startTime.getHours(), startTime.getMinutes(), 30, 0);
        const formattedStopTime = new Date(2019, 10, 16, stopTime.getHours(), stopTime.getMinutes(), 30, 0);
        if (formattedStopTime.getTime() === formattedStartTime.getTime()) {
            this.onHoursForm.get('onHoursSection')
                .get(name)['controls'][index].controls['stopTimeValidationErrorMessage'].setValue('custom.stop_time_equal_validation');
            this.updateStopTimeValidationCommon(index, name, 'stopTimeValidation', true);
            this.updateStopTimeValidationCommon(index, name, 'stopTimeValidationDisabled', '');
        } else if (formattedStopTime.getTime() < formattedStartTime.getTime()) {
            this.onHoursForm.get('onHoursSection')
                .get(name)['controls'][index].controls['stopTimeValidationErrorMessage'].setValue('custom.stop_time_greater_validation');
            this.updateStopTimeValidationCommon(index, name, 'stopTimeValidation', true);
            this.updateStopTimeValidationCommon(index, name, 'stopTimeValidationDisabled', '');
        } else {
            this.updateStopTimeValidationCommon(index, name, 'stopTimeValidation', false);
            this.updateStopTimeValidationCommon(index, name, 'stopTimeValidationDisabled', 'true');
        }
    } else {
        this.updateStopTimeValidationCommon(index, name, 'stopTimeValidation', false);
        this.updateStopTimeValidationCommon(index, name, 'stopTimeValidationDisabled', 'true');
    }
    }

    updateStopTimeValidationCommon(index, mainKey, name, value) {
        this.onHoursForm.get('onHoursSection')
            .get(mainKey)['controls'][index].controls[name].setValue(value);
    }

    ngOnDestroy() {
        this.schedulesSvc.setClickedSchedule(null);
        this.clickedScheduleSubscription.unsubscribe();
        this.rxjsSubscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}
