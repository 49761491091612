import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ExtensionListService } from '../../services/extension-list.service';
import { NavigationConfirmationComponent } from '../../navigation-confirmation/navigation-confirmation.component';
import { rightPanelCloseReason } from '../../shared/constants';
import { NotificationService } from '../../services/notification.service';
import { ExtensionListAdaptorService } from '../../services/extension-list-adaptor.service';

import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { BossApiUtils } from '../../shared/BossApiUtils';
import { DeleteConfirmationComponent } from '../../delete-confirmation/delete-confirmation.component';
import { EgLabels } from 'src/app/models/extension-group';
@Component({
  selector: 'app-extension-list-right-panel',
  templateUrl: './extension-list-right-panel.component.html',
  styleUrls: ['./extension-list-right-panel.components.scss']
})
export class ExtensionListRightPanelComponent implements OnInit, OnDestroy {
  @Input() popUp = false;
  @Input() dialogHeader = 'extensions.new_title';
  @Input() tabbable = true;
  @Input() toOpen: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() resetToOpen = new EventEmitter();
  modalRef: NgbModalRef;
  closeReason: string;
  extensionsForm: FormGroup;
  timeZones: any[] = [];

  submitting = false;
  showFormError = false;
  errMsg = '';
  errMsgStyle: any = {'max-width': '650px'};
  displayLoadingSpinner = false;
  availableExtensions = [];
  selectedExtensions = [];
  items = [{displayName: 'All', active: false, value: 'all'},
      {displayName: 'None', active: false, value: 'none'}];
  dropdownMainCheckBox = false;
  extension: any;
  clickedExtensionSubscription: Subscription;
  updateExtension = false;
  deleteConfirmDisplayFlag = false;
  minimumSelected = false;
  errorMsg: string[];
  otherErr: string[] = [];
  extensionNameError = '';
  rxjsSubscriptions: Subscription[] = []
  newExtensionGroup: boolean = true;
  translationsKeys: string[] = ['extensions.new_title', 'extensions.none_selected', 'extensions.all', 'extensions.selected'];
  egLabels: EgLabels = {
    newEGLabel: '',
    noneSelected: '',
    all: '',
    selected: ''
  }

  constructor(public translateSvc: TranslateService,
              private fb: FormBuilder,
              private activeModal: NgbActiveModal,
              private modalSvc: NgbModal,
              public extensionsSvc: ExtensionListService,
              private notificationService: NotificationService,
              private extensionSvc: ExtensionListAdaptorService) {
  }

  ngOnInit() {
    this.clickedExtensionSubscription = this.extensionsSvc.clickedExtensionChanged.subscribe(async extension => {
      this.extension = extension;
      if (extension !== null) {
        await this.setFormWithExtension();
      }
    });
    this.extension = this.extensionsSvc.getClickedExtension();
    this.updateExtension = !_.isEmpty(this.extension);
    this.initForm();
    this.setFormWithExtension();
    this.rxjsSubscriptions.push(this.translateSvc.stream(this.translationsKeys).subscribe(translations => {
      this.egLabels.newEGLabel = translations['extensions.new_title'];
      this.egLabels.noneSelected = translations['extensions.none_selected'];
      this.egLabels.all = translations['extensions.all'];
      this.egLabels.selected = translations['extensions.selected'];
    }));
  }

  async setFormWithExtension() {
    this.displayLoadingSpinner = true;
    this.extensionsForm.reset();
    if (!this.extension) {
      await this.extensionSvc.getNewExtension().toPromise().then (
          extension => {
            this.newExtensionGroup = true;
            this.extension = extension;
            this.extension.name = 'New Extension Group';
            this.displayLoadingSpinner = false;
          },
          error => {
            this.displayLoadingSpinner = false;
            console.error('failed to get New Extension', error);
            this.showServerError(
              this.translateSvc.instant('error_messages.extension_group.failed_to_get_new') + BossApiUtils.extractErrorMessage(error));
          }
      );
    } else {
      await this.extensionSvc.getExtension(this.extension.id).toPromise().then(
          extension => {
            this.newExtensionGroup = false;
            this.extension = extension;
          },
          error => {
            console.error('failed to fetch extension with id', this.extension.ExtensionListID, error);
            this.showServerError( this.translateSvc.instant('error_messages.extension_group.failed_to_fetch') + BossApiUtils.extractErrorMessage(error));
          }
      );
    }

    if (this.extension != null) {
      this.availableExtensions = this.extension.candidates;
      this.loadAvailableExtensions();
      this.reloadExtensionsWithAssigned(this.extension);
      this.extensionsForm.get('extensionSection').patchValue({
        name: this.newExtensionGroup ? this.egLabels.newEGLabel : this.extension.name,
        selectedExtensionList: this.extension.members
      });
      this.displayLoadingSpinner = false;
    }
  }

  reloadExtensionsWithAssigned(extension) {
    const selectedList = extension.members;
    const availExtensions = this.extension.candidates;
    const reAssignedExtensions = _.map(availExtensions, updateActiveFields);
    function updateActiveFields(item) {
      item['enable'] = _.includes(_.map(selectedList, 'id'), item.id);
      return item;
    }
    this.extensionsForm.get('extensionSection').get('availableExtensions').setValue(reAssignedExtensions);
    this.selectedExtensions = selectedList;
    this.reFormatSelectedExtensionsCountName();
  }

  async initForm() {
    this.extensionsForm = this.fb.group({
      extensionSection: this.fb.group({
        name: ['New Extension Group', Validators.required],
        selectedExtensionList: [],
        availableExtensions: this.fb.array([]),
      })
    });
  }

  getInitialExtensions(): FormGroup {
    return this.fb.group({
      enable: false,
      id: '',
      name: '',
      extension: ''
    });
  }

  loadAvailableExtensions() {
    this.initForm();
    this.availableExtensions = _.isUndefined(this.availableExtensions) ? [] : this.availableExtensions;
    if (this.availableExtensions.length > 0) {
      for (let i = 0; i < this.availableExtensions.length; i++) {
        this.addAvailableExtensions();
      }
    }
  }

  addAvailableExtensions() {
    const dupAvailExtensions = this.extensionsForm.get('extensionSection').get('availableExtensions') as FormArray;
    dupAvailExtensions.push(this.getInitialExtensions());
  }

  cancel() {
    if (this.popUp) {
      this.activeModal.close(false);
    } else {
      this.rightPanelcancel();
    }
  }

  async onSubmit() {
    this.submitting = true;
    this.clearServerErrorOnForm();
    if (this.extensionsForm.valid) {
      this.displayLoadingSpinner = true;
      if (this.extension && this.extension.id != null) {
        const params = this.getAlteredParams();
        console.log('Update Extension Group');
        await this.extensionSvc.updateExtension(this.extension.id, params)
            .toPromise().then(
                response => {
                  this.extension = response;
                  if (this.extension.errors != null && this.extension.errors !== '') {
                    console.error(this.extension.errors);
                    this.displayLoadingSpinner = false;
                    this.showServerError(this.translateSvc.instant('error_messages.extension_group.failed_to_update'));
                    this.parseServerError(this.extension.errors);
                  } else {
                      this.callOnClose(true);
                      this.insertMessage('extensions.updated_extension');
                    }
                },
                error => {
                  console.error('failed to update extension group', error);
                  this.displayLoadingSpinner = false;
                  this.translateSvc.get('error_messages').subscribe(err => {
                    this.showServerError( this.translateSvc.instant('error_messages.extension_group.unable_to_update') + BossApiUtils.extractErrorMessage(error));
                  });
                }
            );
        this.activeModal.close(false);
        this.submitting = false;
      } else {
        const params = this.getAlteredParams();
        console.log('Create Extension Group');
        await this.extensionSvc.createExtension(params)
            .toPromise().then(
                response => {
                  this.extension = response;
                  if (this.extension.errors != null && this.extension.errors !== '') {
                    console.error(this.extension.errors);
                    this.displayLoadingSpinner = false;
                    this.showServerError(this.translateSvc.instant('error_messages.extension_group.failed_to_create'));
                    this.parseServerError(this.extension.errors);
                  } else {
                    if (this.popUp) {
                      this.activeModal.close({id: this.extension.id, name: this.extension.name});
                    } else {
                      this.callOnClose(true);
                      this.insertMessage('extensions.created_extension');
                    }
                  }
                },
                error => {
                  console.error('failed to create extension group', error);
                  this.displayLoadingSpinner = false;
                  this.translateSvc.get('error_messages').subscribe(err => {
                    this.showServerError( this.translateSvc.instant('error_messages.extension_group.unable_to_create') + BossApiUtils.extractErrorMessage(error));
                  });
                }
            );
        this.activeModal.close(false);
        this.submitting = false;
      }
    } else {
      this.validateAllFormFields(this.extensionsForm.get('extensionSection'));
    }
  }

    getAlteredParams() {
    const input = this.extensionsForm.get('extensionSection').value;
    const items = this.selectedExtensions;
    this.extension.name = input.name;
    this.extension.members = items;
    this.extension.errors = null;
    console.log(this.extension);
    return this.extension;
  }

  async onDelete() {
    console.log('Delete Extension');
    if (!this.modalRef) {

      // makes it a modal window that cannot be dismissed by clicking outside.
      const options: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
      };

      this.deleteConfirmDisplayFlag = true;
      this.modalRef = this.modalSvc.open(DeleteConfirmationComponent, options);
      this.modalRef.componentInstance.title = 'extension_delete_confirmation.title';
      this.modalRef.componentInstance.content = 'extension_delete_confirmation.content';
      this.modalRef.result.then(async (result) => {
        this.modalRef = null;
        console.log(result);
        if (result === true) {
          await this.handleDelete();
        } else {
          this.toOpen = true; // keep the Edit side window open
        }
        this.deleteConfirmDisplayFlag = false;
      }, (reason) => {
        // this.closeReason = this.getDismissReason(reason);
        this.modalRef = null;
        this.deleteConfirmDisplayFlag = false;
        console.log(reason);
      });
    } else {
      console.log('modalRef not se to null.');
      this.modalRef = null;
    }
  }

  async handleDelete() {
    this.clearServerErrorOnForm();
    this.displayLoadingSpinner = true;
    await this.extensionSvc.deleteExtension(this.extension.id)
        .toPromise().then(
            response => {
              response = JSON.parse(response);
              console.log('response from delete', response);
              if (!_.isEmpty(response)) {
                this.displayLoadingSpinner = false;
                console.error(response);
                this.showServerError(this.translateSvc.instant('error_messages.extension_group.failed_to_delete'));
                this.parseDeleteError(response);
              } else {
                this.displayLoadingSpinner = false;
                this.callOnClose(true);
                this.insertMessage('extensions.deleted_extension');
              }
              this.deleteConfirmDisplayFlag = false;
            },
            error => {
              console.error('failed to delete extension', error);
              this.displayLoadingSpinner = false;
              this.showServerError( this.translateSvc.instant('error_messages.extension_group.unable_to_delete') + error.message ? error.message : JSON.stringify(error));
            }
            );
  }
  insertMessage(msg) {
    this.notificationService.updateMessage(msg);
  }

  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({onlySelf: true});
    });
  }

  parseServerError( message: string ) {
    const splitMsg =  message.split('Error:');

    this.errorMsg = splitMsg[1] ? splitMsg[1].split('\r\n') : [];
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(err => {
        this.showErrorMessage(err);
      });
    }
  }

  parseDeleteError(response) {
    const msg = JSON.parse(response.split('- ')[1])['message'];
    this.otherErr.push(msg);
  }

  showErrorMessage( message ) {
    const msg = message.split('-');
    if (msg[0].trim() === 'Name') {
      this.extensionNameError = msg[1].trim();
    }
    console.log(msg);
  }
  showServerError( message ) {
    this.errMsg = message;
    this.showFormError = true;
  }

  clearServerErrorOnForm() {
    this.errMsg = '';
    this.showFormError = false;
    this.extensionNameError = '';
    this.otherErr = [];
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return rightPanelCloseReason.ESC;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return rightPanelCloseReason.BACKDROP;
    } else {
      return reason;
    }
  }

  openConfirmDialog() {
    if (this.modalRef) {
      console.log('modalRef not se to null.');
      this.modalRef = null;
      return;
    }
    const options: NgbModalOptions = {
      backdrop: 'static',
      windowClass: 'confimation-box'
    };
    this.modalRef = this.modalSvc.open(NavigationConfirmationComponent, options);
    this.modalRef.result.then((result) => {
      this.modalRef = null;
      if (result === rightPanelCloseReason.LEAVE) {
        this.callOnClose();
      }
    }, (reason) => {
      this.closeReason = this.getDismissReason(reason);
      this.modalRef = null;
    });
  }

  onClose() {
    // TODO reset
    if (this.extensionsForm.dirty) {
      this.openConfirmDialog();
    } else {
      this.callOnClose();
    }
  }

  callOnClose(extensionUpdates = false) {
    // TODO reset
    this.extensionsSvc.setClickedExtension(null);
    this.close.emit(extensionUpdates);
  }

  rightPanelcancel() {
    this.onClose();
  }

  onCheckboxUpdate(event) {
    console.log(event);
    this.reloadExtensionsBasedOnDropdownSelection(event);
    this.extensionsForm.markAsDirty();
  }

  reloadExtensionsBasedOnDropdownSelection(value) {
    const availExtensions = _.cloneDeep(this.extensionsForm.value.extensionSection.availableExtensions);
    const reAssignedExtensions = _.map(availExtensions, updateActiveFields);

    function updateActiveFields(item) {
      item['enable'] = value;
      return item;
    }

    this.extensionsForm.get('extensionSection').get('availableExtensions').setValue(reAssignedExtensions);
    if (value) {
      this.selectedExtensions = reAssignedExtensions;
    } else {
      this.selectedExtensions = [];
    }
    this.reFormatSelectedExtensionsCountName();
  }

  reFormatSelectedExtensionsCountName() {
    this.selectedExtensions = _.isUndefined(this.selectedExtensions) ? [] : this.selectedExtensions;
    if (this.selectedExtensions.length <= 0) {
      this.dropdownMainCheckBox = false;
      this.minimumSelected = false;
      return this.egLabels.noneSelected;
    } else if (this.selectedExtensions.length === this.availableExtensions.length) {
      this.dropdownMainCheckBox = true;
      this.minimumSelected = false;
      return `${this.egLabels.all}${this.selectedExtensions.length}${this.egLabels.selected}`;
    } else {
      this.minimumSelected = true;
      return `${this.selectedExtensions.length}${this.egLabels.selected}`;
    }
  }

  selectExtensions(extension) {
    console.log(extension.value);
    const selectedExtensions = _.cloneDeep(this.selectedExtensions);
    const searchExtension = _.findIndex(selectedExtensions, {id: extension.value['id']});
    if (searchExtension < 0) {
      selectedExtensions.push(extension.value);
    } else {
      selectedExtensions.splice(searchExtension, 1);
    }
    this.selectedExtensions = selectedExtensions;
    this.reFormatSelectedExtensionsCountName();
  }

  ngOnDestroy() {
    this.extensionsSvc.setClickedExtension(null);
    this.clickedExtensionSubscription.unsubscribe();
    this.rxjsSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
