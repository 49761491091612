<span #cuxDatePickerWrapper>
<p-calendar
    *ngIf=viewReady
    [locale]="locale"
    [dateFormat]="dateFormat" 
    [timeOnly]="isTimeOnly"
    [hourFormat]="hourFormat"
    [placeholder]="placeHolder"
    [(ngModel)]="value"
    [showIcon]="showIcon" 
    (onSelect)="select($event)" 
    (onBlur)="blur($event)" 
    (onFocus)="focus($event)"
    (onClose)="close($event)" 
    (onShow)="show($event)" 
    (onClickOutside)="clickOutSide($event)"
    (onInput)="input($event)"
    [minDate]="minDate" 
    [maxDate]="maxDate"
    [appendTo]="appendTo"
></p-calendar>
</span>