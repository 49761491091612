/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UploadResultDC } from '../models/upload-result-dc';
import { EmergencyAniDC } from '../models/emergency-ani-dc';
import { EmergencyRegistrationDC } from '../models/emergency-registration-dc';
import { EmergencyRegistrationHistoryDC } from '../models/emergency-registration-history-dc';
import { E911ConsentDC } from '../models/e911consent-dc';
import { UpdateProfileRegistrationDC } from '../models/update-profile-registration-dc';
import { ProfileAddressDC } from '../models/profile-address-dc';
@Injectable({
  providedIn: 'root',
})
class EmergencyService extends __BaseService {
  static readonly EmergencyDownloadBusinessRegistrationPath = '/api/Account/BusinessRegistration';
  static readonly EmergencyUploadBusinessRegistrationPath = '/api/Account/BusinessRegistration';
  static readonly EmergencyCancelLocationRegistrationPath = '/api/Account/Locations/{locationUuid}/Emergency/Cancel';
  static readonly EmergencyDeregisterLocationPath = '/api/Account/Locations/{locationUuid}/Emergency/Deregister';
  static readonly EmergencyRegisterLocationPath = '/api/Account/Locations/{locationUuid}/Emergency/Register';
  static readonly EmergencyGetLocationRegistrationPath = '/api/Account/Locations/{locationUuid}/Emergency/Registration';
  static readonly EmergencyGetLocationRegistrationHistoryPath = '/api/Account/Locations/{locationUuid}/Emergency/Registration/History';
  static readonly EmergencyReplaceLocationAniPath = '/api/Account/Locations/{locationUuid}/Emergency/ReplaceANI';
  static readonly EmergencyGetAccountLocationsRegistrationPath = '/api/Account/Locations/Emergency/Registration';
  static readonly EmergencyGetProfileRegistrationHistoryPath = '/api/Account/Profile/{profileId}/Emergency/Registration/History';
  static readonly EmergencyAcceptE911ConsentsPath = '/api/Emergency/Account/AcceptE911Consents';
  static readonly EmergencyGetAccountE911ConsentsPath = '/api/Emergency/Account/E911Consents';
  static readonly EmergencyUpdateProfileEmergencyRegistrationPath = '/api/Emergency/Profile';
  static readonly EmergencyGetProfileAddressesPath = '/api/Emergency/Profile/{profileId}/Addresses';
  static readonly EmergencyGetActiveEmergencyRegistrationPath = '/api/Emergency/Registration';
  static readonly EmergencyGetRegistrationStatusesPath = '/api/EmergencyRegistrationStatuses';
  static readonly EmergencyDownloadProofOfResidencyPath = '/api/Locations/{locationUuid}/ProofOfResidency';
  static readonly EmergencyUploadProofOfResidencyPath = '/api/Locations/{locationUuid}/ProofOfResidency';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: EditE911 (121)
   * @param Authorization Access token
   * @return File response
   */
  EmergencyDownloadBusinessRegistrationResponse(Authorization: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/BusinessRegistration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param Authorization Access token
   * @return File response
   */
  EmergencyDownloadBusinessRegistration(Authorization: string): __Observable<Blob> {
    return this.EmergencyDownloadBusinessRegistrationResponse(Authorization).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyUploadBusinessRegistrationParams` containing the following parameters:
   *
   * - `Authorization`: Access token
   *
   * - `file`:
   *
   * @return OK
   */
  EmergencyUploadBusinessRegistrationResponse(params: EmergencyService.EmergencyUploadBusinessRegistrationParams): __Observable<__StrictHttpResponse<UploadResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
   if(params.file !== null && typeof params.file !== "undefined") { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/BusinessRegistration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadResultDC>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyUploadBusinessRegistrationParams` containing the following parameters:
   *
   * - `Authorization`: Access token
   *
   * - `file`:
   *
   * @return OK
   */
  EmergencyUploadBusinessRegistration(params: EmergencyService.EmergencyUploadBusinessRegistrationParams): __Observable<UploadResultDC> {
    return this.EmergencyUploadBusinessRegistrationResponse(params).pipe(
      __map(_r => _r.body as UploadResultDC)
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyCancelLocationRegistrationParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyCancelLocationRegistrationResponse(params: EmergencyService.EmergencyCancelLocationRegistrationParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Emergency/Cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyCancelLocationRegistrationParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyCancelLocationRegistration(params: EmergencyService.EmergencyCancelLocationRegistrationParams): __Observable<{}> {
    return this.EmergencyCancelLocationRegistrationResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyDeregisterLocationParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyDeregisterLocationResponse(params: EmergencyService.EmergencyDeregisterLocationParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Emergency/Deregister`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyDeregisterLocationParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyDeregisterLocation(params: EmergencyService.EmergencyDeregisterLocationParams): __Observable<{}> {
    return this.EmergencyDeregisterLocationResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyRegisterLocationParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyRegisterLocationResponse(params: EmergencyService.EmergencyRegisterLocationParams): __Observable<__StrictHttpResponse<EmergencyAniDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Emergency/Register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmergencyAniDC>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyRegisterLocationParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyRegisterLocation(params: EmergencyService.EmergencyRegisterLocationParams): __Observable<EmergencyAniDC> {
    return this.EmergencyRegisterLocationResponse(params).pipe(
      __map(_r => _r.body as EmergencyAniDC)
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyGetLocationRegistrationParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyGetLocationRegistrationResponse(params: EmergencyService.EmergencyGetLocationRegistrationParams): __Observable<__StrictHttpResponse<Array<EmergencyRegistrationDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Emergency/Registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmergencyRegistrationDC>>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyGetLocationRegistrationParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyGetLocationRegistration(params: EmergencyService.EmergencyGetLocationRegistrationParams): __Observable<Array<EmergencyRegistrationDC>> {
    return this.EmergencyGetLocationRegistrationResponse(params).pipe(
      __map(_r => _r.body as Array<EmergencyRegistrationDC>)
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyGetLocationRegistrationHistoryParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyGetLocationRegistrationHistoryResponse(params: EmergencyService.EmergencyGetLocationRegistrationHistoryParams): __Observable<__StrictHttpResponse<EmergencyRegistrationHistoryDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Emergency/Registration/History`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmergencyRegistrationHistoryDC>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyGetLocationRegistrationHistoryParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyGetLocationRegistrationHistory(params: EmergencyService.EmergencyGetLocationRegistrationHistoryParams): __Observable<EmergencyRegistrationHistoryDC> {
    return this.EmergencyGetLocationRegistrationHistoryResponse(params).pipe(
      __map(_r => _r.body as EmergencyRegistrationHistoryDC)
    );
  }

  /**
   * Required Permission: ReplaceEmergencyPhoneNumbers (235)
   * @param params The `EmergencyService.EmergencyReplaceLocationAniParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyReplaceLocationAniResponse(params: EmergencyService.EmergencyReplaceLocationAniParams): __Observable<__StrictHttpResponse<EmergencyAniDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Locations/${params.locationUuid}/Emergency/ReplaceANI`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmergencyAniDC>;
      })
    );
  }
  /**
   * Required Permission: ReplaceEmergencyPhoneNumbers (235)
   * @param params The `EmergencyService.EmergencyReplaceLocationAniParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyReplaceLocationAni(params: EmergencyService.EmergencyReplaceLocationAniParams): __Observable<EmergencyAniDC> {
    return this.EmergencyReplaceLocationAniResponse(params).pipe(
      __map(_r => _r.body as EmergencyAniDC)
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param Authorization Access token
   * @return OK
   */
  EmergencyGetAccountLocationsRegistrationResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: Array<EmergencyRegistrationDC>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Locations/Emergency/Registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: Array<EmergencyRegistrationDC>}>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param Authorization Access token
   * @return OK
   */
  EmergencyGetAccountLocationsRegistration(Authorization: string): __Observable<{[key: string]: Array<EmergencyRegistrationDC>}> {
    return this.EmergencyGetAccountLocationsRegistrationResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: Array<EmergencyRegistrationDC>})
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyGetProfileRegistrationHistoryParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyGetProfileRegistrationHistoryResponse(params: EmergencyService.EmergencyGetProfileRegistrationHistoryParams): __Observable<__StrictHttpResponse<EmergencyRegistrationHistoryDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Profile/${params.profileId}/Emergency/Registration/History`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmergencyRegistrationHistoryDC>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyGetProfileRegistrationHistoryParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyGetProfileRegistrationHistory(params: EmergencyService.EmergencyGetProfileRegistrationHistoryParams): __Observable<EmergencyRegistrationHistoryDC> {
    return this.EmergencyGetProfileRegistrationHistoryResponse(params).pipe(
      __map(_r => _r.body as EmergencyRegistrationHistoryDC)
    );
  }

  /**
   * Required Permission: AcceptE911Terms (321)
   * @param params The `EmergencyService.EmergencyAcceptE911ConsentsParams` containing the following parameters:
   *
   * - `countryIds`: Array of CountryIds
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyAcceptE911ConsentsResponse(params: EmergencyService.EmergencyAcceptE911ConsentsParams): __Observable<__StrictHttpResponse<Array<E911ConsentDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.countryIds;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Emergency/Account/AcceptE911Consents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<E911ConsentDC>>;
      })
    );
  }
  /**
   * Required Permission: AcceptE911Terms (321)
   * @param params The `EmergencyService.EmergencyAcceptE911ConsentsParams` containing the following parameters:
   *
   * - `countryIds`: Array of CountryIds
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyAcceptE911Consents(params: EmergencyService.EmergencyAcceptE911ConsentsParams): __Observable<Array<E911ConsentDC>> {
    return this.EmergencyAcceptE911ConsentsResponse(params).pipe(
      __map(_r => _r.body as Array<E911ConsentDC>)
    );
  }

  /**
   * Required Permission: AcceptE911Terms (321)
   * @param Authorization Access token
   * @return OK
   */
  EmergencyGetAccountE911ConsentsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<E911ConsentDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Emergency/Account/E911Consents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<E911ConsentDC>>;
      })
    );
  }
  /**
   * Required Permission: AcceptE911Terms (321)
   * @param Authorization Access token
   * @return OK
   */
  EmergencyGetAccountE911Consents(Authorization: string): __Observable<Array<E911ConsentDC>> {
    return this.EmergencyGetAccountE911ConsentsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<E911ConsentDC>)
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyUpdateProfileEmergencyRegistrationParams` containing the following parameters:
   *
   * - `profileRegistration`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyUpdateProfileEmergencyRegistrationResponse(params: EmergencyService.EmergencyUpdateProfileEmergencyRegistrationParams): __Observable<__StrictHttpResponse<EmergencyRegistrationDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.profileRegistration;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Emergency/Profile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmergencyRegistrationDC>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyUpdateProfileEmergencyRegistrationParams` containing the following parameters:
   *
   * - `profileRegistration`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyUpdateProfileEmergencyRegistration(params: EmergencyService.EmergencyUpdateProfileEmergencyRegistrationParams): __Observable<EmergencyRegistrationDC> {
    return this.EmergencyUpdateProfileEmergencyRegistrationResponse(params).pipe(
      __map(_r => _r.body as EmergencyRegistrationDC)
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyGetProfileAddressesParams` containing the following parameters:
   *
   * - `profileId`: Profile ID
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyGetProfileAddressesResponse(params: EmergencyService.EmergencyGetProfileAddressesParams): __Observable<__StrictHttpResponse<Array<ProfileAddressDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Emergency/Profile/${params.profileId}/Addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProfileAddressDC>>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyGetProfileAddressesParams` containing the following parameters:
   *
   * - `profileId`: Profile ID
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  EmergencyGetProfileAddresses(params: EmergencyService.EmergencyGetProfileAddressesParams): __Observable<Array<ProfileAddressDC>> {
    return this.EmergencyGetProfileAddressesResponse(params).pipe(
      __map(_r => _r.body as Array<ProfileAddressDC>)
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyGetActiveEmergencyRegistrationParams` containing the following parameters:
   *
   * - `Authorization`: Access token
   *
   * - `emergencyAniInE164Format`: ANI in E164 Format.
   *
   * - `emergencyAniCountryId`: ANI country ID (ISO 3166).
   *
   * - `emergencyAni`: National ANI.
   *
   * @return OK
   */
  EmergencyGetActiveEmergencyRegistrationResponse(params: EmergencyService.EmergencyGetActiveEmergencyRegistrationParams): __Observable<__StrictHttpResponse<EmergencyRegistrationDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.emergencyAniInE164Format != null) __params = __params.set('emergencyAniInE164Format', params.emergencyAniInE164Format.toString());
    if (params.emergencyAniCountryId != null) __params = __params.set('emergencyAniCountryId', params.emergencyAniCountryId.toString());
    if (params.emergencyAni != null) __params = __params.set('emergencyAni', params.emergencyAni.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Emergency/Registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmergencyRegistrationDC>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyGetActiveEmergencyRegistrationParams` containing the following parameters:
   *
   * - `Authorization`: Access token
   *
   * - `emergencyAniInE164Format`: ANI in E164 Format.
   *
   * - `emergencyAniCountryId`: ANI country ID (ISO 3166).
   *
   * - `emergencyAni`: National ANI.
   *
   * @return OK
   */
  EmergencyGetActiveEmergencyRegistration(params: EmergencyService.EmergencyGetActiveEmergencyRegistrationParams): __Observable<EmergencyRegistrationDC> {
    return this.EmergencyGetActiveEmergencyRegistrationResponse(params).pipe(
      __map(_r => _r.body as EmergencyRegistrationDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  EmergencyGetRegistrationStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/EmergencyRegistrationStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  EmergencyGetRegistrationStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.EmergencyGetRegistrationStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyDownloadProofOfResidencyParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return File response
   */
  EmergencyDownloadProofOfResidencyResponse(params: EmergencyService.EmergencyDownloadProofOfResidencyParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Locations/${params.locationUuid}/ProofOfResidency`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyDownloadProofOfResidencyParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * @return File response
   */
  EmergencyDownloadProofOfResidency(params: EmergencyService.EmergencyDownloadProofOfResidencyParams): __Observable<Blob> {
    return this.EmergencyDownloadProofOfResidencyResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyUploadProofOfResidencyParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * - `file`:
   *
   * @return OK
   */
  EmergencyUploadProofOfResidencyResponse(params: EmergencyService.EmergencyUploadProofOfResidencyParams): __Observable<__StrictHttpResponse<UploadResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
   if(params.file !== null && typeof params.file !== "undefined") { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Locations/${params.locationUuid}/ProofOfResidency`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadResultDC>;
      })
    );
  }
  /**
   * Required Permission: EditE911 (121)
   * @param params The `EmergencyService.EmergencyUploadProofOfResidencyParams` containing the following parameters:
   *
   * - `locationUuid`:
   *
   * - `Authorization`: Access token
   *
   * - `file`:
   *
   * @return OK
   */
  EmergencyUploadProofOfResidency(params: EmergencyService.EmergencyUploadProofOfResidencyParams): __Observable<UploadResultDC> {
    return this.EmergencyUploadProofOfResidencyResponse(params).pipe(
      __map(_r => _r.body as UploadResultDC)
    );
  }
}

module EmergencyService {

  /**
   * Parameters for EmergencyUploadBusinessRegistration
   */
  export interface EmergencyUploadBusinessRegistrationParams {

    /**
     * Access token
     */
    Authorization: string;
    file?: Blob;
  }

  /**
   * Parameters for EmergencyCancelLocationRegistration
   */
  export interface EmergencyCancelLocationRegistrationParams {
    locationUuid: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyDeregisterLocation
   */
  export interface EmergencyDeregisterLocationParams {
    locationUuid: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyRegisterLocation
   */
  export interface EmergencyRegisterLocationParams {
    locationUuid: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyGetLocationRegistration
   */
  export interface EmergencyGetLocationRegistrationParams {
    locationUuid: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyGetLocationRegistrationHistory
   */
  export interface EmergencyGetLocationRegistrationHistoryParams {
    locationUuid: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyReplaceLocationAni
   */
  export interface EmergencyReplaceLocationAniParams {
    locationUuid: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyGetProfileRegistrationHistory
   */
  export interface EmergencyGetProfileRegistrationHistoryParams {
    profileId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyAcceptE911Consents
   */
  export interface EmergencyAcceptE911ConsentsParams {

    /**
     * Array of CountryIds
     */
    countryIds: Array<number>;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyUpdateProfileEmergencyRegistration
   */
  export interface EmergencyUpdateProfileEmergencyRegistrationParams {
    profileRegistration: UpdateProfileRegistrationDC;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyGetProfileAddresses
   */
  export interface EmergencyGetProfileAddressesParams {

    /**
     * Profile ID
     */
    profileId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyGetActiveEmergencyRegistration
   */
  export interface EmergencyGetActiveEmergencyRegistrationParams {

    /**
     * Access token
     */
    Authorization: string;

    /**
     * ANI in E164 Format.
     */
    emergencyAniInE164Format?: string;

    /**
     * ANI country ID (ISO 3166).
     */
    emergencyAniCountryId?: number;

    /**
     * National ANI.
     */
    emergencyAni?: string;
  }

  /**
   * Parameters for EmergencyDownloadProofOfResidency
   */
  export interface EmergencyDownloadProofOfResidencyParams {
    locationUuid: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for EmergencyUploadProofOfResidency
   */
  export interface EmergencyUploadProofOfResidencyParams {
    locationUuid: string;

    /**
     * Access token
     */
    Authorization: string;
    file?: Blob;
  }
}

export { EmergencyService }
