/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AccountPartnerDC } from '../models/account-partner-dc';
import { SupportPartnerInviteDC } from '../models/support-partner-invite-dc';
import { RevokeSupportPartnerInviteDC } from '../models/revoke-support-partner-invite-dc';
@Injectable({
  providedIn: 'root',
})
class PartnersService extends __BaseService {
  static readonly PartnersGetAccountAWPartnersPath = '/api/Account/AWPartners';
  static readonly PartnersGetAccountPartnersPath = '/api/Account/Partners';
  static readonly PartnersGetAccountSupportPartnersPath = '/api/Account/SupportPartners';
  static readonly PartnersCreateInvitePath = '/api/Account/SupportPartners';
  static readonly PartnersGetSupportPartnerByCodePath = '/api/Account/SupportPartners/{partnerCode}';
  static readonly PartnersLogoPath = '/api/Account/SupportPartners/{partnerCode}/Logo';
  static readonly PartnersGetAccountVarPartnersPath = '/api/Account/VarPartners';
  static readonly PartnersGetAccountPartnerRelationshipTypesPath = '/api/AccountPartnerRelationshipTypes';
  static readonly PartnersGetAccountPartnerStatusesPath = '/api/AccountPartnerStatuses';
  static readonly PartnersRevokeSupportPartnerInvitePath = '/api/SupportPartnerInvites/Revoke';
  static readonly PartnersGetSupportPartnerStatusesPath = '/api/SupportPartnerStatuses';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: ViewAWPartner (354)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountAWPartnersResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<AccountPartnerDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/AWPartners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AccountPartnerDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewAWPartner (354)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountAWPartners(Authorization: string): __Observable<Array<AccountPartnerDC>> {
    return this.PartnersGetAccountAWPartnersResponse(Authorization).pipe(
      __map(_r => _r.body as Array<AccountPartnerDC>)
    );
  }

  /**
   * Required Permission: ManagePartnerAccess (219), EditSupportPartner (10317), ViewVarPartner (10326), ViewAWPartner (354)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountPartnersResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<AccountPartnerDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Partners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AccountPartnerDC>>;
      })
    );
  }
  /**
   * Required Permission: ManagePartnerAccess (219), EditSupportPartner (10317), ViewVarPartner (10326), ViewAWPartner (354)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountPartners(Authorization: string): __Observable<Array<AccountPartnerDC>> {
    return this.PartnersGetAccountPartnersResponse(Authorization).pipe(
      __map(_r => _r.body as Array<AccountPartnerDC>)
    );
  }

  /**
   * Required Permission: ManagePartnerAccess (219), EditSupportPartner (10317)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountSupportPartnersResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<AccountPartnerDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/SupportPartners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AccountPartnerDC>>;
      })
    );
  }
  /**
   * Required Permission: ManagePartnerAccess (219), EditSupportPartner (10317)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountSupportPartners(Authorization: string): __Observable<Array<AccountPartnerDC>> {
    return this.PartnersGetAccountSupportPartnersResponse(Authorization).pipe(
      __map(_r => _r.body as Array<AccountPartnerDC>)
    );
  }

  /**
   * Required Permission: EditSupportPartner (10317)
   * @param params The `PartnersService.PartnersCreateInviteParams` containing the following parameters:
   *
   * - `invite`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PartnersCreateInviteResponse(params: PartnersService.PartnersCreateInviteParams): __Observable<__StrictHttpResponse<AccountPartnerDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.invite;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/SupportPartners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccountPartnerDC>;
      })
    );
  }
  /**
   * Required Permission: EditSupportPartner (10317)
   * @param params The `PartnersService.PartnersCreateInviteParams` containing the following parameters:
   *
   * - `invite`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PartnersCreateInvite(params: PartnersService.PartnersCreateInviteParams): __Observable<AccountPartnerDC> {
    return this.PartnersCreateInviteResponse(params).pipe(
      __map(_r => _r.body as AccountPartnerDC)
    );
  }

  /**
   * Required Permission: ManagePartnerAccess (219), EditSupportPartner (10317), ViewSupportPartnerRequests (10320)
   * @param params The `PartnersService.PartnersGetSupportPartnerByCodeParams` containing the following parameters:
   *
   * - `partnerCode`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PartnersGetSupportPartnerByCodeResponse(params: PartnersService.PartnersGetSupportPartnerByCodeParams): __Observable<__StrictHttpResponse<AccountPartnerDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/SupportPartners/${params.partnerCode}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccountPartnerDC>;
      })
    );
  }
  /**
   * Required Permission: ManagePartnerAccess (219), EditSupportPartner (10317), ViewSupportPartnerRequests (10320)
   * @param params The `PartnersService.PartnersGetSupportPartnerByCodeParams` containing the following parameters:
   *
   * - `partnerCode`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PartnersGetSupportPartnerByCode(params: PartnersService.PartnersGetSupportPartnerByCodeParams): __Observable<AccountPartnerDC> {
    return this.PartnersGetSupportPartnerByCodeResponse(params).pipe(
      __map(_r => _r.body as AccountPartnerDC)
    );
  }

  /**
   * Required Permission: ViewVarPartner (10326)
   * @param params The `PartnersService.PartnersLogoParams` containing the following parameters:
   *
   * - `partnerCode`:
   *
   * - `Authorization`: Access token
   *
   * @return File response
   */
  PartnersLogoResponse(params: PartnersService.PartnersLogoParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/SupportPartners/${params.partnerCode}/Logo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Required Permission: ViewVarPartner (10326)
   * @param params The `PartnersService.PartnersLogoParams` containing the following parameters:
   *
   * - `partnerCode`:
   *
   * - `Authorization`: Access token
   *
   * @return File response
   */
  PartnersLogo(params: PartnersService.PartnersLogoParams): __Observable<Blob> {
    return this.PartnersLogoResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Required Permission: ViewVarPartner (10326)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountVarPartnersResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<AccountPartnerDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/VarPartners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AccountPartnerDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewVarPartner (10326)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountVarPartners(Authorization: string): __Observable<Array<AccountPartnerDC>> {
    return this.PartnersGetAccountVarPartnersResponse(Authorization).pipe(
      __map(_r => _r.body as Array<AccountPartnerDC>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountPartnerRelationshipTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AccountPartnerRelationshipTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountPartnerRelationshipTypes(Authorization: string): __Observable<{[key: string]: string}> {
    return this.PartnersGetAccountPartnerRelationshipTypesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountPartnerStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AccountPartnerStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetAccountPartnerStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.PartnersGetAccountPartnerStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: EditSupportPartner (10317)
   * @param params The `PartnersService.PartnersRevokeSupportPartnerInviteParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PartnersRevokeSupportPartnerInviteResponse(params: PartnersService.PartnersRevokeSupportPartnerInviteParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SupportPartnerInvites/Revoke`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditSupportPartner (10317)
   * @param params The `PartnersService.PartnersRevokeSupportPartnerInviteParams` containing the following parameters:
   *
   * - `request`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PartnersRevokeSupportPartnerInvite(params: PartnersService.PartnersRevokeSupportPartnerInviteParams): __Observable<{}> {
    return this.PartnersRevokeSupportPartnerInviteResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetSupportPartnerStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SupportPartnerStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  PartnersGetSupportPartnerStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.PartnersGetSupportPartnerStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }
}

module PartnersService {

  /**
   * Parameters for PartnersCreateInvite
   */
  export interface PartnersCreateInviteParams {
    invite: SupportPartnerInviteDC;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for PartnersGetSupportPartnerByCode
   */
  export interface PartnersGetSupportPartnerByCodeParams {
    partnerCode: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for PartnersLogo
   */
  export interface PartnersLogoParams {
    partnerCode: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for PartnersRevokeSupportPartnerInvite
   */
  export interface PartnersRevokeSupportPartnerInviteParams {
    request: RevokeSupportPartnerInviteDC;

    /**
     * Access token
     */
    Authorization: string;
  }
}

export { PartnersService }
