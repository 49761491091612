import { Injectable } from '@angular/core';
import { ContractsService } from '../modules/boss-api/generated/services';
import { ContractsService as ContractsServicePrevious } from '../modules/boss-api-previous/generated/services';
import { SDApiVersionControlService } from 'src/app/services/sdapi-version-control';
import { Observable } from 'rxjs';

export interface ContractDetails {
  serviceCommitmentDate?: string;
  contractProfileAmount?: number;
  currentProfileAmount?: number;
  builtProfileAmount?: number;
}

@Injectable({
  providedIn: 'root'
})

export class CommitmentDetailsAdaptorService {

  contractService: ContractsService | ContractsServicePrevious;
  constructor(private sdapiVersion: SDApiVersionControlService) {
    this.contractService = this.sdapiVersion.contractsService;
  }

  getCommitmentAmountDetails(): Observable<ContractDetails> {
    return this.contractService.ContractsGetContractCommitmentDetails(null);
  }
}
