import { Injectable } from '@angular/core';
import { CallFlowsService as BossApiCallFlowsService } from '../modules/boss-api/generated/services';
import { UsersService as BossApiUsersService } from '../modules/boss-api/generated/services';
import { CallFlowsService as BossApiCallFlowsServicePrevious } from '../modules/boss-api-previous/generated/services';
import { UsersService as BossApiUsersServicePrevious } from '../modules/boss-api-previous/generated/services';
import { SDApiVersionControlService } from 'src/app/services/sdapi-version-control';
import { mergeMap, map } from 'rxjs/operators';
import { ValidateExtensionDC } from '../modules/boss-api/generated/models/validate-extension-dc';
import {
  CallFlowDC, HuntGroupDC, HuntGroupMembersDC, HuntGroupMemberCandidatesDC,
  AutoAttendantDC, SubMenuDC, SubMenuItemDC, CommonElementDC,
  CallForwardDestinationDC, CallForwardCandidatesDC, PromptDC
} from '../modules/boss-api/generated/models';
import { MediaService as TranscodeApiService } from '../transcode-modules/boss-api/generated/services';
import { Observable } from 'rxjs';
import { PhoneNumberFormatService } from './phone-number-format.service';
import * as _ from 'lodash';


export class Flow {
  id: number;
  name: string;
  componentType: string;
  tn: string;
  tnCountryId: number;
  locationUuid: string;
  locationName: string;
  extension: string;
  canDelete: boolean;
}

export class HuntGroup {
  id: string;
  dnuUid: string;
  name: string;
  locationUUID: string;
  locationName: string;
  backupDN: string;
  backupDNFormatted: string;
  tenantId: number;
  extensionFormatted: string;
  extension: string;
  e164Tn: string;
  tnId: string;
  tnCountryId: number;
  rcfTarget: string;
  managementGUID: string;
  releaseTn: boolean;
  makeNumberPrivate: boolean;
  includeInDialByName: boolean;
  ringMobileUsers: boolean;
  huntPatternID: number;
  ringsPerMember: number;
  noAnswerRings: number;
  cfBusy: string;
  cfBusyFormatted: string;
  cfNoAnswer: string;
  cfNoAnswerFormatted: string;
  onHoursScheduleId: number;
  holidayScheduleId: number;
  cfOffHoursHoliday: string;
  cfOffHoursHolidayFormatted: string;
  ringAllMembers: boolean;
  callForwardOption: number;
  huntGroupMembers: Array<HuntGroupMember>;
  onHourSchedules?: Array<CommonElement>;
  holidaySchedules?: Array<CommonElement>;
  errors: string;
}

export class CommonElement {
  value: string;          // aka id
  displayName: string;
}
export class HuntGroupMember {
  extension: string;
  extensionFormatted: string;
  description: string;
}

export class CallForwardDestination {
  extension: string;
  extensionFormatted: string;
  description: string;
}

export class ValidateExtension {
  isValid: boolean;
  errorMessage: string;
  suggestedExtension: string;
  extension: string;
}

export class HuntGroupMemberCandidates {
  errors: Array<string>;
  totalCount: number;
  recordCount: number;
  candidates: Array<HuntGroupMembersDC>;
}

export class CallForwardCandidates {
  errors: Array<string>;
  totalCount: number;
  recordCount: number;
  candidates: Array<CallForwardDestinationDC>;
}

/****************** begin AA **********************/
export class AutoAttendant {
  id: string;
  dnuUid: string;
  name: string;
  locationUUID: string;
  locationName: string;
  tenantId: number;
  // phoneNumber: string;
  extensionFormatted: string;
  extension: string;
  languageId: number;
  makeNumberPrivate: boolean;
  e164Tn: string;
  tnId: string;
  tnCountryId: number;
  rcfTarget: string;
  managementGUID: string;
  releaseTn: boolean;
  languages?: Array<CommonElement>;
  autoAttendants?: Array<CommonElement>;
  huntGroups?: Array<CommonElement>;
  extensionList?: Array<CommonElement>;
  subMenus: Array<SubMenu>;
  errors: string;
}

export class SubMenu {
  id: string;
  timeTypeId: number;
  scheduleId: number;
  promptId: number;
  promptText: string;
  timeout: number;
  suppressRecordingWarningTone: boolean;
  prompts?: PromptDC;
  subMenuItems: Array<SubMenuItem>;
  // errors: Array<string>;

}

export class SubMenuItem {
  id?: string;
  keyPadId: number;
  opcodeId: number;
  extension: string;
  extensionFormatted: string;
  extensionListId: number;
  _create: boolean;
  // createExtensionType: number;

}

export class PagedFlows {
  flows: Flow[];
  count: number;
  constructor(flows, count) {
    this.flows = flows;
    this.count = count;
  }
}

/****************** end AA **********************/

/**
 * Manages  Front End Flow objects by mapping to server-side REST API calls.
 */@Injectable({
  providedIn: 'root'
})
export class CallflowAdaptorService {

  private readonly authToken = null;  // empty so that our  auth interceptor will handle this. Else should be 'Bearer <token>'
  bossCallFlowsService: BossApiCallFlowsService | BossApiCallFlowsServicePrevious;
  bossUsersService: BossApiUsersService | BossApiUsersServicePrevious;
  translationsMap = [
    { id: "1", description: "aa.schedules.on_hours" },
    { id: "185", description: "aa.schedules.new_on_hours_schedule" },
    { id: "205", description: "aa.schedules.test_on_hours" },
    { id: "181", description: "aa.schedules.new_holiday_schedule" },
    { id: "206", description: "aa.schedules.test_holiday" },
    { id: "33", description: "aa.schedules.new_extension" },
    { id: "43", description: "aa.schedules.test_extension" },
    { id: "240", description: "aa.schedules.new_extension_group_1" },
  ];
  constructor(private transcodeMediaService: TranscodeApiService,
    private phoneNumberFormatService: PhoneNumberFormatService,
    private sdapiVersion: SDApiVersionControlService) {
    this.bossCallFlowsService = this.sdapiVersion.callFlowsService;
    this.bossUsersService = this.sdapiVersion.usersService;
  }

  getPagedFlows(params: BossApiCallFlowsService.CallFlowsGetCallFlowsByAccountPagedParams): Observable<PagedFlows> {
    return this.bossCallFlowsService.CallFlowsGetCallFlowsByAccountPaged(params)
      .pipe(
        mergeMap(async pageResults => {
          const tempFlows = await this.makeFlows(pageResults.items);
          const pagedFlows = new PagedFlows(tempFlows, pageResults.count);
          return pagedFlows;
        })
      );
  }

  getFlows(): Observable<Flow[]> {

    return this.bossCallFlowsService.CallFlowsGetCallFlowsByAccount(this.authToken)
      .pipe(

        // mergeMap
        // wait for promise to resolve, then return resolved result. In this case, flows. See  https://stackoverflow.com/a/53650135
        mergeMap(async flows => {
          return await this.makeFlows(flows); // returns a Promise
        })
        // mergeMap( flows => {
        //
        // return this.makeFlows(flows); // returns a Promise
      );
  }

  getNewHuntGroup(): Observable<HuntGroup> {

    return this.bossCallFlowsService.CallFlowsGetDefaultHuntGroupSettings(this.authToken)
      .pipe(

        mergeMap(huntgroup => {

          return this.makeHuntGroup(huntgroup); // returns a Promise

        })
      );
  }

  validateExtension(extn: string): Observable<ValidateExtension> {

    return this.bossUsersService.UsersExtensionValidation({ Authorization: this.authToken, extension: extn })
      .pipe(

        mergeMap(validateExtensionResponse => {

          return this.makeValidateExtensionResponse(validateExtensionResponse); // returns a Promise

        })
      );
  }

  getHuntGroup(id: number): Observable<HuntGroup> {

    return this.bossCallFlowsService.CallFlowsGetHuntGroupById({ HuntGroupId: id, Authorization: this.authToken })
      .pipe(

        mergeMap(huntgroup => {

          return this.makeHuntGroup(huntgroup); // returns a Promise

        })
      );

  }

  public createHuntGroup(hgData: HuntGroup, phoneNumber: any): Observable<HuntGroup> {

    const members: Array<HuntGroupMembersDC> = [];
    hgData.huntGroupMembers.forEach(mem => {
      members.push(this.makeHuntGroupMembersDC(mem));
    });

    const onHoursSchedules: Array<CommonElementDC> = [];
    onHoursSchedules.push(this.makeScheduleDC({ value: 'none', displayName: 'aa.schedules.none_applied' }));
    onHoursSchedules.push(this.makeScheduleDC({ value: 'new', displayName: 'aa.schedules.new_on_hours' }));
    hgData.onHourSchedules.forEach(schedule => {
      onHoursSchedules.push(this.makeScheduleDC(schedule));
    });

    const holidaySchedules: Array<CommonElementDC> = [];
    holidaySchedules.push(this.makeScheduleDC({ value: 'none', displayName: 'aa.schedules.none_applied' }));
    holidaySchedules.push(this.makeScheduleDC({ value: 'new', displayName: 'aa.schedules.new_holiday' }));
    hgData.holidaySchedules.forEach(schedule => {
      holidaySchedules.push(this.makeScheduleDC(schedule));
    });

    const createHgDC: HuntGroupDC = {

      id: hgData.id,
      dnuUid: hgData.dnuUid,
      name: hgData.name,
      locationUUID: hgData.locationUUID,
      locationName: hgData.locationName,
      tenantId: hgData.tenantId,
      extensionFormatted: hgData.extensionFormatted,
      extension: hgData.extension,
      makeNumberPrivate: hgData.makeNumberPrivate,
      includeInDialByName: hgData.includeInDialByName,
      ringMobileUsers: hgData.ringMobileUsers,
      huntPatternID: hgData.huntPatternID,
      ringsPerMember: hgData.ringsPerMember,
      noAnswerRings: hgData.noAnswerRings,
      cfBusy: hgData.cfBusy,
      cfBusyFormatted: hgData.cfBusyFormatted,
      cfNoAnswer: hgData.cfNoAnswer,
      cfNoAnswerFormatted: hgData.cfNoAnswerFormatted,
      onHoursScheduleId: hgData.onHoursScheduleId,
      holidayScheduleId: hgData.holidayScheduleId,
      cfOffHoursHoliday: hgData.cfOffHoursHoliday,
      cfOffHoursHolidayFormatted: hgData.cfOffHoursHolidayFormatted,
      ringAllMembers: hgData.ringAllMembers,
      callForwardOption: hgData.callForwardOption,
      backupDN: hgData.backupDN,
      backupDNFormatted: hgData.backupDNFormatted,
      errors: hgData.errors,
      huntGroupMembers: members,
      onHourSchedules: onHoursSchedules,
      holidaySchedules: holidaySchedules,
      e164Tn: phoneNumber,
      tnId: hgData.tnId,
      tnCountryId: hgData.tnCountryId,
      releaseTn: hgData.releaseTn,
      rcfTarget: hgData.rcfTarget,
      managementGUID: hgData.managementGUID

    };

    return this.bossCallFlowsService.CallFlowsCreateHuntGroup({ huntgroupDC: createHgDC, Authorization: this.authToken })
      .pipe(

        // convert to huntgroup object
        mergeMap(createHuntgroupResponse => {

          return this.makeHuntGroup(createHuntgroupResponse)
            .then(huntgroup => {
              return Promise.resolve(huntgroup);
            });
        })
      );
  }

  public updateHuntGroup(id: number, hgData: HuntGroup, phoneNumber: any): Observable<HuntGroup> {

    const members: Array<HuntGroupMembersDC> = [];
    hgData.huntGroupMembers.forEach(mem => {
      members.push(this.makeHuntGroupMembersDC(mem));
    });
    const onHoursSchedules: Array<CommonElementDC> = [];
    onHoursSchedules.push(this.makeScheduleDC({ value: 'none', displayName: 'aa.schedules.none_applied' }));
    onHoursSchedules.push(this.makeScheduleDC({ value: 'new', displayName: 'aa.schedules.new_on_hours' }));
    hgData.onHourSchedules.forEach(schedule => {
      onHoursSchedules.push(this.makeScheduleDC(schedule));
    });

    const holidaySchedules: Array<CommonElementDC> = [];
    holidaySchedules.push(this.makeScheduleDC({ value: 'none', displayName: 'aa.schedules.none_applied' }));
    onHoursSchedules.push(this.makeScheduleDC({ value: 'new', displayName: 'aa.schedules.new_holiday' }));
    hgData.holidaySchedules.forEach(schedule => {
      holidaySchedules.push(this.makeScheduleDC(schedule));
    });

    const updateHgDC: HuntGroupDC = {

      id: hgData.id,
      dnuUid: hgData.dnuUid,
      name: hgData.name,
      locationUUID: hgData.locationUUID,
      locationName: hgData.locationName,
      tenantId: hgData.tenantId,
      extensionFormatted: hgData.extensionFormatted,
      extension: hgData.extension,
      makeNumberPrivate: hgData.makeNumberPrivate,
      includeInDialByName: hgData.includeInDialByName,
      ringMobileUsers: hgData.ringMobileUsers,
      huntPatternID: hgData.huntPatternID,
      ringsPerMember: hgData.ringsPerMember,
      noAnswerRings: hgData.noAnswerRings,
      cfBusy: hgData.cfBusy,
      cfBusyFormatted: hgData.cfBusyFormatted,
      cfNoAnswer: hgData.cfNoAnswer,
      cfNoAnswerFormatted: hgData.cfNoAnswerFormatted,
      onHoursScheduleId: hgData.onHoursScheduleId,
      holidayScheduleId: hgData.holidayScheduleId,
      cfOffHoursHoliday: hgData.cfOffHoursHoliday,
      cfOffHoursHolidayFormatted: hgData.cfOffHoursHolidayFormatted,
      ringAllMembers: hgData.ringAllMembers,
      callForwardOption: hgData.callForwardOption,
      backupDN: hgData.backupDN,
      backupDNFormatted: hgData.backupDNFormatted,
      errors: hgData.errors,
      huntGroupMembers: members,
      onHourSchedules: onHoursSchedules,
      holidaySchedules: holidaySchedules,
      e164Tn: phoneNumber,
      tnId: hgData.tnId,
      tnCountryId: hgData.tnCountryId,
      releaseTn: hgData.releaseTn,
      rcfTarget: hgData.rcfTarget,
      managementGUID: hgData.managementGUID
    };

    return this.bossCallFlowsService.CallFlowsUpdateHuntGroup({ huntgroupDC: updateHgDC, HuntGroupId: id, Authorization: this.authToken })
      .pipe(

        // convert to huntgroup object
        mergeMap(updateHuntgroupResponse => {

          return this.makeHuntGroup(updateHuntgroupResponse)
            .then(huntgroup => {
              return Promise.resolve(huntgroup);
            });
        })
      );
  }

  public deleteHuntGroup(huntGroupId: number): Observable<Array<string>> {
    return this.bossCallFlowsService.CallFlowsDeleteHuntGroup({ HuntGroupId: huntGroupId, Authorization: this.authToken })
      .pipe(

        map(apiResponse => {
          return apiResponse;
        })
      );
  }

  getMemberList(filter: string, selectedHgMembers = []): Observable<HuntGroupMemberCandidates> {

    const orderBy = 'DN';
    filter = filter == null ? '' : filter + '';
    if (filter === '') {
      filter = '*';
    } else if (filter != null && filter.indexOf(' ') > -1) {
      filter = filter.replace(' ', ',');
    }
    console.log('getMemberList matching filter', filter);

    function filterSelectedMembers(member) {
      if (_.includes(selectedHgMembers, member.extensionFormatted)) {
        return [];
      }
      return member;
    }

    return this.bossCallFlowsService.CallFlowsGetHuntGroupMemberCandidates({
      searchCriteria: filter, rows: 7, orderBy: orderBy, Authorization: this.authToken
    })
      .pipe(

        // convert to response object
        mergeMap(candidatesResponse => {

          return this.makeHuntGroupMemberCandidates(candidatesResponse)
            .then(candidates => {
              candidates.candidates = _.flatten(_.map(candidates.candidates, filterSelectedMembers));
              return Promise.resolve(candidates);
            });
        })
      );
  }

  getCallForwardDestinations(filter: string, cfType: number): Observable<CallForwardCandidates> {

    const orderBy = 'DN';
    filter = filter == null ? '' : filter + '';
    if (filter.trim() === '') {
      filter = '*';
    } else if (filter.indexOf(' ') > -1) {
      filter = filter.replace(' ', ',');
    }
    console.log('getCallForwardDestinations matching filter', filter);

    return this.bossCallFlowsService.CallFlowsGetCallForwardDestinations({
      SortOrder: orderBy,
      SearchCriteria: filter, Rows: 10, CFType: cfType, Authorization: this.authToken
    })
      .pipe(

        // convert to response object
        mergeMap(response => {

          return this.makeCallforwardDestinationsCandidates(response)
            .then(candidates => {
              return Promise.resolve(candidates);
            });
        })
      );
  }

  /*************** begin AA **************/

  getNewAA(): Observable<AutoAttendant> {

    return this.bossCallFlowsService.CallFlowsGetDefaultAutoAttendantSettings(this.authToken)
      .pipe(

        mergeMap(aa => {
          return this.makeAA(aa); // returns a Promise
        })
      );
  }

  getAA(id: number): Observable<AutoAttendant> {

    return this.bossCallFlowsService.CallFlowsGetAutoAttendantById({ AutoAttendantId: id, Authorization: this.authToken })
      .pipe(

        mergeMap(aa => {
          return this.makeAA(aa); // returns a Promise
        })
      );

  }

  public createAA(aaData: AutoAttendant, phoneNumber: any): Observable<AutoAttendant> {

    // let members: Array<HuntGroupMembersDC> = []
    // aaData.huntGroupMembers.forEach(mem => {
    //   members.push(this.makeHuntGroupMembersDC(mem));
    // })

    const languages: Array<CommonElementDC> = [];
    aaData.languages.forEach(obj => {
      languages.push(this.makeDropdownDC(obj));
    });

    const autoAttendants: Array<CommonElementDC> = [];
    aaData.autoAttendants.forEach(obj => {
      autoAttendants.push(this.makeDropdownDC(obj));
    });
    autoAttendants.push(this.makeDropdownDC({ value: 'new_aa', displayName: 'New auto-attendant...' }));

    const huntGroups: Array<CommonElementDC> = [];
    aaData.huntGroups.forEach(obj => {
      huntGroups.push(this.makeDropdownDC(obj));
    });
    huntGroups.push(this.makeDropdownDC({ value: 'new_hg', displayName: 'New hunt group...' }));

    const extensionList: Array<CommonElementDC> = [];
    extensionList.push(this.makeDropdownDC({ value: '0', displayName: 'Everyone' }));
    aaData.extensionList.forEach(obj => {
      extensionList.push(this.makeDropdownDC(obj));
    });
    extensionList.push(this.makeDropdownDC({ value: 'new_ext_list', displayName: 'New extension group...' }));

    const createAADC: AutoAttendantDC = {

      id: aaData.id,
      dnuUid: aaData.dnuUid,
      name: aaData.name,
      locationUUID: aaData.locationUUID,
      locationName: aaData.locationName,
      tenantId: aaData.tenantId,
      // phoneNumber: aaData.phoneNumber,
      extensionFormatted: aaData.extensionFormatted,
      extension: aaData.extension,
      makeNumberPrivate: aaData.makeNumberPrivate,
      languageId: aaData.languageId,
      subMenus: aaData.subMenus,
      languages: languages,
      autoAttendants: autoAttendants,
      huntGroups: huntGroups,
      extensionList: extensionList,
      errors: aaData.errors,
      e164Tn: phoneNumber,
      tnId: aaData.tnId,
      tnCountryId: aaData.tnCountryId,
      releaseTn: aaData.releaseTn,
      rcfTarget: aaData.rcfTarget,
      managementGUID: aaData.managementGUID
    };

    return this.bossCallFlowsService.CallFlowsCreateAutoAttendant({
      autoattendantDC: createAADC, Authorization: this.authToken
    })
      .pipe(
        mergeMap(apiResponse => {
          return this.makeAA(apiResponse)
            .then(aa => {
              return Promise.resolve(aa);
            });
        })
      );
  }

  public updateAA(id: number, aaData: AutoAttendant, phoneNumber: any): Observable<AutoAttendant> {

    // let members: Array<HuntGroupMembersDC> = []
    // aaData.huntGroupMembers.forEach(mem => {
    //   members.push(this.makeHuntGroupMembersDC(mem));
    // })
    const languages: Array<CommonElementDC> = [];
    aaData.languages.forEach(obj => {
      languages.push(this.makeDropdownDC(obj));
    });

    const autoAttendants: Array<CommonElementDC> = [];
    aaData.autoAttendants.forEach(obj => {
      autoAttendants.push(this.makeDropdownDC(obj));
    });
    autoAttendants.push(this.makeDropdownDC({ value: 'new_aa', displayName: 'New auto-attendant...' }));

    const huntGroups: Array<CommonElementDC> = [];
    aaData.huntGroups.forEach(obj => {
      huntGroups.push(this.makeDropdownDC(obj));
    });
    huntGroups.push(this.makeDropdownDC({ value: 'new_hg', displayName: 'New hunt group...' }));

    const extensionList: Array<CommonElementDC> = [];
    extensionList.push(this.makeDropdownDC({ value: '0', displayName: 'Everyone' }));
    aaData.extensionList.forEach(obj => {
      extensionList.push(this.makeDropdownDC(obj));
    });
    extensionList.push(this.makeDropdownDC({ value: 'new_ext_list', displayName: 'New extension group...' }));

    const updateAADC: AutoAttendantDC = {

      id: aaData.id,
      dnuUid: aaData.dnuUid,
      name: aaData.name,
      locationUUID: aaData.locationUUID,
      locationName: aaData.locationName,
      tenantId: aaData.tenantId,
      // phoneNumber: aaData.phoneNumber,
      extensionFormatted: aaData.extensionFormatted,
      extension: aaData.extension,
      makeNumberPrivate: aaData.makeNumberPrivate,
      languageId: aaData.languageId,
      subMenus: aaData.subMenus,
      languages: languages,
      autoAttendants: autoAttendants,
      huntGroups: huntGroups,
      extensionList: extensionList,
      errors: aaData.errors,
      e164Tn: phoneNumber,
      tnId: aaData.tnId,
      tnCountryId: aaData.tnCountryId,
      releaseTn: aaData.releaseTn,
      rcfTarget: aaData.rcfTarget,
      managementGUID: aaData.managementGUID
    };

    // TODO : CHANGE THE api CALL once update API is available
    return this.bossCallFlowsService.CallFlowsUpdateAutoAttendant({
      autoAttendantDC: updateAADC,
      AutoAttendantId: id,
      Authorization: this.authToken
    })
      .pipe(
        mergeMap(apiResponse => {
          return this.makeAA(apiResponse)
            .then(aa => {
              return Promise.resolve(aa);
            });
        })
      );
  }

  public deleteAA(id: number): Observable<Array<string>> {
    return this.bossCallFlowsService.CallFlowsDeleteAutoAttendant({
      AutoAttendantId: id, Authorization: this.authToken
    })
      .pipe(
        map(apiResponse => {
          return apiResponse;
        })
      );
  }

  public createRecordings(id, timeType, fileData): Observable<any> {
    return this.bossCallFlowsService.CallFlowsPostAutoAttendantPrompt({
      Content: JSON.stringify(fileData),
      TimeTypeId: timeType,
      AutoAttendantId: id,
      Authorization: this.authToken
    })
      .pipe(
        map(apiResponse => {
          return apiResponse;
        })
      );
  }

  public getRecordings(id, timeType, fileName): Observable<any> {
    return this.bossCallFlowsService.CallFlowsGetAutoAttendantPrompt({
      FileName: fileName,
      TimeTypeId: timeType,
      AutoAttendantId: id,
      Authorization: this.authToken
    })
      .pipe(
        map(apiResponse => {
          return apiResponse;
        })
      );
  }

  public transcode(fileData, format): Observable<any> {
    return this.transcodeMediaService.transcodeMedia({
      content: JSON.stringify(fileData),
      encoding: format
    })
      .pipe(
        map(apiResponse => {
          return apiResponse;
        })
      );
  }

  // public audioConversion(content: string): Observable<any> {
  //     return this.bossCallFlowsService.PromptConvertMp3({
  //         content: content,
  //         encoding: 'mulaw',
  //         Authorization: this.authToken})
  //         .pipe(
  //             map(apiResponse => {
  //                 return apiResponse;
  //             })
  //         );
  // }
  /**************** end AA ********************** */

  /**
   *
   * @param person Build a Flow object from various inputs.
   * @param location
   */
  private makeHuntGroup(hg: HuntGroupDC): Promise<HuntGroup> {

    const members: Array<HuntGroupMember> = [];
    hg.huntGroupMembers.forEach(mem => {
      members.push(this.makeHuntGroupMembers(mem));
    });
    const onHoursSchedules: Array<CommonElement> = [];
    onHoursSchedules.push(this.makeSchedules({ id: 'none', description: 'aa.schedules.none_applied' }));
    onHoursSchedules.push(this.makeSchedules({ id: 'new', description: 'aa.schedules.new_on_hours' }));
    hg.onHourSchedules.forEach(schedules => {
      const translationKey = this.getTranslationKey(schedules);
      schedules.description = translationKey !== undefined ? translationKey : schedules.description;
      onHoursSchedules.push(this.makeSchedules(schedules));
    });
    const holidaySchedules: Array<CommonElement> = [];
    holidaySchedules.push(this.makeSchedules({ id: 'none', description: 'aa.schedules.none_applied' }));
    holidaySchedules.push(this.makeSchedules({ id: 'new', description: 'aa.schedules.new_holiday' }));
    hg.holidaySchedules.forEach(schedules => {
      const translationKey = this.getTranslationKey(schedules);
      schedules.description = translationKey !== undefined ? translationKey : schedules.description;
      holidaySchedules.push(this.makeSchedules(schedules));
    });
    const huntgroup = {
      id: hg.id,
      dnuUid: hg.dnuUid,
      name: hg.name,
      locationUUID: hg.locationUUID,
      locationName: hg.locationName,
      tenantId: hg.tenantId,
      // phoneNumber: hg.phoneNumber,
      extensionFormatted: hg.extensionFormatted,
      extension: hg.extension,
      makeNumberPrivate: hg.makeNumberPrivate,
      includeInDialByName: hg.includeInDialByName,
      ringMobileUsers: hg.ringMobileUsers,
      huntPatternID: hg.huntPatternID,
      ringsPerMember: hg.ringsPerMember,
      noAnswerRings: hg.noAnswerRings,
      cfBusy: hg.cfBusy,
      cfBusyFormatted: hg.cfBusyFormatted,
      cfNoAnswer: hg.cfNoAnswer,
      cfNoAnswerFormatted: hg.cfNoAnswerFormatted,
      onHoursScheduleId: hg.onHoursScheduleId,
      holidayScheduleId: hg.holidayScheduleId,
      cfOffHoursHoliday: hg.cfOffHoursHoliday,
      cfOffHoursHolidayFormatted: hg.cfOffHoursHolidayFormatted,
      ringAllMembers: hg.ringAllMembers,
      callForwardOption: hg.callForwardOption,
      backupDN: hg.backupDN,
      backupDNFormatted: hg.backupDNFormatted,
      e164Tn: hg.e164Tn,
      tnId: hg.tnId,
      tnCountryId: hg.tnCountryId,
      rcfTarget: hg.rcfTarget,
      managementGUID: hg.managementGUID,
      releaseTn: hg.releaseTn,
      errors: hg.errors,
      huntGroupMembers: members,
      onHourSchedules: onHoursSchedules,
      holidaySchedules: holidaySchedules
    };
    return Promise.resolve(huntgroup);
  }


  private makeHuntGroupMemberCandidates(hgCandidates: HuntGroupMemberCandidatesDC): Promise<HuntGroupMemberCandidates> {

    const members: Array<HuntGroupMember> = [];
    hgCandidates.candidates.forEach(mem => {
      members.push(this.makeHuntGroupMembers(mem));
    });
    const memberCandidatesResponse = {
      errors: hgCandidates.errors,
      totalCount: hgCandidates.totalCount,
      recordCount: hgCandidates.recordCount,
      candidates: members
    };
    return Promise.resolve(memberCandidatesResponse);
  }

  private makeCallforwardDestinationsCandidates(cfCandidates: CallForwardCandidatesDC): Promise<CallForwardCandidates> {

    const destinations: Array<HuntGroupMember> = [];
    cfCandidates.candidates.forEach(destination => {
      destinations.push(this.makeCallForwardDestination(destination));
    });
    const cfCandidatesResponse = {
      errors: cfCandidates.errors,
      totalCount: cfCandidates.totalCount,
      recordCount: cfCandidates.recordCount,
      candidates: destinations
    };
    return Promise.resolve(cfCandidatesResponse);
  }



  private makeHuntGroupMembers(member: HuntGroupMembersDC): HuntGroupMember {
    return {
      extension: member.extension,
      extensionFormatted: member.extensionFormatted,
      description: member.description,
    };
  }

  private makeCallForwardDestination(destination: CallForwardDestinationDC): CallForwardDestination {
    return {
      extension: destination.extension,
      extensionFormatted: destination.extensionFormatted,
      description: destination.description,
    };
  }



  private makeSchedules(schedules: CommonElementDC): CommonElement {
    return {
      value: schedules.id,
      displayName: schedules.description,
    };
  }


  private makeFlow(flow: CallFlowDC): Flow {
    //         tn: flow.tn ? flow.tn + ' x' + flow.extension : 'x' + flow.extension,
    return {
      id: flow.id,
      name: flow.name.toUpperCase(),
      componentType: flow.componentType,
      tn: flow.tn,
      tnCountryId: flow.tnCountryId,
      locationUuid: flow.locationUuid,
      locationName: flow.locationName,
      extension: flow.extension,
      canDelete: flow.canDelete
    };
  }

  async processPhoneNumber(flows) {
    for (const flow of flows) {
      if (flow.tn !== null) {
        const formattedpn = await this.phoneNumberFormatService.
          formatPhoneNumberBasedOnCountry(flow.tn);
        flow.tn = formattedpn + ' x' + flow.extension;
      } else if (flow.extension && (flow.extension !== '')) {
        flow.tn = 'x' + flow.extension;
      } else {
        flow.tn = '';
      }
    }
  }

  private async makeFlows(flows: CallFlowDC[]): Promise<Flow[]> {

    const callflows: Flow[] = [];
    flows.forEach(flow => {
      callflows.push(this.makeFlow(flow));
    });
    this.processPhoneNumber(callflows);
    return Promise.resolve(callflows);
  }

  private makeHuntGroupMembersDC(member: HuntGroupMember): HuntGroupMembersDC {
    return {
      extension: member.extension,
      extensionFormatted: member.extensionFormatted,
      description: member.description,
    };
  }

  private makeScheduleDC(schedule: CommonElement): CommonElementDC {
    return {
      id: schedule.value,
      description: schedule.displayName
    };
  }

  private makeValidateExtensionResponse(extnObj: ValidateExtensionDC): Promise<ValidateExtension> {
    const extn = {
      isValid: extnObj.isValid,
      errorMessage: extnObj.errorMessage,
      suggestedExtension: extnObj.suggestedExtension,
      extension: extnObj.extension,
    };
    return Promise.resolve(extn);
  }

  /*************** begin AA **************/

  private makeDropdownsNoId(obj: CommonElementDC): CommonElement {
    return {
      value: obj.id,
      displayName: obj.description,
    };
  }

  private makeDropdowns(obj: CommonElementDC): CommonElement {
    return {
      value: obj.id,
      displayName: obj.id + ' : ' + obj.description,
    };
  }

  private makeDropdownDC(obj: CommonElement): CommonElementDC {
    return {
      id: obj.value,
      description: obj.displayName
    };
  }

  private makeAA(aa: AutoAttendantDC): Promise<AutoAttendant> {

    const members: Array<SubMenu> = [];
    aa.subMenus.forEach(mem => {
      mem.id = aa.extension + ',' + mem.timeTypeId;
      members.push(this.makeSubMenus(mem));
    });

    const languages: Array<CommonElement> = [];
    aa.languages.forEach(obj => {
      languages.push(this.makeDropdownsNoId(obj));
    });

    const autoAttendants: Array<CommonElement> = [];
    aa.autoAttendants.forEach(obj => {
      autoAttendants.push(this.makeDropdowns(obj));
    });
    autoAttendants.push(this.makeDropdownsNoId({ id: 'new_aa', description: 'aa.schedules.new_auto_attendant' }));

    const huntGroups: Array<CommonElement> = [];
    aa.huntGroups.forEach(obj => {
      huntGroups.push(this.makeDropdowns(obj));
    });
    huntGroups.push(this.makeDropdownsNoId({ id: 'new_hg', description: 'aa.schedules.new_hunt_group' }));

    const extensionList: Array<CommonElement> = [];
    extensionList.push(this.makeDropdownsNoId({ id: '0', description: 'aa.schedules.everyone' }));
    aa.extensionList.forEach(obj => {
      obj.description = this.getTranslationKey(obj);
      extensionList.push(this.makeDropdownsNoId(obj));
    });
    extensionList.push(this.makeDropdownsNoId({ id: 'new_ext_list', description: 'aa.schedules.new_extension_group' }));

    const aa0 = {
      id: aa.id,
      dnuUid: aa.dnuUid,
      extension: aa.extension,
      extensionFormatted: aa.extensionFormatted,
      name: aa.name,
      locationUUID: aa.locationUUID,
      locationName: aa.locationName,
      tenantId: aa.tenantId,
      // phoneNumber: aa.phoneNumber,
      languageId: aa.languageId,
      languages: languages,
      autoAttendants: autoAttendants,
      huntGroups: huntGroups,
      extensionList: extensionList,
      subMenus: members,
      makeNumberPrivate: aa.makeNumberPrivate,
      includeInDialByName: aa.includeInDialByName,
      schedules: aa.schedules,
      e164Tn: aa.e164Tn,
      tnId: aa.tnId,
      tnCountryId: aa.tnCountryId,
      rcfTarget: aa.rcfTarget,
      managementGUID: aa.managementGUID,
      releaseTn: aa.releaseTn,
      errors: aa.errors
    };

    return Promise.resolve(aa0);
  }

  private makeSubMenus(m0: SubMenuDC): SubMenu {
    const sm: Array<SubMenuItem> = [];
    m0.subMenuItems.forEach(mem => {
      sm.push(this.makeSubMenuItems(mem));
    });

    // null should be replaced by real prompts in drop4. one prompt per schedule tab.
    const p0 = this.makePrompts(m0.prompts);

    return {
      id: m0.id,
      timeTypeId: m0.timeTypeId,
      scheduleId: m0.scheduleId,
      promptId: m0.promptId,
      timeout: m0.timeout,
      promptText: m0.promptText,
      suppressRecordingWarningTone: m0.suppressRecordingWarningTone,
      subMenuItems: sm,
      prompts: p0,
    };
  }

  private makeSubMenusDC(sm0: SubMenu): SubMenuDC {
    return {
      id: sm0.id,
      timeTypeId: sm0.timeTypeId,
      scheduleId: sm0.scheduleId,
      promptId: sm0.promptId,
      timeout: sm0.timeout,
      promptText: sm0.promptText,
      suppressRecordingWarningTone: sm0.suppressRecordingWarningTone,
    };
  }

  private makeSubMenuItems(item0: SubMenuItemDC): SubMenuItem {
    if (item0.id) {
      return {
        id: item0.id,
        keyPadId: item0.keypadId,
        opcodeId: item0.opcodeId,
        extension: item0.extension,
        extensionFormatted: item0.extensionFormatted,
        extensionListId: item0.extensionListId,
        _create: item0._create
        // createExtensionType: m0.createExtensionType
      };
    } else {
      return {
        keyPadId: item0.keypadId,
        opcodeId: item0.opcodeId,
        extension: item0.extension,
        extensionFormatted: item0.extensionFormatted,
        extensionListId: item0.extensionListId,
        _create: item0._create
        // createExtensionType: m0.createExtensionType
      };
    }

  }

  private makePrompts(p0: PromptDC): PromptDC {
    return {
      id: p0 ? p0.id : null,
      fileName: p0 ? p0.fileName : null,
      fileDuration: p0 ? p0.fileDuration : 0,
      _create: p0 ? p0._create : false
    };
  }

  private getTranslationKey(schedule: CommonElementDC): string | undefined {
    const translation = this.translationsMap.find(({ id }) => id === schedule.id);
    return translation ? translation.description : schedule.description;
  }

}
