import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, Observable } from 'rxjs';

import { UserClaims } from '@mitel/cloudlink-sdk';
import { Account } from '@mitel/cloudlink-sdk/admin';
import { AuthenticationService, Config, Token } from '@mitel/cloudlink-sdk';
import { CompanySwitcherService } from '@mitel/cloudlink-console-components';
import { environment } from '../../environments/environment';
import { User, UserRole } from '../services/user-adaptor.service';
import { AccountDC, AccountPartnerDC } from '../modules/boss-api/generated/models';
import { SDApiVersionControlService } from 'src/app/services/sdapi-version-control';
import { AccountsService } from '../modules/boss-api/generated/services';
import { AccountsService as AccountsServicePrevious } from '../modules/boss-api-previous/generated/services';
import { take, shareReplay, switchMap, tap } from 'rxjs/operators';
import { PartnersService } from 'src/app/services/partners.service';

export class MajorAlert {
  constructor(public headline: string, public body: string) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppUserClaimsService {
  public authSvc: AuthenticationService;
  private claims: UserClaims;
  public claimsChanged = new ReplaySubject<UserClaims>(1); // Last 1 and future emits are broadcasted to all subscribed.
  private accountDCSource = new Subject<any>();
  public accountDC$ = this.accountDCSource.asObservable();
  public loggedInUser: User;
  public countryCode: number;

  private initialCompany: Account;
  private currentCompany: Account;
  public companyChanged = new Subject<Account>();

  private bossRoles: UserRole[] = [];

  /**
   * Will trigger a popup modal containing an important message.
   * Message is in the subject.
   *
   * @memberof AppUserClaimsService
   */
  public majorAlert = new Subject<MajorAlert>();
  /**
* When this fires, it indicates that you can safely call getBossRoles()
*/
  public bossRoleIsReady = new ReplaySubject<boolean>(1);

  private invokedAccountId: string;
  private invokeRole = 'ACCOUNT_ADMIN'; // default
  isABossUser = false;
  /**
   * true= application claims & company are loaded. Ready to access SDApi.
   *
   * @type {ReplaySubject<boolean>}
   * @memberof AppUserClaimsService
   */
  applnIdentityIsLoaded: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  // a map of BossApi Observable objects by accountId
  private getBossAccountObservableCache: {
    [accountId: string]: Observable<AccountDC>;
  } = {};

  // Target Account used by TargetAccess.interceptor
  private targetAccount: string;

  private accountPartner: AccountPartnerDC;

  permissions: number[];
  bossAccountsService: AccountsService | AccountsServicePrevious;
  constructor(
    private partnersService: PartnersService,
    private companySwitcherService: CompanySwitcherService,
    private sdapiVersion: SDApiVersionControlService,
  ) {
    Config.cloud = environment.cloud;
    Config.authentication = null;
    this.authSvc = Config.authentication;
    this.bossAccountsService = this.sdapiVersion.accountsService;
  }

  setCountryCode(code) {
    this.countryCode = code;
  }

  getCountryCode() {
    return this.countryCode;
  }

  getClaims() {
    return this.claims;
  }

  setClaims(claims: UserClaims) {
    this.claims = claims;
    this.claimsChanged.next(this.claims);
  }

  setBossRoles(roles: UserRole[]) {
    this.bossRoles = roles;
    this.bossRoleIsReady.next(true);
  }

  getBossRoles(): UserRole[] {
    return this.bossRoles;
  }

  hasPermission(permission: number) {
    return this.permissions.find(item => item === permission);
  }

  setPermissions(permissions: number[]) {
    this.permissions = permissions;
  }

  /**
  * See bossRoleIsReady to learn when bossRoles gets populated
  */
  getPermissions(): number[] {
    return this.permissions;
  }

  setBossUserType(isBossUser: boolean) {
    this.isABossUser = isBossUser;
  }

  isBossUser() {
    return this.isABossUser;
  }

  setInvokedParams(accountId: string, role: string) {
    this.invokedAccountId = accountId;
    this.invokeRole = role;
  }

  getCompany() {
    return this.currentCompany;
  }

  // If the company has never been set before, assume that the token is good for the current company.
  // 'initialCompany' will always be the company of the logged in user
  // 'company' is the currently viewed company
  // if the company passed in is not equal to the current company
  //    if initialCompany == undefined then just assign the initialCompany and company variables
  //    if initialCompany is defined and the new company is equal to the initialCompany and company variables, do nothing
  //    if initialCompany is defined and the new company is equal to the initialCompany but not the current company, revert the role
  //    if initialCompany is defined and the new company is NOT equal to the initialCompany is NOT the current company, and the initalCompany and current company are equal, then assume the new role
  //    if initialCompany is defined and the new company is NOT equal to the initialCompany is NOT the current company, and the initalCompany and current company are NOT equal, then revert the role, then assume the new role
  async setCompany(company: Account) {
    // console.log('APPSVC: initialCompany = ', this.initialCompany);
    // console.log('APPSVC: currentCompany = ', this.currentCompany);
    // console.log('APPSVC: new company = ', company);
    const assumeRole = false;
    const revertRole = false;

    // if initialCompany is defined and the new company is equal to the initialCompany and company variables, do nothing
    if (this.currentCompany && this.currentCompany.accountId === company.accountId) {
      // console.log('SET COMPANY: There is no change required', this.currentCompany);
      return;
    }

    // if the company passed in is not equal to the current company

    // if initialCompany == undefined then just assign the initialCompany and company variables
    if (this.initialCompany === undefined && this.currentCompany === undefined) {
      // console.log('SET COMPANY: This is the first time the company is set, TOKEN is not changed', company);
      this.initialCompany = company;
      this.currentCompany = company;
      this.companyChanged.next(this.currentCompany);
      return;
    }

    // **no need for revert role right now so commenting this out
    // if initialCompany is defined and the new company is equal to the initialCompany but not the current company, revert the role
    if (this.initialCompany.accountId === company.accountId && this.currentCompany.accountId !== company.accountId) {
      // console.log('SET COMPANY: Call REVERT ROLE to initial company', company);
      // TBD how to handle the promise
      console.log('about to call revertRole');
      return this.authSvc.revertRole()
        .then(token => {
          if (token) {
            // console.log('Successfully reverted the Role ', token);
            this.currentCompany = company;
            this.companyChanged.next(this.currentCompany);
            return token;
          } else {
            console.log('Revert role token not set');
          }
        })
        .catch(error => {
          console.error('error reverting token', error);
          return error;
        });
    }

    // if initialCompany is defined and the new company is NOT equal to the initialCompany is NOT the current company, and the initalCompany and current company are equal, then assume the new role
    if (this.initialCompany.accountId !== company.accountId &&
      this.currentCompany.accountId !== company.accountId &&
      this.initialCompany.accountId === this.currentCompany.accountId) {
      // console.log('SET COMPANY: Call ASSUME ROLE to new company', company);
      // TBD how to handle the promise
      const params = { accountId: company.accountId, role: this.invokeRole };
      console.log('about to call assumeRole');
      return this.authSvc.assumeRole(params)
        .then(token => {
          if (token) {
            // console.log('Successfully set assumed role token ', token);
            return this.updateUserClaimsUsingToken(token).then(() => {
              this.setCurrentCompany(company);
              return token;
            });
            // this.currentCompany = company;
            // this.companyChanged.next(this.currentCompany);
            // return token;
          } else {
            console.log('Assumed role token not set');
          }

        })
        .catch(error => {
          console.error('error creating token', error);
          return error;
        });
    }


    // **no need for revert role right now so commenting this out
    // if initialCompany is defined and the new company is NOT equal to the initialCompany is NOT the current company, and the initalCompany and current company are NOT equal, then revert the role, then assume the new role

    if (this.initialCompany.accountId !== company.accountId &&
      this.currentCompany.accountId !== company.accountId &&
      this.initialCompany.accountId !== this.currentCompany.accountId) {
      // console.log('SET COMPANY: Call REVERT ROLE to initial company THEN Call ASSUME ROLE to new company', company);
      // TBD how to handle the promise
      console.log('about to call revertRole');
      return this.authSvc.revertRole().then(token => {
        if (token) {
          // console.log('Successfully reverted the Role ', token);
          const params = { accountId: company.accountId, role: 'ACCOUNT_ADMIN' };
          return this.authSvc.assumeRole(params)
            .then(token => {
              if (token) {
                // console.log('Successfully set assumed role token ', token);
                this.currentCompany = company;
                this.companyChanged.next(this.currentCompany);
                return token;
              } else {
                console.log('Assumed role token not set');
              }

            })
            .catch(error => {
              console.error('error creating token', error);
              return error;
            });
        } else {
          console.log('Revert role token not set');
        }
      })
        .catch(error => {
          console.error('error reverting token', error);
          return error;
        });
    }
  }

  /**
 * Set currentCompany, load up the cached Boss Account and notify interested parties
 */
  setCurrentCompany(company: Account) {
    this.currentCompany = company;
    this.companySwitcherService.setCurrentAccount(this.currentCompany);

    this.companyChanged.next(this.currentCompany);

    // prime the cache
    this.getBossAccountCached()
      .pipe(take(1))
      .subscribe(accountDC => { }); // take(1) will unsubscribe automatically after 1st execution
  }

  private getBossAccountCached_inner(): Observable<AccountDC> {

    // do we already have an observable for this accountId?
    if (!this.getBossAccountObservableCache[this.claims.accountId]) {
      // ..no, get one
      this.getBossAccountObservableCache[
        this.claims.accountId
      ] = this.bossAccountsService.AccountsGetAccount(null).pipe(
        // share last observed value of this stream to all callers of this method.
        // https://www.learnrxjs.io/operators/multicasting/sharereplay.html
        tap((acc) => {
          this.accountDCSource.next(acc);
        }),
        shareReplay(1)
      );
    }

    return this.getBossAccountObservableCache[this.claims.accountId];
  }

  /**
 * Get the BOSS AccountDC record for the current companies accountId
 */
  getBossAccountCached(): Observable<AccountDC> {

    // chain on claimsChanged so that we don't query SDApi until claims recieved. When claims are recieved, we have our SDApi auth tokens
    return this.claimsChanged.asObservable().pipe(
      take(1),                     // take(1) to force the  http req to fire
      switchMap(claims => {
        return this.getBossAccountCached_inner();
      })
    );
  }

  updateUserClaimsUsingToken(token: Token): Promise<void> {
    // need to update the boss roles for this user to the assumed role
    if (this.invokeRole === 'ACCOUNT_ADMIN') {// currently only one supported, will need to change if this req changes
      this.bossRoles = [UserRole.ADMIN];
    }

    return this.getClaimsForToken(token).then(async claims => {
      this.setClaims(claims);
      return;
    });
  }

  getClaimsForToken(token: Token): Promise<UserClaims> {
    return this.authSvc.whoAmI(token).then(claims => claims as UserClaims);
  }

  isApplnIdentityLoaded(): Observable<boolean> {
    return this.applnIdentityIsLoaded;
  }

  getTargetAccount(): string {
    return this.targetAccount;
  }

  setTargetAccount(targetAccount: string): void {
    this.targetAccount = targetAccount;
  }

  getAccountPartner(): AccountPartnerDC {
    return this.accountPartner;
  }

  setAccountPartner(): void {
    if (this.hasPermission(10326)) {
      this.partnersService.getVarPartners().subscribe((partners) => {
        this.accountPartner = partners.filter(partner => partner.partnerStatusId === 1 || partner.partnerStatusId === 4)[0];
      });
    }
  }
}
