import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserClaims } from '@mitel/cloudlink-sdk';
import { Subscription } from 'rxjs';
import { AppUserClaimsService } from '../services/app-user-claims.service';
import { Account } from '@mitel/cloudlink-sdk/admin';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.css']
})
export class SchedulesComponent implements OnInit, OnDestroy {
  rightPanelOpened = false;
  columns = [
    {
      header: 'NAME',
      field: 'name',
      sortable: true,
      sorted: true,
      queryFields : ['name'],
      sortBy: ['name'],
      widthPercent: 60
    },
    {
      header: 'TYPE',
      field: 'timeTypeLabel',
      sortable: true,
      sorted: false,
      queryFields : ['componentType'],
      sortBy: ['componentType'],
      widthPercent: 40
    },
  ];
  claims: UserClaims;
  company: Account;

  claimsChangedSubscription: Subscription;
  companyChangedSubscription: Subscription;
  subscriptions: Subscription[] = [];

  constructor(private appSvc: AppUserClaimsService, private translateSvc: TranslateService) { }

  ngOnInit() {
    this.claimsChangedSubscription = this.appSvc.claimsChanged.subscribe(claims => {
      this.claims = claims;
    });

    this.claims = this.appSvc.getClaims();

    this.companyChangedSubscription = this.appSvc.companyChanged.subscribe(company => {
      this.company = company;
    });

    this.company = this.appSvc.getCompany();
    this.subscriptions.push(this.translateSvc.stream(this.getColumnTitleKeys()).subscribe(translations => {
      this.columns.forEach(column => column.header = translations[`schedules_dashboard.${column.field}`]);
    }));
  }

  getColumnTitleKeys(): string[] {
    const a: string[] = [];
    this.columns.forEach(column => {
        a.push(`schedules_dashboard.${column.field}`);
    });
    return a;
  }

  ngOnDestroy() {
    if (this.claimsChangedSubscription) {
      this.claimsChangedSubscription.unsubscribe();
    }

    if (this.companyChangedSubscription) {
      this.companyChangedSubscription.unsubscribe();
    }

    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  rightPanelOpenedHandler(panelOpened: boolean) {
    this.rightPanelOpened = panelOpened;
  }

}
