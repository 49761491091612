/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ZuoraSubscriptionDC } from '../models/zuora-subscription-dc';
@Injectable({
  providedIn: 'root',
})
class ZuoraSubscriptionService extends __BaseService {
  static readonly ZuoraSubscriptionProvisioningCallBackPath = '/api/Account/Provisioning/CallBack';
  static readonly ZuoraSubscriptionCancelPath = '/api/Account/ZuoraSubscription/Cancel';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `ZuoraSubscriptionService.ZuoraSubscriptionProvisioningCallBackParams` containing the following parameters:
   *
   * - `subscriptionDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  ZuoraSubscriptionProvisioningCallBackResponse(params: ZuoraSubscriptionService.ZuoraSubscriptionProvisioningCallBackParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.subscriptionDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Provisioning/CallBack`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `ZuoraSubscriptionService.ZuoraSubscriptionProvisioningCallBackParams` containing the following parameters:
   *
   * - `subscriptionDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  ZuoraSubscriptionProvisioningCallBack(params: ZuoraSubscriptionService.ZuoraSubscriptionProvisioningCallBackParams): __Observable<{}> {
    return this.ZuoraSubscriptionProvisioningCallBackResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `ZuoraSubscriptionService.ZuoraSubscriptionCancelParams` containing the following parameters:
   *
   * - `zuoraSubscriptionDC`: Zuora subscription data
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  ZuoraSubscriptionCancelResponse(params: ZuoraSubscriptionService.ZuoraSubscriptionCancelParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.zuoraSubscriptionDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/ZuoraSubscription/Cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `ZuoraSubscriptionService.ZuoraSubscriptionCancelParams` containing the following parameters:
   *
   * - `zuoraSubscriptionDC`: Zuora subscription data
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  ZuoraSubscriptionCancel(params: ZuoraSubscriptionService.ZuoraSubscriptionCancelParams): __Observable<{}> {
    return this.ZuoraSubscriptionCancelResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module ZuoraSubscriptionService {

  /**
   * Parameters for ZuoraSubscriptionProvisioningCallBack
   */
  export interface ZuoraSubscriptionProvisioningCallBackParams {
    subscriptionDC: ZuoraSubscriptionDC;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for ZuoraSubscriptionCancel
   */
  export interface ZuoraSubscriptionCancelParams {

    /**
     * Zuora subscription data
     */
    zuoraSubscriptionDC: ZuoraSubscriptionDC;

    /**
     * Access token
     */
    Authorization: string;
  }
}

export { ZuoraSubscriptionService }
