<div  #tableWaper class="virtualTableWrap" *ngIf="data">
    <p-table #ptable styleClass="virtualTable" [metaKeySelection]="singleSelection"
             [value]="data" [scrollable]="scrollable" [rows]="rows" scrollHeight="{{scrollHeight}}" [responsive]="true"
             [sortField] = "sortField" [virtualScroll]="true" [lazy]="lazy" [totalRecords]="totalRecords" [virtualRowHeight]="virtualRowHeight"
             [resizableColumns]="true" selectionMode="selectionMode" [(selection)]="selectedData"
             (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" [metaKeySelection]="false" (onLazyLoad)="loadDataOnScroll($event)">
    <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of cols" [pSortableColumn]="col.field" [ngStyle]="{'width.%': col.widthPercent}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row >
            <tr [pSelectableRow]="row">
                <td *ngIf="!singleSelection" class="chkbox-size">
                    <p-tableCheckbox [value]="row" [disabled]="rowDisableField.length > 0 ? row[rowDisableField]: false"
                                     [ngbTooltip]="row[rowDisableTextField]" placement="right" [disableTooltip]="rowDisableField.length > 0 ? !row[rowDisableField]: true">
                    </p-tableCheckbox>
                </td>
                <td *ngFor="let col of cols" (click)="onRowClick(row)" cuxEllipsify [ngStyle]="{'width.%': col.widthPercent}">
                    <span>
                        {{row[col.field]}}
                    </span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="noGridData" *ngIf="noGridDataMsg">
                {{noGridDataMsg}}
            </div>
        </ng-template>
    </p-table>
</div>
