import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CloudlinkAuthService } from './cloudlink-auth.service';
import { environment } from 'src/environments/environment';
import { PartnerInfo } from './app-info';

export class Bootstrapper {
  commonName: string
  callflowBaseUrl: string;
  bossApiBaseUrl: string;
  bossWebPortalBaseUrl: string;
}

/**
 * Service to provide URIs of back-end service end-points.  The back-end chosen is determined by the logged in account's partner ID.
 */
@Injectable({
  providedIn: 'root'
})
export class BootstrapperService {

  private partnerId: string;
  bootstrapUpdated = new ReplaySubject<Bootstrapper>(1);// Last 1 and future emits are broadcasted to all subscribed.

  /**
   * Mapping of partnerId to BOSS service URIs.
   * Every account has a partnerId. The partnerId is used to indicate the BOSS system which the account
   * is assigned to. This code contains a hard coded mapping of account partner ID to back-end servers.
   *
   * Some of this data is from https://github.com/mitel-networks/cloudlink-auth-portal/issues/549
   *
   * @private
   * @type {{[key:string]:Bootstrapper}}
   * @memberof BootstrapperService
   */
  private backEndUris: { [key: string]: Bootstrapper } = {
    [PartnerInfo.productionUS.partnerId]: {
      commonName: PartnerInfo.productionUS.commonName,
      callflowBaseUrl: 'https://callflow.connect.mitel.io',
      bossApiBaseUrl: 'https://sdapi.shoretelsky.com',
      bossWebPortalBaseUrl: 'https://portal.connect.mitel.io'
    },
    [PartnerInfo.alpha.partnerId]: {
      commonName: PartnerInfo.alpha.commonName,
      callflowBaseUrl: 'https://callflow.connect-stage.mitel.io',
      bossApiBaseUrl: 'https://sdapi.alpha.shoretel.com',
      bossWebPortalBaseUrl: 'https://portal.connect-stage.mitel.io'
    },
    [PartnerInfo.can01.partnerId]: {
      commonName: PartnerInfo.can01.commonName,
      callflowBaseUrl: 'https://callflow-connect-latest-stage.dev.mitel.io',
      bossApiBaseUrl: 'https://portal-can01.mitellabs.com:8443',
      bossWebPortalBaseUrl: 'https://portal-can01.connect.dev.mitel.io'
    },
    [PartnerInfo.mift.partnerId]: {
      commonName: PartnerInfo.mift.commonName,
      callflowBaseUrl: 'https://callflow-connect-latest.dev.mitel.io',
      bossApiBaseUrl: 'https://portal-mift.mitellabs.com:8443',
      bossWebPortalBaseUrl: 'https://portal-mift.connect.dev.mitel.io'
    },
    [PartnerInfo.sit.partnerId]: {
      commonName: PartnerInfo.sit.commonName,
      callflowBaseUrl: 'https://callflow-connect-latest-stage.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi.sit.shoretel.com',
      bossWebPortalBaseUrl: 'https://portal-sit.connect.dev.mitel.io'
    },
    [PartnerInfo.utit.partnerId]: {
      commonName: PartnerInfo.utit.commonName,  // UT/IT
      callflowBaseUrl: 'https://callflow.connect.dev.mitel.io',
      bossApiBaseUrl: 'https://utit-sdapi.sit.shoretel.com',
      bossWebPortalBaseUrl: 'https://portal-utit.connect.dev.mitel.io'
    },
    [PartnerInfo.performance.partnerId]: {
      commonName: PartnerInfo.performance.commonName,
      callflowBaseUrl: 'https://callflow.connect.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi-perf.mitellabs.com',
      bossWebPortalBaseUrl: 'https://portal-perf.mitellabs.com'
    },
    [PartnerInfo.bossUSQA.partnerId]: {
      commonName: PartnerInfo.bossUSQA.commonName,
      callflowBaseUrl: 'https://callflow.connect.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi-qa.shoretelsky.com',
      bossWebPortalBaseUrl: 'https://portal-qa.connect.dev.mitel.io'
    },
    [PartnerInfo.bossSCOQA.partnerId]: {
      commonName: PartnerInfo.bossSCOQA.commonName,
      callflowBaseUrl: 'https://callflow-connect-latest-stage.dev.mitel.io',
      bossApiBaseUrl: 'https://ba1mcss01qav.stsky.biz:50200',
      bossWebPortalBaseUrl: 'https://10.198.105.68'
    },
    [PartnerInfo.ukdMAC.partnerId]: {
      commonName: PartnerInfo.ukdMAC.commonName,
      callflowBaseUrl: 'https://callflow.connect.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi.mac.shoretel.com',
      bossWebPortalBaseUrl: 'https://portal-mac.connect.dev.mitel.io'
    },
    [PartnerInfo.ukqWIN.partnerId]: {
      commonName: PartnerInfo.ukqWIN.commonName,
      callflowBaseUrl: 'https://callflow-connect-latest-stage.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi.win.shoretel.com',
      bossWebPortalBaseUrl: 'https://portal-win.connect.dev.mitel.io'
    },
    [PartnerInfo.productionUK.partnerId]: {
      commonName: PartnerInfo.productionUK.commonName,
      callflowBaseUrl: 'https://callflow.connect.mitel.io',
      bossApiBaseUrl: 'https://sdapi.sd.mitelcloud.co.uk',
      bossWebPortalBaseUrl: 'https://portal.mac.shoretel.com' // TO BE UPDATED TO PROD
    },
    [PartnerInfo.euq.partnerId]: {
      commonName: PartnerInfo.euq.commonName,
      callflowBaseUrl: 'https://callflow.connect.dev.mitel.io',
      bossApiBaseUrl: 'https://sdapi.dev.sd.dev.mitelcloud.eu',
      bossWebPortalBaseUrl: 'https://portal.dev.sd.dev.mitelcloud.eu',
    },
    [PartnerInfo.productionEU.partnerId]: {
      commonName: PartnerInfo.productionEU.commonName,
      callflowBaseUrl: 'https://callflow.connect.mitel.io',
      bossApiBaseUrl: 'https://sdapi.sd.mitelcloud.eu',
      bossWebPortalBaseUrl: 'https://euportal.connect.mitel.io'
    }
  };


  constructor(private authenticationService: CloudlinkAuthService) {

    this.authenticationService.getToken().then(
      token => {
        try {
          const decodeAuth = JSON.parse(atob(token.access_token));
          const decodeBearerToken = JSON.parse(atob(decodeAuth.bearerToken.split('.')[1])); // get the JWT payload
          this.setPartnerId(decodeBearerToken.partnerId);
        } catch (e) {
          // ignore; likely no token
        }
      }
    )
  }


  getBootstrap(): Bootstrapper | undefined {

    let bootstrapper = this.backEndUris[this.getPartnerId()];
    if (!bootstrapper) {
      console.error('BootstrapperService does not have a configuration for partner id=' + this.getPartnerId());
      bootstrapper = {
        commonName: 'Empty Bootstrap Configuration',
        callflowBaseUrl: 'Empty Bootstrap Configuration',
        bossApiBaseUrl: 'Empty Bootstrap Configuration',
        bossWebPortalBaseUrl: 'Empty Bootstrap Configuration',
      }
    }
    if (environment['mockApiBaseUrl']) {
      bootstrapper.bossApiBaseUrl = environment['mockApiBaseUrl'];
    }
    return bootstrapper;
  }


  setPartnerId(partnerId: string) {
    if (partnerId && partnerId !== this.partnerId) {
      this.partnerId = partnerId;
      this.bootstrapUpdated.next(this.getBootstrap());
    }
  }

  getPartnerId() {
    return this.partnerId;
  }

}
