import { Injectable } from '@angular/core';
import { Observable, Subject, of, Subscription } from 'rxjs';
import { AppInfo, APP_INFO } from './app-info';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class AppInfoService {
    private appInfo: AppInfo[];
    private appSubscription: Subscription;
    invokedLocale: string;

    constructor(private translateSvc: TranslateService) { }
    supportedLanguages = [
        { name: 'English (US)', langCode: 'en', displayAbbr: 'EN (US)' },
    ];

    getAvailableAppList(): Observable<any[]> {
        // TBD; DO WE EXPECT THIS LIST TO COME SORTED AND IF NOT HOW IS IT TO BE SORTED???

        // possible future code
        /* let availableAppList: any[] = [];
        this.appSubscription = someAppService.getAvailableApps().subscribe( (appList: AppInfo[]) => {
          this.appInfo = appList;
      });
  
      if ( (this.appInfo) && (this.appInfo.length > 0) ) {
          availableAppList = this.appInfo.map( app => {
              let appChildren: any[] = [];
              if ((app.getChildren()) && ( app.getChildren().length > 0)) {
                  appChildren = app.getChildren().map(child => {
                      return {name: child.name, id: child.id, minRole: child.minRole, navigation: child.navigation};
                  });
              }
              return {name: app.name, id: app.id, minRole: app.minRole, navigation: app.navigation,
                      iconActive: app.getIconActive(), iconInactive: app.getIconActive(), children: appChildren };
          });
      }
      return of(availableAppList); */
        return of(APP_INFO);
    }

    getSupportedLanguages(): any[] {
        return (this.supportedLanguages);
    }

    getLangCodes(): string[] {
        const langCodes: string[] = [];
        for (const lang of this.supportedLanguages) {
            langCodes.push(lang.langCode);
        }
        return langCodes;
    }

    getUserLanguage() {
        return localStorage.getItem('userLangPreference');
    }

    getLocale(locale?: string) {
        if (locale) {
            return locale;
        }
        const langRegex = new RegExp(
            this.getLangCodes().join('|') + '|' +
            this.getLangCodes().toString().split('-').toString().split(',').join('|'),
            'gi'
        );
        this.translateSvc.addLangs(this.getLangCodes());
        let browserLang = this.translateSvc.getBrowserCultureLang();
        console.log(' SHARED TS browser lang: ', browserLang);
        let userLang = this.getUserLanguage();
        /*istanbul ignore else*/
        if (userLang) {
            return this.formatLocale(userLang);
        } else {
            return this.formatLocale(browserLang.match(langRegex) ? browserLang : 'en-US');
        }
    }

    formatLocale(lang: string) {
        switch (lang) {
            case 'en':
            case 'en-US':
                return lang = 'en-US';
            case 'en-GB':
                return lang = 'en-GB';
            case 'de':
            case 'de-DE':
                return lang = 'de-DE';
            case 'fr':
            case 'fr-FR':
                return lang = 'fr-FR';
            default:
                return lang = 'en-US';
        }
    }

    getLanguagePrefix() {
        let selectedLang = this.getLocale(this.invokedLocale);
        /*istanbul ignore else*/
        if (selectedLang) {
            selectedLang = selectedLang.split('-')[0]
        }
        return selectedLang;
    }



}
