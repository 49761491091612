import { Injectable } from '@angular/core';
import { LocationsService, LocationsService as BossApiLocationsService } from '../modules/boss-api/generated/services/locations.service';
import { LocationsService as BossApiLocationsServicePrevious } from '../modules/boss-api-previous/generated/services/locations.service';
import { SDApiVersionControlService } from 'src/app/services/sdapi-version-control';
import { map, shareReplay } from 'rxjs/operators';
import { LocationDC } from '../modules/boss-api/generated/models';
import { Observable, throwError } from 'rxjs';
import LocationsGetTermsOfServiceParams = LocationsService.LocationsGetTermsOfServiceParams;
import { of } from 'rxjs/internal/observable/of';
import LocationsGetLocationParams = LocationsService.LocationsGetLocationParams;


export interface Location {
  value: string;          // aka id
  displayName: string;
}

export enum LocationTypes {
  PRIMARY_LOCATION = 0,
  LOCATION = 1,
  GLOBAL_LOCATION = 2
}

export enum LocationStatuses {
  ACTIVE = 0,
  CLOSED = 1,
  PROSPECT = 2,
  PENDING = 3,
}

/**
 * Populates a Front End Location object by mapping to server-side REST API calls.
 * Back-end server responses are cached by accountId.
 */
@Injectable({
  providedIn: 'root'
})
export class LocationAdaptorService {
  public termsAndServiceLink = '';

  private readonly authToken = null;  // empty so that our  auth interceptor will handle this.  Else should be 'Bearer <token>'


  // a map of locations by accountId
  private onboardingSimpleLocations: {
    [accountId: string]: Location[]
  } = {};
  private locations: {
    [accountId: string]: Location[]
  } = {};
  private fullLocations: {
    [accountId: string]: LocationDC[]
  } = {};
  private onBoardingFullLocations: {
    [accountId: string]: LocationDC[]
  } = {};
  // a map of BossApi Observable objects by accountId
  private getLocationsApiObservable: { [accountId: string]: Observable<any> } = {};
  private getOnboardingSimpleLocationsApiObservable: { [accountId: string]: Observable<Location[]> } = {};
  private getOnboardingFullLocationsApiObservable: { [accountId: string]: Observable<LocationDC[]> } = {};
  bossLocSvc: BossApiLocationsService | BossApiLocationsServicePrevious;

  constructor(private sdapiVersion: SDApiVersionControlService) {
    this.bossLocSvc = this.sdapiVersion.locationSevice;
  }

  getLocations(accountId: string): Observable<Location[]> {

    // cached by AccountId

    if (!accountId) {
      throwError('AcountId required');
    }

    //
    // We are sharing the BossApi Observable object to all callers, thus caching the BossApi response.
    // The shareRelay() in pipe below is the key to making this sharing happen.
    //

    // Do we already have a BossApi response for this accountId ?
    if (!this.getLocationsApiObservable[accountId]) {

      // ...no
      // Hit the BossApi
      this.getLocationsApiObservable[accountId] = this.bossLocSvc.LocationsGetLocationsByAccount({ typeIds: [], statusIds: [], Authorization: this.authToken })
        .pipe(

          map(locationDCs => {

            this.locations[accountId] = [];

            locationDCs
              .filter(locationDC => locationDC.locationStatusId !== LocationStatuses.CLOSED
                && locationDC.locationTypeId !== LocationTypes.GLOBAL_LOCATION)
              .forEach(locationDC => {
                this.locations[accountId].push(this._makeLocation(locationDC));
              });

            return this.locations[accountId].slice();  // clone
          }),

          shareReplay(1)  // share last observed value of this stream to all callers of this method.  https://www.learnrxjs.io/operators/multicasting/sharereplay.html
        );
    }

    return this.getLocationsApiObservable[accountId];
  }

  getOnboardingSimpleLocations(accountId: string): Observable<Location[]> {

    // cached by AccountId

    if (!accountId) {
      throwError('AccountId required');
    }

    //
    // We are sharing the BossApi Observable object to all callers, thus caching the BossApi response.
    // The shareRelay() in pipe below is the key to making this sharing happen.
    //

    // Do we already have a BossApi response for this accountId ?
    if (!this.getOnboardingSimpleLocationsApiObservable[accountId]) {

      // ...no
      // Hit the boss api & Populate both the fullLocation and simpleLocation arrays
      this.getOnboardingSimpleLocationsApiObservable[accountId] = this.bossLocSvc.LocationsGetOnboardingLocationsByAccount(this.authToken)
        .pipe(
          map(locationDCs => {
            this.onboardingSimpleLocations[accountId] = [];
            this.onBoardingFullLocations[accountId] = [];
            locationDCs.forEach(locationDC => {
              this.onboardingSimpleLocations[accountId].push(this._makeLocation(locationDC));
              this.onBoardingFullLocations[accountId].push(locationDC);
            });

            // also populate the full locations cache
            setTimeout(() => {
              this.getOnboardingFullLocationsForAccount(accountId);
            }, 0);

            return this.onboardingSimpleLocations[accountId].slice();  // clone
          }),
          // share last observed value of this stream to all callers of this method.
          // https://www.learnrxjs.io/operators/multicasting/sharereplay.html
          shareReplay(1)
        );
    }

    return this.getOnboardingSimpleLocationsApiObservable[accountId];
  }
  getOnboardingFullLocationsForAccount(accountId: string): Observable<LocationDC[]> {
    // cached by AccountId

    if (!accountId) {
      throwError('AccountId required');
    }

    //
    // We are sharing the BossApi Observable object to all callers, thus caching the BossApi response.
    // The shareRelay() in pipe below is the key to making this sharing happen.
    //

    // Do we already have a BossApi response for this accountId ?
    if (!this.getOnboardingFullLocationsApiObservable[accountId]) {

      // ...no
      // Hit the boss api & Populate both the fullLocation and simpleLocation arrays
      this.getOnboardingFullLocationsApiObservable[accountId] = this.bossLocSvc.LocationsGetOnboardingLocationsByAccount(this.authToken)
        .pipe(
          map(locationDCs => {

            if (!locationDCs) {
              locationDCs = [];
            }
            this.onboardingSimpleLocations[accountId] = [];
            this.onBoardingFullLocations[accountId] = [];
            locationDCs.forEach(locationDC => {
              this.onboardingSimpleLocations[accountId].push(this._makeLocation(locationDC));
              this.onBoardingFullLocations[accountId].push(locationDC);
            });

            // also populate the simple locations cache
            setTimeout(() => {
              this.getOnboardingSimpleLocations(accountId);
            }, 0);

            return this.onBoardingFullLocations[accountId].slice();  // clone
          }),
          // share last observed value of this stream to all callers of this method.
          // https://www.learnrxjs.io/operators/multicasting/sharereplay.html
          shareReplay(1)
        );
    }

    return this.getOnboardingFullLocationsApiObservable[accountId];
  }

  getFullLocationObjectForSelectedLocation(accountId: string, selectedLocationUuid: string): Observable<LocationDC> {
    if (!this.fullLocations[accountId]) {
      const params: LocationsGetLocationParams = {
        Authorization: this.authToken,
        locationUuid: selectedLocationUuid
      };

      return this.bossLocSvc.LocationsGetLocation(params);
    }
    return of(this.fullLocations[accountId].find((loc) => {
      return loc.uuid === selectedLocationUuid;
    }));
  }


  _makeLocation(raw: LocationDC): Location {

    return {
      value: raw.uuid,
      displayName: raw.name
    };
  }

  getTermsAndServiceLinkWhenBillingImpact(locationUuid: string): Observable<string> {
    const params: LocationsGetTermsOfServiceParams = {
      locationUuid: locationUuid,
      Authorization: null
    };
    return this.bossLocSvc.LocationsGetTermsOfService(params);
  }

  sendTermsAndServiceLink(link: string) {
    return this.termsAndServiceLink = link;
  }
  refreshCache(accountId: string): Observable<Location[]> {
    this.onboardingSimpleLocations = {};
    this.onBoardingFullLocations = {};
    this.getOnboardingFullLocationsApiObservable = {};
    this.getOnboardingSimpleLocationsApiObservable = {};
    return this.getOnboardingSimpleLocations(accountId);
  }
  // This is just workaround since location dropdown shared component is using getsimpleLocations()
  getSimpleLocations(account) {
    return this.getLocations(account);
  }
}
