
import { Injectable, OnDestroy } from '@angular/core';

import { TnsService as BossTNService, TnsService } from '../modules/boss-api/generated/services/tns.service';
import { ProductsService } from '../modules/boss-api/generated/services';
import { TnsService as BossTNServicePrevious } from '../modules/boss-api-previous/generated/services/tns.service';
import { ProductsService as ProductsServicePrevious } from '../modules/boss-api-previous/generated/services';
import { SDApiVersionControlService } from 'src/app/services/sdapi-version-control';
import { RateCenterDC } from '../modules/boss-api/generated/models/rate-center-dc';
import { LocationDC } from '../modules/boss-api/generated/models/location-dc';
import { PhoneNumberFormatService } from './phone-number-format.service';

import { from, Observable, of, Subject, Subscription } from 'rxjs';
import { switchMap, mergeMap, map, toArray } from 'rxjs/operators';


import {
  OrderNumbersRequestDC, GetAvailableNumbersDataContract, BillingImpactDC, QuotePriceRequestDC, QuotePriceProductDC,
  CarrierTelephoneOrder
} from '../modules/boss-api/generated/models';

export interface RateCenter extends RateCenterDC {
  address: string;
  areaCodesString: string;
  locationUUID: string;
}

export interface CurrentSearchCriteria {
  location: LocationDC;
  request: any;
  consecutive?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TnAdapterService implements OnDestroy {
  private readonly authToken = null;  // empty so that our  auth interceptor will handle this.  Else should be 'Bearer <token>'
  private tnSearchCritera: any = {};
  private currentSearchCriteria: CurrentSearchCriteria;

  wasNewPhoneNumberPreviouslyFetched = false;
  availNumberValue: Array<any> = [];
  bossService1Subscription: Subscription;
  bossService2Subscription: Subscription;
  subscriptions: Subscription[] = [];
  bossTnSvc: BossTNService | BossTNServicePrevious;
  productService: ProductsService | ProductsServicePrevious;
  constructor(
    private formatService: PhoneNumberFormatService,
    private sdapiVersion: SDApiVersionControlService) {
    this.bossTnSvc = this.sdapiVersion.tnsService;
    this.productService = this.sdapiVersion.productsService;
  }

  private collectRc(_rc: RateCenterDC[], locUuid) {
    const rc: RateCenter[] = [];
    for (let i = 0; i < _rc.length; i++) {
      const rateCenter = _rc[i];
      // _rc.forEach((rateCenter) => {
      rc.push({
        ...rateCenter,
        address: `${rateCenter.cityName}, ${rateCenter.stateCode}`,
        areaCodesString: rateCenter.areaCodes && rateCenter.areaCodes.length ? rateCenter.areaCodes.join(', ') : '',
        locationUUID: locUuid
      });
    }
    return of(rc);
  }

  private collectPhoneNumbers(unprocessedNumbers, address: string = '', countryCode?: number, locationUuid?: string) {
    if (unprocessedNumbers && unprocessedNumbers.isOutsideServiceArea) {
      return of({ isOutsideServiceArea: true });
    } else {
      const availableTNs: { number: string, address: string, reservation_id: string, carrier: string }[] = [];
      if (unprocessedNumbers && unprocessedNumbers.results && unprocessedNumbers.results.length) {
        return from(unprocessedNumbers.results[0].numbers).pipe(mergeMap((number: string, index: number) => {
          return from(this.formatService.formatPhoneNumberBasedOnCountry(number).then(pn => {
            return {
              displayName: pn,
              value: number,
              countryId: countryCode,
              locationUuid: locationUuid,
              address: address,
              reservation_id: unprocessedNumbers.results[0].reservation_ids[index],
              carrier: unprocessedNumbers.results[0].carrier,
              turnupProductId: unprocessedNumbers.turnupProductId
            }
          }));
        }));
      } else {
        return of({});
      }
    }
  }

  getBillingImpact(turnupId: number, locationUuid: string): Observable<BillingImpactDC> {
    const quoteRequest: QuotePriceRequestDC = {
      products: []
    };

    const dev: QuotePriceProductDC = {
      productId: turnupId,
      quantity: 1
    };
    quoteRequest.products.push(dev);


    return this.productService.ProductsQuotePurchasePrice({ request: quoteRequest, locationUuid: locationUuid, Authorization: null });
  }

  public getTnSearchCriteria(location: LocationDC): Observable<any> {

    const searchCriteriaObs = new Observable((observer) => {
      if (location && location.uuid) {
        if (this.tnSearchCritera[location.uuid]) {
          observer.next(this.tnSearchCritera[location.uuid]);
        } else {
          const params = {
            locationUuid: location.uuid,
            Authorization: this.authToken
          };

          this.subscriptions.push(this.bossTnSvc.TnsGetLocationTnSearchCriteria(params)
            .subscribe((data: { id: number, isDefault: boolean, searchCriteria: any }) => {
              this.tnSearchCritera[location.uuid] = data.searchCriteria;
              this.currentSearchCriteria = {
                location,
                request: data.searchCriteria
              };
              observer.next(this.tnSearchCritera[location.uuid]);
            }));
        }
      } else {
        observer.next(null);
      }
    });

    return searchCriteriaObs;
  }

  public updateTNSearchCriteria() {
    const locationUuid = this.currentSearchCriteria.location.uuid;
    const searchCriteria = this.currentSearchCriteria.request;
    this.tnSearchCritera[locationUuid] = searchCriteria;

    const params = {
      locationUuid,
      searchCriteria,
      Authorization: this.authToken
    };

    this.subscriptions.push(this.bossTnSvc.TnsUpdateLocationTnSearchCriteria(params).subscribe());
  }

  public getRateCenters(location: LocationDC, searchQuery: string): Observable<any> {
    const filterParams = {
      search: searchQuery,
      countryId: location.countryId,
      Authorization: this.authToken

    };
    return this.bossTnSvc.TnsSuggestRateCenter(filterParams)
      .pipe(
        switchMap((rc: RateCenterDC[]) => this.collectRc(rc, location.uuid))
      );
  }


  public getAvailableNumbersFromSearchCriteria(location: LocationDC, quantity: number = 10, consecutive?: boolean): Observable<any> {
    return this.getTnSearchCriteria(location).pipe(
      switchMap((_searchCriteria: any) => {
        this.currentSearchCriteria = {
          location,
          request: _searchCriteria,
          consecutive
        };
        let request: GetAvailableNumbersDataContract = _searchCriteria;
        let searchParams: BossTNService.TnsGetAvailableNumbersParams = {
          request: {
            ...request,
            quantity
          },
          locationUuid: location.uuid,
          Authorization: this.authToken,
        };
        if (consecutive) {
          searchParams.request['consecutive'] = quantity;
        }
        const addressString = (request.cityName && request.cityName.length) ? `${request.cityName}, ${request.stateCode}` : '';

        return this.bossTnSvc.TnsGetAvailableNumbers(searchParams)
          .pipe(
            switchMap((availableTns) => {
              // return this.collectPhoneNumbers(availableTns, `${location.city}, ${location.stateCodeAlpha}`,
              //  location.countryId, location.uuid).pipe(toArray());
              return this.collectPhoneNumbers(availableTns, addressString, location.countryId, location.uuid).pipe(toArray());

            })
          );
      })
    );
  }

  public getAvailableNumbersFromRC(rateCenter: RateCenter, quantity: number, areaCode: string = "", location: LocationDC,
    consecutive?: boolean): Observable<any> {
    const request: GetAvailableNumbersDataContract = {
      rc: rateCenter.rc,
      cityName: rateCenter.cityName,
      stateCode: rateCenter.stateCode,
      prefix: (areaCode.length) ? areaCode : ''
    };
    this.currentSearchCriteria = {
      location,
      request,
      consecutive
    };
    const searchParams: BossTNService.TnsGetAvailableNumbersParams = {
      request: {
        ...request,
        quantity
      },
      locationUuid: rateCenter.locationUUID,
      Authorization: this.authToken,
    };
    if (consecutive) {
      searchParams.request['consecutive'] = quantity;
    }
    const addressString = (request.cityName && request.cityName.length) ? `${request.cityName}, ${request.stateCode}` : '';

    return this.bossTnSvc.TnsGetAvailableNumbers(searchParams)
      .pipe(
        switchMap((availableTns) => {
          return this.collectPhoneNumbers(availableTns, addressString, location.countryId, location.uuid).pipe(toArray());
        })
      );
  }

  // location should not be required. This is to work around BOSS API until it is resolved
  public searchAvailableNumberswithPartials(partialNumber: string, quantity: number, location: LocationDC,
    consecutive?: boolean): Observable<any> {
    const request: GetAvailableNumbersDataContract = {
      prefix: partialNumber,
      cityName: '',
      stateCode: ''
    };
    this.currentSearchCriteria = {
      location,
      request,
      consecutive
    };
    const searchParams: BossTNService.TnsGetAvailableNumbersParams = {
      request: {
        ...request,
        quantity
      },
      locationUuid: location.uuid,
      Authorization: this.authToken
    };
    if (consecutive) {
      searchParams.request['consecutive'] = quantity;
    }
    // let addressString = (request.cityName && request.cityName.length)? `${request.cityName}, ${request.stateCode}` : ''; 
    // Backend support for cityName and sateCode is not available yet for area code search
    const addressString = '';
    return this.bossTnSvc.TnsGetAvailableNumbers(searchParams)
      .pipe(
        switchMap((availableTns) => {
          return this.collectPhoneNumbers(availableTns, addressString, location.countryId, location.uuid).pipe(toArray());
          // return this.collectPhoneNumbers(availableTns, `${location.city}, ${location.stateCodeAlpha}`, location.countryId,
          // location.uuid).pipe(toArray());
        })
      );
  }

  // location should not be required. This is to work around BOSS API until it is resolved
  public searchAvailableNumberswithVanity(vanityNumber: string, partialNumber: string, quantity: number, location: LocationDC,
    consecutive?: boolean): Observable<any> {
    const request: GetAvailableNumbersDataContract = {
      prefix: partialNumber,
      cityName: '',
      stateCode: '',
    };
    if (vanityNumber) {
      request.localVanity = vanityNumber;
    }
    this.currentSearchCriteria = {
      location,
      request,
      consecutive
    };
    const searchParams: BossTNService.TnsGetAvailableNumbersParams = {
      request: {
        ...request,
        quantity
      },
      locationUuid: location.uuid,
      Authorization: this.authToken
    }
    if (consecutive) {
      searchParams.request['consecutive'] = quantity;
    }
    // let addressString = (request.cityName && request.cityName.length)? `${request.cityName}, ${request.stateCode}` : '';
    // Backend support for cityName and sateCode is not available yet for area code search
    const addressString = '';
    return this.bossTnSvc.TnsGetAvailableNumbers(searchParams)
      .pipe(
        switchMap((availableTns) => {
          return this.collectPhoneNumbers(availableTns, addressString, location.countryId, location.uuid).pipe(toArray());
        })
      );
  }

  public getMoreNumbers(quantity: number): Observable<any> {
    const location: LocationDC = this.currentSearchCriteria.location;
    const request: GetAvailableNumbersDataContract = this.currentSearchCriteria.request;
    const consecutive = this.currentSearchCriteria.consecutive;
    const searchParams: BossTNService.TnsGetAvailableNumbersParams = {
      request: {
        ...request,
        quantity
      },
      locationUuid: location.uuid,
      Authorization: this.authToken,
    };
    if (consecutive) {
      searchParams.request['consecutive'] = quantity;
    }
    const addressString = (request.cityName && request.cityName.length) ? `${request.cityName}, ${request.stateCode}` : '';

    return this.bossTnSvc.TnsGetAvailableNumbers(searchParams)
      .pipe(
        switchMap((availableTns) => {
          return this.collectPhoneNumbers(availableTns, addressString, location.countryId, location.uuid).pipe(toArray());
        })
      );
  }

  getTollFreeAreaCodes(countryId): Observable<string[]> {
    const params: TnsService.TnsGetTollFreeAreaCodesParams = {
      countryId: countryId,
      Authorization: this.authToken
    }

    return this.bossTnSvc.TnsGetTollFreeAreaCodes(params);
  }

  getTemporaryNumber(mgmtGUID: string, countryId: number): Observable<string> {
    const params: TnsService.TnsReserveRcfTnsParams = {
      maxCount: 1,
      managementGUID: mgmtGUID,
      countryId: countryId,
      Authorization: this.authToken
    };

    return this.bossTnSvc.TnsReserveRcfTns(params)
      .pipe(
        switchMap((availableRcfTns) => {
          return of(availableRcfTns[0].id);
        })
      );
  }

  releaseTemporaryNumber(mgmtGUID: string) {
    const params: TnsService.TnsReleaseTnsParams = {
      managementGUID: mgmtGUID,
      Authorization: this.authToken
    };

    return this.bossTnSvc.TnsReleaseTns(params);
  }

  placeOrder(order: Array<any>, selectedLocId: string): Observable<CarrierTelephoneOrder> {
    console.log('placing TN order...');
    const phoneNumbers = order.map(val => val.value);
    const reservationIds = order.map(val => val.reservation_id);
    const request: OrderNumbersRequestDC = {
      'locationUuid': selectedLocId,
      'carrier': order[0].carrier,
      'phoneNumbers': phoneNumbers,
      'reservationIds': reservationIds
    };
    const params = {
      request,
      Authorization: this.authToken
    };
    return this.bossTnSvc.TnsOrderNumbers(params);
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }
}
