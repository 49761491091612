
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FlowComponentCRUDActionModel, HierarchyDatum } from '../models/flow-model';
import { flowComponentCRUDActionValues, callFlowComponentTypeValues, newComponentId, rightPanelCloseReason } from '../shared/constants';
import { CallFlowsService as BossApiCallFlowsService } from '../modules/boss-api/generated/services';
import { CallFlowsService as BossApiCallFlowsServicePrevious } from '../modules/boss-api-previous/generated/services';
import { SDApiVersionControlService } from 'src/app/services/sdapi-version-control'; import { CallFlowVisualizationDC } from '../modules/boss-api/generated/models/call-flow-visualization-dc';
import { FlowsService } from './flows.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ModalTemplate } from '../cl-modal/modal-template';
import { NgbModalOptions, NgbModal, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClModalComponent } from '../cl-modal/cl-modal.component';
import { VisualizationErrorComponent } from '../flows/flow-visualizer/visualization-error/visualization-error.component';
import { PhoneNumberFormatService } from './phone-number-format.service';


@Injectable({
  providedIn: 'root'
})

export class FlowVisualizerService {
  public getFlowComponent = new BehaviorSubject({ action: null, tree: { name: null } });
  public visualizationError: Subject<boolean> = new Subject<boolean>();
  public ignoreClicked: Subject<null> = new Subject<null>();
  private authToken = null;
  private modalRef: NgbModalRef;
  private treeRootStub: HierarchyDatum = {
    'name': 'Start',
    'id': null,
    'componentType': callFlowComponentTypeValues.start,
    'connection_text': null,
    'children': []
  };

  private newFlowComponentStub: HierarchyDatum = {
    'name': '',
    'id': null,
    'componentType': callFlowComponentTypeValues.empty,
    'connection_text': ['Incoming Call'],
    'children': []
  };

  private exemptedWords = ['a', 'an', 'the', 'for', 'and', 'nor', 'but', 'or', 'yet', 'so', 'with', 'at',
    'from', 'into', 'upon', 'of', 'to', 'in', 'on', 'by', 'like', 'over',
    'plus', 'up', 'down', 'off', 'near'];

  callFlowSvc: BossApiCallFlowsService | BossApiCallFlowsServicePrevious;
  constructor(
    private flowSvc: FlowsService,
    private translateSvc: TranslateService,
    private modalSvc: NgbModal,
    private activeModal: NgbActiveModal,
    private phFormatSvc: PhoneNumberFormatService,
    private sdapiVersion: SDApiVersionControlService) {
    this.callFlowSvc = this.sdapiVersion.callFlowsService;
  }

  public setSelectedFlow(flowComponent: any, action: FlowComponentCRUDActionModel, level?: number) {
    if (flowComponent !== null) {
      this.getFlowComponentFromBOSS(flowComponent, action, level);
    } else {
      this.getFlowComponent.next(null);
    }
  }

  public getFlowComponentFromBOSS(flowComponent: any, action: FlowComponentCRUDActionModel, level?: number) {
    const dnid = flowComponent.id;
    //Call API to fetch the tree when it is "read" / "update"
    if (dnid && action === flowComponentCRUDActionValues.read || action === flowComponentCRUDActionValues.update) {
      let vizParams = {
        DnId: dnid,
        Authorization: this.authToken,
        Level: level
      };

      this.callFlowSvc.CallFlowsGetCallFlowVisualization(vizParams).subscribe((response: CallFlowVisualizationDC) => {
        if (response.isSuccess) {
          let treeData = JSON.parse(response.content.replace(/\"/g, "\""));
          this.collectTree(action, treeData);
        } else {
          this.openVisErrorModal();
        }
      }, (error) => {
        this.openVisErrorModal();
      });
    }
    else if (action === flowComponentCRUDActionValues.create) {
      let treeRoot: HierarchyDatum = _.cloneDeep(this.treeRootStub);
      let newFlowComponentStub: HierarchyDatum = _.cloneDeep(this.newFlowComponentStub);
      newFlowComponentStub.name = flowComponent.name;
      newFlowComponentStub.id = flowComponent.id;
      newFlowComponentStub.subText = flowComponent.subText;
      newFlowComponentStub.componentType = flowComponent.componentType;
      treeRoot.children.push(newFlowComponentStub);
      this.getFlowComponent.next({
        tree: treeRoot,
        action
      });
    }
    else if (action === flowComponentCRUDActionValues.delete) {
      this.getFlowComponent.next({
        tree: flowComponent,
        action
      });
    }
  }

  public updateFlowForm(flowComponent) {
    this.flowSvc.setClickedFlow(flowComponent);
  }

  private collectTree = (action, tree?) => {
    let formatPromises = this.recurseAndUpdateTN(tree, []);
    Promise.all(formatPromises).then(() => {
      if (action === flowComponentCRUDActionValues.read) {
        let treeRoot: HierarchyDatum = _.cloneDeep(this.treeRootStub);
        treeRoot.children.push(tree);
        this.getFlowComponent.next({
          tree: treeRoot,
          action
        });
      } else if (action === flowComponentCRUDActionValues.update) {
        this.getFlowComponent.next({
          tree,
          action
        });
      }
    });
  }

  openVisErrorModal() {
    //notify other components that the visualization error is showing
    this.visualizationError.next(true);

    const modTemp: ModalTemplate = {
      title: 'error_messages.visualization_error_title',
      content: VisualizationErrorComponent,
      isContentHTML: true,
      primaryBtn: {
        value: true,
        label: 'error_messages.visualization_error_return',
        width: 150,
        // disabled: true
      },
      defaultBtn: {
        value: false,
        label: 'error_messages.visualization_error_ignore',
        width: 95
      }
    };

    const options: NgbModalOptions = {
      // windowClass: 'confimation-box',
      centered: true,
      backdrop: 'static',
      keyboard: false
    };

    this.modalRef = this.modalSvc.open(ClModalComponent, options);
    this.modalRef.componentInstance.modalTemp = modTemp;

    this.modalRef.result.then((result) => {
      if (result === true) { // return to flows was clicked
        this.visualizationError.next(false);
        this.activeModal.close();
      } else { // ignore was clicked
        this.ignoreClicked.next();
        this.activeModal.close();
      }
    }, (reason) => {
      this.visualizationError.next(false);
      this.activeModal.close();
    });
  }

  public toTitleCase = (sentence: string) => {
    const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.substr(1);
    const normalizeSentence = (s: string) => s.toLowerCase().trim();
    const shouldCapitalize = (word: string, fullWordList: string[], stringPosition: number) => {
      if ((stringPosition === 0) || (stringPosition === fullWordList.length - 1)) {
        return true;
      }
      return !(this.exemptedWords.includes(word));
    };

    sentence = normalizeSentence(sentence);

    // We check whether the word in the sentence should be converted to title case or be left alone.
    const words = sentence.split(' ');
    for (let i = 0; i < words.length; i++) {
      words[i] = (shouldCapitalize(words[i], words, i) ? capitalizeFirstLetter(words[i]) : words[i]);
    }
    return words.join(' ');
  }

  private async formatTN(tn: string) {
    let countryCode = 840; // Temporary hard coding. International format support is not available at the moment.
    let tnFormatted = await this.phFormatSvc.formatOutOfCountryPhoneNumberUsingIso3166NumericCountryCode(tn, countryCode);
    return tnFormatted;
  }

  private recurseAndUpdateTN(tree, promises) {
    if (!tree.name) return;

    if (tree.tn && tree.tn.length) {
      let formatPromise = this.formatTN(tree.tn);
      formatPromise.then(formattedNumber => tree.tn = formattedNumber);
      promises.push(formatPromise);
    }

    if (tree.children && tree.children.length) {
      tree.children.forEach((node) => {
        this.recurseAndUpdateTN(node, promises);
      });
    }
    return promises;
  }
}

