/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SalesforceCaseDC } from '../models/salesforce-case-dc';
import { GetCasesRequestDC } from '../models/get-cases-request-dc';
@Injectable({
  providedIn: 'root',
})
class SalesforceService extends __BaseService {
  static readonly SalesforceGetManualEmergencyRegistrationRequestsPath = '/api/Salesforce/Cases';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: ReadSalesforceCases (10349)
   * @param params The `SalesforceService.SalesforceGetManualEmergencyRegistrationRequestsParams` containing the following parameters:
   *
   * - `request`: Request data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  SalesforceGetManualEmergencyRegistrationRequestsResponse(params: SalesforceService.SalesforceGetManualEmergencyRegistrationRequestsParams): __Observable<__StrictHttpResponse<Array<SalesforceCaseDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Salesforce/Cases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SalesforceCaseDC>>;
      })
    );
  }
  /**
   * Required Permission: ReadSalesforceCases (10349)
   * @param params The `SalesforceService.SalesforceGetManualEmergencyRegistrationRequestsParams` containing the following parameters:
   *
   * - `request`: Request data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  SalesforceGetManualEmergencyRegistrationRequests(params: SalesforceService.SalesforceGetManualEmergencyRegistrationRequestsParams): __Observable<Array<SalesforceCaseDC>> {
    return this.SalesforceGetManualEmergencyRegistrationRequestsResponse(params).pipe(
      __map(_r => _r.body as Array<SalesforceCaseDC>)
    );
  }
}

module SalesforceService {

  /**
   * Parameters for SalesforceGetManualEmergencyRegistrationRequests
   */
  export interface SalesforceGetManualEmergencyRegistrationRequestsParams {

    /**
     * Request data
     */
    request: GetCasesRequestDC;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { SalesforceService }
