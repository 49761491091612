import { PartnerInfo } from 'src/app/services/app-info';
import { Countries } from '../constants';

/* Default values for launchdarkly feature flags */
export const LaunchDarklyInfo = {

  // add here the default values if there are any new feature flags
  featureFlagsByEnvironmentDefault: {
    [PartnerInfo.productionUS.partnerId]: {
      'commonName': PartnerInfo.productionUS.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.alpha.partnerId]: {
      'commonName': PartnerInfo.alpha.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.can01.partnerId]: {
      'commonName': PartnerInfo.can01.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.3'
    },
    [PartnerInfo.mift.partnerId]: {
      'commonName': PartnerInfo.mift.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.2'
    },
    [PartnerInfo.sit.partnerId]: {
      'commonName': PartnerInfo.sit.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.utit.partnerId]: {
      'commonName': PartnerInfo.utit.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.performance.partnerId]: {
      'commonName': PartnerInfo.performance.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.bossUSQA.partnerId]: {
      'commonName': PartnerInfo.bossUSQA.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false
    },
    [PartnerInfo.bossSCOQA.partnerId]: {
      'commonName': PartnerInfo.bossSCOQA.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.2'
    },
    [PartnerInfo.ukdMAC.partnerId]: {
      'commonName': PartnerInfo.ukdMAC.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.2'
    },
    [PartnerInfo.ukqWIN.partnerId]: {
      'commonName': PartnerInfo.ukqWIN.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.3'
    },
    [PartnerInfo.productionUK.partnerId]: {
      'commonName': PartnerInfo.productionUK.commonName,
      'phoneapi-latest': false,
      'sdapi-latest': false,
      'console-version': '3.1'
    },
    [PartnerInfo.euq.partnerId]: {
      'commonName': PartnerInfo.euq.commonName,
      'phoneapi-latest': true,
      'sdapi-latest': true,
      'console-version': '3.4'
    },
    'default': true
  },
  featureFlagsBycountryDefault: {
    '124': {
      'commonName': 'CANADA',
      'emergency_console': false
    },
    '276': {
      'commonName': 'GERMANY',
      'emergency_console': true
    },
    '826': {
      'commonName': 'UK',
      'emergency_console': false
    },
    '840': {
      'commonName': 'US',
      'emergency_console': false
    },
    'all': {
      'commonName': 'ALL',
      'hardware_console': true,
      'inventory_console': true,
      'number_purchase': true,
      'transfer_request': true
    },
    'default': false
  }
};


export const ActiveFeatureFlags = {
  "flags_by_country": {
    [Countries.Canada]: {
      "automatic_number_purchase": true,
      "commonName": "CANADA",
      "emergency_console": false,
      "raybaum_emergency_cfm": true,
      "transfer_request": true
    },
    [Countries.Germany]: {
      "DECT_Phones_Section": true,
      "automatic_number_purchase": false,
      "boss_iframe": [
        "STAFF",
        "PARTNER"
      ],
      "commonName": "GERMANY",
      "emergency_console": true,
      "emergency_registration_proof_upload": true,
      "gemrmany_geo_non_geo": true,
      "germany_transfer_request": true,
      "hide_TOS_links": true,
      "hide_personal_emergency_address": true,
      "transfer_request": true
    },
    [Countries.UnitedKingdom]: {
      "automatic_number_purchase": false,
      "commonName": "UK",
      "emergency_console": false,
      "hide_TOS_links": true,
      "hide_personal_emergency_address": true,
      "transfer_request": true
    },
    [Countries.UnitedStates]: {
      "DECT_Phones_Section": false,
      "automatic_number_purchase": true,
      "commonName": "US",
      "emergency_console": false,
      "raybaum_emergency_cfm": true,
      "transfer_request": true,
      "Users_old": false
    },
    "all": {
      "commonName": "ALL",
      "hardware_boss": false,
      "hardware_console": true,
      "inventory_boss": false,
      "inventory_console": true,
      "number_purchase": true,
      "user_bulk_add_phone": true,
      "user_bulk_edit": true,
      "user_bulk_edit_addons": true,
      "user_bulk_edit_personal_info": true,
      "user_bulk_edit_phone": true,
      "users_old": false
    },
    "default": false
  },
  "flags_by_environment": {
    [PartnerInfo.productionUS.partnerId]: {
    'commonName': PartnerInfo.productionUS.commonName,
    "console-version": "4.4",
    "phoneapi-latest": true,
    "sdapi-latest": true
    },
    [PartnerInfo.bossUSQA.partnerId]: {
      'commonName': PartnerInfo.bossUSQA.commonName,
      "console-version": "4.2",
      "phoneapi-latest": true,
      "sdapi-latest": true
      },
    [527812169]: {
      'commonName': 'Automated Test',
      "console-version": "4.4",
      "phoneapi-latest": true,
      "sdapi-latest": true
      },
    "default": false
  }
};