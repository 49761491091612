import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[cuxTitleTooltip]',
})
export class CuxTitleTooltipDirective {
  domElement: HTMLElement;
  // send true value to cuxTitleTooltip when content could not be reached i.e. a disabled element
  @Input() cuxTitleTooltip: boolean = false;
  constructor(private elementRef: ElementRef) {
    this.domElement = this.elementRef.nativeElement;
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.cuxTitleTooltip ?
      this.domElement.title = this.domElement.children[0].textContent
      : this.domElement.title = this.domElement.textContent;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.domElement.removeAttribute('title');
  }
}
