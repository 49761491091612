import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/primeng';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { CuxDatepickerComponent } from './components/cux-datepicker/cux-datepicker.component';
import { CuxDropdownComponent } from './components/cux-dropdown/cux-dropdown.component';
import { CuxTableComponent } from './components/cux-table/cux-table.component';
import { LocationDropdownComponent } from './components/dropdown/location-dropdown/location-dropdown.component';
import { CuxEllipsifyDirective } from './directives/cux-ellipsify/cux-ellipsify.directive';
import { CuxTitleTooltipDirective } from './directives/cux-title-tooltip/cux-title-tooltip.directive';
import { GeographicFeatureService } from './features/geographic-feature.service';
import { ODataBuilderService } from '../services/o-data-builder.service';

@NgModule({
  declarations: [
    CuxDatepickerComponent,
    CuxDropdownComponent,
    CuxEllipsifyDirective,
    CuxTableComponent,
    CuxTitleTooltipDirective,
    LocationDropdownComponent
  ],
  entryComponents: [],
  imports: [
    CalendarModule,
    CommonModule,
    DropdownModule,
    FormsModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    TableModule
  ],
  providers: [
    GeographicFeatureService,
    ODataBuilderService
  ],
  exports: [
    CuxDropdownComponent,
    CuxDatepickerComponent,
    CuxEllipsifyDirective,
    CuxTableComponent,
    CuxTitleTooltipDirective,
    LocationDropdownComponent
  ]
})
export class SharedModule { }
