import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  AfterViewChecked,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { SchedulesService } from '../../services/schedules.service';
import { ScheduleAdaptorService } from '../../services/schedule-adaptor.service';
import { SchedulesModelComponent } from '../schedules-model/schedules-model.component';

import { Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';

import { rightPanelCloseReason, TableSortingOrder } from 'src/app/shared/constants';

import { UserClaims } from '@mitel/cloudlink-sdk';
import { Account } from '@mitel/cloudlink-sdk/admin';

import { BossApiUtils } from 'src/app/shared/BossApiUtils';
import { ClHeaderComponent } from '@mitel/cloudlink-console-components';
import { CuxTableComponent } from '../../shared/components/cux-table/cux-table.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CallFlowsService } from '../../modules/boss-api/generated/services/call-flows.service';
import { ODataBuilderService } from '../../services/o-data-builder.service';


@Component({
  selector: 'app-schedules-dashboard',
  templateUrl: './schedules-dashboard.component.html',
  styleUrls: ['./schedules-dashboard.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translate3d(100%, 0, 0)' }),
        animate('400ms ease-in-out', style({ transform: 'translate3d(0, 0, 0)' })),
      ]),
      transition(':leave', [
        animate('400ms ease-in-out', style({ transform: 'translate3d(100%, 0, 0)' }))
      ])
    ])
  ]
})
export class SchedulesDashboardComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {

  @Input() columns: any;
  @Input() claims: UserClaims;
  @Input() company: Account;
  @Output() rightPanelOpened: EventEmitter<boolean> = new EventEmitter();

  selectedSchedules = [];
  scheduleRightPanelState = 'out';
  toOpenRightPanel = false;
  scheduleSelected: string;
  accountId: string;
  clickedScheduleSubscription: Subscription;
  schedules: any;
  storedSchedules = [];

  modalRef: NgbModalRef;
  closeReason: string;

  displayLoadingSpinner = true;

  showFormError = false;
  errMsg = '';
  errMsgStyle: any = { 'max-width': '650px' };
  searchItem = '';
  popUp = false;
  loadedByIframe = false;
  searchValue = new Subject<string>();

  // p-table
  totalRecords: number;
  scrollable: boolean;
  virtualScroll: boolean;
  rows: number;
  scrollHeight: string;
  lazy: boolean;
  virtualRowHeight: number;
  loading: boolean;

  @ViewChild('cuxtable') cuxTable: CuxTableComponent;

  defaultSortField = 'name';
  oDataSortingOrder = TableSortingOrder['asc'];
  oDataSortingColumn = 'name';
  oDataSearchFilter: string;
  oDataTop: number;
  oDataSkip: number;
  scheduleTypeLabels: string[] = ['schedules_model.custom_schedule', 'schedules_model.holidays_schedule', 'schedules_model.hours_schedule'];
  translations: string[];
  subscriptions: Subscription[] = [];
  noSchedules: any;
  isFilteringAction: boolean;

  constructor(private schedulesSvc: SchedulesService,
              public translateSvc: TranslateService,
              private modalService: NgbModal,
              public cd: ChangeDetectorRef,
              private scheduleSvc: ScheduleAdaptorService,
              private clHeader: ClHeaderComponent,
              private oDataService: ODataBuilderService) {
    console.log('======Toolbar visibility==========');
    this.loadedByIframe = !clHeader.headerAuth.isToolbarVisible();
    console.log('==================================');
    this.initScrollProperties();
  }

  async ngOnInit() {
    this.clickedScheduleSubscription = this.schedulesSvc.clickedScheduleChanged.subscribe(schedule => {
      if (schedule) {
        this.scheduleRightPanelState = 'in';
        this.toOpenRightPanel = true;
        this.rightPanelOpened.emit(this.toOpenRightPanel);
        if (schedule.timeTypeId === 1) {
          this.scheduleSelected = 'hours';
        } else if (schedule.timeTypeId === 3) {
          this.scheduleSelected = 'holiday';
        } else {
          this.scheduleSelected = 'custom';
        }
      } else {
        this.scheduleRightPanelState = 'out';
      }
    });

    if (this.company) {
      this.accountId = this.company.accountId;
      // this.initSchedules();
    }

    this.searchValue.pipe(
        debounceTime(300),
        distinctUntilChanged())
        .subscribe(searchKey => {
          if (searchKey.length > 2 || searchKey.length === 0) {
            this.isFilteringAction = true;
            this.onSearch(searchKey);
          }
        });
    this.subscriptions.push(this.translateSvc.stream(this.scheduleTypeLabels).subscribe(translations => {
      this.translations = translations;
      this.mapTimeTypeTranslations();
    }));
  }

  initScrollProperties() {
    this.scrollable = true;
    this.rows = 15;
    this.scrollHeight = '450px';
    this.virtualScroll = true;
    this.lazy = true;
    this.virtualRowHeight = 36;
    this.initTopAndSkip();
  }

  initTopAndSkip() {
    this.oDataTop = this.rows * 2;
    this.oDataSkip = 0;
  }

  /**
   * Respond when Angular (re)sets data-bound input properties. The method receives a SimpleChanges object of
   * current and previous property values.  Called before ngOnInit() and whenever one or more data-bound
   * input properties change.    https://angular.io/guide/lifecycle-hooks
   *
   * @param changes
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.company && changes.company.currentValue && changes.company.currentValue.accountId !== this.accountId) {
      console.log('entered');
      this.company = changes.company.currentValue;
      this.accountId = this.company.accountId;
      await this.initSchedules();
    }
  }

  isTabbable() {
    return this.scheduleRightPanelState === 'out';
  }

  onAddSchedule() {
    console.log('click add schedule');
    if (this.modalRef) {
      console.log('modalRef not se to null.');
      this.modalRef = null;
      return;
    }
    const options: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'add-schedule-popup'
    };
    this.modalRef = this.modalService.open(SchedulesModelComponent, options);
    this.popUp = false;
    this.modalRef.result.then((result) => {
      console.log('--> result from popup', result);
      this.scheduleRightPanelState = 'in';
      this.toOpenRightPanel = true;
      this.rightPanelOpened.emit(this.toOpenRightPanel);
      this.scheduleSelected = result;
      this.modalRef = null;

    }, (reason) => {
      this.closeReason = this.getDismissReason(reason);
      this.modalRef = null;
    });
  }

  async loadChunckData(event) {
    // Lets not fetch for already loaded data
    if (this.oDataTop === event.rows && this.oDataSkip === event.first &&
        this.oDataSortingColumn === event.sortField && this.oDataSortingOrder === event.sortOrder) {
      console.log('Discarding fetch for same query');
      return;
    }

    this.oDataTop = event.rows;
    this.oDataSkip = event.first;

    // If its a new sorting, resetScroll scroll to beginning
    /* istanbul ignore else */
    if (this.oDataSortingColumn !== event.sortField || this.oDataSortingOrder !== event.sortOrder) {
      this.cuxTable.resetScrollTop();
    }

    this.oDataSortingColumn = event.sortField;
    this.oDataSortingOrder = event.sortOrder;
    await this.initSchedules();
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return rightPanelCloseReason.ESC;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return rightPanelCloseReason.BACKDROP;
    } else {
      return reason;
    }
  }

  onSelectedSchedulesChanged(event) {
    const schedule = this.schedules.find(item => item.id === event.data.id);
    this.selectedSchedules = schedule;
    this.schedulesSvc.setClickedSchedule(schedule);
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  async initSchedules() {
    this.clearServerError();
    if (!!this.oDataTop) {
      try {
        const params = this.buildODataQuery();
        this.displayLoadingSpinner = true;
        await this.scheduleSvc.getPagedSchedules(params).toPromise()
          .then(pagedData => {
            this.noSchedules = pagedData.schedules.length === 0 ? this.isFilteringAction ? this.translateSvc.instant('schedules_dashboard.no_filtered_data') :
            this.translateSvc.instant('schedules_dashboard.no_schedules_added') : '';
            this.isFilteringAction = false;
            this.schedules = pagedData.schedules; 
            this.mapTimeTypeTranslations();
            this.totalRecords = pagedData.count;
          },
            error => {
              console.error('failed to retrieve schedules', error);
              this.displayLoadingSpinner = false;
              this.showServerError('Failed to retrieve schedules. ' + BossApiUtils.extractErrorMessage(error));
            });
      } catch (error) {
        console.error('failed to retrieve schedules', error);
        this.displayLoadingSpinner = false;
        this.showServerError('Failed to retrieve schedules. ' + BossApiUtils.extractErrorMessage(error));
      } finally {
        this.displayLoadingSpinner = false;
      }
    } else {
      console.error('Verify the OData properties!');
    }
  }

  buildODataQuery() {
    const params: CallFlowsService.CallFlowsGetSchedulesByAccountPagedParams = {
      Authorization: null,
      Top: this.oDataTop,
      Skip: this.oDataSkip,
      Filter: this.getFilter(),
      OrderBy: this.getOrderby(),
      Count: true
    };
    return params;
  }

  getFilter() {
    if (!!this.oDataSearchFilter) {
      return this.oDataSearchFilter;
    } else {
      return null;
    }
  }

  getOrderby() {
    if (!!this.oDataSortingColumn) {
      const column = this.columns.find(item => item.field === this.oDataSortingColumn);
      const sortOrder = TableSortingOrder[this.oDataSortingOrder];
      return column.sortBy + ' ' + sortOrder;
    } else {
      return null;
    }
  }

  onSearch(searchKey) {
    const fields = this.columns.map(item => item.queryFields).flat();
    this.oDataSearchFilter = this.oDataService.buildSearchQuery(fields, searchKey);
    this.refreshPage();
  }

  refreshPage() {
    this.cuxTable.resetScrollTop();
    this.resetTopAndSkip();
    this.initSchedules();
  }

  resetTopAndSkip() {
    this.oDataTop = this.rows * 2;
    this.oDataSkip = 0;
  }

  async onCloseRightPanel(schedulesUpdated: boolean) {
    this.scheduleRightPanelState = 'out';
    this.toOpenRightPanel = false;
    this.rightPanelOpened.emit(this.toOpenRightPanel);
    if (schedulesUpdated) {
      await this.initSchedules();
    }
  }

  ngOnDestroy() {
    if (this.clickedScheduleSubscription) {
      this.clickedScheduleSubscription.unsubscribe();
    }

    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  showServerError(message) {
    this.errMsg = message;
    this.showFormError = true;
  }

  clearServerError() {
    this.errMsg = '';
    this.showFormError = false;
  }

  mapTimeTypeTranslations() { 
    if (this.schedules && this.translations) {
      this.schedules.forEach(schedule => {
        switch (schedule.timeTypeId) {
          case 4:
            schedule.timeTypeLabel = this.translations['schedules_model.custom_schedule']
            break;
          case 3:
            schedule.timeTypeLabel = this.translations['schedules_model.holidays_schedule']
            break;
          case 1:
            schedule.timeTypeLabel = this.translations['schedules_model.hours_schedule']
            break;
          default:
            schedule.timeTypeLabel = schedule.timeType
            break;
        }
      });
    }
  }

}
