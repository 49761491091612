
import {PhoneNumber} from './tn-adaptor';

export class PhoneService {
  uuid: string;
  name: string;
  description: string;
  mrc: number;
  nrc: number;
  products: PhoneServiceProduct[];
  currency: string;
}

export class PhoneServiceCarousel extends PhoneService {
  configured ?: boolean = false;
}
export enum ProfileStatus {
  ported = 2,
  available = 4,
  current = 5,
  transferRequested = 15,
  pendingTurnup = 16
}

export class PhoneServiceProduct {
  name: string;
}

export class PhoneServiceCompare {
  currentPhoneService: PhoneService;
  newPhoneService: PhoneService;
  // currentUser: User;
}

export class UserPhoneService {
  constructor(public serviceBundle: PhoneService,
              public userId?: string,
              public extension?: string,
              public phoneNumber?: PhoneNumber) {}

}

export class InvoiceInfo {
  bundleName: string;
  priceMonth: number;
}

export const mockPhoneServices: PhoneService[] = [
  {
    uuid: '1',
    name: 'None',
    description: 'No phone service',
    mrc: 0.00,
    nrc: 0.00,
    products: [],
    currency: "USD",
  },
  {
    uuid: '2',
    name: '2 Basic Service',
    description: 'Pretty lame, better than nothing',
    mrc: 27.00,
    nrc: 15.00,
    products: [
      { name: 'Product one' },
      { name: 'Product Two' },
    ],
    currency: "USD"
  },
  {
    uuid: '3',
    name: '3 Blowout Sale',
    description: 'Get better than basic at little cost',
    mrc: 73.00,
    nrc: 15.00,
    products: [
      { name: 'Product one' },
      { name: 'Product Two' },
    ],
    currency: "USD"
  },
  {
    uuid: '4',
    name: '4 Enterprise',
    description: 'Like enterprise, but not really',
    mrc: 104.00,
    nrc: 17.00,
    products: [
      { name: 'Product one' },
      { name: 'Product Two' },
    ],
    currency: "USD"
  },
  {
    uuid: '5',
    name: '5 Random',
    description: 'It\' the best bundle. Ever!',
    mrc: 223.00,
    nrc: 23.00,
    products: [
      { name: 'Product one' },
      { name: 'Product Two' },
    ],
    currency: "USD"
  },
  {
    uuid: '6',
    name: '6 Fax',
    description: 'What is a Fax?',
    mrc: 244.00,
    nrc: 23.00,
    products: [
      { name: 'Product one' },
      { name: 'Product Two' },
    ],
    currency: "USD"
  },
  {
    uuid: '7',
    name: '7 Silver',
    description: 'Just this',
    mrc: 244.00,
    nrc: 23.00,
    products: [
      { name: 'Product one' },
      { name: 'Product Two' },
    ],
    currency: "USD"
  },
  {
    uuid: '8',
    name: '8 Gold',
    description: 'This and that',
    mrc: 223.00,
    nrc: 23.00,
    products: [
      { name: 'Product one' },
      { name: 'Product Two' },
    ],
    currency: "USD"
  },
  {
    uuid: '9',
    name: '9 Platinum',
    description: 'This and that and everything',
    mrc: 244.00,
    nrc: 23.00,
    products: [
      { name: 'Product one' },
      { name: 'Product Two' },
    ],
    currency: "USD"
  },
  {
    uuid: '10',
    name: '10 Water',
    description: 'You get the picture..',
    mrc: 244.00,
    nrc: 23.00,
    products: [
      { name: 'Product one' },
      { name: 'Product Two' },
    ],
    currency: "USD"
  }
];

export const availableExtensions = [
  '7788',
  '9900',
  '1234',
  '2222'
];

export const assignedPhoneNumbers = [
  '5143237894',
  '4051238844'
];

export const portedPhoneNumbers = [
  '6135927894',
  '8193214568'
];

export const PHONE_NUMBER_ASSIGNMENTS = [
  {value: "None", displayName: "aa.phone_number_assignment.none"},
  {value: "Existing", displayName: "aa.phone_number_assignment.existing"},
  {value: "New", displayName: "aa.phone_number_assignment.new"}
];

export const PHONE_NUMBER_ASSIGNMENTS_EXCEPT_EXISTING = [
  {value: "None", displayName: "aa.phone_number_assignment.none"},
  {value: "New", displayName: "aa.phone_number_assignment.new"}
];

export const diallingPlanExtensionLength = 4;
export const maxPhoneNumberLength = 15;

export const emergencyNumbers =  [
  "5143237894",
  "8193214568"
];

export const states = [
  {
    value: 'AL',
    displayName: 'AL',
  }, {
    value: 'AK',
    displayName: 'AK'
  }, {
    value: 'AZ',
    displayName: 'AZ'
  }, {
    value: 'AR',
    displayName: 'AR'
  }, {
    value: 'CA',
    displayName: 'CA'
  }, {
    value: 'CT',
    displayName: 'CT'
  }, {
    value: 'HI',
    displayName: 'HI'
  }, {
    value: 'ID',
    displayName: 'ID'
  }, {
    value: 'KS',
    displayName: 'KS'
  }, {
    value: 'LA',
    displayName: 'LA'
  }, {
    value: 'NE',
    displayName: 'NE'
  }, {
    value: 'MO',
    displayName: 'MO'
  }, {
    value: 'NM',
    displayName: 'NM'
  }, {
    value: 'OK',
    displayName: 'OK'
  }, {
  value: 'TX',
    displayName: 'TX'
  }
];

export const PHONE_SERVICE_SETTINGS_GROUP = [
    {value: "Default", displayName: "Default"},
    {value: "Custom1", displayName: "Custom1"},
    {value: "Custom2", displayName: "Custom2"}
];

export const PHONE_TIME_FORMAT = [
    {value: "24-Hour", displayName: "24-Hour"},
    {value: "12-Hour", displayName: "12-Hour"}
];

export const PHONE_WALLPAPER_SETTING = [
    {value: "None", displayName: "No Wallpaper"},
    {value: "System Setting", displayName: "Use System Setting"},
    {value: "Custom", displayName: "Custom"}
];

export const PHONE_RING_TONE_SETTING = [
    {value: "System Setting", displayName: "Use System Setting"},
    {value: "1", displayName: "1"},
    {value: "2", displayName: "2"},
    {value: "3", displayName: "3"},
    {value: "4", displayName: "4"}
];

export const PHONE_WIRELESS_AUDIO_QUALITY = [
    {value: "Narrowband", displayName: "More Efficient"},
    {value: "Wideband", displayName: "Better Quality"}
];

export const PHONE_WIRELESS_TX_STRENGTH = [
    {value: "Normal", displayName: "More Devices"},
    {value: "High", displayName: "Better Range"}
];

export const PHONE_EMAIL_NOTIFICATION = [
    {value: "None", displayName: "None"},
    {value: "Send Email", displayName: "Send Email"},
    {value: "Send Email, then mark 'heard'", displayName: "Send Email, then mark 'heard'"},
    {value: "Send Email, with voicemail attached", displayName: "Send Email, with voicemail attached"},
    {value: "Send Email, with voicemail attached and mark 'heard'", displayName: "Send Email, with voicemail attached and mark 'heard'"}
];

export const VOICE_MESSAGE_FORWARDING = [
    {value: "None", displayName: "None"},
    {value: "Forward", displayName: "Forward"},
    {value: "Forward and delete original", displayName: "Forward and delete original"}
];

export const EXTERNAL_CALLER_ID_SET = [
    {value: "own_number", displayName: "Own number: 1(505)343-3434"},
    {value: "other_number", displayName: "Other number: 1(613)592-2122"}
];
