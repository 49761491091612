import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, Validators, FormArray, FormBuilder, FormControl} from '@angular/forms';
import { ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { NavigationConfirmationComponent } from '../../navigation-confirmation/navigation-confirmation.component';
import { rightPanelCloseReason } from '../../shared/constants';
import { SchedulesService } from '../../services/schedules.service';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { ScheduleAdaptorService } from '../../services/schedule-adaptor.service';
import { DeleteConfirmationComponent } from '../../delete-confirmation/delete-confirmation.component';
import { BossApiUtils } from '../../shared/BossApiUtils';
import { CustomScheduleLabels } from 'src/app/models/schedules-model';

@Component({
    selector: 'app-custom-schedule',
    templateUrl: './custom-schedule.component.html',
    styleUrls: ['./custom-schedule.component.css']
})
export class CustomScheduleComponent implements OnInit, OnDestroy {

    @Input() popUp = false;
    @Input() dialogHeader = 'custom.new_title';
    @Input() tabbable = true;
    @Input() toOpen: boolean;
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() resetToOpen = new EventEmitter();
    modalRef: NgbModalRef;
    closeReason: string;
    customForm: FormGroup;
    customSection: FormGroup;
    customSchedule: FormArray;
    showCustomSchedule = false;
    timeZones: any[] = [];
    submitting = false;
    showFormError = false;
    errMsg = '';
    errMsgStyle: any = {'max-width': '650px'};
    displayLoadingSpinner = false;
    deleteConfirmDisplayFlag = false;
    schedule: any;
    updateSchedule = false;
    scheduleType = 4;
    deletedSchedule = [];
    otherErr: string[] = [];
    errorMsg: string[];
    scheduleNameError = '';
    stopTimeValidationMessage = 'custom.stop_time_greater_validation';
    rxjsSubscriptions: Subscription[] = []
    newCustomSchedule: boolean = true;
    translationsKeys: string[] = ['custom.new_title', 'custom.new_period'];
    customScheduleLabels: CustomScheduleLabels = {
        titleLabel: '', 
        newPeriod: ''
    }

    constructor(public translateSvc: TranslateService,
                private fb: FormBuilder,
                private activeModal: NgbActiveModal,
                private modalSvc: NgbModal,
                public schedulesSvc: SchedulesService,
                private notificationService: NotificationService,
                private scheduleSvc: ScheduleAdaptorService) {
    }

    ngOnInit() {
        this.rxjsSubscriptions.push(this.schedulesSvc.clickedScheduleChanged.subscribe(async schedule => {
            this.schedule = schedule;
            if (schedule !== null) {
                await this.setFormWithSchedule();
            }
        }));
        this.schedule = this.schedulesSvc.getClickedSchedule();
        this.updateSchedule = !_.isEmpty(this.schedule);
        this.initForm();
        this.setFormWithSchedule();
        this.rxjsSubscriptions.push(this.translateSvc.stream(this.translationsKeys).subscribe(translations => {
            this.customScheduleLabels.titleLabel = translations['custom.new_title'];
            this.customScheduleLabels.newPeriod = translations['custom.new_period'];
        }));
    }

    async initForm() {
        this.customForm = this.fb.group({
            customSection: this.fb.group({
                name: ['New Custom Schedule', Validators.required],
                timeZone: [null, Validators.required],
                customSchedule: this.fb.array([]),
            })
        });
    }

    async setFormWithSchedule() {
        this.displayLoadingSpinner = true;
        this.customForm.reset();
        if (!this.schedule) {
            await this.scheduleSvc.getNewSchedule(this.scheduleType).toPromise().then (
                custom => {
                    this.newCustomSchedule = true;
                    this.schedule = custom;
                    // this.schedule.name = this.schedule.name === null ? 'New Custom Schedule' : this.schedule.name;
                    this.schedule.name = 'New Custom Schedule';
                    this.displayLoadingSpinner = false;
                },
                error => {
                    console.error('failed to getNewSchedule', error);
                    this.showServerError(
                        this.translateSvc.instant('error_messages.custom_schedule.failed_to_get_new') + BossApiUtils.extractErrorMessage(error));
                    this.displayLoadingSpinner = false;
                }
            );
        } else {
            // this.reInitForm();
            await this.scheduleSvc.getSchedule(this.scheduleType, this.schedule.id).toPromise().then(
                custom => {
                    this.newCustomSchedule = false;
                    this.schedule = custom;
                },
                error => {
                    console.error('failed to fetch custom schedule with id', this.schedule.id, error);
                    this.showServerError(this.translateSvc.instant('error_messages.custom_schedule.failed_to_get_new') + BossApiUtils.extractErrorMessage(error));
                }
            );
        }
        if (this.schedule != null) {
            this.timeZones = this.schedule.timeZones;
            const customSchedules = this.loadAvailableSchedules(this.schedule);
            this.customForm.get('customSection').patchValue({
                name: this.newCustomSchedule ? this.customScheduleLabels.titleLabel : this.schedule.name,
                customSchedule: customSchedules
            });
            if (this.schedule.timeZone) {
                this.customForm.get('customSection').patchValue({timeZone: {value: this.schedule.timeZone}});
            }
            this.displayLoadingSpinner = false;
        }
    }

    loadAvailableSchedules(schedule) {
        this.initForm();
        let schedules = _.cloneDeep(schedule.scheduleItems);
        schedules = _.map(schedules, returnDaySchedules);
        function returnDaySchedules(value) {
            const splitStartTime = _.split(value.startTime, ':');
            const splitStopTime = _.split(value.stopTime, ':');
            value.startTime = new Date(2019, 10, 16, splitStartTime[0], splitStartTime[1], 30, 0);
            value.stopTime = new Date(2019, 10, 16, splitStopTime[0], splitStopTime[1], 30, 0);
            value.scheduleDate = new Date(value.scheduleDate);
            value._destroy = 0;
            return value;
        }
        /* istanbul ignore else*/
        if (schedules.length > 0) {
            for (let i = 0; i < schedules.length; i++) {
                this.reAddCustomSchedule(i);
            }
        }
        return schedules;
    }

    reAddCustomSchedule(index) {
        const scheduleObj = this.customForm.get('customSection').get('customSchedule') as FormArray;
        scheduleObj.insert(index + 1, this.createCustomScheduleArray());
        this.showCustomSchedule = true;
    }

    addCustomSchedule(index) {
        const scheduleObj = this.customForm.get('customSection').get('customSchedule') as FormArray;
        scheduleObj.insert(index + 1, this.createCustomScheduleArray());
        this.customForm.markAsDirty();
        this.showCustomSchedule = true;
    }

    removeCustomSchedule(index) {
        const scheduleObj = this.customForm.get('customSection').get('customSchedule') as FormArray;
        const selectedObject = scheduleObj.controls[index].value;
        if (selectedObject.id !== null) {
            selectedObject._destroy = 1;
            this.deletedSchedule.push(selectedObject);
        }
        scheduleObj.removeAt(index);
        this.customForm.markAsDirty();
        if (scheduleObj && scheduleObj.length === 0) {
            this.showCustomSchedule = false;
        }
    }

    createCustomScheduleArray(): FormGroup {
        return this.fb.group({
            id: null,
            scheduleDate: [new Date(), Validators.required],
            name: [this.customScheduleLabels.newPeriod, Validators.required],
            startTime: [new Date(2019, 10, 16, 8, 0, 30, 0), Validators.required],
            stopTime: [new Date(2019, 10, 16, 17, 0, 30, 0), Validators.required],
            scheduleDateInvalid: false,
            scheduleDateReq: false,
            startTimeReq: false,
            startTimeInvalid: false,
            stopTimeReq: false,
            stopTimeInvalid: false,
            stopTimeValidation: false,
            stopTimeValidationErrorMessage: '',
            stopTimeValidationDisabled: ['true', Validators.required],
            uniqueId: null,
            _destroy: 0
        });
    }

    async onSubmit() {
        this.submitting = true;
        this.clearServerErrorOnForm();
        if (this.customForm.valid) {
            this.displayLoadingSpinner = true;
            if (this.schedule && this.schedule.id != null) {
                console.log('Update Custom schedule');
                const params = this.getCustomScheduleParams(false);
                await this.scheduleSvc.updateCustomSchedule(this.schedule.id, this.scheduleType, params)
                    .toPromise().then(
                        response => {
                            this.schedule = response;
                            if (this.schedule.errors != null && this.schedule.errors !== '') {
                                console.error(this.schedule.errors);
                                this.displayLoadingSpinner = false;
                                this.showServerError(this.translateSvc.instant('error_messages.custom_schedule.failed_to_update_custom'));
                                this.parseServerError(this.schedule.errors);
                            } else {
                                this.displayLoadingSpinner = false;
                                this.callOnClose(true);
                                this.insertMessage('custom.updated_custom');
                            }
                        },
                        error => {
                            // if not caught here, will get an 'core.js:15714
                            // ERROR Error: Uncaught (in promise)' error without a useful stack trace
                            console.error('failed to update custom schedule', error);
                            this.displayLoadingSpinner = false;
                            this.translateSvc.get('error_messages').subscribe(err => {
                                this.showServerError(this.translateSvc.instant('error_messages.custom_schedule.unable_to_update') +  BossApiUtils.extractErrorMessage(error));
                            });
                        }
                    );
                this.submitting = false;
            } else {
                console.log('Create Custom schedule');
                const params = this.getCustomScheduleParams(true);
                await this.scheduleSvc.createCustomSchedule(this.scheduleType, params)
                    .toPromise().then(
                        response => {
                            this.schedule = response;
                            if (this.schedule.errors != null && this.schedule.errors !== '') {
                                console.error(this.schedule.errors);
                                this.displayLoadingSpinner = false;
                                this.showServerError(this.translateSvc.instant('error_messages.custom_schedule.failed_to_create'));
                                this.parseServerError(this.schedule.errors);
                            } else {
                                this.displayLoadingSpinner = false;
                                if (this.popUp) {
                                    this.activeModal.close({id: this.schedule.id, name: this.schedule.name});
                                  } else {
                                    this.callOnClose(true);
                                    this.insertMessage('custom.created_custom');
                                  }
                            }
                        },
                        error => {
                            // if not caught here, will get an 'core.js:15714
                            // ERROR Error: Uncaught (in promise)' error without a useful stack trace
                            console.error('failed to create custom schedule', error);
                            this.displayLoadingSpinner = false;
                            this.translateSvc.get('error_messages').subscribe(err => {
                                this.showServerError( this.translateSvc.instant('error_messages.custom_schedule.unable_to_create') +  BossApiUtils.extractErrorMessage(error));
                            });
                        }
                    );
                this.submitting = false;
            }
        } else {
            this.validateAllFormFields(this.customForm.get('customSection'));
        }
    }

    insertMessage(msg) {
        this.notificationService.updateMessage(msg);
    }

    validateAllFormFields(formGroup: any) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            control.markAsTouched({onlySelf: true});
        });
    }

    cancel() {
        if (this.popUp) {
            this.activeModal.close(false);
        } else {
            this.rightPanelcancel();
        }
    }

    getCustomScheduleParams(isCreate) {
        const _this = this;
        const input = this.customForm.get('customSection').value;
        const items = input.customSchedule;
        let scheduleItems = [];
        this.schedule.name = input.name;
        this.schedule.timeZone = input.timeZone.value;
        _.forEach(items, function (value) {
            if (isCreate) {
                value._create = true;
            }
            value.startTime = _this.scheduleSvc.validateDateString(value.startTime, 'HH:mm');
            value.scheduleDate = _this.scheduleSvc.validateDateString(value.scheduleDate, 'MM/dd/yyyy');
            value.stopTime = _this.scheduleSvc.validateDateString(value.stopTime, 'HH:mm');
            value.yearly = false;
            scheduleItems.push(value);
        });
        scheduleItems = _.union(scheduleItems, this.deletedSchedule);
        this.schedule.customCreateItems = scheduleItems;
        this.schedule.errors = null;
        console.log(this.schedule);
        return this.schedule;
    }

    async onDelete() {
        console.log('Delete Schedule');
        if (!this.modalRef) {

            // makes it a modal window that cannot be dismissed by clicking outside.
            const options: NgbModalOptions = {
                backdrop: 'static',
                keyboard: false
            };

            this.deleteConfirmDisplayFlag = true;
            this.modalRef = this.modalSvc.open(DeleteConfirmationComponent, options);
            this.modalRef.componentInstance.title = 'schedule_delete_confirmation.title';
            this.modalRef.componentInstance.content = 'schedule_delete_confirmation.content';
            this.modalRef.result.then(async (result) => {
                this.modalRef = null;
                console.log(result);
                if (result === true) {
                    await this.handleDelete();
                } else {
                    this.toOpen = true; // keep the Edit side window open
                }
                this.deleteConfirmDisplayFlag = false;
            }, (reason) => {
                // this.closeReason = this.getDismissReason(reason);
                this.modalRef = null;
                this.deleteConfirmDisplayFlag = false;
                console.log(reason);
            });
        } else {
            console.log('modalRef not se to null.');
            this.modalRef = null;
        }
    }

    async handleDelete() {
        this.clearServerErrorOnForm();
        this.displayLoadingSpinner = true;
        const scheduleName = this.schedule.name;
        await this.scheduleSvc.deleteSchedule(this.scheduleType, this.schedule.id)
            .toPromise().then(
                response => {
                    response = JSON.parse(response);
                    console.log('response from delete', response);
                    if (!_.isEmpty(response)) {
                        console.error(response);
                        this.showServerError(this.translateSvc.instant('error_messages.custom_schedule.failed_to_delete'));
                        this.parseDeleteError(response);
                        this.displayLoadingSpinner = false;
                    } else {
                        this.callOnClose(true);
                        this.insertMessage('custom.deleted_custom');
                        this.displayLoadingSpinner = false;
                    }
                    this.deleteConfirmDisplayFlag = false;
                },
                error => {
                    // if not caught here, will get an 'core.js:15714
                    // ERROR Error: Uncaught (in promise)' error without a useful stack trace
                    console.error('failed to delete custom schedule ', error);
                    this.displayLoadingSpinner = false;
                    this.showServerError(this.translateSvc.instant('error_messages.custom_schedule.unable_to_delete') + error.message ? error.message : JSON.stringify(error));
                }
            );
    }

    parseServerError( message: string ) {
        const splitMsg =  message.split('Error:');

        this.errorMsg = splitMsg[1] ? splitMsg[1].split('\r\n') : [];
        if (this.errorMsg.length > 0) {
            this.errorMsg.forEach(err => {
                this.showErrorMessage(err);
            });
        }
    }

    showErrorMessage( message ) {
        const msg = message.split('-');
        if (msg[0].trim() === 'ScheduleName') {
            this.scheduleNameError = msg[1].trim();
        }
        console.log(msg);
        console.log(this.scheduleNameError);
    }
    parseDeleteError( message) {
        const msg = message.split('Error: delete - ');
        const extractedMessage = msg[1].split('",')[0];
        this.otherErr.push(extractedMessage);
    }

    showServerError( message ) {
        this.errMsg = message;
        this.showFormError = true;
        this.scheduleNameError = '';
        this.otherErr = [];
    }

    clearServerErrorOnForm() {
        this.errMsg = '';
        this.showFormError = false;
        this.scheduleNameError = '';
    }

    openConfirmDialog() {
        if (!this.modalRef) {
            // makes it a modal window that cannot be dismissed by clicking outside.
            const options: NgbModalOptions = {
                backdrop: 'static',
                windowClass: 'confimation-box'
            };
            this.modalRef = this.modalSvc.open(NavigationConfirmationComponent, options);
            this.modalRef.result.then((result) => {
                this.modalRef = null;
                if (result === rightPanelCloseReason.LEAVE) {
                    this.callOnClose();
                }
            }, (reason) => {
                this.closeReason = this.getDismissReason(reason);
                this.modalRef = null;
            });
        } else {
            console.log('modalRef not set to null.');
            this.modalRef = null;
        }
    }

    getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return rightPanelCloseReason.ESC;
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return rightPanelCloseReason.BACKDROP;
        } else {
            return reason;
        }
    }

    onClose() {
        // TODO reset
        if (this.customForm.dirty) {
            this.openConfirmDialog();
        } else {
            this.callOnClose();
        }
    }

    callOnClose(flowsUpdated = false) {
        // TODO reset
        this.schedulesSvc.setClickedSchedule(null);
        this.close.emit(flowsUpdated);
    }

    rightPanelcancel() {
        this.onClose();
    }

    updateScheduleDate(event, index) {
        this.customForm.get('customSection').get('customSchedule')['controls'][index].controls['scheduleDate'].setValue(event);
        this.customForm.markAsDirty();
    }
    updateStartTime(event, index) {
        this.customForm.get('customSection').get('customSchedule')['controls'][index].controls['startTime'].setValue(event);
        this.customForm.markAsDirty();
        this.validateStartStopTime(index);
    }
    updateStopTime(event, index) {
        this.customForm.get('customSection').get('customSchedule')['controls'][index].controls['stopTime'].setValue(event);
        this.customForm.markAsDirty();
        this.validateStartStopTime(index);
    }

    validateStartStopTime(index) {
        const startTime = this.customForm.get('customSection')
            .get('customSchedule')['controls'][index].controls['startTime'].value;
        const stopTime = this.customForm.get('customSection')
            .get('customSchedule')['controls'][index].controls['stopTime'].value;
        if (!_.isNull(startTime) && typeof(startTime) === 'object' && !_.isNull(stopTime) && typeof(stopTime) === 'object') {
            const formattedStartTime = new Date(2019, 10, 16, startTime.getHours(), startTime.getMinutes(), 30, 0);
            const formattedStopTime = new Date(2019, 10, 16, stopTime.getHours(), stopTime.getMinutes(), 30, 0);
            if (formattedStopTime.getTime() === formattedStartTime.getTime()) {
                this.customForm.get('customSection')
                    .get('customSchedule')['controls'][index].controls['stopTimeValidationErrorMessage']
                        .setValue('custom.stop_time_equal_validation');
                this.updateStopTimeValidationCommon(index, 'stopTimeValidation', true);
                this.updateStopTimeValidationCommon(index, 'stopTimeValidationDisabled', '');
            } else if (formattedStopTime.getTime() < formattedStartTime.getTime()) {
                this.customForm.get('customSection')
                    .get('customSchedule')['controls'][index].controls['stopTimeValidationErrorMessage']
                        .setValue('custom.stop_time_greater_validation');
                this.updateStopTimeValidationCommon(index, 'stopTimeValidation', true);
                this.updateStopTimeValidationCommon(index, 'stopTimeValidationDisabled', '');
            } else {
                this.updateStopTimeValidationCommon(index, 'stopTimeValidation', false);
                this.updateStopTimeValidationCommon(index, 'stopTimeValidationDisabled', 'true');
            }
        } else {
            this.updateStopTimeValidationCommon(index, 'stopTimeValidation', false);
            this.updateStopTimeValidationCommon(index, 'stopTimeValidationDisabled', 'true');
        }
    }

    updateStopTimeValidationCommon(index, name, value) {
        this.customForm.get('customSection')
            .get('customSchedule')['controls'][index].controls[name].setValue(value);
    }

    ngOnDestroy() {
        this.schedulesSvc.setClickedSchedule(null);
        this.rxjsSubscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
          });
    }
}

