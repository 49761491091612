/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AccountDC } from '../models/account-dc';
import { AccountSummaryDC } from '../models/account-summary-dc';
@Injectable({
  providedIn: 'root',
})
class AccountsService extends __BaseService {
  static readonly AccountsGetAccountPath = '/api/Account';
  static readonly AccountsGetAccountSummaryPath = '/api/Account/Summary';
  static readonly AccountsGetAccountCreditHoldsPath = '/api/AccountCreditHolds';
  static readonly AccountsGetAccountStatusesPath = '/api/AccountStatuses';
  static readonly AccountsGetAccountTypesPath = '/api/AccountTypes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  AccountsGetAccountResponse(Authorization: string): __Observable<__StrictHttpResponse<AccountDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccountDC>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  AccountsGetAccount(Authorization: string): __Observable<AccountDC> {
    return this.AccountsGetAccountResponse(Authorization).pipe(
      __map(_r => _r.body as AccountDC)
    );
  }

  /**
   * Required Permission: ViewConsoleAccountSummary (340), ViewFullConsoleAccountSummary (341)
   * @param Authorization Access token
   * @return OK
   */
  AccountsGetAccountSummaryResponse(Authorization: string): __Observable<__StrictHttpResponse<AccountSummaryDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccountSummaryDC>;
      })
    );
  }
  /**
   * Required Permission: ViewConsoleAccountSummary (340), ViewFullConsoleAccountSummary (341)
   * @param Authorization Access token
   * @return OK
   */
  AccountsGetAccountSummary(Authorization: string): __Observable<AccountSummaryDC> {
    return this.AccountsGetAccountSummaryResponse(Authorization).pipe(
      __map(_r => _r.body as AccountSummaryDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  AccountsGetAccountCreditHoldsResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AccountCreditHolds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  AccountsGetAccountCreditHolds(Authorization: string): __Observable<{[key: string]: string}> {
    return this.AccountsGetAccountCreditHoldsResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  AccountsGetAccountStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AccountStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  AccountsGetAccountStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.AccountsGetAccountStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  AccountsGetAccountTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/AccountTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  AccountsGetAccountTypes(Authorization: string): __Observable<{[key: string]: string}> {
    return this.AccountsGetAccountTypesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }
}

module AccountsService {
}

export { AccountsService }
