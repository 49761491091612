export class AppInfo {
    name: string;
    id: string;
    minRole: string;
    navigation: string;
    private iconActive: string;
    private iconInactive: string;
    private children: AppInfoChild[];

    // returns base64 encoded icon string
    getIconActive(): string {
        return this.iconActive;
    }

    // returns base64 encoded icon string
    getIconInactive(): string {
        return this.iconActive;
    }

    getChildren(): AppInfoChild[] {
        return this.children;
    }
}

export class AppInfoChild extends AppInfo {
}

export const APP_INFO = [
    {
        name: 'Dashboard',
        translationKey: 'Dashboard',
        id: 'dashboard',
        minRole: 'USERS',
        navigation: '/',
    },
    {
        name: 'Billing',
        translationKey: 'Billing',
        id: 'billing',
        minRole: 'BILLING_ADMIN',
        navigation: '/billing',
        children: [
            {
                name: 'Invoices',
                translationKey: 'Invoices',
                id: 'invoices',
                minRole: 'BILLING_ADMIN',
                navigation: '/billing',
            },
            {
                name: 'Payment Methods',
                translationKey: 'Payment Methods',
                id: 'paymentmethods',
                minRole: 'BILLING_ADMIN',
                navigation: '/billing',
            },
            {
                name: 'Usage',
                translationKey: 'Usage',
                id: 'usage',
                minRole: 'BILLING_ADMIN',
                navigation: '/billing',
            }
        ]
    },
    {
        name: 'Line Handling',
        translationKey: 'Line Handling',
        id: 'linehandling',
        minRole: 'ACCOUNT_ADMIN',
        navigation: '/linehandling',
        children: [
            {
                name: 'Flows',
                translationKey: 'Flows',
                id: 'flows',
                minRole: 'ACCOUNT_ADMIN',
                navigation: '/flows',
            },
            {
                name: 'Schedules',
                translationKey: 'Schedules',
                id: 'Schedules',
                minRole: 'ACCOUNT_ADMIN',
                navigation: '/linehandling',
            },
            {
                name: 'Extension Groups',
                translationKey: 'Extension Groups',
                id: 'extensiongroups',
                minRole: 'ACCOUNT_ADMIN',
                navigation: '/linehandling',
            }
        ]
    },
    {
        name: 'Reports',
        translationKey: 'Reports',
        id: 'Reports',
        minRole: 'USERS',
        navigation: '/reports',
    },
    {
        name: 'Users',
        translationKey: 'Users',
        id: 'Users',
        minRole: 'ACCOUNT_ADMIN',
        navigation: '/users',
    },
];

/**
* Information related to the partner
*/
export const PartnerInfo = {
    productionUS: {
        partnerId: '992000002',
        commonName: 'Production US'
    },
    alpha: {
        partnerId: '992000001',
        commonName: 'Alpha'
    },
    can01: {
        partnerId: '527812169',
        commonName: 'CAN01'
    },
    mift: {
        partnerId: '441870381',
        commonName: 'MIFT'
    },
    sit: {
        partnerId: '991000002',
        commonName: 'SIT'
    },
    utit: {
        partnerId: '991000001',
        commonName: 'UTIT'
    },
    performance: {
        partnerId: '995500001',
        commonName: 'Performance'
    },
    bossUSQA: {
        partnerId: '991000008',
        commonName: 'BOSS US QA'
    },
    bossSCOQA: {
        partnerId: '991000006',
        commonName: 'BOSS SCO QA'
    },
    ukdMAC: {
        partnerId: '991000022',
        commonName: 'UKD-MAC'
    },
    ukqWIN: {
        partnerId: '991000023',
        commonName: 'UKQ-WIN'
    },
    productionUK: {
        partnerId: '992000005',
        commonName: 'PRODUCTION - UK'
    },
    euq: {
        partnerId: '991000025',
        commonName: 'EUQ'
    },
    productionEU: {
      partnerId: '992000006',
      commonName: 'PRODUCTION - EU'
    }
}
