import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  AfterViewChecked,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ExtensionListService } from '../../services/extension-list.service';
import { ExtensionListAdaptorService } from '../../services/extension-list-adaptor.service';

import {Subject, Subscription} from 'rxjs';
import * as _ from 'lodash';

import {rightPanelCloseReason, TableSortingOrder} from 'src/app/shared/constants';

import { UserClaims } from '@mitel/cloudlink-sdk';
import { Account } from '@mitel/cloudlink-sdk/admin';

import { BossApiUtils } from 'src/app/shared/BossApiUtils';
import { ClHeaderComponent } from '@mitel/cloudlink-console-components';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CuxTableComponent} from '../../shared/components/cux-table/cux-table.component';
import {CallFlowsService} from '../../modules/boss-api/generated/services/call-flows.service';
import {ODataBuilderService} from '../../services/o-data-builder.service';

@Component({
  selector: 'app-extension-list-dashboard',
  templateUrl: './extension-list-dashboard.component.html',
  styleUrls: ['./extension-list-dashboard.component.scss'],
  providers: [],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translate3d(100%, 0, 0)'}),
        animate('400ms ease-in-out', style({transform: 'translate3d(0, 0, 0)'})),
      ]),
      transition(':leave', [
        animate('400ms ease-in-out', style({transform: 'translate3d(100%, 0, 0)'}))
      ])
    ])
  ]
})
export class ExtensionListDashboardComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {

  @Input() columns: any;
  @Input() claims: UserClaims;
  @Input() company: Account;
  @Output() rightPanelOpened: EventEmitter<boolean> = new EventEmitter();

  selectedExtensions = [];
  extensionRightPanelState = 'out';
  toOpenRightPanel = false;
  extensionSelected: string;
  accountId: string;
  clickedExtensionSubscription: Subscription;
  extensions: any;
  storedExtensions = [];
  modalRef: NgbModalRef;
  closeReason: string;

  displayLoadingSpinner = true;

  showFormError = false;
  errMsg = '';
  errMsgStyle: any = {'max-width': '650px'};
  searchItem = '';
  popUp = false;
  loadedByIframe = false;
  searchValue = new Subject<string>();

  // p-table
  totalRecords: number;
  scrollable: boolean;
  virtualScroll: boolean;
  rows: number;
  scrollHeight: string;
  lazy: boolean;
  virtualRowHeight: number;
  loading: boolean;

  @ViewChild('cuxtable') cuxTable: CuxTableComponent;

  defaultSortField  = 'name';
  oDataSortingOrder = TableSortingOrder['asc'];
  oDataSortingColumn = 'name';
  oDataSearchFilter: string;
  oDataTop: number;
  oDataSkip: number;
  noExtensionsGroups: any = '';
  isFilteringAction: boolean;

  constructor(private extensionsSvc: ExtensionListService,
              public translateSvc: TranslateService,
              private modalService: NgbModal,
              public cd: ChangeDetectorRef,
              private extensionSvc: ExtensionListAdaptorService,
              private clHeader: ClHeaderComponent,
              private oDataService: ODataBuilderService) {
    console.log('======Toolbar visibility==========');
    this.loadedByIframe = !clHeader.headerAuth.isToolbarVisible();
    console.log('==================================');
    this.initScrollProperties();
  }

  async ngOnInit() {
    this.clickedExtensionSubscription = this.extensionsSvc.clickedExtensionChanged.subscribe(extension => {
      if (extension) {
        this.extensionRightPanelState = 'in';
        this.toOpenRightPanel = true;
        this.rightPanelOpened.emit(this.toOpenRightPanel);
        this.extensionSelected = 'extensions';
      } else {
        this.extensionRightPanelState = 'out';
      }
    });

    if (this.company) {
      this.accountId = this.company.accountId;
      // this.initExtensions();
    }

    this.searchValue.pipe(
        debounceTime(300),
        distinctUntilChanged())
        .subscribe(searchKey => {
          if (searchKey.length > 2 || searchKey.length === 0) {
            this.isFilteringAction = true;
            this.onSearch(searchKey);
          }
        });

  }

  initScrollProperties() {
    this.scrollable = true;
    this.rows = 15;
    this.scrollHeight = '450px';
    this.virtualScroll = true;
    this.lazy = true;
    this.virtualRowHeight = 36;
    this.initTopAndSkip();
  }

  initTopAndSkip() {
    this.oDataTop = this.rows * 2;
    this.oDataSkip = 0;
  }

  /**
   * Respond when Angular (re)sets data-bound input properties. The method receives a SimpleChanges object of
   * current and previous property values.  Called before ngOnInit() and whenever one or more data-bound
   * input properties change.    https://angular.io/guide/lifecycle-hooks
   *
   * @param changes
   */
  async ngOnChanges(changes: SimpleChanges) {


    if (changes.company && changes.company.currentValue && changes.company.currentValue.accountId !== this.accountId) {
      this.company = changes.company.currentValue;
      this.accountId = this.company.accountId;
      await this.initExtensions();
    }
  }

  isTabbable() {
    return this.extensionRightPanelState === 'out';
  }

  hasSelectedExtensions() {
    return this.selectedExtensions && this.selectedExtensions.length > 0;
  }

  onAddExtension() {
    this.extensionRightPanelState = 'in';
    this.toOpenRightPanel = true;
    this.rightPanelOpened.emit(this.toOpenRightPanel);
    // this.extensionRightPanelComponent.getAvailableExtensions();
  }

  async loadChunckData(event) {
    // Lets not fetch for already loaded data
    if (this.oDataTop === event.rows && this.oDataSkip === event.first &&
        this.oDataSortingColumn === event.sortField && this.oDataSortingOrder === event.sortOrder) {
      console.log('Discarding fetch for same query');
      return;
    }

    this.oDataTop = event.rows;
    this.oDataSkip = event.first;

    // If its a new sorting, resetScroll scroll to beginning
    /* istanbul ignore else */
    if (this.oDataSortingColumn !== event.sortField  || this.oDataSortingOrder !== event.sortOrder) {
      this.cuxTable.resetScrollTop();
    }

    this.oDataSortingColumn = event.sortField;
    this.oDataSortingOrder = event.sortOrder;
    await this.initExtensions();
  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return rightPanelCloseReason.ESC;
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return rightPanelCloseReason.BACKDROP;
    } else {
      return  reason;
    }
  }

  onSelectedExtensionsChanged(event) {
    const extension = this.extensions.find(item => item.id === event.data.id);
    this.selectedExtensions = extension;
    this.extensionsSvc.setClickedExtension(extension);
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  async initExtensions() {
    this.clearServerError();
    if (!!this.oDataTop) {
      try {
        const params = this.buildODataQuery();
        this.displayLoadingSpinner = true;
        await this.extensionSvc.getPagedExtensions(params).toPromise()
            .then(pagedData => { 
                  this.noExtensionsGroups = pagedData.extensions.length === 0 ? this.isFilteringAction ? this.translateSvc.instant('extensions_dashboard.no_filtered_data') :
                  this.translateSvc.instant('extensions_dashboard.no_extension_groups_added') : '';
                  this.isFilteringAction = false;
                  this.extensions = pagedData.extensions;
                  this.totalRecords = pagedData.count;
                },
                error => {
                  console.error('failed to retrieve Extension Groups', error);
                  this.displayLoadingSpinner = false;
                  this.showServerError( 'Failed to retrieve Extension Groups. ' + BossApiUtils.extractErrorMessage(error));
                });
      } catch (error) {
        console.error('failed to retrieve schedules', error);
        this.displayLoadingSpinner = false;
        this.showServerError( 'Failed to retrieve Extensions List. ' + BossApiUtils.extractErrorMessage(error));
      } finally {
        this.displayLoadingSpinner = false;
      }
    } else {
      console.error('Verify the OData properties!');
    }
  }

  buildODataQuery() {
    const params: CallFlowsService.CallFlowsGetExtensionListsByAccountPagedParams = {
      Authorization: null,
      Top: this.oDataTop,
      Skip: this.oDataSkip,
      Filter: this.getFilter(),
      OrderBy: this.getOrderby(),
      Count: true
    };
    return params;
  }

  getFilter() {
    /* istanbul ignore else */
    if (!!this.oDataSearchFilter) {
      return this.oDataSearchFilter;
    }
    return null;
  }

  getOrderby() {
    if (!!this.oDataSortingColumn) {
      const column = this.columns.find(item => item.field === this.oDataSortingColumn);
      const sortOrder = TableSortingOrder[this.oDataSortingOrder];
      return column.sortBy + ' ' + sortOrder;
    } else {
      return null;
    }
  }

  onSearch(searchKey) {
    const fields = this.columns.map(item => item.queryFields).flat();
    this.oDataSearchFilter = this.oDataService.buildSearchQuery(fields, searchKey);
    this.refreshPage();
  }

  refreshPage() {
    this.cuxTable.resetScrollTop();
    this.resetTopAndSkip();
    this.initExtensions();
  }

  resetTopAndSkip() {
    this.oDataTop = this.rows * 2;
    this.oDataSkip = 0;
  }

  async onCloseRightPanel(extensionsUpdated: boolean) {
    this.extensionRightPanelState = 'out';
    this.toOpenRightPanel = false;
    this.rightPanelOpened.emit(this.toOpenRightPanel);
    if (extensionsUpdated) {
      await this.initExtensions();
    }
  }

  ngOnDestroy() {
    if (this.clickedExtensionSubscription) {
      this.clickedExtensionSubscription.unsubscribe();
    }
  }

  showServerError( message ) {
    this.errMsg = message;
    this.showFormError = true;
  }

  clearServerError() {
    this.errMsg = '';
    this.showFormError = false;
  }
}
