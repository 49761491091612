import { Injectable } from '@angular/core';

import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { LocationsService } from '../modules/boss-api/generated/services/locations.service';
import { LocationsService as LocationsServicePrevious } from '../modules/boss-api-previous/generated/services/locations.service';
import { SDApiVersionControlService } from 'src/app/services/sdapi-version-control';
import { CountryDC } from '../modules/boss-api/generated/models';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { Countries } from 'src/app/shared/constants';
@Injectable({
  providedIn: 'root'
})
export class PhoneNumberFormatService {
  currentCountryCode: number;
  nationalFormatCountries = [Countries.UnitedStates, Countries.Canada];
  countries: CountryDC[] = [];
  getCountriesObservable: Observable<CountryDC[]>;
  locSvc: LocationsService | LocationsServicePrevious;
  constructor(private appService: AppService,
    private sdapiVersion: SDApiVersionControlService) {
    this.locSvc = this.sdapiVersion.locationSevice;
  }

  getCountryCodeList(): Observable<CountryDC[]> {
    return this.getCountriesObservable
      ? this.getCountriesObservable
      : (this.getCountriesObservable = this.locSvc
        .LocationsGetCountries(null)
        .pipe(
          map(countries => {
            this.countries = countries;
            return this.countries;
          }),
          shareReplay(1)
        ));
  }

  getAlphaCodeFromIso3166NumericCountryCode(iso3166num_cc: number) {
    return this.getCountryCodeList().pipe(
      map(countryList => {
        const countryCode = countryList.find(
          country => country.id === iso3166num_cc
        );
        return (countryCode && countryCode.codeAlpha2) || '';
      })
    );
  }

  async formatPhoneNumberUsingIso3166NumericCountryCode(
    phoneNumber: string,
    iso3166num_cc: number
  ) {
    try {
      const cc = await this.getAlphaCodeFromIso3166NumericCountryCode(
        iso3166num_cc
      ).toPromise();

      const pnUtil = PhoneNumberUtil.getInstance();
      const pn = pnUtil.parseAndKeepRawInput(phoneNumber, cc);
      return pnUtil.format(pn, PhoneNumberFormat.NATIONAL);
    } catch (err) {
      console.error(
        'Error formatting phone number: ',
        phoneNumber,
        iso3166num_cc
      );
      console.error('Error message: ', err);
      return '';
    }
  }

  async formatInternationalPhoneNumberUsingIso3166NumericCountryCode(phoneNumber: string, iso3166num_cc: number) {
    try {
      const cc = await this.getAlphaCodeFromIso3166NumericCountryCode(iso3166num_cc).toPromise();
      const pnUtil = PhoneNumberUtil.getInstance();
      const pn = pnUtil.parseAndKeepRawInput(phoneNumber, cc);
      return pnUtil.format(pn, PhoneNumberFormat.INTERNATIONAL);
    } catch (err) {
      console.error('Error formatting international phone number: ', phoneNumber, iso3166num_cc);
      console.error('Error message: ', err);
      return '';
    }
  }

  async formatE164PhoneNumberUsingIso3166NumericCountryCode(phoneNumber: string, iso3166num_cc: number) {
    try {
      const cc = await this.getAlphaCodeFromIso3166NumericCountryCode(iso3166num_cc).toPromise();

      const pnUtil = PhoneNumberUtil.getInstance();
      const pn = pnUtil.parseAndKeepRawInput(phoneNumber, cc);
      return pnUtil.format(pn, PhoneNumberFormat.E164);
    } catch (err) {
      console.error('Error formatting phone number: ', phoneNumber, iso3166num_cc);
      console.error('Error message: ', err);
      return '';
    }
  }

  async formatOutOfCountryPhoneNumberUsingIso3166NumericCountryCode(phoneNumber: string, iso3166num_cc: number) {
    try {
      const cc = await this.getAlphaCodeFromIso3166NumericCountryCode(iso3166num_cc).toPromise();

      const pnUtil = PhoneNumberUtil.getInstance();
      const pn = pnUtil.parseAndKeepRawInput(phoneNumber, cc);
      return pnUtil.formatOutOfCountryCallingNumber(pn, cc);
    } catch (err) {
      console.error('Error formatting phone number: ', phoneNumber, iso3166num_cc);
      console.error('Error message: ', err);
      return '';
    }
  }

  async formatPhoneNumberBasedOnCountry(phoneNumber: string) {
    this.currentCountryCode = this.appService.getCountryCode();
    if (this.nationalFormatCountries.includes(this.currentCountryCode)) {
      // National Format
      return this.formatPhoneNumberUsingIso3166NumericCountryCode(phoneNumber, this.currentCountryCode);
    } else {
      // International Format
      return this.formatInternationalPhoneNumberUsingIso3166NumericCountryCode(phoneNumber, this.currentCountryCode);
    }
  }
}
