/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TnDC } from '../models/tn-dc';
import { CarrierOrderDC } from '../models/carrier-order-dc';
import { DirectoryCategoryDC } from '../models/directory-category-dc';
import { GetNumberPurchaseChargeResponseDC } from '../models/get-number-purchase-charge-response-dc';
import { GetNumberTransferChargeResponseDC } from '../models/get-number-transfer-charge-response-dc';
import { AssignPhoneNumberRequestDC } from '../models/assign-phone-number-request-dc';
import { TnWithDestinationDC } from '../models/tn-with-destination-dc';
import { AdditionalNumbersAcknowledgementDC } from '../models/additional-numbers-acknowledgement-dc';
import { TransferRequestDC } from '../models/transfer-request-dc';
import { CreateTransferRequestDC } from '../models/create-transfer-request-dc';
import { UpdateTransferRequestDC } from '../models/update-transfer-request-dc';
import { UploadResultDC } from '../models/upload-result-dc';
import { TransferRequestTnValidation } from '../models/transfer-request-tn-validation';
import { PageResultTransferRequestDC } from '../models/page-result-transfer-request-dc';
import { GetAvailableNumbersResponseDC } from '../models/get-available-numbers-response-dc';
import { GetAvailableNumbersDataContract } from '../models/get-available-numbers-data-contract';
import { CarrierTelephoneOrder } from '../models/carrier-telephone-order';
import { OrderNumbersRequestDC } from '../models/order-numbers-request-dc';
import { NotificationRequestDC } from '../models/notification-request-dc';
import { PushPortabilityCheckResultDC } from '../models/push-portability-check-result-dc';
import { PushPortabilityImpactResultDC } from '../models/push-portability-impact-result-dc';
import { CustomerActionItemReasonDC } from '../models/customer-action-item-reason-dc';
import { LosingCarrierDC } from '../models/losing-carrier-dc';
import { RateCenterDC } from '../models/rate-center-dc';
import { PageResultTnsDC } from '../models/page-result-tns-dc';
@Injectable({
  providedIn: 'root',
})
class TnsService extends __BaseService {
  static readonly TnsGetAssignableTnsPath = '/api/Account/AssignableTns';
  static readonly TnsGetCarrierOrdersPath = '/api/Account/CarrierOrders';
  static readonly TnsGetDirectoryCategoriesPath = '/api/Account/DirectoryCategories/{filter}';
  static readonly TnsGetLocationTnSearchCriteriaPath = '/api/Account/Location/{locationUuid}/TnSearchCriteria';
  static readonly TnsUpdateLocationTnSearchCriteriaPath = '/api/Account/Location/{locationUuid}/TnSearchCriteria';
  static readonly TnsDeleteLocationTnSearchCriteriaPath = '/api/Account/Location/{locationUuid}/TnSearchCriteria';
  static readonly TnsGetNumberPurchaseChargePath = '/api/Account/NumberPurchaseCharge';
  static readonly TnsGetNumberTransferChargePath = '/api/Account/NumberTransferCharge';
  static readonly TnsGetTnsPath = '/api/Account/Tns';
  static readonly TnsAssignPhoneNumberPath = '/api/Account/Tns';
  static readonly TnsReleaseTnsPath = '/api/Account/Tns/Release';
  static readonly TnsReserveTnsPath = '/api/Account/Tns/Reserve';
  static readonly TnsReserveRcfTnsPath = '/api/Account/Tns/ReserveRCF';
  static readonly TnsGetTnsWithDestinationPath = '/api/Account/Tns/WithDestination';
  static readonly TnsGetAdditionalNumbersAckOptionsPath = '/api/Account/TransferRequest/AdditionalNumbersAcknowledgement/Options';
  static readonly TnsGetTransferRequestStatusesPath = '/api/Account/TransferRequest/Statuses';
  static readonly TnsGetTransferRequestTypesPath = '/api/Account/TransferRequest/Types';
  static readonly TnsGetTransferRequestsPath = '/api/Account/TransferRequests';
  static readonly TnsCreateTransferRequestPath = '/api/Account/TransferRequests';
  static readonly TnsGetTransferRequestPath = '/api/Account/TransferRequests/{id}';
  static readonly TnsUpdateTransferRequestPath = '/api/Account/TransferRequests/{id}';
  static readonly TnsDeleteTransferRequestPath = '/api/Account/TransferRequests/{id}';
  static readonly TnsDeleteTransferRequestInvoicePath = '/api/Account/TransferRequests/{id}/Invoice';
  static readonly TnsDeleteTransferRequestLOAPath = '/api/Account/TransferRequests/{id}/LOA';
  static readonly TnsGetTransferRequestFilePath = '/api/Account/TransferRequests/Files/{fileId}';
  static readonly TnsUploadInvoicePath = '/api/Account/TransferRequests/Invoice';
  static readonly TnsUploadLOAPath = '/api/Account/TransferRequests/LOA';
  static readonly TnsValidateTransferRequestTNsPath = '/api/Account/TransferRequests/ValidateTNs';
  static readonly TnsGetTransferRequestsPagedPath = '/api/Account/TransferRequestsPaged';
  static readonly TnsGetAvailableNumbersPath = '/api/Carrier/AvailableNumbers';
  static readonly TnsOrderNumbersPath = '/api/Carrier/Order';
  static readonly TnsNotifyPhoneNumberOrderRequestPath = '/api/Carrier/OrderNotify';
  static readonly TnsPushPortabilityCheckResultsPath = '/api/Carrier/PushPortabilityCheckResults';
  static readonly TnsPushPortabilityImpactResultsPath = '/api/Carrier/PushPortabilityImpactResults';
  static readonly TnsDeleteReservationsAsyncPath = '/api/Carrier/Reservation';
  static readonly TnsUploadNRANumberCertificatePath = '/api/Carrier/UploadNRANumberCertificate';
  static readonly TnsGetCarrierOrderStatusesPath = '/api/CarrierOrderStatuses';
  static readonly TnsGetCustomerActionItemReasonsPath = '/api/CustomerActionItemReasons';
  static readonly TnsGetLosingCarriersPath = '/api/LosingCarriers';
  static readonly TnsSuggestRateCenterPath = '/api/RateCenters/Suggest';
  static readonly TnsGetTollFreeAreaCodesPath = '/api/Ratecenters/TollFreeAreaCodes';
  static readonly TnsTnDestinationTypesPath = '/api/TnDestinationTypes';
  static readonly TnsGetTns_1Path = '/api/Tns';
  static readonly TnsGetTnWithDestinationPath = '/api/Tns/{countryId}/{id}/WithDestination';
  static readonly TnsGetTnStatusesPath = '/api/TnStatuses';
  static readonly TnsGetTnTypesPath = '/api/TnTypes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetAssignableTnsParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `countryId`: Only return Tns that are in this country. See /api/Countries for values
   *
   * @return OK
   */
  TnsGetAssignableTnsResponse(params: TnsService.TnsGetAssignableTnsParams): __Observable<__StrictHttpResponse<Array<TnDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/AssignableTns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TnDC>>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetAssignableTnsParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `countryId`: Only return Tns that are in this country. See /api/Countries for values
   *
   * @return OK
   */
  TnsGetAssignableTns(params: TnsService.TnsGetAssignableTnsParams): __Observable<Array<TnDC>> {
    return this.TnsGetAssignableTnsResponse(params).pipe(
      __map(_r => _r.body as Array<TnDC>)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetCarrierOrdersParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * - `updatePendingOrders`: Update carrier orders status from carrier api
   *
   * @return OK
   */
  TnsGetCarrierOrdersResponse(params: TnsService.TnsGetCarrierOrdersParams): __Observable<__StrictHttpResponse<Array<CarrierOrderDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.locationUuid != null) __params = __params.set('locationUuid', params.locationUuid.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.updatePendingOrders != null) __params = __params.set('updatePendingOrders', params.updatePendingOrders.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CarrierOrders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CarrierOrderDC>>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetCarrierOrdersParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * - `updatePendingOrders`: Update carrier orders status from carrier api
   *
   * @return OK
   */
  TnsGetCarrierOrders(params: TnsService.TnsGetCarrierOrdersParams): __Observable<Array<CarrierOrderDC>> {
    return this.TnsGetCarrierOrdersResponse(params).pipe(
      __map(_r => _r.body as Array<CarrierOrderDC>)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetDirectoryCategoriesParams` containing the following parameters:
   *
   * - `filter`: String to filter category names by (returned names must start with filter string)
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetDirectoryCategoriesResponse(params: TnsService.TnsGetDirectoryCategoriesParams): __Observable<__StrictHttpResponse<DirectoryCategoryDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/DirectoryCategories/${params.filter}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DirectoryCategoryDC>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetDirectoryCategoriesParams` containing the following parameters:
   *
   * - `filter`: String to filter category names by (returned names must start with filter string)
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetDirectoryCategories(params: TnsService.TnsGetDirectoryCategoriesParams): __Observable<DirectoryCategoryDC> {
    return this.TnsGetDirectoryCategoriesResponse(params).pipe(
      __map(_r => _r.body as DirectoryCategoryDC)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetLocationTnSearchCriteriaParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   */
  TnsGetLocationTnSearchCriteriaResponse(params: TnsService.TnsGetLocationTnSearchCriteriaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Location/${params.locationUuid}/TnSearchCriteria`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetLocationTnSearchCriteriaParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   */
  TnsGetLocationTnSearchCriteria(params: TnsService.TnsGetLocationTnSearchCriteriaParams): __Observable<null> {
    return this.TnsGetLocationTnSearchCriteriaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsUpdateLocationTnSearchCriteriaParams` containing the following parameters:
   *
   * - `searchCriteria`: Tn Search Criteria
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return Ok
   */
  TnsUpdateLocationTnSearchCriteriaResponse(params: TnsService.TnsUpdateLocationTnSearchCriteriaParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.searchCriteria;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/Location/${params.locationUuid}/TnSearchCriteria`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsUpdateLocationTnSearchCriteriaParams` containing the following parameters:
   *
   * - `searchCriteria`: Tn Search Criteria
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return Ok
   */
  TnsUpdateLocationTnSearchCriteria(params: TnsService.TnsUpdateLocationTnSearchCriteriaParams): __Observable<{}> {
    return this.TnsUpdateLocationTnSearchCriteriaResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsDeleteLocationTnSearchCriteriaParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return Ok
   */
  TnsDeleteLocationTnSearchCriteriaResponse(params: TnsService.TnsDeleteLocationTnSearchCriteriaParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Account/Location/${params.locationUuid}/TnSearchCriteria`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsDeleteLocationTnSearchCriteriaParams` containing the following parameters:
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return Ok
   */
  TnsDeleteLocationTnSearchCriteria(params: TnsService.TnsDeleteLocationTnSearchCriteriaParams): __Observable<{}> {
    return this.TnsDeleteLocationTnSearchCriteriaResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetNumberPurchaseChargeParams` containing the following parameters:
   *
   * - `numberPurchaseType`: Number Purchase Type(LOCAL = 1, TOLLFREE = 2, NATIONAL = 3)
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetNumberPurchaseChargeResponse(params: TnsService.TnsGetNumberPurchaseChargeParams): __Observable<__StrictHttpResponse<GetNumberPurchaseChargeResponseDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.numberPurchaseType != null) __params = __params.set('numberPurchaseType', params.numberPurchaseType.toString());
    if (params.locationUuid != null) __params = __params.set('locationUuid', params.locationUuid.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/NumberPurchaseCharge`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetNumberPurchaseChargeResponseDC>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetNumberPurchaseChargeParams` containing the following parameters:
   *
   * - `numberPurchaseType`: Number Purchase Type(LOCAL = 1, TOLLFREE = 2, NATIONAL = 3)
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetNumberPurchaseCharge(params: TnsService.TnsGetNumberPurchaseChargeParams): __Observable<GetNumberPurchaseChargeResponseDC> {
    return this.TnsGetNumberPurchaseChargeResponse(params).pipe(
      __map(_r => _r.body as GetNumberPurchaseChargeResponseDC)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetNumberTransferChargeParams` containing the following parameters:
   *
   * - `transferRequestType`: Transfer Request Type as int
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetNumberTransferChargeResponse(params: TnsService.TnsGetNumberTransferChargeParams): __Observable<__StrictHttpResponse<GetNumberTransferChargeResponseDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.transferRequestType != null) __params = __params.set('transferRequestType', params.transferRequestType.toString());
    if (params.locationUuid != null) __params = __params.set('locationUuid', params.locationUuid.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/NumberTransferCharge`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetNumberTransferChargeResponseDC>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetNumberTransferChargeParams` containing the following parameters:
   *
   * - `transferRequestType`: Transfer Request Type as int
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetNumberTransferCharge(params: TnsService.TnsGetNumberTransferChargeParams): __Observable<GetNumberTransferChargeResponseDC> {
    return this.TnsGetNumberTransferChargeResponse(params).pipe(
      __map(_r => _r.body as GetNumberTransferChargeResponseDC)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetTnsParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `typeIds`: Only return Tns that are in of these types. See /api/TnTypes for values
   *
   * - `statusIds`: Only return Tns that are in these statues. See /api/TnStatuses for values
   *
   * - `includeCarrierInfo`: Include carrier transfer request information in the result
   *
   * - `includeAssigned`: If false only return Tns where IsAssigned=false (those that do not have a profile assigned to them or scheduled to be assigned). Note that profiles may be assigned to Tns that are in Available/PendingPortIn/PendingTurnup/TransferRequested statues.
   *
   * - `countryId`: Only return Tns that are in this country. See /api/Countries for values
   *
   * @return OK
   */
  TnsGetTnsResponse(params: TnsService.TnsGetTnsParams): __Observable<__StrictHttpResponse<Array<TnDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.typeIds || []).forEach(val => {if (val != null) __params = __params.append('typeIds', val.toString())});
    (params.statusIds || []).forEach(val => {if (val != null) __params = __params.append('statusIds', val.toString())});
    if (params.includeCarrierInfo != null) __params = __params.set('includeCarrierInfo', params.includeCarrierInfo.toString());
    if (params.includeAssigned != null) __params = __params.set('includeAssigned', params.includeAssigned.toString());
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Tns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TnDC>>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetTnsParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `typeIds`: Only return Tns that are in of these types. See /api/TnTypes for values
   *
   * - `statusIds`: Only return Tns that are in these statues. See /api/TnStatuses for values
   *
   * - `includeCarrierInfo`: Include carrier transfer request information in the result
   *
   * - `includeAssigned`: If false only return Tns where IsAssigned=false (those that do not have a profile assigned to them or scheduled to be assigned). Note that profiles may be assigned to Tns that are in Available/PendingPortIn/PendingTurnup/TransferRequested statues.
   *
   * - `countryId`: Only return Tns that are in this country. See /api/Countries for values
   *
   * @return OK
   */
  TnsGetTns(params: TnsService.TnsGetTnsParams): __Observable<Array<TnDC>> {
    return this.TnsGetTnsResponse(params).pipe(
      __map(_r => _r.body as Array<TnDC>)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsAssignPhoneNumberParams` containing the following parameters:
   *
   * - `request`: {SDApi.Core.DataContracts.Tns.AssignPhoneNumberRequestDC}
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsAssignPhoneNumberResponse(params: TnsService.TnsAssignPhoneNumberParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/Tns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsAssignPhoneNumberParams` containing the following parameters:
   *
   * - `request`: {SDApi.Core.DataContracts.Tns.AssignPhoneNumberRequestDC}
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsAssignPhoneNumber(params: TnsService.TnsAssignPhoneNumberParams): __Observable<{}> {
    return this.TnsAssignPhoneNumberResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsReleaseTnsParams` containing the following parameters:
   *
   * - `managementGUID`: A number of maximum results
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsReleaseTnsResponse(params: TnsService.TnsReleaseTnsParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.managementGUID != null) __params = __params.set('managementGUID', params.managementGUID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/Tns/Release`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsReleaseTnsParams` containing the following parameters:
   *
   * - `managementGUID`: A number of maximum results
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsReleaseTns(params: TnsService.TnsReleaseTnsParams): __Observable<number> {
    return this.TnsReleaseTnsResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsReserveTnsParams` containing the following parameters:
   *
   * - `tns`: A list of Id / Country Tns to reserve
   *
   * - `managementGUID`: A management GUID string
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsReserveTnsResponse(params: TnsService.TnsReserveTnsParams): __Observable<__StrictHttpResponse<Array<TnDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.tns;
    if (params.managementGUID != null) __params = __params.set('managementGUID', params.managementGUID.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/Tns/Reserve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TnDC>>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsReserveTnsParams` containing the following parameters:
   *
   * - `tns`: A list of Id / Country Tns to reserve
   *
   * - `managementGUID`: A management GUID string
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsReserveTns(params: TnsService.TnsReserveTnsParams): __Observable<Array<TnDC>> {
    return this.TnsReserveTnsResponse(params).pipe(
      __map(_r => _r.body as Array<TnDC>)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsReserveRcfTnsParams` containing the following parameters:
   *
   * - `maxCount`: The maximum number of Tns to reserve
   *
   * - `managementGUID`: A management GUID string
   *
   * - `countryId`: CountryId
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsReserveRcfTnsResponse(params: TnsService.TnsReserveRcfTnsParams): __Observable<__StrictHttpResponse<Array<TnDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.managementGUID != null) __params = __params.set('managementGUID', params.managementGUID.toString());
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/Tns/ReserveRCF`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TnDC>>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsReserveRcfTnsParams` containing the following parameters:
   *
   * - `maxCount`: The maximum number of Tns to reserve
   *
   * - `managementGUID`: A management GUID string
   *
   * - `countryId`: CountryId
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsReserveRcfTns(params: TnsService.TnsReserveRcfTnsParams): __Observable<Array<TnDC>> {
    return this.TnsReserveRcfTnsResponse(params).pipe(
      __map(_r => _r.body as Array<TnDC>)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param Authorization access token
   * @return OK
   */
  TnsGetTnsWithDestinationResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<TnWithDestinationDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Tns/WithDestination`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TnWithDestinationDC>>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param Authorization access token
   * @return OK
   */
  TnsGetTnsWithDestination(Authorization: string): __Observable<Array<TnWithDestinationDC>> {
    return this.TnsGetTnsWithDestinationResponse(Authorization).pipe(
      __map(_r => _r.body as Array<TnWithDestinationDC>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetAdditionalNumbersAckOptionsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<AdditionalNumbersAcknowledgementDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/TransferRequest/AdditionalNumbersAcknowledgement/Options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AdditionalNumbersAcknowledgementDC>>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetAdditionalNumbersAckOptions(Authorization: string): __Observable<Array<AdditionalNumbersAcknowledgementDC>> {
    return this.TnsGetAdditionalNumbersAckOptionsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<AdditionalNumbersAcknowledgementDC>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetTransferRequestStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/TransferRequest/Statuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetTransferRequestStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.TnsGetTransferRequestStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetTransferRequestTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/TransferRequest/Types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetTransferRequestTypes(Authorization: string): __Observable<{[key: string]: string}> {
    return this.TnsGetTransferRequestTypesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsGetTransferRequestsParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `transferRequestTypeIds`: A list of TransferRequest types
   *
   * - `transferRequestStatusIds`: A list of TransferRequest statuses
   *
   * @return OK
   */
  TnsGetTransferRequestsResponse(params: TnsService.TnsGetTransferRequestsParams): __Observable<__StrictHttpResponse<Array<TransferRequestDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.transferRequestTypeIds || []).forEach(val => {if (val != null) __params = __params.append('transferRequestTypeIds', val.toString())});
    (params.transferRequestStatusIds || []).forEach(val => {if (val != null) __params = __params.append('transferRequestStatusIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/TransferRequests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TransferRequestDC>>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsGetTransferRequestsParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `transferRequestTypeIds`: A list of TransferRequest types
   *
   * - `transferRequestStatusIds`: A list of TransferRequest statuses
   *
   * @return OK
   */
  TnsGetTransferRequests(params: TnsService.TnsGetTransferRequestsParams): __Observable<Array<TransferRequestDC>> {
    return this.TnsGetTransferRequestsResponse(params).pipe(
      __map(_r => _r.body as Array<TransferRequestDC>)
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsCreateTransferRequestParams` containing the following parameters:
   *
   * - `data`: TransferRequest data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsCreateTransferRequestResponse(params: TnsService.TnsCreateTransferRequestParams): __Observable<__StrictHttpResponse<TransferRequestDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/TransferRequests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransferRequestDC>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsCreateTransferRequestParams` containing the following parameters:
   *
   * - `data`: TransferRequest data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsCreateTransferRequest(params: TnsService.TnsCreateTransferRequestParams): __Observable<TransferRequestDC> {
    return this.TnsCreateTransferRequestResponse(params).pipe(
      __map(_r => _r.body as TransferRequestDC)
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsGetTransferRequestParams` containing the following parameters:
   *
   * - `id`: Transfer Request ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetTransferRequestResponse(params: TnsService.TnsGetTransferRequestParams): __Observable<__StrictHttpResponse<TransferRequestDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/TransferRequests/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransferRequestDC>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsGetTransferRequestParams` containing the following parameters:
   *
   * - `id`: Transfer Request ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetTransferRequest(params: TnsService.TnsGetTransferRequestParams): __Observable<TransferRequestDC> {
    return this.TnsGetTransferRequestResponse(params).pipe(
      __map(_r => _r.body as TransferRequestDC)
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsUpdateTransferRequestParams` containing the following parameters:
   *
   * - `id`: Transfer Request ID
   *
   * - `data`: TransferRequest data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsUpdateTransferRequestResponse(params: TnsService.TnsUpdateTransferRequestParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/TransferRequests/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsUpdateTransferRequestParams` containing the following parameters:
   *
   * - `id`: Transfer Request ID
   *
   * - `data`: TransferRequest data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsUpdateTransferRequest(params: TnsService.TnsUpdateTransferRequestParams): __Observable<{}> {
    return this.TnsUpdateTransferRequestResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsDeleteTransferRequestParams` containing the following parameters:
   *
   * - `id`: Transfer Request ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsDeleteTransferRequestResponse(params: TnsService.TnsDeleteTransferRequestParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Account/TransferRequests/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsDeleteTransferRequestParams` containing the following parameters:
   *
   * - `id`: Transfer Request ID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsDeleteTransferRequest(params: TnsService.TnsDeleteTransferRequestParams): __Observable<{}> {
    return this.TnsDeleteTransferRequestResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsDeleteTransferRequestInvoiceParams` containing the following parameters:
   *
   * - `id`: Transfer request id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsDeleteTransferRequestInvoiceResponse(params: TnsService.TnsDeleteTransferRequestInvoiceParams): __Observable<__StrictHttpResponse<TransferRequestDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Account/TransferRequests/${params.id}/Invoice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransferRequestDC>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsDeleteTransferRequestInvoiceParams` containing the following parameters:
   *
   * - `id`: Transfer request id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsDeleteTransferRequestInvoice(params: TnsService.TnsDeleteTransferRequestInvoiceParams): __Observable<TransferRequestDC> {
    return this.TnsDeleteTransferRequestInvoiceResponse(params).pipe(
      __map(_r => _r.body as TransferRequestDC)
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsDeleteTransferRequestLOAParams` containing the following parameters:
   *
   * - `id`: Transfer request id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsDeleteTransferRequestLOAResponse(params: TnsService.TnsDeleteTransferRequestLOAParams): __Observable<__StrictHttpResponse<TransferRequestDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Account/TransferRequests/${params.id}/LOA`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransferRequestDC>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsDeleteTransferRequestLOAParams` containing the following parameters:
   *
   * - `id`: Transfer request id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsDeleteTransferRequestLOA(params: TnsService.TnsDeleteTransferRequestLOAParams): __Observable<TransferRequestDC> {
    return this.TnsDeleteTransferRequestLOAResponse(params).pipe(
      __map(_r => _r.body as TransferRequestDC)
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsGetTransferRequestFileParams` containing the following parameters:
   *
   * - `fileId`: File ID
   *
   * - `Authorization`: access token
   *
   * @return File response
   */
  TnsGetTransferRequestFileResponse(params: TnsService.TnsGetTransferRequestFileParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/TransferRequests/Files/${params.fileId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsGetTransferRequestFileParams` containing the following parameters:
   *
   * - `fileId`: File ID
   *
   * - `Authorization`: access token
   *
   * @return File response
   */
  TnsGetTransferRequestFile(params: TnsService.TnsGetTransferRequestFileParams): __Observable<Blob> {
    return this.TnsGetTransferRequestFileResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsUploadInvoiceParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `file`:
   *
   * @return OK
   */
  TnsUploadInvoiceResponse(params: TnsService.TnsUploadInvoiceParams): __Observable<__StrictHttpResponse<UploadResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
   if(params.file !== null && typeof params.file !== "undefined") { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/TransferRequests/Invoice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadResultDC>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsUploadInvoiceParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `file`:
   *
   * @return OK
   */
  TnsUploadInvoice(params: TnsService.TnsUploadInvoiceParams): __Observable<UploadResultDC> {
    return this.TnsUploadInvoiceResponse(params).pipe(
      __map(_r => _r.body as UploadResultDC)
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsUploadLOAParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `file`:
   *
   * @return OK
   */
  TnsUploadLOAResponse(params: TnsService.TnsUploadLOAParams): __Observable<__StrictHttpResponse<UploadResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
   if(params.file !== null && typeof params.file !== "undefined") { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/TransferRequests/LOA`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadResultDC>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsUploadLOAParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `file`:
   *
   * @return OK
   */
  TnsUploadLOA(params: TnsService.TnsUploadLOAParams): __Observable<UploadResultDC> {
    return this.TnsUploadLOAResponse(params).pipe(
      __map(_r => _r.body as UploadResultDC)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsValidateTransferRequestTNsParams` containing the following parameters:
   *
   * - `tns`: List of tns
   *
   * - `Authorization`: access token
   *
   * - `requestId`: Transfer request id
   *
   * - `raiseWarnings`: raise warnings boolean
   *
   * - `countryId`: Country id
   *
   * @return OK
   */
  TnsValidateTransferRequestTNsResponse(params: TnsService.TnsValidateTransferRequestTNsParams): __Observable<__StrictHttpResponse<TransferRequestTnValidation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.tns;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.requestId != null) __params = __params.set('requestId', params.requestId.toString());
    if (params.raiseWarnings != null) __params = __params.set('raiseWarnings', params.raiseWarnings.toString());
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/TransferRequests/ValidateTNs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransferRequestTnValidation>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsValidateTransferRequestTNsParams` containing the following parameters:
   *
   * - `tns`: List of tns
   *
   * - `Authorization`: access token
   *
   * - `requestId`: Transfer request id
   *
   * - `raiseWarnings`: raise warnings boolean
   *
   * - `countryId`: Country id
   *
   * @return OK
   */
  TnsValidateTransferRequestTNs(params: TnsService.TnsValidateTransferRequestTNsParams): __Observable<TransferRequestTnValidation> {
    return this.TnsValidateTransferRequestTNsResponse(params).pipe(
      __map(_r => _r.body as TransferRequestTnValidation)
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsGetTransferRequestsPagedParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `transferRequestTypeIds`: A list of TransferRequest types
   *
   * - `transferRequestStatusIds`: A list of TransferRequest statuses
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  TnsGetTransferRequestsPagedResponse(params: TnsService.TnsGetTransferRequestsPagedParams): __Observable<__StrictHttpResponse<PageResultTransferRequestDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    (params.transferRequestTypeIds || []).forEach(val => {if (val != null) __params = __params.append('transferRequestTypeIds', val.toString())});
    (params.transferRequestStatusIds || []).forEach(val => {if (val != null) __params = __params.append('transferRequestStatusIds', val.toString())});
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Select != null) __params = __params.set('$select', params.Select.toString());
    if (params.OrderBy != null) __params = __params.set('$orderBy', params.OrderBy.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    if (params.Count != null) __params = __params.set('$count', params.Count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/TransferRequestsPaged`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultTransferRequestDC>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsGetTransferRequestsPagedParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `transferRequestTypeIds`: A list of TransferRequest types
   *
   * - `transferRequestStatusIds`: A list of TransferRequest statuses
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  TnsGetTransferRequestsPaged(params: TnsService.TnsGetTransferRequestsPagedParams): __Observable<PageResultTransferRequestDC> {
    return this.TnsGetTransferRequestsPagedResponse(params).pipe(
      __map(_r => _r.body as PageResultTransferRequestDC)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetAvailableNumbersParams` containing the following parameters:
   *
   * - `request`: Request data contract
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetAvailableNumbersResponse(params: TnsService.TnsGetAvailableNumbersParams): __Observable<__StrictHttpResponse<GetAvailableNumbersResponseDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.locationUuid != null) __params = __params.set('locationUuid', params.locationUuid.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Carrier/AvailableNumbers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetAvailableNumbersResponseDC>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetAvailableNumbersParams` containing the following parameters:
   *
   * - `request`: Request data contract
   *
   * - `locationUuid`: Location UUID
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetAvailableNumbers(params: TnsService.TnsGetAvailableNumbersParams): __Observable<GetAvailableNumbersResponseDC> {
    return this.TnsGetAvailableNumbersResponse(params).pipe(
      __map(_r => _r.body as GetAvailableNumbersResponseDC)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsOrderNumbersParams` containing the following parameters:
   *
   * - `request`: Request data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsOrderNumbersResponse(params: TnsService.TnsOrderNumbersParams): __Observable<__StrictHttpResponse<CarrierTelephoneOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.request;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Carrier/Order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CarrierTelephoneOrder>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsOrderNumbersParams` containing the following parameters:
   *
   * - `request`: Request data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsOrderNumbers(params: TnsService.TnsOrderNumbersParams): __Observable<CarrierTelephoneOrder> {
    return this.TnsOrderNumbersResponse(params).pipe(
      __map(_r => _r.body as CarrierTelephoneOrder)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsNotifyPhoneNumberOrderRequestParams` containing the following parameters:
   *
   * - `notificationRequest`: Request data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsNotifyPhoneNumberOrderRequestResponse(params: TnsService.TnsNotifyPhoneNumberOrderRequestParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.notificationRequest;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Carrier/OrderNotify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsNotifyPhoneNumberOrderRequestParams` containing the following parameters:
   *
   * - `notificationRequest`: Request data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsNotifyPhoneNumberOrderRequest(params: TnsService.TnsNotifyPhoneNumberOrderRequestParams): __Observable<{}> {
    return this.TnsNotifyPhoneNumberOrderRequestResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: PushPortabilityCheckResults (10329)
   * @param params The `TnsService.TnsPushPortabilityCheckResultsParams` containing the following parameters:
   *
   * - `portabilityCheckResult`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsPushPortabilityCheckResultsResponse(params: TnsService.TnsPushPortabilityCheckResultsParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.portabilityCheckResult;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Carrier/PushPortabilityCheckResults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: PushPortabilityCheckResults (10329)
   * @param params The `TnsService.TnsPushPortabilityCheckResultsParams` containing the following parameters:
   *
   * - `portabilityCheckResult`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsPushPortabilityCheckResults(params: TnsService.TnsPushPortabilityCheckResultsParams): __Observable<{}> {
    return this.TnsPushPortabilityCheckResultsResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: PushPortabilityCheckResults (10329)
   * @param params The `TnsService.TnsPushPortabilityImpactResultsParams` containing the following parameters:
   *
   * - `portabilityImpactResult`: Request data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsPushPortabilityImpactResultsResponse(params: TnsService.TnsPushPortabilityImpactResultsParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.portabilityImpactResult;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Carrier/PushPortabilityImpactResults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: PushPortabilityCheckResults (10329)
   * @param params The `TnsService.TnsPushPortabilityImpactResultsParams` containing the following parameters:
   *
   * - `portabilityImpactResult`: Request data
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsPushPortabilityImpactResults(params: TnsService.TnsPushPortabilityImpactResultsParams): __Observable<{}> {
    return this.TnsPushPortabilityImpactResultsResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsDeleteReservationsAsyncParams` containing the following parameters:
   *
   * - `reservationIds`: A list of reservation IDs
   *
   * - `Authorization`: access token
   *
   * - `carrierName`: Carrier name
   *
   * @return OK
   */
  TnsDeleteReservationsAsyncResponse(params: TnsService.TnsDeleteReservationsAsyncParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.reservationIds;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.carrierName != null) __params = __params.set('carrierName', params.carrierName.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Carrier/Reservation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsDeleteReservationsAsyncParams` containing the following parameters:
   *
   * - `reservationIds`: A list of reservation IDs
   *
   * - `Authorization`: access token
   *
   * - `carrierName`: Carrier name
   *
   * @return OK
   */
  TnsDeleteReservationsAsync(params: TnsService.TnsDeleteReservationsAsyncParams): __Observable<{}> {
    return this.TnsDeleteReservationsAsyncResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsUploadNRANumberCertificateParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `file`:
   *
   * @return OK
   */
  TnsUploadNRANumberCertificateResponse(params: TnsService.TnsUploadNRANumberCertificateParams): __Observable<__StrictHttpResponse<UploadResultDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
   if(params.file !== null && typeof params.file !== "undefined") { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Carrier/UploadNRANumberCertificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadResultDC>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param params The `TnsService.TnsUploadNRANumberCertificateParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `file`:
   *
   * @return OK
   */
  TnsUploadNRANumberCertificate(params: TnsService.TnsUploadNRANumberCertificateParams): __Observable<UploadResultDC> {
    return this.TnsUploadNRANumberCertificateResponse(params).pipe(
      __map(_r => _r.body as UploadResultDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetCarrierOrderStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CarrierOrderStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetCarrierOrderStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.TnsGetCarrierOrderStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: EditTransferRequests (10308)
   * @param Authorization access token
   * @return OK
   */
  TnsGetCustomerActionItemReasonsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<CustomerActionItemReasonDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CustomerActionItemReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CustomerActionItemReasonDC>>;
      })
    );
  }
  /**
   * Required Permission: EditTransferRequests (10308)
   * @param Authorization access token
   * @return OK
   */
  TnsGetCustomerActionItemReasons(Authorization: string): __Observable<Array<CustomerActionItemReasonDC>> {
    return this.TnsGetCustomerActionItemReasonsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<CustomerActionItemReasonDC>)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetLosingCarriersParams` containing the following parameters:
   *
   * - `countryId`: Country
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetLosingCarriersResponse(params: TnsService.TnsGetLosingCarriersParams): __Observable<__StrictHttpResponse<Array<LosingCarrierDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/LosingCarriers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LosingCarrierDC>>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetLosingCarriersParams` containing the following parameters:
   *
   * - `countryId`: Country
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetLosingCarriers(params: TnsService.TnsGetLosingCarriersParams): __Observable<Array<LosingCarrierDC>> {
    return this.TnsGetLosingCarriersResponse(params).pipe(
      __map(_r => _r.body as Array<LosingCarrierDC>)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsSuggestRateCenterParams` containing the following parameters:
   *
   * - `search`: Search term. May be an area code, city name, state, or city plus state. e.g. "Atlanta, GA".
   *
   * - `countryId`: Country to search in
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsSuggestRateCenterResponse(params: TnsService.TnsSuggestRateCenterParams): __Observable<__StrictHttpResponse<Array<RateCenterDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/RateCenters/Suggest`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RateCenterDC>>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsSuggestRateCenterParams` containing the following parameters:
   *
   * - `search`: Search term. May be an area code, city name, state, or city plus state. e.g. "Atlanta, GA".
   *
   * - `countryId`: Country to search in
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsSuggestRateCenter(params: TnsService.TnsSuggestRateCenterParams): __Observable<Array<RateCenterDC>> {
    return this.TnsSuggestRateCenterResponse(params).pipe(
      __map(_r => _r.body as Array<RateCenterDC>)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetTollFreeAreaCodesParams` containing the following parameters:
   *
   * - `countryId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetTollFreeAreaCodesResponse(params: TnsService.TnsGetTollFreeAreaCodesParams): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Ratecenters/TollFreeAreaCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetTollFreeAreaCodesParams` containing the following parameters:
   *
   * - `countryId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetTollFreeAreaCodes(params: TnsService.TnsGetTollFreeAreaCodesParams): __Observable<Array<string>> {
    return this.TnsGetTollFreeAreaCodesResponse(params).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsTnDestinationTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TnDestinationTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsTnDestinationTypes(Authorization: string): __Observable<{[key: string]: string}> {
    return this.TnsTnDestinationTypesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `TnsService.TnsGetTns_1Params` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  TnsGetTns_1Response(params: TnsService.TnsGetTns_1Params): __Observable<__StrictHttpResponse<PageResultTnsDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.Top != null) __params = __params.set('$top', params.Top.toString());
    if (params.Skip != null) __params = __params.set('$skip', params.Skip.toString());
    if (params.Select != null) __params = __params.set('$select', params.Select.toString());
    if (params.OrderBy != null) __params = __params.set('$orderBy', params.OrderBy.toString());
    if (params.Filter != null) __params = __params.set('$filter', params.Filter.toString());
    if (params.Count != null) __params = __params.set('$count', params.Count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageResultTnsDC>;
      })
    );
  }
  /**
   * Required Permission: SystemSDApiAccess (10315)
   * @param params The `TnsService.TnsGetTns_1Params` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `$top`: Number of items to include
   *
   * - `$skip`: Number of items to skip
   *
   * - `$select`: Select this field
   *
   * - `$orderBy`: Order by this field
   *
   * - `$filter`: Odata filter param
   *
   * - `$count`: Include the total item count
   *
   * @return OK
   */
  TnsGetTns_1(params: TnsService.TnsGetTns_1Params): __Observable<PageResultTnsDC> {
    return this.TnsGetTns_1Response(params).pipe(
      __map(_r => _r.body as PageResultTnsDC)
    );
  }

  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetTnWithDestinationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `countryId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetTnWithDestinationResponse(params: TnsService.TnsGetTnWithDestinationParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Tns/${params.countryId}/${params.id}/WithDestination`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneNumbers (119)
   * @param params The `TnsService.TnsGetTnWithDestinationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `countryId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  TnsGetTnWithDestination(params: TnsService.TnsGetTnWithDestinationParams): __Observable<{}> {
    return this.TnsGetTnWithDestinationResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetTnStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TnStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetTnStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.TnsGetTnStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetTnTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TnTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  TnsGetTnTypes(Authorization: string): __Observable<{[key: string]: string}> {
    return this.TnsGetTnTypesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }
}

module TnsService {

  /**
   * Parameters for TnsGetAssignableTns
   */
  export interface TnsGetAssignableTnsParams {

    /**
     * access token
     */
    Authorization: string;

    /**
     * Only return Tns that are in this country. See /api/Countries for values
     */
    countryId?: number;
  }

  /**
   * Parameters for TnsGetCarrierOrders
   */
  export interface TnsGetCarrierOrdersParams {

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;

    /**
     * Update carrier orders status from carrier api
     */
    updatePendingOrders?: boolean;
  }

  /**
   * Parameters for TnsGetDirectoryCategories
   */
  export interface TnsGetDirectoryCategoriesParams {

    /**
     * String to filter category names by (returned names must start with filter string)
     */
    filter: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsGetLocationTnSearchCriteria
   */
  export interface TnsGetLocationTnSearchCriteriaParams {

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsUpdateLocationTnSearchCriteria
   */
  export interface TnsUpdateLocationTnSearchCriteriaParams {

    /**
     * Tn Search Criteria
     */
    searchCriteria: {};

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsDeleteLocationTnSearchCriteria
   */
  export interface TnsDeleteLocationTnSearchCriteriaParams {

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsGetNumberPurchaseCharge
   */
  export interface TnsGetNumberPurchaseChargeParams {

    /**
     * Number Purchase Type(LOCAL = 1, TOLLFREE = 2, NATIONAL = 3)
     */
    numberPurchaseType: number;

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsGetNumberTransferCharge
   */
  export interface TnsGetNumberTransferChargeParams {

    /**
     * Transfer Request Type as int
     */
    transferRequestType: number;

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsGetTns
   */
  export interface TnsGetTnsParams {

    /**
     * access token
     */
    Authorization: string;

    /**
     * Only return Tns that are in of these types. See /api/TnTypes for values
     */
    typeIds?: Array<number>;

    /**
     * Only return Tns that are in these statues. See /api/TnStatuses for values
     */
    statusIds?: Array<number>;

    /**
     * Include carrier transfer request information in the result
     */
    includeCarrierInfo?: boolean;

    /**
     * If false only return Tns where IsAssigned=false (those that do not have a profile assigned to them or scheduled to be assigned). Note that profiles may be assigned to Tns that are in Available/PendingPortIn/PendingTurnup/TransferRequested statues.
     */
    includeAssigned?: boolean;

    /**
     * Only return Tns that are in this country. See /api/Countries for values
     */
    countryId?: number;
  }

  /**
   * Parameters for TnsAssignPhoneNumber
   */
  export interface TnsAssignPhoneNumberParams {

    /**
     * {SDApi.Core.DataContracts.Tns.AssignPhoneNumberRequestDC}
     */
    request: AssignPhoneNumberRequestDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsReleaseTns
   */
  export interface TnsReleaseTnsParams {

    /**
     * A number of maximum results
     */
    managementGUID: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsReserveTns
   */
  export interface TnsReserveTnsParams {

    /**
     * A list of Id / Country Tns to reserve
     */
    tns: Array<TnDC>;

    /**
     * A management GUID string
     */
    managementGUID: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsReserveRcfTns
   */
  export interface TnsReserveRcfTnsParams {

    /**
     * The maximum number of Tns to reserve
     */
    maxCount: number;

    /**
     * A management GUID string
     */
    managementGUID: string;

    /**
     * CountryId
     */
    countryId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsGetTransferRequests
   */
  export interface TnsGetTransferRequestsParams {

    /**
     * access token
     */
    Authorization: string;

    /**
     * A list of TransferRequest types
     */
    transferRequestTypeIds?: Array<number>;

    /**
     * A list of TransferRequest statuses
     */
    transferRequestStatusIds?: Array<number>;
  }

  /**
   * Parameters for TnsCreateTransferRequest
   */
  export interface TnsCreateTransferRequestParams {

    /**
     * TransferRequest data
     */
    data: CreateTransferRequestDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsGetTransferRequest
   */
  export interface TnsGetTransferRequestParams {

    /**
     * Transfer Request ID
     */
    id: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsUpdateTransferRequest
   */
  export interface TnsUpdateTransferRequestParams {

    /**
     * Transfer Request ID
     */
    id: number;

    /**
     * TransferRequest data
     */
    data: UpdateTransferRequestDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsDeleteTransferRequest
   */
  export interface TnsDeleteTransferRequestParams {

    /**
     * Transfer Request ID
     */
    id: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsDeleteTransferRequestInvoice
   */
  export interface TnsDeleteTransferRequestInvoiceParams {

    /**
     * Transfer request id
     */
    id: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsDeleteTransferRequestLOA
   */
  export interface TnsDeleteTransferRequestLOAParams {

    /**
     * Transfer request id
     */
    id: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsGetTransferRequestFile
   */
  export interface TnsGetTransferRequestFileParams {

    /**
     * File ID
     */
    fileId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsUploadInvoice
   */
  export interface TnsUploadInvoiceParams {

    /**
     * access token
     */
    Authorization: string;
    file?: Blob;
  }

  /**
   * Parameters for TnsUploadLOA
   */
  export interface TnsUploadLOAParams {

    /**
     * access token
     */
    Authorization: string;
    file?: Blob;
  }

  /**
   * Parameters for TnsValidateTransferRequestTNs
   */
  export interface TnsValidateTransferRequestTNsParams {

    /**
     * List of tns
     */
    tns: Array<string>;

    /**
     * access token
     */
    Authorization: string;

    /**
     * Transfer request id
     */
    requestId?: number;

    /**
     * raise warnings boolean
     */
    raiseWarnings?: boolean;

    /**
     * Country id
     */
    countryId?: number;
  }

  /**
   * Parameters for TnsGetTransferRequestsPaged
   */
  export interface TnsGetTransferRequestsPagedParams {

    /**
     * access token
     */
    Authorization: string;

    /**
     * A list of TransferRequest types
     */
    transferRequestTypeIds?: Array<number>;

    /**
     * A list of TransferRequest statuses
     */
    transferRequestStatusIds?: Array<number>;

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Select this field
     */
    Select?: string;

    /**
     * Order by this field
     */
    OrderBy?: string;

    /**
     * Odata filter param
     */
    Filter?: string;

    /**
     * Include the total item count
     */
    Count?: boolean;
  }

  /**
   * Parameters for TnsGetAvailableNumbers
   */
  export interface TnsGetAvailableNumbersParams {

    /**
     * Request data contract
     */
    request: GetAvailableNumbersDataContract;

    /**
     * Location UUID
     */
    locationUuid: string;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsOrderNumbers
   */
  export interface TnsOrderNumbersParams {

    /**
     * Request data
     */
    request: OrderNumbersRequestDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsNotifyPhoneNumberOrderRequest
   */
  export interface TnsNotifyPhoneNumberOrderRequestParams {

    /**
     * Request data
     */
    notificationRequest: NotificationRequestDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsPushPortabilityCheckResults
   */
  export interface TnsPushPortabilityCheckResultsParams {
    portabilityCheckResult: PushPortabilityCheckResultDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsPushPortabilityImpactResults
   */
  export interface TnsPushPortabilityImpactResultsParams {

    /**
     * Request data
     */
    portabilityImpactResult: PushPortabilityImpactResultDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsDeleteReservationsAsync
   */
  export interface TnsDeleteReservationsAsyncParams {

    /**
     * A list of reservation IDs
     */
    reservationIds: Array<string>;

    /**
     * access token
     */
    Authorization: string;

    /**
     * Carrier name
     */
    carrierName?: string;
  }

  /**
   * Parameters for TnsUploadNRANumberCertificate
   */
  export interface TnsUploadNRANumberCertificateParams {

    /**
     * access token
     */
    Authorization: string;
    file?: Blob;
  }

  /**
   * Parameters for TnsGetLosingCarriers
   */
  export interface TnsGetLosingCarriersParams {

    /**
     * Country
     */
    countryId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsSuggestRateCenter
   */
  export interface TnsSuggestRateCenterParams {

    /**
     * Search term. May be an area code, city name, state, or city plus state. e.g. "Atlanta, GA".
     */
    search: string;

    /**
     * Country to search in
     */
    countryId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsGetTollFreeAreaCodes
   */
  export interface TnsGetTollFreeAreaCodesParams {
    countryId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TnsGetTns_1
   */
  export interface TnsGetTns_1Params {

    /**
     * access token
     */
    Authorization: string;

    /**
     * Number of items to include
     */
    Top?: number;

    /**
     * Number of items to skip
     */
    Skip?: number;

    /**
     * Select this field
     */
    Select?: string;

    /**
     * Order by this field
     */
    OrderBy?: string;

    /**
     * Odata filter param
     */
    Filter?: string;

    /**
     * Include the total item count
     */
    Count?: boolean;
  }

  /**
   * Parameters for TnsGetTnWithDestination
   */
  export interface TnsGetTnWithDestinationParams {
    id: string;
    countryId: number;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { TnsService }
