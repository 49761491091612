import { Component, OnInit, Input } from '@angular/core';
import { InvoiceSmallModel } from './invoice-small.model';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'user-invoice-small',
  templateUrl: './invoice-small.component.html',
  styleUrls: ['./invoice-small.component.css']
})
export class InvoiceSmallComponent implements OnInit {

@Input() invoice: InvoiceSmallModel;

constructor(private localizationSvc: LocalizationService) {
}

  ngOnInit(){
    /* istanbul ignore else  */
    if (this.invoice.sections) {
      this.invoice.sections.forEach(section => {
        /* istanbul ignore else  */
            if (section.items) {
              section.items.forEach(item => {
                item.priceString = this.getPriceString(parseFloat(item.price), item.prefix);
              });
            }
        /* istanbul ignore else  */
        if (section.summary) {
          section.summary.priceString = this.getPriceString(parseFloat(section.summary.price), section.summary.prefix);
        }
      });
    }

  }

  getPriceString(price: number, currency?: string) {
    return currency ? this.localizationSvc.getLocalizedCurrency(price, currency) : this.localizationSvc.getLocalizedNumber(price)
  }


}
