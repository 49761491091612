/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MacAddressAvailabilityDC } from '../models/mac-address-availability-dc';
import { PhoneDC } from '../models/phone-dc';
import { CreateEditPhoneDC } from '../models/create-edit-phone-dc';
@Injectable({
  providedIn: 'root',
})
class PhonesService extends __BaseService {
  static readonly PhonesCheckMacAddressAvailabilityPath = '/api/Account/Partition/CheckMacAddressAvailability';
  static readonly PhonesGetPhonesPath = '/api/Account/Partition/Phones';
  static readonly PhonesEditPhonePath = '/api/Account/Partition/Phones';
  static readonly PhonesCreatePhonePath = '/api/Account/Partition/Phones';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: ViewPhoneDetails (300)
   * @param params The `PhonesService.PhonesCheckMacAddressAvailabilityParams` containing the following parameters:
   *
   * - `macAddress`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PhonesCheckMacAddressAvailabilityResponse(params: PhonesService.PhonesCheckMacAddressAvailabilityParams): __Observable<__StrictHttpResponse<MacAddressAvailabilityDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.macAddress != null) __params = __params.set('macAddress', params.macAddress.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Partition/CheckMacAddressAvailability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MacAddressAvailabilityDC>;
      })
    );
  }
  /**
   * Required Permission: ViewPhoneDetails (300)
   * @param params The `PhonesService.PhonesCheckMacAddressAvailabilityParams` containing the following parameters:
   *
   * - `macAddress`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PhonesCheckMacAddressAvailability(params: PhonesService.PhonesCheckMacAddressAvailabilityParams): __Observable<MacAddressAvailabilityDC> {
    return this.PhonesCheckMacAddressAvailabilityResponse(params).pipe(
      __map(_r => _r.body as MacAddressAvailabilityDC)
    );
  }

  /**
   * Required Permission: ViewPhoneDetails (300)
   * @param Authorization Access token
   * @return OK
   */
  PhonesGetPhonesResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<PhoneDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Partition/Phones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PhoneDC>>;
      })
    );
  }
  /**
   * Required Permission: ViewPhoneDetails (300)
   * @param Authorization Access token
   * @return OK
   */
  PhonesGetPhones(Authorization: string): __Observable<Array<PhoneDC>> {
    return this.PhonesGetPhonesResponse(Authorization).pipe(
      __map(_r => _r.body as Array<PhoneDC>)
    );
  }

  /**
   * Required Permission: EditPhoneDetails (302)
   * @param params The `PhonesService.PhonesEditPhoneParams` containing the following parameters:
   *
   * - `phoneDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PhonesEditPhoneResponse(params: PhonesService.PhonesEditPhoneParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.phoneDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/Partition/Phones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: EditPhoneDetails (302)
   * @param params The `PhonesService.PhonesEditPhoneParams` containing the following parameters:
   *
   * - `phoneDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PhonesEditPhone(params: PhonesService.PhonesEditPhoneParams): __Observable<{}> {
    return this.PhonesEditPhoneResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: AddPhoneDetails (301)
   * @param params The `PhonesService.PhonesCreatePhoneParams` containing the following parameters:
   *
   * - `phoneDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PhonesCreatePhoneResponse(params: PhonesService.PhonesCreatePhoneParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.phoneDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Partition/Phones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: AddPhoneDetails (301)
   * @param params The `PhonesService.PhonesCreatePhoneParams` containing the following parameters:
   *
   * - `phoneDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  PhonesCreatePhone(params: PhonesService.PhonesCreatePhoneParams): __Observable<{}> {
    return this.PhonesCreatePhoneResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module PhonesService {

  /**
   * Parameters for PhonesCheckMacAddressAvailability
   */
  export interface PhonesCheckMacAddressAvailabilityParams {
    macAddress: string;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for PhonesEditPhone
   */
  export interface PhonesEditPhoneParams {
    phoneDC: CreateEditPhoneDC;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for PhonesCreatePhone
   */
  export interface PhonesCreatePhoneParams {
    phoneDC: CreateEditPhoneDC;

    /**
     * Access token
     */
    Authorization: string;
  }
}

export { PhonesService }
