/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AccountTaskDC } from '../models/account-task-dc';
import { CreateTaskDC } from '../models/create-task-dc';
import { UpdateTaskDC } from '../models/update-task-dc';
import { TaskPresetDC } from '../models/task-preset-dc';
import { TaskTypeDC } from '../models/task-type-dc';
@Injectable({
  providedIn: 'root',
})
class TasksService extends __BaseService {
  static readonly TasksGetTasksByAccountPath = '/api/Account/Tasks';
  static readonly TasksCreateTaskPath = '/api/Account/Tasks';
  static readonly TasksUpdateTaskPath = '/api/Account/Tasks/{taskTypeId}';
  static readonly TasksDeleteTaskPath = '/api/Account/Tasks/{taskTypeId}';
  static readonly TasksCreatePresetTasksPath = '/api/Account/Tasks/Preset/{taskPresetId}';
  static readonly TasksPatchPresetTasksPath = '/api/Account/Tasks/Preset/{taskPresetId}';
  static readonly TasksCreatePresetTasks_1Path = '/api/Account/Tasks/Preset/{taskPresetId}/{countryId}';
  static readonly TasksPatchPresetTasks_1Path = '/api/Account/Tasks/Preset/{taskPresetId}/{countryId}';
  static readonly TasksGetTaskPresetsPath = '/api/TaskPresets';
  static readonly TasksGetTaskStatusesPath = '/api/TaskStatuses';
  static readonly TasksGetTaskTypesPath = '/api/TaskTypes';
  static readonly TasksGetTaskValueKindsPath = '/api/TaskValueKinds';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param Authorization Access token
   * @return OK
   */
  TasksGetTasksByAccountResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<AccountTaskDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AccountTaskDC>>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param Authorization Access token
   * @return OK
   */
  TasksGetTasksByAccount(Authorization: string): __Observable<Array<AccountTaskDC>> {
    return this.TasksGetTasksByAccountResponse(Authorization).pipe(
      __map(_r => _r.body as Array<AccountTaskDC>)
    );
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksCreateTaskParams` containing the following parameters:
   *
   * - `createTaskDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksCreateTaskResponse(params: TasksService.TasksCreateTaskParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.createTaskDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksCreateTaskParams` containing the following parameters:
   *
   * - `createTaskDC`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksCreateTask(params: TasksService.TasksCreateTaskParams): __Observable<{}> {
    return this.TasksCreateTaskResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksUpdateTaskParams` containing the following parameters:
   *
   * - `updateTaskDC`:
   *
   * - `taskTypeId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksUpdateTaskResponse(params: TasksService.TasksUpdateTaskParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.updateTaskDC;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Account/Tasks/${params.taskTypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksUpdateTaskParams` containing the following parameters:
   *
   * - `updateTaskDC`:
   *
   * - `taskTypeId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksUpdateTask(params: TasksService.TasksUpdateTaskParams): __Observable<{}> {
    return this.TasksUpdateTaskResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksDeleteTaskParams` containing the following parameters:
   *
   * - `taskTypeId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksDeleteTaskResponse(params: TasksService.TasksDeleteTaskParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Account/Tasks/${params.taskTypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksDeleteTaskParams` containing the following parameters:
   *
   * - `taskTypeId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksDeleteTask(params: TasksService.TasksDeleteTaskParams): __Observable<{}> {
    return this.TasksDeleteTaskResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksCreatePresetTasksParams` containing the following parameters:
   *
   * - `taskPresetId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksCreatePresetTasksResponse(params: TasksService.TasksCreatePresetTasksParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Tasks/Preset/${params.taskPresetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksCreatePresetTasksParams` containing the following parameters:
   *
   * - `taskPresetId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksCreatePresetTasks(params: TasksService.TasksCreatePresetTasksParams): __Observable<{}> {
    return this.TasksCreatePresetTasksResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksPatchPresetTasksParams` containing the following parameters:
   *
   * - `taskPresetId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksPatchPresetTasksResponse(params: TasksService.TasksPatchPresetTasksParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/Account/Tasks/Preset/${params.taskPresetId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksPatchPresetTasksParams` containing the following parameters:
   *
   * - `taskPresetId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksPatchPresetTasks(params: TasksService.TasksPatchPresetTasksParams): __Observable<{}> {
    return this.TasksPatchPresetTasksResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksCreatePresetTasks_1Params` containing the following parameters:
   *
   * - `taskPresetId`:
   *
   * - `countryId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksCreatePresetTasks_1Response(params: TasksService.TasksCreatePresetTasks_1Params): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Tasks/Preset/${params.taskPresetId}/${params.countryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksCreatePresetTasks_1Params` containing the following parameters:
   *
   * - `taskPresetId`:
   *
   * - `countryId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksCreatePresetTasks_1(params: TasksService.TasksCreatePresetTasks_1Params): __Observable<{}> {
    return this.TasksCreatePresetTasks_1Response(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksPatchPresetTasks_1Params` containing the following parameters:
   *
   * - `taskPresetId`:
   *
   * - `countryId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksPatchPresetTasks_1Response(params: TasksService.TasksPatchPresetTasks_1Params): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/Account/Tasks/Preset/${params.taskPresetId}/${params.countryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `TasksService.TasksPatchPresetTasks_1Params` containing the following parameters:
   *
   * - `taskPresetId`:
   *
   * - `countryId`:
   *
   * - `Authorization`: Access token
   *
   * @return OK
   */
  TasksPatchPresetTasks_1(params: TasksService.TasksPatchPresetTasks_1Params): __Observable<{}> {
    return this.TasksPatchPresetTasks_1Response(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param Authorization Access token
   * @return OK
   */
  TasksGetTaskPresetsResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<TaskPresetDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TaskPresets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TaskPresetDC>>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param Authorization Access token
   * @return OK
   */
  TasksGetTaskPresets(Authorization: string): __Observable<Array<TaskPresetDC>> {
    return this.TasksGetTaskPresetsResponse(Authorization).pipe(
      __map(_r => _r.body as Array<TaskPresetDC>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  TasksGetTaskStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TaskStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  TasksGetTaskStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.TasksGetTaskStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  TasksGetTaskTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<Array<TaskTypeDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TaskTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TaskTypeDC>>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  TasksGetTaskTypes(Authorization: string): __Observable<Array<TaskTypeDC>> {
    return this.TasksGetTaskTypesResponse(Authorization).pipe(
      __map(_r => _r.body as Array<TaskTypeDC>)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  TasksGetTaskValueKindsResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/TaskValueKinds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization Access token
   * @return OK
   */
  TasksGetTaskValueKinds(Authorization: string): __Observable<{[key: string]: string}> {
    return this.TasksGetTaskValueKindsResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }
}

module TasksService {

  /**
   * Parameters for TasksCreateTask
   */
  export interface TasksCreateTaskParams {
    createTaskDC: CreateTaskDC;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TasksUpdateTask
   */
  export interface TasksUpdateTaskParams {
    updateTaskDC: UpdateTaskDC;
    taskTypeId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TasksDeleteTask
   */
  export interface TasksDeleteTaskParams {
    taskTypeId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TasksCreatePresetTasks
   */
  export interface TasksCreatePresetTasksParams {
    taskPresetId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TasksPatchPresetTasks
   */
  export interface TasksPatchPresetTasksParams {
    taskPresetId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TasksCreatePresetTasks_1
   */
  export interface TasksCreatePresetTasks_1Params {
    taskPresetId: number;
    countryId: number;

    /**
     * Access token
     */
    Authorization: string;
  }

  /**
   * Parameters for TasksPatchPresetTasks_1
   */
  export interface TasksPatchPresetTasks_1Params {
    taskPresetId: number;
    countryId: number;

    /**
     * Access token
     */
    Authorization: string;
  }
}

export { TasksService }
