import { Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  formatConfDefault = '1.2-2';

  constructor(public translateSvc: TranslateService) { }

  getLocalizedCurrency(number: number, currency?: string, formatConf?: string): string {
    const browserLang = this.translateSvc.getBrowserLang();
    const prefix = number < 0 ? '-' : '';
    const formatedNumber = formatNumber(Math.abs(number), browserLang, formatConf ? formatConf : this.formatConfDefault);
    return `${prefix}${currency}${formatedNumber}`;
  }

  getLocalizedNumber(number: number, formatConf?: string) {
    const browserLang = this.translateSvc.getBrowserLang();
    return formatNumber(number, browserLang, formatConf ? formatConf : this.formatConfDefault)
  }
}
