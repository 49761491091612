/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FeatureDC } from '../models/feature-dc';
import { FeatureUserDC } from '../models/feature-user-dc';
import { OrderDC } from '../models/order-dc';
import { AddFeatureOrderDC } from '../models/add-feature-order-dc';
import { BillingImpactDC } from '../models/billing-impact-dc';
@Injectable({
  providedIn: 'root',
})
class FeaturesService extends __BaseService {
  static readonly FeaturesGetAccountFeaturesPath = '/api/Account/Features';
  static readonly FeaturesGetFeatureAvailableUsersPath = '/api/Account/Features/{id}/AvailableUsers';
  static readonly FeaturesGetFeatureUsersPath = '/api/Account/Features/{id}/Users';
  static readonly FeaturesCreateFeatureAddOrderPath = '/api/Account/Features/Add';
  static readonly FeaturesPreviewFeatureAddOrderPath = '/api/Account/Features/Add/Preview';
  static readonly FeaturesGetFeaturesPath = '/api/Features';
  static readonly FeaturesGetFeaturePath = '/api/Features/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: EditProfiles (117)
   * @param params The `FeaturesService.FeaturesGetAccountFeaturesParams` containing the following parameters:
   *
   * - `countryId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesGetAccountFeaturesResponse(params: FeaturesService.FeaturesGetAccountFeaturesParams): __Observable<__StrictHttpResponse<FeatureDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Features`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureDC>;
      })
    );
  }
  /**
   * Required Permission: EditProfiles (117)
   * @param params The `FeaturesService.FeaturesGetAccountFeaturesParams` containing the following parameters:
   *
   * - `countryId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesGetAccountFeatures(params: FeaturesService.FeaturesGetAccountFeaturesParams): __Observable<FeatureDC> {
    return this.FeaturesGetAccountFeaturesResponse(params).pipe(
      __map(_r => _r.body as FeatureDC)
    );
  }

  /**
   * Required Permission: EditProfiles (117)
   * @param params The `FeaturesService.FeaturesGetFeatureAvailableUsersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesGetFeatureAvailableUsersResponse(params: FeaturesService.FeaturesGetFeatureAvailableUsersParams): __Observable<__StrictHttpResponse<FeatureUserDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Features/${params.id}/AvailableUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureUserDC>;
      })
    );
  }
  /**
   * Required Permission: EditProfiles (117)
   * @param params The `FeaturesService.FeaturesGetFeatureAvailableUsersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesGetFeatureAvailableUsers(params: FeaturesService.FeaturesGetFeatureAvailableUsersParams): __Observable<FeatureUserDC> {
    return this.FeaturesGetFeatureAvailableUsersResponse(params).pipe(
      __map(_r => _r.body as FeatureUserDC)
    );
  }

  /**
   * Required Permission: EditProfiles (117)
   * @param params The `FeaturesService.FeaturesGetFeatureUsersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesGetFeatureUsersResponse(params: FeaturesService.FeaturesGetFeatureUsersParams): __Observable<__StrictHttpResponse<FeatureUserDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Features/${params.id}/Users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureUserDC>;
      })
    );
  }
  /**
   * Required Permission: EditProfiles (117)
   * @param params The `FeaturesService.FeaturesGetFeatureUsersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesGetFeatureUsers(params: FeaturesService.FeaturesGetFeatureUsersParams): __Observable<FeatureUserDC> {
    return this.FeaturesGetFeatureUsersResponse(params).pipe(
      __map(_r => _r.body as FeatureUserDC)
    );
  }

  /**
   * Required Permission: EditProfiles (117)
   * @param params The `FeaturesService.FeaturesCreateFeatureAddOrderParams` containing the following parameters:
   *
   * - `addFeatureOrderDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesCreateFeatureAddOrderResponse(params: FeaturesService.FeaturesCreateFeatureAddOrderParams): __Observable<__StrictHttpResponse<OrderDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.addFeatureOrderDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Features/Add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderDC>;
      })
    );
  }
  /**
   * Required Permission: EditProfiles (117)
   * @param params The `FeaturesService.FeaturesCreateFeatureAddOrderParams` containing the following parameters:
   *
   * - `addFeatureOrderDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesCreateFeatureAddOrder(params: FeaturesService.FeaturesCreateFeatureAddOrderParams): __Observable<OrderDC> {
    return this.FeaturesCreateFeatureAddOrderResponse(params).pipe(
      __map(_r => _r.body as OrderDC)
    );
  }

  /**
   * Required Permission: EditProfiles (117)
   * @param params The `FeaturesService.FeaturesPreviewFeatureAddOrderParams` containing the following parameters:
   *
   * - `addFeatureOrderDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesPreviewFeatureAddOrderResponse(params: FeaturesService.FeaturesPreviewFeatureAddOrderParams): __Observable<__StrictHttpResponse<BillingImpactDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.addFeatureOrderDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/Features/Add/Preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BillingImpactDC>;
      })
    );
  }
  /**
   * Required Permission: EditProfiles (117)
   * @param params The `FeaturesService.FeaturesPreviewFeatureAddOrderParams` containing the following parameters:
   *
   * - `addFeatureOrderDC`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesPreviewFeatureAddOrder(params: FeaturesService.FeaturesPreviewFeatureAddOrderParams): __Observable<BillingImpactDC> {
    return this.FeaturesPreviewFeatureAddOrderResponse(params).pipe(
      __map(_r => _r.body as BillingImpactDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  FeaturesGetFeaturesResponse(Authorization: string): __Observable<__StrictHttpResponse<FeatureDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Features`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureDC>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  FeaturesGetFeatures(Authorization: string): __Observable<FeatureDC> {
    return this.FeaturesGetFeaturesResponse(Authorization).pipe(
      __map(_r => _r.body as FeatureDC)
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `FeaturesService.FeaturesGetFeatureParams` containing the following parameters:
   *
   * - `id`: Feature Id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesGetFeatureResponse(params: FeaturesService.FeaturesGetFeatureParams): __Observable<__StrictHttpResponse<FeatureDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Features/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureDC>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param params The `FeaturesService.FeaturesGetFeatureParams` containing the following parameters:
   *
   * - `id`: Feature Id
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  FeaturesGetFeature(params: FeaturesService.FeaturesGetFeatureParams): __Observable<FeatureDC> {
    return this.FeaturesGetFeatureResponse(params).pipe(
      __map(_r => _r.body as FeatureDC)
    );
  }
}

module FeaturesService {

  /**
   * Parameters for FeaturesGetAccountFeatures
   */
  export interface FeaturesGetAccountFeaturesParams {
    countryId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for FeaturesGetFeatureAvailableUsers
   */
  export interface FeaturesGetFeatureAvailableUsersParams {
    id: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for FeaturesGetFeatureUsers
   */
  export interface FeaturesGetFeatureUsersParams {
    id: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for FeaturesCreateFeatureAddOrder
   */
  export interface FeaturesCreateFeatureAddOrderParams {
    addFeatureOrderDC: AddFeatureOrderDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for FeaturesPreviewFeatureAddOrder
   */
  export interface FeaturesPreviewFeatureAddOrderParams {
    addFeatureOrderDC: AddFeatureOrderDC;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for FeaturesGetFeature
   */
  export interface FeaturesGetFeatureParams {

    /**
     * Feature Id
     */
    id: number;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { FeaturesService }
