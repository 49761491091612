/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ContractCommitmentDetailsDC } from '../models/contract-commitment-details-dc';
import { ContractDC } from '../models/contract-dc';
import { ContractOnboardingSummaryDC } from '../models/contract-onboarding-summary-dc';
import { AccountContractTermsResponseDC } from '../models/account-contract-terms-response-dc';
import { AddHardwareOrderDC } from '../models/add-hardware-order-dc';
@Injectable({
  providedIn: 'root',
})
class ContractsService extends __BaseService {
  static readonly ContractsGetContractCommitmentDetailsPath = '/api/Account/CommitmentDetails';
  static readonly ContractsGetContractsByAccountPath = '/api/Account/Contracts';
  static readonly ContractsGetContractOnboardingSummaryPath = '/api/Account/Contracts/{contractId}/OnboardingSummary';
  static readonly ContractsGetCurrentContractTermsPath = '/api/Account/CurrentContractTerms';
  static readonly ContractsCreateHardwareAddOrderPath = '/api/Account/HardwareAddOrder';
  static readonly ContractsGetContractStatusesPath = '/api/ContractStatuses';
  static readonly ContractsGetContractTypesPath = '/api/ContractTypes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param Authorization access token
   * @return OK
   */
  ContractsGetContractCommitmentDetailsResponse(Authorization: string): __Observable<__StrictHttpResponse<ContractCommitmentDetailsDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CommitmentDetails`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContractCommitmentDetailsDC>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param Authorization access token
   * @return OK
   */
  ContractsGetContractCommitmentDetails(Authorization: string): __Observable<ContractCommitmentDetailsDC> {
    return this.ContractsGetContractCommitmentDetailsResponse(Authorization).pipe(
      __map(_r => _r.body as ContractCommitmentDetailsDC)
    );
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `ContractsService.ContractsGetContractsByAccountParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `typeId`:
   *
   * - `statusId`:
   *
   * @return OK
   */
  ContractsGetContractsByAccountResponse(params: ContractsService.ContractsGetContractsByAccountParams): __Observable<__StrictHttpResponse<Array<ContractDC>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.typeId != null) __params = __params.set('typeId', params.typeId.toString());
    if (params.statusId != null) __params = __params.set('statusId', params.statusId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Contracts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ContractDC>>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `ContractsService.ContractsGetContractsByAccountParams` containing the following parameters:
   *
   * - `Authorization`: access token
   *
   * - `typeId`:
   *
   * - `statusId`:
   *
   * @return OK
   */
  ContractsGetContractsByAccount(params: ContractsService.ContractsGetContractsByAccountParams): __Observable<Array<ContractDC>> {
    return this.ContractsGetContractsByAccountResponse(params).pipe(
      __map(_r => _r.body as Array<ContractDC>)
    );
  }

  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `ContractsService.ContractsGetContractOnboardingSummaryParams` containing the following parameters:
   *
   * - `contractId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ContractsGetContractOnboardingSummaryResponse(params: ContractsService.ContractsGetContractOnboardingSummaryParams): __Observable<__StrictHttpResponse<ContractOnboardingSummaryDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/Contracts/${params.contractId}/OnboardingSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContractOnboardingSummaryDC>;
      })
    );
  }
  /**
   * Required Permission: WelcomeScreens (10325)
   * @param params The `ContractsService.ContractsGetContractOnboardingSummaryParams` containing the following parameters:
   *
   * - `contractId`:
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ContractsGetContractOnboardingSummary(params: ContractsService.ContractsGetContractOnboardingSummaryParams): __Observable<ContractOnboardingSummaryDC> {
    return this.ContractsGetContractOnboardingSummaryResponse(params).pipe(
      __map(_r => _r.body as ContractOnboardingSummaryDC)
    );
  }

  /**
   * Required Permission: SystemSyncCPQ (10341)
   * @param Authorization access token
   * @return OK
   */
  ContractsGetCurrentContractTermsResponse(Authorization: string): __Observable<__StrictHttpResponse<AccountContractTermsResponseDC>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account/CurrentContractTerms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccountContractTermsResponseDC>;
      })
    );
  }
  /**
   * Required Permission: SystemSyncCPQ (10341)
   * @param Authorization access token
   * @return OK
   */
  ContractsGetCurrentContractTerms(Authorization: string): __Observable<AccountContractTermsResponseDC> {
    return this.ContractsGetCurrentContractTermsResponse(Authorization).pipe(
      __map(_r => _r.body as AccountContractTermsResponseDC)
    );
  }

  /**
   * Required Permission: AddUsers (221)
   * @param params The `ContractsService.ContractsCreateHardwareAddOrderParams` containing the following parameters:
   *
   * - `addHardwareOrderDC`: Add Order Contract Data Contract
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ContractsCreateHardwareAddOrderResponse(params: ContractsService.ContractsCreateHardwareAddOrderParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.addHardwareOrderDC;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/HardwareAddOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * Required Permission: AddUsers (221)
   * @param params The `ContractsService.ContractsCreateHardwareAddOrderParams` containing the following parameters:
   *
   * - `addHardwareOrderDC`: Add Order Contract Data Contract
   *
   * - `Authorization`: access token
   *
   * @return OK
   */
  ContractsCreateHardwareAddOrder(params: ContractsService.ContractsCreateHardwareAddOrderParams): __Observable<{}> {
    return this.ContractsCreateHardwareAddOrderResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  ContractsGetContractStatusesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ContractStatuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  ContractsGetContractStatuses(Authorization: string): __Observable<{[key: string]: string}> {
    return this.ContractsGetContractStatusesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  ContractsGetContractTypesResponse(Authorization: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Authorization != null) __headers = __headers.set('Authorization', Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ContractTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * Required Permission: None (open to all authenticated users)
   * @param Authorization access token
   * @return OK
   */
  ContractsGetContractTypes(Authorization: string): __Observable<{[key: string]: string}> {
    return this.ContractsGetContractTypesResponse(Authorization).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }
}

module ContractsService {

  /**
   * Parameters for ContractsGetContractsByAccount
   */
  export interface ContractsGetContractsByAccountParams {

    /**
     * access token
     */
    Authorization: string;
    typeId?: number;
    statusId?: number;
  }

  /**
   * Parameters for ContractsGetContractOnboardingSummary
   */
  export interface ContractsGetContractOnboardingSummaryParams {
    contractId: number;

    /**
     * access token
     */
    Authorization: string;
  }

  /**
   * Parameters for ContractsCreateHardwareAddOrder
   */
  export interface ContractsCreateHardwareAddOrderParams {

    /**
     * Add Order Contract Data Contract
     */
    addHardwareOrderDC: AddHardwareOrderDC;

    /**
     * access token
     */
    Authorization: string;
  }
}

export { ContractsService }
