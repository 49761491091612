<div class="container" [ngClass]="{'container_non_pop': !popUp}">
    <div class="row top-header" [ngClass]="{'title-row-non-pop': !popUp}">
        <div class="col-12 title_non_popup" *ngIf="!popUp">
            {{ !newOHSchedule ? schedule.name : onScheduleLabels.titleLabel }} 
        <div class="col-10 title" *ngIf="popUp">
            {{'on_hours.add_title' | translate}}
        </div>
        <div class="col-2 header-close" *ngIf=popUp>
            <button id="on-hours-close" type="button" class="close closeX" aria-label="Close"
                    (click)="cancel()"
                    aria-hidden="true">
                &#10005;
            </button>
        </div>
    </div>
    <div *ngIf="showFormError" class="errorPlacement">
        <cl-error-alert [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></cl-error-alert>
    </div>
    <div *ngFor="let errmsg of otherErr" class="text-danger error-align"> {{errmsg}}</div>
    <form [formGroup]="onHoursForm" (ngSubmit)="onSubmit()" id="onHoursPanel">
        <div formGroupName="onHoursSection" class="content-scroll-block"
             [ngClass]="popUp===true ? 'schedule-content': 'schedule-content-non-popup'">
            <div class="row content-1">
                <div class="row">
                    <div class="form-group col-11 name">
                        <label for="name">{{ 'on_hours.name' | translate }}</label>
                        <input type="text"
                               formControlName="name"
                               [attr.tabindex]="tabbable ? 0 : -1"
                               maxlength="50"
                               class="form-control schedules-non-popup-width">
                        <div class="text-danger"
                             *ngIf="onHoursForm.get('onHoursSection.name').touched && onHoursForm.get('onHoursSection.name').hasError('required')">
                            {{ 'on_hours.name_required' | translate }}
                        </div>
                        <div class="text-danger" *ngIf="scheduleNameError != ''"> {{ scheduleNameError }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-11 timezone" *ngIf="timeZones.length > 0">
                        <label for="timeZone">{{ 'on_hours.time_zone' | translate }}</label>
                        <cl-dropdown [items]="timeZones" formControlName="timeZone" id="onHoursTimeZoneDropdown"
                                     [manualWidth]="true"
                                     [manualWidthValue]="'412px'"
                                     [tabbable]="tabbable"
                                     ngDefaultControl (keyup.esc)="$event.preventDefault(); onClose()"></cl-dropdown>
                        <div class="text-danger" id="timeZoneTextDanger"
                             *ngIf="onHoursForm.get('onHoursSection.timeZone').touched && onHoursForm.get('onHoursSection.timeZone').hasError('required')">
                            {{ 'on_hours.time_zone_required' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row content-2" [ngClass]="{'content-2-non-popup': !popUp}">
                <div *ngFor="let scheduleName of dayScheduleNames"
                     [style.padding-bottom]="verifySchedulesCount(scheduleName[0]) ? '0' : '10px'" class="col-12">
                    <div *ngIf="verifySchedulesCount(scheduleName[0])" formGroupName="{{scheduleName[2]}}"
                         style="max-height: 27px;">
                        <div class="row hours-row">
                            <div class="form-group repeatYearly col-3">
                                <div class="yearly-repeat">
                                    <div class="checkbox">
                                        <input type="checkbox"
                                               formControlName="enable"
                                               id="repeatYearly{{scheduleName[0]}}"
                                               class="form-control"
                                               (change)="enableScheduleByInitialCheckevent(scheduleName[0], scheduleName[2], scheduleName[3])">
                                        <span class="checkmark"></span>
                                    </div>
                                    <label for="repeatYearly{{scheduleName[0]}}"
                                           class="form-check-label schedule-label-non-popup"
                                           [ngClass]="{'label-highlight': onHoursForm.get('onHoursSection').get(scheduleName[0]).controls[0]}"
                                           class="schedule-label-pointer">
                                        {{ scheduleName[4] | translate }}
                                    </label>
                                </div>
                            </div>
                            <div class="form-group align-hours-closed col-1">
                                <span>{{ 'on_hours.closed' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!verifySchedulesCount(scheduleName[0])">
                        <div formArrayName="{{scheduleName[0]}}" style="max-height: 32px;"
                             *ngFor="let mschedule of onHoursForm.get('onHoursSection').get(scheduleName[0]).controls; let i = index">
                            <div class="row hours-row" [formGroupName]="i">
                                <div class="form-group repeatYearly col-3">
                                    <div class="yearly-repeat">
                                        <div class="checkbox" [style.visibility]="i === 0 ?'visible' :'hidden'">
                                            <input type="checkbox"
                                                   formControlName="enable"
                                                   id="repeatYearly{{scheduleName[1]}}"
                                                   (change)="enableSchedule(scheduleName[0], scheduleName[2])"
                                                   class="form-control">
                                            <span class="checkmark"></span>
                                        </div>
                                        <label for="repeatYearly{{scheduleName[1]}}"
                                               class="form-check-label schedule-label-non-popup"
                                               [ngClass]="{'label-highlight': onHoursForm.get('onHoursSection').get(scheduleName[0]).controls[0].value.enable}"
                                               [style.pointer-events]="i === 0 ?'visible' :'none'"
                                               [style.cursor]="i === 0 ?'pointer' :'none'" cuxEllipsify cuxTitleTooltip>
                                            {{ scheduleName[4] | translate }}
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group col-3 hours-time-span"
                                     *ngIf="this.onHoursForm.get('onHoursSection').get(scheduleName[0]).controls[0].value.enable">
                                    <cux-datepicker
                                        id="{{i}}startTime"
                                        formControlName="startTime"
                                        [isTimeOnly]="true"
                                        [appendTo]="'body'"
                                        (onSelect)="updateStartTime($event, i, scheduleName[0])"
                                        (onBlur)="updateStartTime($event, i, scheduleName[0])">
                                    </cux-datepicker>
                                </div>
                                <div class="span-block">
                                    <span class="span-time">-</span>
                                </div>
                                <div class="form-group col-3 hours-time-span"
                                     *ngIf="this.onHoursForm.get('onHoursSection').get(scheduleName[0]).controls[0].value.enable">                                            
                                    <cux-datepicker
                                        id="{{i}}stopTime"
                                        formControlName="stopTime"
                                        [isTimeOnly]="true"
                                        [appendTo]="'body'"
                                        (onSelect)="updateStopTime($event, i, scheduleName[0])"
                                        (onBlur)="updateStopTime($event, i, scheduleName[0])">
                                    </cux-datepicker>
                                </div>
                                <div class="form-group col-1"
                                     *ngIf="this.onHoursForm.get('onHoursSection').get(scheduleName[0]).controls[0].value.enable">
                                    <a href="javascript:" class="hours-add hours-delete"
                                       [ngClass]="{'schedule-delete-non-pop': !popUp}"
                                       [style.display]="i === 0 ? 'none':'block'"
                                       (click)="deleteCurrentSchedule(scheduleName[0], i)"><span
                                            class="clear-icon"></span></a>
                                    <a href="javascript:" class="hours-add schedule-add-non-pop"
                                       [style.display]="i === 0 ? 'block':'none'"
                                       (click)="addMoreSchedule(scheduleName[0], scheduleName[3])" cuxEllipsify cuxTitleTooltip>{{ 'on_hours.add' |
                                        translate
                                        }}</a>
                                </div>
                                <div class="text-danger text-danger-fix stop-time-validation-block"
                                     *ngIf="onHoursForm.get('onHoursSection').get(scheduleName[0]).controls[i].controls.stopTimeValidation.value">
                                        <span class="stop-time-validation-span">
                                             {{ onHoursForm.get('onHoursSection').get(scheduleName[0]).controls[i].controls.stopTimeValidationErrorMessage.value | translate }}
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" [ngClass]="{'footer-non-popup': !popUp}">
            <div class="form-group col-12 footer-block">
                <div *ngIf="updateSchedule" class="delete-action">
                    <button
                            type="button"
                            class="btn btn-default delete-hg"
                            (click)="onDelete()">
                        <img class="icon-delete-button"
                             src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAQABADASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAII/8QAHxAAAgICAgMBAAAAAAAAAAAAAQMCEQQSACEiMlET/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/ANIZCzkFscl85LW8LENIkeQFX137cY6zj/lHGyJhbMiS5R0iBYBuuuvXl5Rgp74tZBQm5bomZoEDW6P3xPGMVteiKWQbo9jpGBsAHarP3yHA/9k="
                             alt="delete">{{ 'on_hours.delete' | translate }}
                    </button>
                </div>

                <div class="pull-right actions">
                    <button
                            type="button"
                            class="btn btn-default"
                            [attr.tabindex]="tabbable ? 0 : -1"
                            (click)="cancel()" cuxEllipsify cuxTitleTooltip>{{ 'on_hours.cancel' | translate }}
                    </button>
                    <button *ngIf="!updateSchedule"
                            type="submit"
                            class="btn btn-primary" style="width:148px"
                            [attr.tabindex]="tabbable ? 0 : -1" cuxEllipsify cuxTitleTooltip>{{ 'on_hours.add_schedule' | translate }}
                    </button>
                    <button *ngIf="updateSchedule"
                            type="submit"
                            class="btn btn-primary"
                            [attr.tabindex]="tabbable ? 0 : -1" cuxEllipsify cuxTitleTooltip>{{ 'on_hours.update' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<app-spinner delay="1000" [showSpinner]="displayLoadingSpinner" [customPosition]="{ top: '45%', left: '22%' }" message="{{ 'app.loading' | translate}}"></app-spinner>
