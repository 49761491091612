import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';

// boss api current version start
import { AccountsService } from '../modules/boss-api/generated/services/accounts.service';
import { AddressService } from '../modules/boss-api/generated/services/address.service';
import { BillingService } from '../modules/boss-api/generated/services/billing.service';
import { CallFlowsService } from '../modules/boss-api/generated/services/call-flows.service';
import { ClustersService } from '../modules/boss-api/generated/services/clusters.service';
import { ContractsService } from '../modules/boss-api/generated/services/contracts.service';
import { EmergencyService } from '../modules/boss-api/generated/services/emergency.service';
import { EntitlementsService } from '../modules/boss-api/generated/services/entitlements.service';
import { FeaturesService } from '../modules/boss-api/generated/services/features.service';
import { LocationsService } from '../modules/boss-api/generated/services/locations.service';
import { NotificationsService } from '../modules/boss-api/generated/services/notifications.service';
import { OrdersService } from '../modules/boss-api/generated/services/orders.service';
import { PartnersService } from '../modules/boss-api/generated/services/partners.service';
import { PasswordManagementService } from '../modules/boss-api/generated/services/password-management.service';
import { PhoneSettingsService } from '../modules/boss-api/generated/services/phone-settings.service';
import { PhonesService } from '../modules/boss-api/generated/services/phones.service';
import { ProductsService } from '../modules/boss-api/generated/services/products.service';
import { ShippingService } from '../modules/boss-api/generated/services/shipping.service';
import { SystemService } from '../modules/boss-api/generated/services/system.service';
import { TasksService } from '../modules/boss-api/generated/services';
import { TnsService } from '../modules/boss-api/generated/services/tns.service';
import { UsersService } from '../modules/boss-api/generated/services/users.service';
import { ZuoraSubscriptionService } from '../modules/boss-api/generated/services/zuora-subscription.service';
// boss api current version end

// boss api previous version start
import { AccountsService as AccountsServicePrevious } from '../modules/boss-api-previous/generated/services/accounts.service';
import { AddressService as AddressServicePrevious } from '../modules/boss-api-previous/generated/services/address.service';
import { BillingService as BillingServicePrevious } from '../modules/boss-api-previous/generated/services/billing.service';
import { CallFlowsService as CallFlowsServicePrevious } from '../modules/boss-api-previous/generated/services/call-flows.service';
import { ClustersService as ClustersServicePrevious } from '../modules/boss-api-previous/generated/services/clusters.service';
import { ContractsService as ContractsServicePrevious } from '../modules/boss-api-previous/generated/services/contracts.service';
import { EmergencyService as EmergencyServicePrevious } from '../modules/boss-api-previous/generated/services/emergency.service';
import { EntitlementsService as EntitlementsServicePrevious } from '../modules/boss-api-previous/generated/services/entitlements.service';
import { FeaturesService as FeaturesServicePrevious } from '../modules/boss-api-previous/generated/services/features.service';
import { LocationsService as LocationsServicePrevious } from '../modules/boss-api-previous/generated/services/locations.service';
import { NotificationsService as NotificationsServicePrevious } from '../modules/boss-api-previous/generated/services/notifications.service';
import { OrdersService as OrdersServicePrevious } from '../modules/boss-api-previous/generated/services/orders.service';
import { PartnersService as PartnersServicePrevious } from '../modules/boss-api-previous/generated/services/partners.service';
import { PasswordManagementService as PasswordManagementServicePrevious } from '../modules/boss-api-previous/generated/services/password-management.service';
import { PhoneSettingsService as PhoneSettingsServicePrevious } from '../modules/boss-api-previous/generated/services/phone-settings.service';
import { PhonesService as PhonesServicePrevious } from '../modules/boss-api-previous/generated/services/phones.service';
import { ProductsService as ProductsServicePrevious } from '../modules/boss-api-previous/generated/services/products.service';
import { ShippingService as ShippingServicePrevious } from '../modules/boss-api-previous/generated/services/shipping.service';
import { SystemService as SystemServicePrevious } from '../modules/boss-api-previous/generated/services/system.service';
import { TasksService as TasksServicePrevious } from '../modules/boss-api-previous/generated/services';
import { TnsService as TnsServicePrevious } from '../modules/boss-api-previous/generated/services/tns.service';
import { UsersService as UsersServicePrevious } from '../modules/boss-api-previous/generated/services/users.service';
import { ZuoraSubscriptionService as ZuoraSubscriptionServicePrevious } from '../modules/boss-api-previous/generated/services/zuora-subscription.service';
// boss api previous version end

import { LaunchDarklyService } from 'src/app/shared/features/launchdarkly.service';


@Injectable({
  providedIn: 'root'
})
export class SDApiVersionControlService {

  accountsService: AccountsService | AccountsServicePrevious;
  addressService: AddressService | AddressServicePrevious;
  billingService: BillingService | BillingServicePrevious;
  callFlowsService: CallFlowsService | CallFlowsServicePrevious;
  clustersService: ClustersService | ClustersServicePrevious;
  contractsService: ContractsService | ContractsServicePrevious;
  emergencyService: EmergencyService | EmergencyServicePrevious;
  entitlementsService: EntitlementsService | EntitlementsServicePrevious;
  featuresService: FeaturesService | FeaturesServicePrevious;
  locationSevice: LocationsService | LocationsServicePrevious;
  notificationsService: NotificationsService | NotificationsServicePrevious;
  ordersService: OrdersService | OrdersServicePrevious;
  partnersService: PartnersService | PartnersServicePrevious;
  passwordManagementService: PasswordManagementService | PasswordManagementServicePrevious;
  phoneSettingsService: PhoneSettingsService | PhoneSettingsServicePrevious;
  phonesService: PhonesService | PhonesServicePrevious;
  productsService: ProductsService | ProductsServicePrevious;
  shippingService: ShippingService | ShippingServicePrevious;
  systemService: SystemService | SystemServicePrevious;
  tasksService: TasksService | TasksServicePrevious;
  tnsService: TnsService | TnsServicePrevious;
  usersService: UsersService | UsersServicePrevious;
  zuoraSubscriptionService: ZuoraSubscriptionService | ZuoraSubscriptionServicePrevious;

  constructor(private injector: Injector,
    private ld: LaunchDarklyService
  ) {
    this.intializeService(false);
    const allFeatureFlags = this.ld.allFeatureFlags;
    let checkSDAPIVersionFlag = false;
    if (allFeatureFlags['flags_by_environment']) {
      checkSDAPIVersionFlag = allFeatureFlags['flags_by_environment']['sdapi-latest'];
    }
    if (checkSDAPIVersionFlag) {
      this.intializeService(checkSDAPIVersionFlag);
    }
  }

  intializeService(checkSDAPIVersionFlag) {
    console.log('check version flag sdapi-version-latest: ', checkSDAPIVersionFlag);
    if (checkSDAPIVersionFlag) {
      this.accountsService = <AccountsService>this.injector.get(AccountsService);
      this.addressService = <AddressService>this.injector.get(AddressService);
      this.billingService = <BillingService>this.injector.get(BillingService);
      this.callFlowsService = <CallFlowsService>this.injector.get(CallFlowsService);
      this.clustersService = <ClustersService>this.injector.get(ClustersService);
      this.contractsService = <ContractsService>this.injector.get(ContractsService);
      this.emergencyService = <EmergencyService>this.injector.get(EmergencyService);
      this.entitlementsService = <EntitlementsService>this.injector.get(EntitlementsService);
      this.featuresService = <FeaturesService>this.injector.get(FeaturesService);
      this.locationSevice = <LocationsService>this.injector.get(LocationsService);
      this.notificationsService = <NotificationsService>this.injector.get(NotificationsService);
      this.ordersService = <OrdersService>this.injector.get(OrdersService);
      this.partnersService = <PartnersService>this.injector.get(PartnersService);
      this.passwordManagementService = <PasswordManagementService>this.injector.get(PasswordManagementService);
      this.phoneSettingsService = <PhoneSettingsService>this.injector.get(PhoneSettingsService);
      this.phonesService = <PhonesService>this.injector.get(PhonesService);
      this.productsService = <ProductsService>this.injector.get(ProductsService);
      this.shippingService = <ShippingService>this.injector.get(ShippingService);
      this.systemService = <SystemService>this.injector.get(SystemService);
      this.tasksService = <TasksService>this.injector.get(TasksService);
      this.tnsService = <TnsService>this.injector.get(TnsService);
      this.usersService = <UsersService>this.injector.get(UsersService);
      this.zuoraSubscriptionService = <ZuoraSubscriptionService>this.injector.get(ZuoraSubscriptionService);
    } else {
      this.accountsService = <AccountsServicePrevious>this.injector.get(AccountsServicePrevious);
      this.addressService = <AddressServicePrevious>this.injector.get(AddressServicePrevious);
      this.billingService = <BillingServicePrevious>this.injector.get(BillingServicePrevious);
      this.callFlowsService = <CallFlowsServicePrevious>this.injector.get(CallFlowsServicePrevious);
      this.clustersService = <ClustersServicePrevious>this.injector.get(ClustersServicePrevious);
      this.contractsService = <ContractsServicePrevious>this.injector.get(ContractsServicePrevious);
      this.emergencyService = <EmergencyServicePrevious>this.injector.get(EmergencyServicePrevious);
      this.entitlementsService = <EntitlementsServicePrevious>this.injector.get(EntitlementsServicePrevious);
      this.featuresService = <FeaturesServicePrevious>this.injector.get(FeaturesServicePrevious);
      this.locationSevice = <LocationsServicePrevious>this.injector.get(LocationsServicePrevious);
      this.notificationsService = <NotificationsServicePrevious>this.injector.get(NotificationsServicePrevious);
      this.ordersService = <OrdersServicePrevious>this.injector.get(OrdersServicePrevious);
      this.partnersService = <PartnersServicePrevious>this.injector.get(PartnersServicePrevious);
      this.passwordManagementService = <PasswordManagementServicePrevious>this.injector.get(PasswordManagementServicePrevious);
      this.phoneSettingsService = <PhoneSettingsServicePrevious>this.injector.get(PhoneSettingsServicePrevious);
      this.phonesService = <PhonesServicePrevious>this.injector.get(PhonesServicePrevious);
      this.productsService = <ProductsServicePrevious>this.injector.get(ProductsServicePrevious);
      this.shippingService = <ShippingServicePrevious>this.injector.get(ShippingServicePrevious);
      this.systemService = <SystemServicePrevious>this.injector.get(SystemServicePrevious);
      this.tasksService = <TasksServicePrevious>this.injector.get(TasksServicePrevious);
      this.tnsService = <TnsServicePrevious>this.injector.get(TnsServicePrevious);
      this.usersService = <UsersServicePrevious>this.injector.get(UsersServicePrevious);
      this.zuoraSubscriptionService = <ZuoraSubscriptionServicePrevious>this.injector.get(ZuoraSubscriptionServicePrevious);
    }
  }
}
