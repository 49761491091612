import { Injectable, Output, EventEmitter } from '@angular/core';

import { Observable, from, Subject } from 'rxjs';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { AppInfoService } from './app-info.service';

import * as _ from 'lodash';

export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    console.log(
      'CustomTranslateLoader is loading the strings language: ', lang
    );

    switch(lang) {
      case 'en':
      case 'en-US':
        lang = 'en-US';
        break;
      case 'en-GB':
        lang = 'en-GB';
          break;
      case 'de':
      case 'de-DE':
        lang = 'de-DE';
        break;
      case 'fr':
      case 'fr-FR':
        lang = 'fr-FR';
        break;
      default:
        lang = 'en-US';
    }

    console.log(
      'CustomTranslateLoader is loading the strings language: ', lang
    );

    let files = [
      import(`../../assets/cl-console-components/i18n/${lang}.json`),
      import(`../../assets/i18n/${lang}.json`)
    ];

    return from(Promise.all( files ).then( values => {
      return  _.merge(...values);
    }));
  }
}