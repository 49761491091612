import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserClaims } from '@mitel/cloudlink-sdk';
import { Subscription } from 'rxjs';
import { AppUserClaimsService } from '../services/app-user-claims.service';
import { Account } from '@mitel/cloudlink-sdk/admin';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-flows',
  templateUrl: './flows.component.html',
  styleUrls: ['./flows.component.css']
})
export class FlowsComponent implements OnInit, OnDestroy {
  rightPanelOpened = false;
  columns = [
    {
        header: 'NAME',
        field: 'name',
        sortable: true,
        sorted: true,
        queryFields : ['name'],
        sortBy: ['name'],
        widthPercent: 32
    },
    {
        header: 'TYPE',
        field: 'componentTypeLabel',
        sortable: true,
        sorted: false,
        queryFields : ['componentType'],
        sortBy: ['componentType'],
        widthPercent: 20,
    },
    {
        header: 'PHONE NUMBER & EXT.',
        field: 'tn',
        sortable: true,
        sorted: false,
        queryFields : ['tn', 'extension'],
        sortBy: ['tn'],
        widthPercent: 28,
    },
    {
        header: 'LOCATION',
        field: 'locationName',
        sortable: true,
        sorted: false,
        queryFields : ['locationUuid', 'locationName'],
        sortBy: ['locationName'],
        widthPercent: 15,
    },
];
claims: UserClaims;
company: Account;

claimsChangedSubscription: Subscription;
companyChangedSubscription: Subscription;

constructor(private appSvc: AppUserClaimsService, private translateSvc: TranslateService) { }

ngOnInit() {
  this.claimsChangedSubscription = this.appSvc.claimsChanged.subscribe(claims => {
    this.claims = claims;
  });

  this.claims = this.appSvc.getClaims();

  this.companyChangedSubscription = this.appSvc.companyChanged.subscribe(company => {
    this.company = company;
    });

    this.company = this.appSvc.getCompany();

    this.translateSvc.stream(this.getColumnTitleKeys()).subscribe(translations => {
      this.columns.forEach(column => column.header = translations[`flows_list.${column.field}`]);
    });
  }

  getColumnTitleKeys(): string[] {
    const a: string[] = [];
    this.columns.forEach(column => {
        a.push(`flows_list.${column.field}`);
    });
    return a;
  }

  rightPanelOpenedHandler(panelOpened: boolean) {
    this.rightPanelOpened = panelOpened;
  }

  ngOnDestroy() {
    if (this.claimsChangedSubscription) {
        this.claimsChangedSubscription.unsubscribe();
    }
    if (this.companyChangedSubscription) {
        this.companyChangedSubscription.unsubscribe();
    }

}

}
