import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CreateModelLabels } from 'src/app/models/flow-model';
import { CallflowAdaptorService } from 'src/app/services/callflow-adaptor.service';
import { BossApiUtils } from 'src/app/shared/BossApiUtils';
import {PhoneServicesService} from '../../services/phone-services.service';


@Component({
  selector: 'app-flows-create-model',
  templateUrl: './flows-create-model.component.html',
  styleUrls: ['./flows-create-model.component.css']
})
export class FlowsCreateModelComponent implements OnInit {
  @Input() accountId: string;
  @Input() tabbable = true;
  @Input() title = 'flows_create_model.title';
  @Input() addText = 'flows_create_model.add';
  @Input() flow: string;
  @Input() locationUUID: string;
  @Input() locationName: string;

  flowsForm: FormGroup;
  flowsSection: FormGroup;

  flowData: any;

  showFormError = false;

  dnErr: string;
  otherErr: string[] = [];
  extnErrMessage = '';
  errorMsg: string[];
  errMsg = '';
  errMsgStyle: any = {'max-width': '650px'};

  submitting = false;
  displayLoadingSpinner = false;

  translationsKeys: string[] = ['flows_model.unable_to_create_huntgroup'];
  
  flowModelLabels: CreateModelLabels = {
   unableToCreateHuntGroup: ''
  }

  constructor(public translateSvc: TranslateService,
    private activeModal: NgbActiveModal,
    private flowsSvc: CallflowAdaptorService,
    public phSvc: PhoneServicesService) { }

  ngOnInit() {
    this.initForm();
    this.setFormWithNewFlowData();
    this.translateSvc.stream(this.translationsKeys).subscribe(translations => {
      this.flowModelLabels.unableToCreateHuntGroup = translations['flows_model.unable_to_create_huntgroup']
    })
  }

  initForm() {
    this.flowsForm = new FormGroup({
      flowsSection: new FormGroup({
      'name': new FormControl('New Hunt Group', Validators.required),
      'extension': new FormControl('', Validators.required),
      'location': new FormControl('', Validators.required)
      })
    });
  }

  async setFormWithNewFlowData() {
    console.log('setFormWithNewFlowData');
    this.flowsForm.reset();
    if (this.flow === 'hg') {
      await this.flowsSvc.getNewHuntGroup().toPromise().then (
        hg => {
        this.flowData = hg;
      },
      error => {
        console.error('failed to getNewHuntgroup', error);
        this.showServerError( 'Failed to get new huntgroup data. ' + BossApiUtils.extractErrorMessage(error));
      });
    } else if (this.flow === 'aa') {
      await this.flowsSvc.getNewAA().toPromise().then (
        aa => {
          aa.name = 'New Auto-Attendant';
          this.flowData = aa;
      },
      error => {
        console.error('failed to getNewAA', error);
        this.showServerError( 'Failed to get new auto attendant data. ' + BossApiUtils.extractErrorMessage(error));
      });
    }
    if (this.flowData != null) {
      this.flowsForm.get('flowsSection').patchValue({
        name: this.flowData.extensionFormatted + ' ' + this.flowData.name,
        extension: this.flowData.extensionFormatted,
        location: this.locationUUID
      });
    }
  }

  async validateExtension(extn: string) {
    console.log('Validate Extension');
    this.extnErrMessage  = '';

    if ((extn !== '' && this.flowData.id == null) ||
      (extn !== '' && this.flowData.id !== null &&
      this.flowData.extensionFormatted !== extn)) {

      await this.flowsSvc.validateExtension(extn).toPromise().then (
        response => {

          if (!response.isValid && response.errorMessage) {
            this.extnErrMessage = response.errorMessage;
          }
          if (this.extnErrMessage !== '') {
            this.flowsForm.controls['flowsSection'].get('extension').setErrors({'invalidExtension': true});
            this.flowsForm.updateValueAndValidity();
          }
        },
        error => {
          console.error('failed to validate extension', error);
          this.translateSvc.get('error_messages').subscribe(err => {
          this.showServerError( 'validate extension. ' + BossApiUtils.extractErrorMessage(error));
          });
        }
      );

    }
  }

  async save() {
    console.log('submit');
    this.submitting = true;
    const extension = this.flowsForm.get('flowsSection').value.extension;
    if (extension != null && extension.length === this.phSvc.clusterBasedExtensionLength.value && this.extnErrMessage === '') {
      this.clearServerErrorOnForm();
      if (this.flowsForm.valid) {
        this.displayLoadingSpinner = true;
        if (this.flow === 'hg') {
          console.log('Create Hunt Group');
          const params = this.getFlowParams();
          await this.flowsSvc.createHuntGroup(params, null)
              .toPromise().then(
                  response => {
                    this.flowData = response;
                    if (this.flowData.errors != null && this.flowData.errors !== '') {
                      console.error(this.flowData.errors);
                      this.displayLoadingSpinner = false;
                      this.showServerError('Failed to create Huntgroup');
                      this.parseServerError(this.flowData.errors);
                    } else {
                      this.activeModal.close({dn: this.flowData.extensionFormatted, name: this.flowData.name});
                    }
                  },
                  error => {
                    // if not caught here, will get an 'core.js:15714 ERROR Error: Uncaught (in promise)' error without a useful stack trace
                    console.error('failed to create huntgroup', error);
                    this.displayLoadingSpinner = false;
                    this.translateSvc.get('error_messages').subscribe(err => {
                      this.showServerError( this.flowModelLabels.unableToCreateHuntGroup +  BossApiUtils.extractErrorMessage(error));
                    });
                  });
        } else if (this.flow === 'aa') {
          console.log('Create AA');
          const params = this.getFlowParams();
          await this.flowsSvc.createAA(params, null)
              .toPromise().then(
                  response => {
                    this.flowData = response;
                    if (this.flowData.errors != null && this.flowData.errors !== '') {
                      console.error(this.flowData.errors);
                      this.displayLoadingSpinner = false;
                      this.showServerError('Failed to create AA');
                      this.parseServerError(this.flowData.errors);
                    } else {
                      this.activeModal.close({dn: this.flowData.extensionFormatted, name: this.flowData.name});
                    }
                  },
                  error => {
                    // if not caught here, will get an 'core.js:15714 ERROR Error: Uncaught (in promise)' error without a useful stack trace
                    console.error('failed to create AA', error);
                    this.displayLoadingSpinner = false;
                    this.translateSvc.get('error_messages').subscribe(err => {
                      this.showServerError( 'Unable to create AA: ' +  BossApiUtils.extractErrorMessage(error));
                    });
                  });

        }
        this.submitting = false;
      } else {
        this.validateAllFormFields(this.flowsForm);
      }
    }
}

getFlowParams() {
  const input = this.flowsForm.get('flowsSection').value;

  this.flowData.errors = null;
  const tenantPrefix = this.flowData.extension ? this.flowData.extension.split('-')[0] + '-' : '';
  this.flowData.name = input.name;
  this.flowData.locationName = input.location;
  this.flowData.locationUUID = input.location;
  this.flowData.extensionFormatted = input.extension;
  this.flowData.extension = tenantPrefix + input.extension;
  return this.flowData;
}

validateAllFormFields(formGroup: FormGroup) {
  console.log('Validate all form fields');
  Object.keys(formGroup.controls).forEach( field => {
    const control = formGroup.get(field);
    if ( control instanceof FormControl ) {
      control.markAsTouched( {onlySelf: true} );
      console.log('check control:', field, control.status, control.value);
    } else if ( control instanceof FormGroup ) {
      this.validateAllFormFields( control );
    }
  });
}

parseServerError( message: string ) {
  const splitMsg =  message.split('Error:');

  this.errorMsg = splitMsg[1] ? splitMsg[1].split('\r\n') : [];
  if (this.errorMsg.length > 0) {
    this.errorMsg.forEach(err => {
      this.showErrorMessage(err);
    });
  }
}

showErrorMessage( message ) {
  const msg = message.split('-');

  if (msg[0].trim() === 'dn.DN') {
    this.dnErr = msg[1];
  } else {
    this.otherErr.push(message);
  }
}

  clearServerErrorOnForm() {
    this.errMsg = '';
    this.showFormError = false;
    this.extnErrMessage = '';
  }


  showServerError( message ) {
    this.errMsg = message;
    this.showFormError = true;
  }

  cancel() {
    this.activeModal.close(false);
  }

}
